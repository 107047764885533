/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react';
import Partner from './common/partner';
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
export const Footer = (props) => {
	const [visible, setVisible] = useState(false);
  
	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 350){
			setVisible(true)
		} 
		else if (scrolled <= 350){
			setVisible(false)
		}
	};

	const scrollToTop = () =>{
		window.scrollTo({
		top: 0, 
		behavior: 'smooth'
		/* you can also use 'auto' behaviour
			in place of 'smooth' */
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisible);

		return () => window.removeEventListener('scroll', toggleVisible)

	}, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
		<>
		{(typeof props.showPartner === "undefined" || props.showPartner === true) && (
			<>
			<div id="pg-w5f31c29a23cab-0"  className="panel-grid panel-has-style" >
				<div className="siteorigin-panels-stretch panel-row-style panel-row-style-for-w5f31c29a23cab-0" data-stretch-type="full" >
					<div id="pgc-w5f31c29a23cab-0-0"  className="panel-grid-cell" >
						<div id="panel-w5f31c29a23cab-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0" >
							<div className="panel-widget-style panel-widget-style-for-w5f31c29a23cab-0-0-0" >
							<div className="so-widget-sow-editor so-widget-sow-editor-base">
								<div className="siteorigin-widget-tinymce textwidget">
								</div>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid container-md text-center" id="iffooterpartners">
				<h3 className="title">PARTNERS</h3>
				<Partner/>
			</div>
			</>
		)}
		{/* <!-- Footer --> */}
		<footer className={`footer-main ${typeof props.showPartner !== "undefined" && props.showPartner === false ? 'mt-0' : ''}`}>
			<div className="footer-top">
				<div className="container">
					<div className="section-wrapper row">
						<div className="col-lg-4 col-md-6">
							<div className="post-item">
								{/* <a href="/" className="footer-logo"> */}
								<a href="/">
									<h5>IFSPD</h5>
								</a>
								{/* <div className="footer-logo">
									<img alt="International Foundation for Sustainable Peace and Development" src={require('../assets/images/main/logo/ifspd_logo_m.png')} title="International Foundation for Sustainable Peace and Development" />
								</div> */}

								<p><b>IFSPD</b> – International Foundation for Sustainable Peace and Development is an international, non-governmental and non-profit organization established upon the initiative of distinguished intellectuals, scientific and public figures, diplomats, representatives of influential non-governmental organizations.</p>
							</div>
						</div>
						<div className="col-lg-1 col-md-1">
						</div>
						<div className="col-lg-3 col-md-3">
							<div>
								<div>
									<h5>Useful Link</h5>
								</div>
								<ul className="footer-link">
									<li><i className="fas fa-hand-point-right"></i><a href="/">Home</a></li>
									<li><i className="fas fa-hand-point-right"></i><a href="/history">About</a></li>
									{/* <li><i className="fas fa-hand-point-right"></i><a href="/fellowship">Fellowship</a></li> */}
									<li><i className="fas fa-hand-point-right"></i><a href="/contact">Contact Us</a></li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div>
								<div>
									<h5>General Link</h5>
								</div>
								<ul className="footer-link">
									<li><i className="fas fa-hand-point-right"></i><a href="/privacy-policy">Privacy Policy</a></li>
									<li><i className="fas fa-hand-point-right"></i><a href="/terms-and-conditions">Terms & Conditions</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<div className="footer-bottom">
			<div className="container">
				<p className="text-center"><a href="/">© International Foundation for Sustainable Peace and Development {currentYear}</a></p>
			</div>
		</div>
		{/* <div className="site-footer">
			<div id="footer-widgets" className="site footer-widgets">
				<div className="footer-widgets-container grid-container grid-parent">
					<div className="inside-footer-widgets">
					<div className="footer-widget-1 grid-parent grid-100 tablet-grid-50 mobile-grid-100">
						<aside id="siteorigin-panels-builder-2" className="widget inner-padding widget_siteorigin-panels-builder">
							<div id="pl-w5f31c29a23cab"  className="panel-layout" >
								<div id="pg-w5f31c29a23cab-0"  className="panel-grid panel-has-style" >
								<div className="siteorigin-panels-stretch panel-row-style panel-row-style-for-w5f31c29a23cab-0" data-stretch-type="full" >
									<div id="pgc-w5f31c29a23cab-0-0"  className="panel-grid-cell" >
										<div id="panel-w5f31c29a23cab-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0" >
											<div className="panel-widget-style panel-widget-style-for-w5f31c29a23cab-0-0-0" >
											<div className="so-widget-sow-editor so-widget-sow-editor-base">
												<div className="siteorigin-widget-tinymce textwidget">
												</div>
											</div>
											</div>
										</div>
									</div>
								</div>
								</div>
								<div id="pg-w5f31c29a23cab-1"  className="panel-grid panel-has-style" >
								<div className="iffooterpartners panel-row-style panel-row-style-for-w5f31c29a23cab-1" id="iffooterpartners" >
									<div id="pgc-w5f31c29a23cab-1-0"  className="panel-grid-cell" >
										<div id="panel-w5f31c29a23cab-1-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="1" >
											<div className="so-widget-sow-editor so-widget-sow-editor-base">
											<div className="siteorigin-widget-tinymce textwidget">
												<h3>PARTNERS</h3>
												<div className="container-fluid container-md">
													<Partner/>
												</div>
												<div className="carousel-slider-outer carousel-slider-outer-image-carousel carousel-slider-outer-6264">
												<div id="&#039;id-6264" className="owl-carousel carousel-slider carousel-slider-6264 arrows-visibility-hover arrows-inside dots-visibility-never dots-center dots-square" data-slide-type="image-carousel" data-owl-settings={{"nav":true,"dots":false,"slideBy":1,"stagePadding":0,"margin":20,"loop":true,"autoplay":true,"autoplayTimeout":4000,"autoplaySpeed":500,"autoplayHoverPause":true,"lazyLoad":false,"autoWidth":false,"responsive":{"300":{"items":1},"600":{"items":1},"768":{"items":2},"1024":{"items":3},"1200":{"items":6},"1921":{"items":5}}}}>
														<div className="carousel-slider__item"><a  href="http://www.teatrulioncreanga.ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="316" height="69" src={require('../assets/images/main/partner/teatrulioncreanga.png')} className="attachment-medium_large size-medium_large" alt="teatrulioncreanga" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://www.coca-cola.ro/ro/home/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="69" height="69" src="wp-content/uploads/2018/08/cocacola.png" className="attachment-medium_large size-medium_large" alt="cocacola" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://www.romenergo.ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="110" height="69" src="wp-content/uploads/2018/08/romenergo.png" className="attachment-medium_large size-medium_large" alt="romenergo" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://www.upetrom1mai.com/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="69" height="69" src="wp-content/uploads/2018/08/upetrom.png" className="attachment-medium_large size-medium_large" alt="upetrom" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://www.spiruharet.ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="69" height="69" src="wp-content/uploads/2018/08/spiruharet.png" className="attachment-medium_large size-medium_large" alt="spiruharet" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://www.minac.ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="69" height="69" src="wp-content/uploads/2018/08/minac.png" className="attachment-medium_large size-medium_large" alt="minac" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://www.siveco.ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="199" height="69" src="wp-content/uploads/2018/08/siveco.png" className="attachment-medium_large size-medium_large" alt="siveco" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://www.hmultiplex.ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="91" height="69" src="wp-content/uploads/2018/08/hmultiplex.png" className="attachment-medium_large size-medium_large" alt="hmultiplex" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://www.rompetrol.ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="91" height="69" src="wp-content/uploads/2018/08/rompetrol.png" className="attachment-medium_large size-medium_large" alt="rompetrol" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://oztasar.ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="260" height="69" src="wp-content/uploads/2018/08/oztasar.png" className="attachment-medium_large size-medium_large" alt="oztasar" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://nirogroup.ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="165" height="69" src="wp-content/uploads/2018/08/niro.png" className="attachment-medium_large size-medium_large" alt="niro" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="http://www.ubbcluj.ro/ro/" target="_blank" rel="noreferrer" rel="noreferrer"><img width="70" height="69" src="wp-content/uploads/2018/08/babes.png" className="attachment-medium_large size-medium_large" alt="ubbcluj" decoding="async" loading="lazy" /></a></div>
														<div className="carousel-slider__item"><a  href="index.html#0a" target="_blank" rel="noreferrer" rel="noreferrer"><img width="169" height="81" src="wp-content/uploads/2018/08/logo-AEDIDH.gif" className="attachment-medium_large size-medium_large" alt="AEDIDH" decoding="async" loading="lazy" /></a></div>
													</div>
												</div>
											</div>
											</div>
										</div>
									</div>
								</div>
								</div>
								<div id="pg-w5f31c29a23cab-2"  className="panel-grid panel-has-style" >
								<div className="iffootercontacts siteorigin-panels-stretch panel-row-style panel-row-style-for-w5f31c29a23cab-2" id="iffootercontacts" data-stretch-type="full" >
									<div id="pgc-w5f31c29a23cab-2-0"  className="panel-grid-cell" >
										<div id="panel-w5f31c29a23cab-2-0-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="2" >
											<div className="panel-widget-style panel-widget-style-for-w5f31c29a23cab-2-0-0" >
											<div className="so-widget-sow-editor so-widget-sow-editor-base">
												<div className="siteorigin-widget-tinymce textwidget">
													<h3>QUICK CONTACTS</h3>
												</div>
											</div>
											</div>
										</div>
										<div id="panel-w5f31c29a23cab-2-0-1" className="so-panel widget widget_siteorigin-panels-builder panel-last-child" data-index="3" >
											<div className="panel-widget-style panel-widget-style-for-w5f31c29a23cab-2-0-1" >
											<div id="pl-w5f14dc996ba34"  className="panel-layout" >
												<div id="pg-w5f14dc996ba34-0"  className="panel-grid panel-has-style" >
													<div className="iffootercontactslist panel-row-style panel-row-style-for-w5f14dc996ba34-0" id="iffootercontactslist" >
														<div id="pgc-w5f14dc996ba34-0-0"  className="panel-grid-cell" >
														<div id="panel-w5f14dc996ba34-0-0-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="0" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-0-0" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p>Sofia Central Office<br />
																		+359 2 4440033<br />
																		secretariat@ifspd.butasbureau.nl
																	</p>
																	</div>
																</div>
															</div>
														</div>
														<div id="panel-w5f14dc996ba34-0-0-1" className="so-panel widget widget_sow-editor" data-index="1" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-0-1" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p>Moscow Branch<br />
																		+7 (495) 7272870<br />
																		apfund@bk.ru
																	</p>
																	</div>
																</div>
															</div>
														</div>
														<div id="panel-w5f14dc996ba34-0-0-2" className="so-panel widget widget_sow-editor panel-last-child" data-index="2" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-0-2" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p>South Africa Branch<br />
																		+27 115666813<br />
																		info@ndcsouthafrica.org
																	</p>
																	</div>
																</div>
															</div>
														</div>
														</div>
														<div id="pgc-w5f14dc996ba34-0-1"  className="panel-grid-cell" >
														<div id="panel-w5f14dc996ba34-0-1-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="3" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-1-0" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p>Istanbul – ICSCEC<br />
																		+90 212 6032720/ 21<br />
																		info@icscec.org
																	</p>
																	</div>
																</div>
															</div>
														</div>
														<div id="panel-w5f14dc996ba34-0-1-1" className="so-panel widget widget_sow-editor" data-index="4" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-1-1" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p>Varna Branch<br />
																		+359 89 8618968<br />
																		dkostov2001@yahoo.com
																	</p>
																	</div>
																</div>
															</div>
														</div>
														<div id="panel-w5f14dc996ba34-0-1-2" className="so-panel widget widget_sow-editor panel-last-child" data-index="5" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-1-2" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p>Minsk Branch<br />
																		+375 017 328 47 11<br />
																		prototipmanagers@yahoo.com
																	</p>
																	</div>
																</div>
															</div>
														</div>
														</div>
														<div id="pgc-w5f14dc996ba34-0-2"  className="panel-grid-cell" >
														<div id="panel-w5f14dc996ba34-0-2-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="6" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-2-0" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p>Brussels Branch<br />
																		+32 2 7348438<br />
																		bscsif.brussels@bscsif.org
																	</p>
																	</div>
																</div>
															</div>
														</div>
														<div id="panel-w5f14dc996ba34-0-2-1" className="so-panel widget widget_sow-editor" data-index="7" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-2-1" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p>Branch of the Balkan Region<br />
																		+381 11 2682055<br />
																		office@ifspd.butasbureau.nl.rs
																	</p>
																	</div>
																</div>
															</div>
														</div>
														<div id="panel-w5f14dc996ba34-0-2-2" className="so-panel widget widget_sow-editor panel-last-child" data-index="8" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-2-2" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p>India Branch - Bangalore<br />
																		+91 9886649897<br />
																		sangeethv@leadcap.net
																	</p>
																	</div>
																</div>
															</div>
														</div>
														</div>
														<div id="pgc-w5f14dc996ba34-0-3"  className="panel-grid-cell" >
														<div id="panel-w5f14dc996ba34-0-3-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="9" >
															<div className="panel-widget-style panel-widget-style-for-w5f14dc996ba34-0-3-0" >
																<div className="so-widget-sow-editor so-widget-sow-editor-base">
																	<div className="siteorigin-widget-tinymce textwidget">
																	<p><a href="contact/index.html"><button className="ifbighbutton">LIST ALL INFO</button></a></p>
																	</div>
																</div>
															</div>
														</div>
														</div>
													</div>
												</div>
											</div>
											</div>
										</div>
									</div>
								</div>
								</div>
								<div id="pg-w5f31c29a23cab-3"  className="panel-grid panel-has-style" >
								<div className="iffootercopyright siteorigin-panels-stretch panel-row-style panel-row-style-for-w5f31c29a23cab-3" id="iffootercopyright" data-stretch-type="full" >
									<div id="pgc-w5f31c29a23cab-3-0"  className="panel-grid-cell" >
										<div id="panel-w5f31c29a23cab-3-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="4" >
											<div className="so-widget-sow-editor so-widget-sow-editor-base">
											<div className="siteorigin-widget-tinymce textwidget">
												<p>
													© International Foundation for Sustainable Peace and Development {currentYear}.
												</p>
											</div>
											</div>
										</div>
									</div>
								</div>
								</div>
							</div>
						</aside>
					</div>
					</div>
				</div>
			</div>
			<footer className="site-info" aria-label="Site"  itemType="https://schema.org/WPFooter" itemScope>
				<div className="inside-site-info grid-container grid-parent">
					<div className="copyright-bar">
					Designed and hosted by <a href="/" target="_blank" rel="noreferrer" rel="noreferrer">Ritika</a>				
					</div>
				</div>
			</footer>
   		</div> */}
		{/* footer */}

		<a
			title="Scroll back to top"
			aria-label="Scroll back to top"
			rel="nofollow" href="#"
			className={`back-to-top ${visible ? 'back-top-active' : ''}`}
			onClick={scrollToTop}
		>
			<i className="fa fa-chevron-up"></i>
		</a>		
		{/* <nav id="generate-slideout-menu" className="main-navigation slideout-navigation" itemType="https://schema.org/SiteNavigationElement" itemScope style={{display: 'none'}}>
			<div className="inside-navigation grid-container grid-parent">
				<div className="main-nav">
					<ul id="menu-mobile-menu-en" className=" slideout-menu">
					<li id="menu-item-6544" className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-6544"><a href="index.html" aria-current="page">Home</a></li>
					<li id="menu-item-6545" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6545">
						<a href="#">About<span role="presentation" className="dropdown-menu-toggle"></span></a>
						<ul className="sub-menu">
							<li id="menu-item-6546" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6546"><a href="history/index.html">History</a></li>
							<li id="menu-item-6547" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6547"><a href="founders/index.html">Founders</a></li>
							<li id="menu-item-6548" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6548"><a href="principles-and-objectives/index.html">Principles and Objectives</a></li>
						</ul>
					</li>
					<li id="menu-item-6551" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6551">
						<a href="#">Organization<span role="presentation" className="dropdown-menu-toggle"></span></a>
						<ul className="sub-menu">
							<li id="menu-item-6552" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6552"><a href="presidential-committee/index.html">Presidential Committee</a></li>
							<li id="menu-item-6553" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6553"><a href="board-members-and-vice-presidents/index.html">Board Members &#038; Vice-Presidents</a></li>
							<li id="menu-item-6554" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6554"><a href="honorary-members-of-the-board/index.html">Honorary Members of the Board</a></li>
							<li id="menu-item-6555" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6555"><a href="fully-fledged-and-associate-members/index.html">Fully-fledged &#038; Associate Members</a></li>
							<li id="menu-item-6557" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6557"><a href="legal-entity/index.html">Legal Entity</a></li>
							<li id="menu-item-6558" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6558"><a href="our-team/index.html">Our Team</a></li>
							<li id="menu-item-6559" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6559"><a href="our-partners/index.html">Our Partners</a></li>
						</ul>
					</li>
					<li id="menu-item-6560" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6560"><a href="list/board-meetings/index.html">Events</a></li>
					<li id="menu-item-6562" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6562"><a href="list/statements/index.html">Statements</a></li>
					<li id="menu-item-6563" className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6563"><a href="list/projects/index.html">Projects</a></li>
					<li id="menu-item-6564" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6564"><a href="magazine/index.html">Magazine</a></li>
					<li id="menu-item-7860" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7860">
						<a href="#">Membership<span role="presentation" className="dropdown-menu-toggle"></span></a>
						<ul className="sub-menu">
							<li id="menu-item-7859" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7859"><a href="statute/index.html">Statute</a></li>
							<li id="menu-item-6550" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6550"><a href="membership-applications/index.html">Membership Applications</a></li>
						</ul>
					</li>
					<li id="menu-item-6565" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6565"><a href="gallery-board-meetings/index.html">Gallery</a></li>
					<li id="menu-item-6566" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6566"><a href="contact/index.html">Contact</a></li>
					<li id="menu-item-6587-en" className="lang-item lang-item-2 lang-item-en current-lang lang-item-first menu-item menu-item-type-custom menu-item-object-custom current_page_item menu-item-home menu-item-6587-en"><a href="index.html" hrefLang="en-GB" lang="en-GB"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAt1BMVEWSmb66z+18msdig8La3u+tYX9IaLc7W7BagbmcUW+kqMr/q6n+//+hsNv/lIr/jIGMnNLJyOP9/fyQttT/wb3/////aWn+YWF5kNT0oqz0i4ueqtIZNJjhvt/8gn//WVr/6+rN1+o9RKZwgcMPJpX/VFT9UEn+RUX8Ozv2Ly+FGzdYZrfU1e/8LS/lQkG/mbVUX60AE231hHtcdMb0mp3qYFTFwNu3w9prcqSURGNDaaIUMX5FNW5wYt7AAAAAjklEQVR4AR3HNUJEMQCGwf+L8RR36ajR+1+CEuvRdd8kK9MNAiRQNgJmVDAt1yM6kSzYVJUsPNssAk5N7ZFKjVNFAY4co6TAOI+kyQm+LFUEBEKKzuWUNB7rSH/rSnvOulOGk+QlXTBqMIrfYX4tSe2nP3iRa/KNK7uTmWJ5a9+erZ3d+18od4ytiZdvZyuKWy8o3UpTVAAAAABJRU5ErkJggg==" alt="Eng" width="16" height="11" style={{width: '16px', height: '11px'}} /><span style={{marginLeft: '0.3em'}}>Eng</span></a></li>
					<li id="menu-item-6587-ru" className="lang-item lang-item-5 lang-item-ru menu-item menu-item-type-custom menu-item-object-custom menu-item-6587-ru"><a href="ru/index.html" hrefLang="ru-RU" lang="ru-RU"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAdVBMVEX19f/u7vjm5/H+/v75+fng4Ove3ulFRfyysv6cnP6QkPmIiPh/f/YAAOYAAP1ycv5QUP06OvkxMfcoKPcgIPYUFPS0AADdaYzTRG/RPGnOM2LKLFzIIVPCEUZ7AAD0AQH7YGH3ODj0JyfzERDgAAD4TU3pAABIfLuPAAAAT0lEQVR4AQXBAQqDMAAAsZytyHzA/v9LYRS7JIAQMkBb0ATsgLoKInnHvIrHrdRaBzxupTDxuFUifUsp4R3zU4iwzmOyT1ibBtP2u3C+wB+SHBB5JNY7DAAAAABJRU5ErkJggg==" alt="Рус" width="16" height="11" style={{width: '16px', height: '11px'}} /><span style={{marginLeft:'0.3em'}}>Рус</span></a></li>
					</ul>
				</div>
			</div>
		</nav> */}
   		{/* <!-- #site-navigation --> */}
		{/* <div className="slideout-overlay">
			<button className="slideout-exit ">
			<span className="screen-reader-text">Close</span>
			</button>
		</div> */}

		</>
    )
}
