import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event4(){
    SetMetaData("Resolution of 16th General Assembly meeting", "", "");

    return (
        <div className="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
            <div className="site-content" id="content">
            <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                <main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                    <article id="post-509" className="post-509 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings" itemType="https://schema.org/CreativeWork" itemScope="">
                        <div className="inside-article">
                        <header className="entry-header" aria-label="Content">
                            <h1 className="entry-title title" itemProp="headline">Resolution of 16th General Assembly meeting</h1>
                            {/* <div className="entry-meta">
                                <span className="posted-on"><time className="updated" dateTime="2018-08-23T12:10:11+03:00" itemProp="dateModified">August 23, 2018</time><time className="entry-date published" dateTime="2017-06-20T10:03:00+03:00" itemProp="datePublished">June 20, 2017</time></span> 		
                            </div> */}
                        </header>
                        <div className="featured-image  page-header-image-single ">
                            <img width="210" height="130" src={require("../../assets/images/main/event/eskilogo.png")} className="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                        </div>
                        <div className="entry-content" itemProp="text">
                            <p dir="ltr"><strong>QUORUM FOR THE BOARD MEETING HAS BEEN ESTABLISHED.</strong></p>
                            <p dir="ltr">FOLLOWING THE COMPREHENSIVE DISCUSSION OF THE AGENDA, THE GENERAL ASSEMBLY HAS ADOPTED THE FOLLOWING DECISIONS:</p>
                            <p dir="ltr"><strong>QUESTION</strong> №1: Welcoming speech and report of the BSCSIF President Mr. Chingiz Abdullayev, regarding the two-year activity of the BSCSIF during his presidency.</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>: The members of the International Foundation have expressed their gratitude to Mr. Chingiz Abdullayev for his two-year presidency in the organization and have approved the report.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№2: Renaming of the BSCSIF. Report of the BSCSIF President Mr. Chingiz Abdullayev, BSCSIF Vice-president Mr. Viktor Khmarin and BSCSIF Vice-president Mr. Dimitar Kostov.</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>: BSCSIF is renamed to «International Foundation for Sustainable Peace and Development».</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION</strong> №3: Discussion of the amendments and additions to the Statute of the BFCSIF. Report by the President of the International Foundation Mr. Chingiz Abdullayev and the lawyer of the International Foundation Mr. Roman Bradu</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>:&nbsp;The amendments and additions to the Statute have been approved. On this basis, the Statute of the International Foundation has been adopted.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 4: Approval of the list of founders of the renamed organization. Report by the Vice-president of the International Foundation Mr. Dimitar Kostov, President of the International Foundation Mr. Chingiz Abdullayev and lawyer of the International Foundation Mr. Roman Bradu</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>: The list of the founders of the International Foundation is approved.</p>
                            <p dir="ltr">Voted – majority.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 5: Election and approval of vice-presidents and members of the Board of Directors of the International Foundation in connection with its renaming. Report of the President of the International Foundation Mr. Chingiz Abdullayev.</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>: Vice-presidents and members of the Board of Directors have been approved: Mr. Mikhail Zernov from Russian Federation and Stejarel Olaru from Romania.</p>
                            <p dir="ltr">Voted unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 6: Report on the topic “Modern Challenges and Risks to Peace and Security”. Adoption of the draft Istanbul Declaration of the International Foundation. Report by the Vice-president, Secretary General of the International Foundation professor Eldar Hasanov</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>:&nbsp;Istanbul Declaration of the International Foundation for Sustainable Peace and Development (the text is attached) has been adopted. It has been decided to distribute Declaration to all influent international organizations, including UN, EU, Europe Council, OSCE and OIC, government structures of the countries represented in the International Foundation as well as international and national NGOs.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 7: Appointment of the heads of the branches and representation offices of the International Foundation for Sustainable Peace and Development, namely, Mr. Mikhail Zernov in Moscow, H.E. Zolani Mkiva in South Africa, Ms.Esma Sirbubalo in Balkans and Mr. Bahruz Asadbayli in Istanbul. Dissolving Brussels branch and opening of a new branch in Brussels, in accordance with the national legislation of Belgium. Sending a letter to Head of the Minsk Branch Mr.Vitalie Buzdugan with the aim of presentation of the report about the activities of the branch in accordance with the Statute of the International Foundation.</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>: It has been decided 1) to approve the heads of the branches and representation offices of the International Foundation for Sustainable Peace and Development; 2) to dissolve Brussels branch and launch works for opening of a new Brussels branch; 3) to send a letter to Mr. Vitalie Buzdugan, head of the &nbsp;Minsk branch with the aim of presentation of the detailed report about the activities of the branch in accordance with the Statute of the International Foundation.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 8: Research projects of the International Foundation, Report by Mr. Ismail Safi, Vice-President of the International Foundation</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>:&nbsp;Decided to continue to pay special attention to the implementation of research projects, the involvement of experienced experts and specialists in the strategic studies of the International Fund, and actively conduct training and improvement of experts. Supported and voted unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 9: Report on the activities of the International Foundation’s Branch in the Republic of South Africa by H.R.H. Zolani Mkiva, member of the Board of Directors of the International Foundation</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>:&nbsp;Gratitude has been expressed for creation and activities of the branch in Johannesburg, South Africa Republic and it has been decided to support the activities of the foundation in the region.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 10: Presentation by the Director of the Branch of International Foundation in the Russian Federation Mr. Mikhail Zernov on the International Forum of Public Diplomacy</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>: Mr. M. Zernov has been thanked for sharing information on the International Forum of Public Diplomacy. The information has been taken into account. It has been decided to continue following activities of the Forum and consider possibility of synchronization of arranging the next meetings of the Board of Directors and General Assembly and International Forum of Public Diplomacy.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 11: Presentation of the Mrs. Ekaterina Tishchenko on the implementation of the project “Community of Youth Leaders of the World” in the countries participating in the International Foundation</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>: Participants of the meeting took into account the presentation and recommended to the members of the International Foundation to consider possibility of rendering support to the project.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p>&nbsp;</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 12: Report by Ms. Leonela Lenes&nbsp;on “Multiculturalism in the context of the modern globalizing world. The Romanian narrative on pluralism and diversity”</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>: The participants of the meeting pointed out to the importance of researching various aspects of multiculturalism, highly appreciated the activities of Ms. L. Lenes and the Romanian branch of the International Foundation in preparation of the report and organization of the event on multiculturalism on June 8, 2017, in Parliament of Romania in Bucharest.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 13: Creation of the international youth festival orchestra. Presentation by Mr. Chingiz Abdullaev</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>:&nbsp;The project on creation of the international youth festival orchestra has been approved</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 14: Transfer of the Chairmanship from Mr. Chingiz Abdullayev (Azerbaijan) to Mr. Dimitar Kostov (Bulgaria) in accordance with the principle of rotation provided for by the Statute.</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>:&nbsp;Mr. Dimitar Kostov has been approved as the President of the International Foundation for the next two years, in accordance with its Statute.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 15: Cooperation with International Peace Bureau (in French: Bureau International Permanent de la Paix), an international movement for peace, founded as a result of the International Peace Congress, held in Rome in 1891. Cooperation with the University of Peace with the Headquarter in Kosta-Rika, established in 1980 by the UN General Assembly under UN mandate.</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>: It has been decided to conduct negotiations about strategic partnership with the International Peace Bureau and University of Peace under the leadership of Vice-President and member of the Board of Directors Mr. Ismail Safi, Vice-President and Secretary General Mr. Eldar Hasanov, Executive Director Ms. Elmira Uygun and Coordinator of Balkans Office Ms. Esma Sirbubalo. Inform the leadership of the International Foundation about the results.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                            <p dir="ltr"><strong>QUESTION </strong>№ 16: Discussion on the venue of the next meeting of the Board of Directors and the General Assembly of the International Foundation</p>
                            <p dir="ltr"><strong>RESOLUTION</strong>:&nbsp;Instruct the Secretary General and Secretariat of the International Foundation to consider proposals for determining the venue of the next meeting in the city of Sofia, Bulgaria. Inform all Board members about the results.</p>
                            <p dir="ltr">Voted – unanimously.</p>
                        </div>
                        </div>
                    </article>
                    
                    <a className="singlepostlinkback" href="/board-meetings" onclick="goBackOrGoToCategory()">← Back to the list of all Board Meetings</a>		
                </main>
            </div>
            </div>
        </div>
    )
}

export default Event4;