import React from "react";
import Fancybox from '../../components/plugin/fancyox';

function Gallery2(){
    return (
        <Fancybox
            // Sample options
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Gala-Dinner-003A-500x332.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Gala-Dinner-003A-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Gala-Dinner-005A-500x332.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Gala-Dinner-005A-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Gala-Dinner-011A-500x332.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Gala-Dinner-011A-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-049-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-049-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-048-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-048-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-046-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-046-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-035-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-035-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-034-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-034-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-033-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-033-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-032-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-032-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-030-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-030-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-029-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-029-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-020-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-020-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-021-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-021-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-022-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-022-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-023-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-023-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-024-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-024-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-028-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-028-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-019-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-019-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-018-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-018-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-017-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-017-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-016-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-016-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-013-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-013-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-012-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-012-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-011-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-011-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-002-500x332.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-002-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-003-500x332.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-003-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-004-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-004-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-005-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-005-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-006-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-006-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-007-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-007-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-008-500x333.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-008-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-009-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-009-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/3/Meeting-010-333x500.jpg")} className="fancybox image gallery-item" title="IFSPD 17th Extended Board of Directors and General Assembly Meetings" rel="gallery-1"><img src={require("../../assets/images/main/event/3/Meeting-010-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
        </Fancybox>
    )
}

export default Gallery2;