import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event6(){
    SetMetaData("16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea", "", "");

    return (
        <div className="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
        <div className="site-content" id="content">
           <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
              <main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                 <article id="post-513" className="post-513 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news" itemType="https://schema.org/CreativeWork" itemScope="">
                    <div className="inside-article">
                       <header className="entry-header" aria-label="Content">
                          <h1 className="entry-title title" itemProp="headline">16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea</h1>
                          {/* <div className="entry-meta">
                             <span className="posted-on"><time className="updated" dateTime="2018-08-23T12:12:30+03:00" itemProp="dateModified">August 23, 2018</time><time className="entry-date published" dateTime="2017-06-10T10:16:06+03:00" itemProp="datePublished">June 10, 2017</time></span> 		
                          </div> */}
                       </header>
                       <div className="featured-image  page-header-image-single ">
                          <img width="1024" height="683" src={require("../../assets/images/main/event/6/f408384f-8a8d-405a-8dea-de5535cbf8c6_konferans_Blacksea_8-1024x683.jpg")} className="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                       </div>
                       <div className="entry-content" itemProp="text">
                          <p><strong>Press release,</strong></p>
                          {/* <p dir="ltr"><strong>Istanbul, 10th of June, 2017</strong></p> */}
                          <p dir="ltr"><strong>Istanbul</strong></p>
                          <p dir="ltr">In Istanbul, Turkey the Meeting of the General Assembly and Board of Directors of the International Foundation for Cooperation and Partnership of the Black Sea and the Caspian Sea (International Fund) took place.</p>
                          {/* <p dir="ltr">On June 10th in Istanbul, Turkey the Meeting of the General Assembly and Board of Directors of the International Foundation for Cooperation and Partnership of the Black Sea and the Caspian Sea (International Fund) took place.</p> */}
                          <p dir="ltr">&nbsp;Honorable representatives, members of the Board of Directors from the countries in the International Fund, attended the event and discussed both strategic and organizational issues concerning the further development of the International Fund.</p>
                          <p dir="ltr">Meetings of the Board of Directors and the General Assembly were chaired by the President of the International Fund and the outstanding writer Mr. Chingiz Abdullayev, who made a report regarding the two-year activity of the BSCSIF during his presidency. The members of the International Fund have expressed their gratitude to Mr. Chingiz Abdullayev for his two-year presidency in the organization.</p>
                          <p dir="ltr">At the meeting individuals and legal entities from different countries were admitted as new full members to the International Fund.</p>
                          <p dir="ltr">Eight years of successful activities of the International Fund, led to expansion of the ranks of the BSCSIF to which joined the intellectuals, famous and talented people from countries such as, for example, Estonia, South Africa, China, Switzerland, Greece, India, Montenegro. Thus, the activities of the International Fund geographically moved beyond the regions of the Black Sea and Caspian Sea. This process, against the background of increasing security threats and challenges in the world, even more actualized the significance and responsibility of the International Fund. From this perspective the members agreed in the previous sessions of the General Assembly and the Board of Directors upon the idea of changing the name of the BSCSIF in order to fully reflect the current objective reality. On this basis, in accordance with its Chapter, goals and objectives of the International Fund, BSCSIF, with due respect to its history and maintaining its continuity, has been renamed to «International Foundation for Sustainable Peace and Development» (IFSPD).</p>
                          <p dir="ltr">Within this context,&nbsp;after a thorough discussion, the amendments and additions to the Statute of the International Foundation for Cooperation and Partnership of the Black Sea and the Caspian Sea have been approved.</p>
                          <p dir="ltr">The International Fund in its activities aims, inter alia, to contribute to international efforts in the fight against terrorism.&nbsp;The issue of counter-terrorism has been also reflected in the agenda of the Istanbul meeting of the International Foundation. Vice-president, Secretary General of the International Foundation professor Eldar Hasanov presented report entitled “Modern challenges and risks to peace and security” which has been followed by adoption of the Istanbul Declaration of the General Assembly of the IFSPD. It has been decided to distribute the&nbsp;text of the Declaration to all influential international organizations, including UN, EU, Europe Council, OSCE and OIC, government structures of the countries represented in the International Foundation as well as international and national NGOs.</p>
                          <p dir="ltr">During the meeting in Istanbul, heads of the branches and representation offices of the International Foundation for Sustainable Peace and Development, namely, Mr. Mikhail Zernov in Moscow, H.R.H. Zolani Mkiva in South Africa, Ms. Esma Sirbubalo in Balkans and Mr. Bahruz Asadbayli in Istanbul have been elected. The Vice-Presidents have been elected, and members of the Board of Directors, the Secretary General and the Executive Director were also approved.</p>
                          <p dir="ltr">At the meeting it has been also decided to continue paying special attention to the implementation of research projects as one of the main activities of this think tank, involvement of experienced experts and specialists in the strategic studies of the IFSPD and training and improvement of experts.</p>
                          <p dir="ltr">H.R.H. Zolani Mkiva has delivered a report on the activities of the International Foundation’s Branch in the Republic of South Africa and it has been decided to support the activities of the foundation in the region.</p>
                          <p dir="ltr">Also, reports have been presented by members of the Board of Directors on the role of youth and public diplomacy as well as multiculturalism in the context of the modern globalizing world.</p>
                          <p dir="ltr">The participants of the meeting have decided to enter into negotiations to establish strategic partnership with the International Peace Bureau and University of Peace.</p>
                          <p dir="ltr">At the end of the meeting the Chairmanship in the International Foundation has been transferred from Mr. Chingiz Abdullayev (Azerbaijan) to Mr. Dimitar Kostov (Bulgaria) in accordance with the principle of rotation provided for by its Statute.</p>
                          <p dir="ltr">At the meeting it has been also positively assessed the proposal to hold the next meeting of the Board of Directors in Sofia, Bulgaria.</p>
                          <p dir="ltr">The International Fund for Cooperation and Partnership of the Black Sea and the Caspian Sea is an international non-governmental organization, founded on March 4, 2009 upon the initiative of outstanding scientific and public figures, well-known intellectuals, influential non-governmental organizations from 8 countries: Azerbaijan, Georgia, Kazakhstan, Kyrgyzstan, Moldova, Romania, Turkey and Ukraine. In 2011-2015 representatives of other countries joined the Fund: Russia, Bulgaria, Serbia, Montenegro, Bosnia and Herzegovina, Macedonia, Belarus, Croatia, Iran, Albania, Switzerland, Austria, Luxembourg, South Africa, the Republic of San Sao Tome and Principe, China, Sri Lanka, Ghana, Estonia, Czech Republic, Slovenia, Greece, Hungary. In 2016, representatives from France and Italy joined the International Fund. The Central Office of the International Foundation is located in Bucharest. Its branches actively work in Istanbul, Moscow, Belgrade, Brussels, Varna, Minsk and&nbsp;Johannesburg.</p>
                          <p dir="ltr">In August 2013, the International Fund was awarded special consultative status with the Economic and Social Council of the United Nations (ECOSOC). The International Fund has also established cooperation with international organizations such as the OSCE, BSEC, PA BSEC, the Organization of Islamic Cooperation, the European Union institutions, the European Centre for Policy, International Institute for Peace (Vienna), Spanish Society for International Human Rights Law.</p>
                          <div id="gallery-1" className="gallery galleryid-513 gallery-columns-3 gallery-size-medium">
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/6/3ae2b57b-356d-4488-9f94-4aa511466785_konferans_Blacksea_25-1-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/6/3ae2b57b-356d-4488-9f94-4aa511466785_konferans_Blacksea_25-1-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" fetchpriority="high" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/6/5d2c0932-4595-4b4f-be70-0bb00ee52e54_Konferans_blacsea-2_13-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/6/5d2c0932-4595-4b4f-be70-0bb00ee52e54_Konferans_blacsea-2_13-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/6/9e1251ef-c610-4622-bd1f-bee029cb3249_Konferans_blacsea-2_23-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/6/9e1251ef-c610-4622-bd1f-bee029cb3249_Konferans_blacsea-2_23-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/6/68a4311f-d68c-4c47-b5bf-01c80036f988_Konferans_blacsea-2_15-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/6/68a4311f-d68c-4c47-b5bf-01c80036f988_Konferans_blacsea-2_15-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/6/cd24284a-332c-4baa-94ba-63a6a5202969_Konferans_blacsea_25-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/6/cd24284a-332c-4baa-94ba-63a6a5202969_Konferans_blacsea_25-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/6/ef306f84-a38c-4392-bde3-de62209751da_Konferans_blacsea-2_14-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/6/ef306f84-a38c-4392-bde3-de62209751da_Konferans_blacsea-2_14-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/6/f1c98021-bce2-4aa1-9530-21270b307daf_Konferans_blacsea-2_12-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/6/f1c98021-bce2-4aa1-9530-21270b307daf_Konferans_blacsea-2_12-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/6/f9941e1f-72fa-43e6-bda7-7fed6bbcf4ff_Konferans_blacsea-2-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/6/f9941e1f-72fa-43e6-bda7-7fed6bbcf4ff_Konferans_blacsea-2-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/6/f408384f-8a8d-405a-8dea-de5535cbf8c6_konferans_Blacksea_8-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/6/f408384f-8a8d-405a-8dea-de5535cbf8c6_konferans_Blacksea_8-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                          </div>
                       </div>
                    </div>
                 </article>
                 
                 <a className="singlepostlinkback" href="/board-meetings" >← Back to the list of all Board Meetings</a>		
              </main>
           </div>
        </div>
     </div>
    )
}

export default Event6;