import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/about.css';

function Team(){
    SetMetaData("Our Team", "", "");

    return (
        <div className="site hfeed grid-parent" id="page">
            <div className="site-content" id="content">

                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">

                        <article id="post-1595" className="post-1595 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope="">
                            <div className="inside-article">

                                <header className="entry-header" aria-label="Content">
                                    <h1 className="entry-title title" itemProp="headline">Our Team</h1>
                                </header>


                                <div className="entry-content" itemProp="text">
                                    <div id="pl-1595" className="panel-layout">
                                        <div id="pg-1595-0" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-no-style">
                                            <div id="pgc-1595-0-0" className="panel-grid-cell">
                                                <div id="panel-1595-0-0-0" className="so-panel widget widget_sow-image panel-first-child panel-last-child" data-index="0">
                                                    <div className="so-widget-sow-image so-widget-sow-image-default-d6014b76747a-1595">

                                                        <div className="sow-image-container">
                                                            <img src={require("../../assets/images/main/body/inner-pages-grapshic-element.jpg")} width="1920" height="231" sizes="(max-width: 1920px) 100vw, 1920px" title="inner pages grapshic element" alt="" loading="lazy" className="so-widget-image" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-1" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-1">
                                                <div id="pgc-1595-1-0" className="panel-grid-cell">
                                                    <div id="panel-1595-1-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="1">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>Sofia Headquarters</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-2" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1595-2">
                                                <div id="pgc-1595-2-0" className="panel-grid-cell panel-grid-cell-mobile-last">
                                                    <div id="panel-1595-2-0-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="2">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-2-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Executive Directors:</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-1595-2-0-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="3">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-2-0-1">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Central office coordinators<br/>
                                                                        <strong>Sandra BOGUNOVIC</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1595-2-1" className="panel-grid-cell panel-grid-cell-empty"></div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-3" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-3">
                                                <div id="pgc-1595-3-0" className="panel-grid-cell">
                                                    <div id="panel-1595-3-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="4">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>IFSPD Branches</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-4" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-4">
                                                <div id="pgc-1595-4-0" className="panel-grid-cell">
                                                    <div id="panel-1595-4-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="5">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>International Center for Support and Coordination of Economic Cooperation<br/>
                                                                    (ICSCEC) - Istanbul (Turkey)</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-5" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1595-5">
                                                <div id="pgc-1595-5-0" className="panel-grid-cell panel-grid-cell-mobile-last">
                                                    <div id="panel-1595-5-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="6">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-5-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>ICSCEC Executive Directors</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1595-5-1" className="panel-grid-cell panel-grid-cell-empty"></div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-6" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-6">
                                                <div id="pgc-1595-6-0" className="panel-grid-cell">
                                                    <div id="panel-1595-6-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="7">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>Balkan Branch Office - Serbia - Belgrade</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-7" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1595-7">
                                                <div id="pgc-1595-7-0" className="panel-grid-cell panel-grid-cell-mobile-last">
                                                    <div id="panel-1595-7-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="8">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-7-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Director<br/>
                                                                        <strong>Sandra BOGUNOVIC</strong>
                                                                    </p>
                                                                    <p>Assistant director<br/>
                                                                        <strong>Bojana STANKOVIC</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1595-7-1" className="panel-grid-cell panel-grid-cell-empty"></div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-8" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-8">
                                                <div id="pgc-1595-8-0" className="panel-grid-cell">
                                                    <div id="panel-1595-8-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="9">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>IFSPD Branch in Russian Federation - Moscow</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-9" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1595-9">
                                                <div id="pgc-1595-9-0" className="panel-grid-cell panel-grid-cell-mobile-last">
                                                    <div id="panel-1595-9-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="10">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-9-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Commission on Innovation and Investment<br/>
                                                                        Director<br/>
                                                                        <strong>Mikhail ZERNOV</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1595-9-1" className="panel-grid-cell panel-grid-cell-empty"></div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-10" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-10">
                                                <div id="pgc-1595-10-0" className="panel-grid-cell">
                                                    <div id="panel-1595-10-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="11">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>IFSPD Branch in Bulgaria - Varna</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-11" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1595-11">
                                                <div id="pgc-1595-11-0" className="panel-grid-cell panel-grid-cell-mobile-last">
                                                    <div id="panel-1595-11-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="12">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-11-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Chief office representative<br/>
                                                                        <strong>Dimitar KOSTOV</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1595-11-1" className="panel-grid-cell panel-grid-cell-empty"></div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-12" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-12">
                                                <div id="pgc-1595-12-0" className="panel-grid-cell">
                                                    <div id="panel-1595-12-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="13">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>IFSPD Branch in Republic of Belarus – Minsk</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-13" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1595-13">
                                                <div id="pgc-1595-13-0" className="panel-grid-cell panel-grid-cell-mobile-last">
                                                    <div id="panel-1595-13-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="14">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-13-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Director<br/>
                                                                        <strong>Vitalie BUZDUGAN</strong>
                                                                    </p>
                                                                    <p>Deputy-director<br/>
                                                                        <strong>Olga KURBAN</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1595-13-1" className="panel-grid-cell panel-grid-cell-empty"></div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-14" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-14">
                                                <div id="pgc-1595-14-0" className="panel-grid-cell">
                                                    <div id="panel-1595-14-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="15">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>IFSPD India Branch Office - Bangalore, Mumbai</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-15" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1595-15">
                                                <div id="pgc-1595-15-0" className="panel-grid-cell panel-grid-cell-mobile-last">
                                                    <div id="panel-1595-15-0-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="16">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-15-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Founder Director<br/>
                                                                        <strong>Prof. Dr. Sangeeth VARGHESE</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-1595-15-0-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="17">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-15-0-1">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Executive Directors<br/>
                                                                        <strong>Vidyadhar PRABHUDESAI, FRSA</strong><br/>
                                                                    </p>
                                                                    <p><strong>Cdr Jagan GIRISABALLA</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1595-15-1" className="panel-grid-cell panel-grid-cell-empty"></div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-16" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-16">
                                                <div id="pgc-1595-16-0" className="panel-grid-cell">
                                                    <div id="panel-1595-16-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="18">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>IFSPD Experts</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-17" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1595-17">
                                                <div id="pgc-1595-17-0" className="panel-grid-cell">
                                                    <div id="panel-1595-17-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="19">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-17-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>Mr. Mihai SANDU</strong></p>
                                                                    <p><strong>Mr. Orhan MAMMADOV</strong></p>
                                                                    <p><strong>Mr. Ovidiu TESLOVAN</strong></p>
                                                                    <p><strong>Mrs. Julia AKHUNOVA</strong></p>
                                                                    <p><strong>Mrs. Sabina HUSEYNOVA</strong></p>
                                                                    <p><strong>Mrs. Valentina ALBU</strong></p>
                                                                    <p><strong>Ms. Alice FARCAS</strong></p>
                                                                    <p><strong>Ms. Diana CALIN</strong></p>
                                                                    <p><strong>Ms. Diana COMAN</strong></p>
                                                                    <p><strong>Ms. Irina TRAVICA</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1595-17-1" className="panel-grid-cell">
                                                    <div id="panel-1595-17-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="20">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-17-1-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>Ms. Katarina PERIC</strong></p>
                                                                    <p><strong>Ms. Liliana COMAN</strong></p>
                                                                    <p><strong>Ms. Loredana COMAN</strong></p>
                                                                    <p><strong>Ms. Luiza NITA</strong></p>
                                                                    <p><strong>Ms. Marcella CURMEI</strong></p>
                                                                    <p><strong>Ms. Natalia CIOBANU</strong></p>
                                                                    <p><strong>Ms. Raluca CLOTARU</strong></p>
                                                                    <p><strong>Ms. Saida SAFIKHANOVA</strong></p>
                                                                    <p><strong>Ms. Sandra BOGUNOVIC</strong></p>
                                                                    <p><strong>Prof. dr. Ljubica VASIC</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-18" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1595-18">
                                                <div id="pgc-1595-18-0" className="panel-grid-cell">
                                                    <div id="panel-1595-18-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="21">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>IFSPD Consultants</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1595-19" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1595-19">
                                                <div id="pgc-1595-19-0" className="panel-grid-cell">
                                                    <div id="panel-1595-19-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="22">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-19-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>Dr. Ismail SAFI</strong></p>
                                                                    <p><strong>Prof dr. Kamala IMRANLI-LOWE</strong></p>
                                                                    <p><strong>Prof. dr. Darko TANASKOVIC</strong></p>
                                                                    <p><strong>Prof. dr. Gheorghe AVORNIC</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1595-19-1" className="panel-grid-cell">
                                                    <div id="panel-1595-19-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="23">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1595-19-1-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>Prof. dr. Leonela LECA</strong></p>
                                                                    <p><strong>Prof. dr. Manana SANADZE</strong></p>
                                                                    <p><strong>Prof. dr. Nigar VELIYEVA</strong></p>
                                                                    <p><strong>Prof. dr. Tasin GEMIL</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </article>
                    </main>
                </div>


            </div>
        </div>
    )    
}

export default Team;