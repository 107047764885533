import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/fellow.css';
import FellowAboutCom from "../../components/fellow/about";

function FellowAbout(){
    SetMetaData("About Fellowship", "", "");

    return (
        <div className="container-fluid container-xl pe-lg-5 ps-lg-5 mt-60 fellow-overview">
            <FellowAboutCom />
        </div>
    )    
}

export default FellowAbout;