import React, {useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
// import moment from "moment";
import { RSstandardStyle } from "../../config/react-select";
import {post_api_call, get_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
import {LoaderMedium} from './../../components/loader';
import { CharacterCount } from "../../components/form/count";
const currentDate = new Date();
const cMonth = currentDate.getMonth()+1;
const cDate = `${currentDate.getDate() > 9 ? "" : 0}${currentDate.getDate()}`;
const currentDDMMYYYY = `${currentDate.getFullYear()}-${cMonth < 10 ? 0 : ""}${cMonth}-${cDate}`;
const initialFormData = {
    ss_profile: {
        problem: '',
        kpi: '',
        date: '',
        address: '',
        address_type: ''
    }
};
function StepThreeForm(props){
    const [pageLoading, setPageLoader] = useState(true);
    //Forms
    const [formDataProfile, setFormDataProfile] = useState({...initialFormData.ss_profile});
    const [errorData, setErrorData] = useState({Profile: []});
    const [isProcess, setIsProcess] = useState({Profile: false});
    const [isBtnDisabled, setBtnDisabled] = useState({Profile: true});

    useEffect(() => {
        //Get Portfolio Details
        // const fetchPortfolioData = async() => {
        //     try{
        //         const portfolioApiData = await post_api_call(`portfolio-details`, {});
        //         // const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
        //         if(portfolioApiData.status === 200){
        //             console.log(portfolioApiData, 'portfolioApiData')
        //             // if(portfolioApiData.data.headline){
        //             //     window.location.href = `/port/${portfolioApiData.data.url}`;
        //             // }else{
        //                 if(!portfolioApiData.data.headline){
        //                     setIsFirstEdit(true);
        //                 }
        //                 //GeneralDetails
        //                 const fieldKeys = ['first_name' , 'last_name', 'email', 'dob', 'language', 'mobile_number', 'logo'];
        //                 const generalFieldData = {...formDataGeneral};
        //                 fieldKeys.forEach(key => {
        //                     generalFieldData[key] = portfolioApiData.data[key] ? portfolioApiData.data[key] : '';
        //                 });
        //                 generalFieldData.country_id = portfolioApiData.data.country_id ? {id: portfolioApiData.data.country_id, name: portfolioApiData.data.country} : '';
        //                 generalFieldData.phone_code_id = portfolioApiData.data.mobile_code_id ? {id: portfolioApiData.data.mobile_code_id, phone_code: portfolioApiData.data.phone_code} : '';
        //                 generalFieldData.school_name_id = portfolioApiData.data.school_id ? {id: portfolioApiData.data.school_id, school_name: portfolioApiData.data.school_name} : '';
        //                 generalFieldData.cohort_name_id = portfolioApiData.data.cohort_id ? {id: portfolioApiData.data.cohort_id, cohort_name: portfolioApiData.data.cohort_name} : '';
        //                 setFormDataGeneral({...generalFieldData});
        //                 setUserUrl(portfolioApiData.data.user_name);
        //                 //Headline
        //                 setFormDataHeadline(portfolioApiData.data.position || '');
        //                 //Short Description
        //                 setFormDataDesc({short_description: portfolioApiData.data.headline || '',
        //                                 basic_achievement: portfolioApiData.data.achievement || '',
        //                                 basic_passion: portfolioApiData.data.passion || ''});
        //                 //Unique Skills
        //                 if(portfolioApiData.data.unique_skills.length > 0){
        //                     var uniqueData = [];
        //                     portfolioApiData.data.unique_skills.forEach(skill => {
        //                         uniqueData.push({passion_id : {id: skill.passion_id, title: `${skill.title}: ${skill.unique_ability}`}});
        //                     });
        //                     setFormDataUnique(uniqueData);
        //                 }
        //                 //Education
        //                 updateInpState('Edu', portfolioApiData.data.education_details);
        //                 //Achievements
        //                 if(portfolioApiData.data.glosco_data.length > 0){
        //                     var gloscoData = formDataAchieve;
        //                     portfolioApiData.data.glosco_data.forEach(glosco => {
        //                         gloscoData[glosco.level_id-1] = {...gloscoData[glosco.level_id-1],
        //                                                         id: glosco.level_id,
        //                                                         level: glosco.level,
        //                                                         year: {id: glosco.year, year: glosco.year},
        //                                                         indi_award: glosco.indi_award,
        //                                                         team_award: glosco.team_award};

        //                     });
        //                     setFormDataAchieve(gloscoData);
        //                 }
        //                 //Experience
        //                 updateInpState('Exp', portfolioApiData.data.experience_details);
        //                 //Research
        //                 updateInpState('Research', portfolioApiData.data.research_details);
        //                 //Sport
        //                 updateInpState('Sport', portfolioApiData.data.sport_details);
        //                 //Award
        //                 updateInpState('Award', portfolioApiData.data.award_details);
        //                 //Career Skills
        //                 if(portfolioApiData.data.carrer_skill_details.length > 0){
        //                     setFormDataCareer(portfolioApiData.data.carrer_skill_details);
        //                 }
        //                 //Personal Skills
        //                 if(portfolioApiData.data.personal_skill_details.length > 0){
        //                     setFormDataPersonal(portfolioApiData.data.personal_skill_details);
        //                 }
        //                 // setFormData({...fieldData});
                        setPageLoader(false);
        //             // }
        //         }
        //     }catch(e){
        //         console.log(e);
        //         alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
        //         error_api_call({error: `portfolio/edit|fetchPortfolioData|${e}`});
        //     }
        // }
	}, []);
    //Update Input
    const updateInput = (identifier_key, identifier_type, input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            console.log(identifier_key, input_type, field, value, field_type, form_field, index, 'inputtt')
            if(errorData[identifier_key].length){
                setErrorData({...errorData, [identifier_key]: []});
            }
            setBtnDisabled({...isBtnDisabled, [identifier_key]: false});
            if(input_type === 'input'
                || input_type === 'textarea'
            ){
                value = value.trimStart();
            }
            var fieldData = eval('formData'+identifier_key);
            if(identifier_type === 'object'){
                fieldData = {...fieldData}
            }else if(identifier_type === 'array'){
                fieldData = [...fieldData]
            }
            if(field_type === 'array'){
                console.log('fieldData[form_field][index][field] = value', form_field,',', index,',', field,',',  value)
                fieldData[index][field] = value;
            }else{
                identifier_type === 'single_value' ? fieldData = value : fieldData[field] = value;
            }
            switch (identifier_key) {
                case 'Profile':
                    setFormDataProfile({...fieldData});
                    break;
                default:
            }
        }catch(e){
            console.log(e);
            error_api_call({error: `social|step-three|updateInput|${e}`});
        }
    }
     //Add Fellow on Submit Click
     const editSocialThree = async(e, form_type='') => {
        try{
            console.log(form_type, 'form_typeform_typeform_type')
            e.preventDefault();
            setErrorData({...errorData, [form_type]: []});
            setIsProcess({...isProcess, [form_type]: true});
            var errorDetails = {
                Profile: []
            };
            var apiFormData = new FormData();
            //Validation
            var validation = [];
            var validationRules = {};
            switch (form_type) {
                case 'Profile':
                    validationRules = {
                        problem: 'required|max:250',
                        kpi: 'required|max:250',
                        date: 'required',
                        // address: 'required|max:250||',
                        address_type: 'required'
                    };
                    if(formDataProfile.address_type && formDataProfile.address_type.id === 2){
                        validationRules.address = 'required|max:250';
                    }
                    validation = ValidationHelper.validation(formDataProfile, validationRules).error;
                    if(validation.length){
                        // errorDetails[form_type] = validation;
                        console.log(validation, 'validationvalidationvalidation')
                        errorDetails[form_type] = ['Please fill all the details'];
                    }else{
                        formDataProfile.address_type = formDataProfile.address_type.id;
                        apiFormData.append('desc_data', JSON.stringify(formDataProfile));
                    }
                    break;
                default:
            }
            console.log(errorDetails[form_type], 'errorDetails[form_type]')
            if(errorDetails[form_type].length > 0){
                setErrorData({...errorData, [form_type]: errorDetails[form_type]});
                setIsProcess({...isProcess, [form_type]: false});
                setBtnDisabled({...isBtnDisabled, [form_type]: true});
            }else{
                //Delete Later
                props.updateFormStep({next_step: 4,
                    // social_id: socialId,
                    // url: socialUrl,
                    // url: 'demo',
                    stage_id: props.data.stage_id < 3 ? 3 : props.data.stage_id
                });
                return;
                //Send Data to Server
                apiFormData.append('update_type', form_type.toLowerCase());
                // const updateFellow = await post_api_call(`update-portfolio/${form_type.toLowerCase()}`, apiFormData);
                const updateFellow = await post_api_call(`update-portfolio`, apiFormData);
				if(updateFellow.status === 200){
                    // setIsSuccess(true);
                    alertResponseMessage({alert_type: 1, message: "Information Updated Successfully"});
                    setBtnDisabled({...isBtnDisabled, [form_type]: true});
                    // setViewDisabled({...isViewDisabled, [form_type]: false});
                    console.log("Information Updated Successfully", 'Information Updated Successfully')
                }else{
                    if(typeof updateFellow.message !== "undefined"){
                        setErrorData({...errorData, [form_type]: [updateFellow.message]});
                        console.log("Errorooo")
                    }
                }
                setIsProcess({...isProcess, [form_type]: false});
            }
            
        }catch(e){
            console.log(e);
            setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
            setIsProcess({...isProcess, [form_type]: false});
            error_api_call({error: `social|stepThree|editSocialThree|${e}`});
        }
    }
    return (
        <>
        {pageLoading
        ?
            <div className="text-center mt-5 mb-5">
                <LoaderMedium size={45} />
            </div>
        :
            <>
            <div className="col-md-10 col-12 col-sm-12">
            <div className="text-center">
                <h2 className="mb-1 mt-4"><b>Step 3: SOCIAL START-UP PLANNING</b></h2>
                <p>Analyze your social start-up in-depth; deep dive into the problem that you are addressing, come up with a tangible, visible and measurable solution</p>
            </div>
            <div className="card p-3">
                <ul className="mb-4 ms-3">
                    <li>Visit the location where you have identified the problem.</li>
                    <li>Observe the problem. Collect data. Capture photos.</li>
                    <li>Come up with a Key Performance Indicator (KPI), a measurable number that you will be able to achieve within one single day of your social start-up.</li>
                </ul>
                {/* Profile Details */}
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Your social start-up profile: In below 250 characters each</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        {/* Problem */}
                                        <tr>
                                            <th className="w-50">
                                                Explain the problem and the current situation that you are considering to make an impact through your social start-up:<br/><br/>
                                                <ul className="fst-italic fw-normal li-sty-none ms-0">
                                                    <li><b>Example 1:</b> In XYZ corner outside my school, garbage is piling up. It is attracting flies, and hazardous to the health.</li>
                                                    <li><b>Example 2:</b> In XYZ construction site, small children of poor construction workers are not going to school.</li>
                                                </ul>
                                            </th>
                                            <td>
                                                <textarea className="form-control" name="problem" autoComplete="off" value={formDataProfile.problem} onChange={(e) => updateInput('Profile', 'object', 'input', 'problem', e.target.value)} rows={5}/>
                                                <CharacterCount
                                                    max={250}
                                                    string={formDataProfile.problem}
                                                />
                                            </td>
                                        </tr>
                                        {/* KPI */}
                                        <tr>
                                            <th>
                                                This is the most important step in your social start-up.<br/><br/>
                                                <p className="fw-normal mb-0"><b>Explain your proposed solution to your identified problem using a Key Performance Indicator (KPI).</b> KPI is a measurable number used to evaluate the success of your social start-up. Please mention one such number through which you will measure the success of your social start-up. You should be able to achieve this KPI in one single day. (Your social start-up will be rejected, if a KPI number is missing):</p><br/>
                                                <ul className="fst-italic fw-normal li-sty-none ms-0">
                                                    <li><b>Example 1:</b> Teach English alphabets to 10 poor kids in my neighborhood.</li>
                                                    <li><b>Example 2:</b> Clear 100% garbage outside my school.</li>
                                                    <li><b>Example 3:</b> Raise Rs.25,000 in donations to aid landslide victims.</li>
                                                </ul>
                                            </th>
                                            <td>
                                                <textarea className="form-control" name="kpi" autoComplete="off" value={formDataProfile.kpi} onChange={(e) => updateInput('Profile', 'object', 'input', 'kpi', e.target.value)} rows={5}/>
                                                <CharacterCount
                                                    max={250}
                                                    string={formDataProfile.kpi}
                                                />
                                            </td>
                                        </tr>
                                        {/* Date */}
                                        <tr>
                                            <th>Your KPI number will have to be achieved within a single day of social start-up. What date are you proposing for your social start-up?</th>
                                            <td><input className="form-control" type="date" name="date" autoComplete="off" value={formDataProfile.date} onChange={(e) => updateInput('Profile', 'object', 'input', 'date', e.target.value)} min={`${currentDDMMYYYY}`}/></td>
                                        </tr>
                                        {/* Location */}
                                        <tr>
                                            <th>Specify the address of the place you are rolling out your social start-up?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={[{id: 1, type: 'Online'}, {id: 2, type: 'Physical'}]}
                                                    defaultValue={formDataProfile.address_type}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Profile', 'object', 'select-react', 'address_type', value)}
                                                    getOptionLabel ={(option) => option.type}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                    // menuPortalTarget={document.querySelector('body')}
                                                    // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                    // className="is-valid"
                                                />
                                                </div><br/>
                                                {formDataProfile.address_type && formDataProfile.address_type.id === 2 && (
                                                    <>
                                                    <textarea className="form-control" placeholder="Address" name="address" autoComplete="off" value={formDataProfile.address} onChange={(e) => updateInput('Profile', 'object', 'input', 'address', e.target.value)} rows={3}/>
                                                    <CharacterCount
                                                        max={250}
                                                        string={formDataProfile.problem}
                                                    />
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['Profile']
                                ?
                                    isBtnDisabled['Profile']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialThree(e, 'Profile')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                    <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['Profile'].length > 0 && (
                                <div className="alert alert-danger mt-p" role="alert">
                                    {/* <p>Please enter/modify following Data:</p> */}
                                    <ol className="li-sty-none ms-0">
                                    {errorData['Profile'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            </div>
            </>
        }
        </>
    )    
}

export default StepThreeForm;