import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/about.css';

function Founder(){
    SetMetaData("Founder", "", "");

    return (
        <div className="site hfeed grid-parent" id="page">
            <div className="site-content" id="content">

                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">

                        <article id="post-1491" className="post-1491 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope="">
                            <div className="inside-article">

                                <header className="entry-header" aria-label="Content">
                                    <h1 className="entry-title title" itemProp="headline">Founders</h1>
                                </header>


                                <div className="entry-content" itemProp="text">
                                    <div id="pl-1491" className="panel-layout">
                                        <div id="pg-1491-0" className="container container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1491-0">
                                                <div id="pgc-1491-0-0" className="panel-grid-cell">
                                                    <div id="panel-1491-0-0-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="0">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>IFSPD Founders</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-1491-0-0-1" className="so-panel widget widget_sow-image" data-index="1">
                                                        <div className="so-widget-sow-image so-widget-sow-image-default-d6014b76747a-1491">

                                                            <div className="sow-image-container">
                                                                <img src={require("./../../assets/images/main/body/inner-pages-grapshic-element.jpg")} width="1920" height="231" sizes="(max-width: 1920px) 100vw, 1920px" title="inner pages grapshic element" alt="" loading="lazy" className="so-widget-image" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div id="panel-1491-0-0-2" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                                        <div className="ifhightlight panel-widget-style panel-widget-style-for-1491-0-0-2">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>As per Article 13. (1) of the Charter of the International Foundation for Sustainable Peace and Development
                                                                        (IFSPD), <em>the founding members are the first members of the Association. They are persons that participated in
                                                                            the Constitutive Meeting held on June 10, 2017, in Istanbul (Turkey), and who signed the Constitutive Act and
                                                                            adopted the Charter of the Association</em>.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1491-1" className="container container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1491-1">
                                                <div id="pgc-1491-1-0" className="panel-grid-cell">
                                                    <div id="panel-1491-1-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="3">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1491-1-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>Mrs. Biserka JEVTIMIJEVIC </strong><br/>
                                                                        President<br/>
                                                                        (Serbia)</p>
                                                                    <p><strong>Mr. Chingiz ABDULLAYEV</strong><br/>
                                                                        Vice-president<br/>
                                                                        (Azerbaijan)</p>
                                                                    <p><strong>Mr. Dimitar KOSTOV</strong><br/>
                                                                        Vice-president<br/>
                                                                        (Bulgaria)</p>
                                                                    <p><strong>Prof. dr. Dossym SULEYEV</strong><br/>
                                                                        Vice-president<br/>
                                                                        (Kazakhstan)</p>
                                                                    <p><strong>Prof. dr. Eldar HASANOV</strong><br/>
                                                                        Secretary-General &amp; Vice-president<br/>
                                                                        (Azerbaijan)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1491-1-1" className="panel-grid-cell">
                                                    <div id="panel-1491-1-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="4">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1491-1-1-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>Prof. dr. Gheorghe AVORNIC</strong><br/>
                                                                        Vice-president<br/>
                                                                        (Moldova)</p>
                                                                    <p><strong>HRH Zolani MKIVA</strong><br/>
                                                                        Vice-president<br/>
                                                                        (South Africa)</p>
                                                                    <p><strong>Dr. Ismail SAFI</strong><br/>
                                                                        Vice-president<br/>
                                                                        (Turkey)</p>
                                                                    <p><strong>Prof. dr. Iulian CHIFU</strong><br/>
                                                                        Vice-president<br/>
                                                                        (Romania)</p>
                                                                    <p><strong>Dr. Mehmet Akkan SUVER</strong><br/>
                                                                        Vice-president<br/>
                                                                        (Turkey)</p>
                                                                    <p><strong>Mr. Viktor KHMARIN</strong><br/>
                                                                        Vice-president<br/>
                                                                        (Russia)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1491-2" className="container container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="iffounders-page panel-row-style panel-row-style-for-1491-2">
                                                <div id="pgc-1491-2-0" className="panel-grid-cell">
                                                    <div id="panel-1491-2-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="5">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>BSCSIF Founders</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1491-3" className="container container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1491-3">
                                                <div id="pgc-1491-3-0" className="panel-grid-cell">
                                                    <div id="panel-1491-3-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="6">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1491-3-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>Mr. Iulian FOTA</strong><br/>
                                                                        Former Presidential Advisor for National Security, Romania</p>
                                                                    <p><strong>Mr. Chingiz ABDULLAYEV</strong><br/>
                                                                        Secretary of the Writers’ Union of the Republic of Azerbaijan</p>
                                                                    <p><strong>Prof. dr. Eldar HASANOV</strong><br/>
                                                                        Ambassador Extraordinary and Plenipotentiary of the Republic of Azerbaijan to Romania, Republic of Serbia,
                                                                        Montenegro and Bosnia and Herzegovina</p>
                                                                    <p><strong>Dr. Mehmet Akkan SUVER</strong><br/>
                                                                        President of the Marmara Group Strategic and Social Research Foundation, Istanbul, Republic of Turkey</p>
                                                                    <p><strong>Prof. dr. Tamaz BERADZE</strong><br/>
                                                                        Doctor of Historical Sciences, State University of Tbilisi, Georgia</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1491-3-1" className="panel-grid-cell">
                                                    <div id="panel-1491-3-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="7">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1491-3-1-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>Mr. Vadym KOLESNICHENKO</strong><br/>
                                                                        Member of the Committee for Justice Affairs of the Verkhovna Rada (Parliament) of Ukraine</p>
                                                                    <p><strong>Mrs. Zhyldyz OSKONBAEVA</strong><br/>
                                                                        Councilor of the Department of External Affairs within the Parliament of the Republic of Kyrgyzstan</p>
                                                                    <p><strong>Dr. Lidia ROMANCIUC</strong><br/>
                                                                        Executive Director of the Association of Research and Development, Republic of Moldova</p>
                                                                    <p><strong>Prof. dr. Dossym SULEYEV</strong><br/>
                                                                        Honorary rector of Kazakh National Technical University, Academician of the Academics of Science of the Republic of
                                                                        Kazakhstan</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </article>
                    </main>
                </div>

            </div>
        </div>
    )    
}

export default Founder;