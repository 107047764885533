import React from 'react';
import {
    Col,
    Row
  } from 'react-bootstrap';
//   import { useCallback, useLayoutEffect, useState } from 'react';
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/fellow.css';
// import FellowFeatureCom from "../../components/fellow/features";
// import FellowApplyBtn from "../../components/fellow/apply-button";

function Sustainability(props){
    SetMetaData("Global Sustainability Fellowship", "", "");
    // const hasWindow = typeof window !== 'undefined';

    // const getWindowDimensions = useCallback(() => {
    //     const windowWidth = hasWindow ? window.innerWidth : null;
    //     const windowHeight = hasWindow ? window.innerHeight : null;

    //     return {
    //         windowWidth,
    //         windowHeight,
    //     };
    // }, [hasWindow]);

    // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    // useLayoutEffect(() => {
    //     if (hasWindow) {
    //         function handleResize() {
    //             setWindowDimensions(getWindowDimensions());
    //         }

    //         window.addEventListener('resize', handleResize);
    //         return () => window.removeEventListener('resize', handleResize);
    //     }
    // }, [getWindowDimensions, hasWindow]);

    return (
        <>
        {/* <div className="container-fluid container-xl pe-lg-5 ps-lg-5 mt-60 fellow-overview"> */}
        <div className={`fellow-overview fellow-campaign ${props.showHeader===true ? "mt-50": ""}`}>
            <div className="container-fluid">
                <Row className="justify-content-center">
                    <Col md="12" sm="12" className="p-0">
                        <div className="card fellow-sus" style={{background: `url(${require("../../assets/images/fp/bg-fellowship-2.jpg")}) 50% no-repeat`}}>
                            {/* <img src={require("../../assets/images/fp/bg-fellowship-1.png") } className="card-img d-none d-sm-block" alt="Fellowship"/> */}
                            {/* <div className="card-img-overlay" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}> */}
                            {/* <div className="card-img-overlay" style={{backgroundColor: "#ffffff61"}}> */}
                                <div className="row m-2">
                                    <div className="col-md-3 col-4 col-sm-3 text-center text-sm-start">
                                        <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../../assets/images/main/logo/ifspd_logo_m.png')} title="International Foundation for Sustainable Peace and Development" />
                                    </div>
                                    <div className="col-md-9 col-8 col-sm-9">
                                        <div className="float-sm-end text-center text-sm-start">
                                            <p className="banner-head">LeadCap Ventures</p>
                                            <p className="banner-title mb-3">Research | Analytics | Consulting</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-12 col-sm-12 text-center mb-sm-4 mb-0">
                                        <h1 className="page-title page-med-title mb-2 mb-sm-0">Enabling sustainability champions</h1>
                                        <h2 className="page-sub-title page-sub-med-title">certification in global sustainability leadership</h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center text-center text-uppercase justify-content-center mt-2 mt-md-3 mt-xl-5 fellow-f-btn ms-3 me-3">
                                    {/* <div className="col-md-12 col-12 col-sm-12 d-md-flex text-uppercase justify-content-center mt-2 mt-lg-5"> */}
                                        <div className="col-12 col-md-12 col-sm-12 col-xl-12"><div className="m-2"><p className="box-blue1 mb-0 box-camp-1">Alumni from 60+ countries include UN Heads, Senators,<br/>Bureaucrats, Entrepreneurs, CEOs, Global Awardees & Ivy<br/> League College Attendees.</p></div></div>
                                        {/* <div className="col-12 col-md-6 col-sm-6 col-xl-5"><div className="m-2"><p className="box-blue2 mb-0">20,000 applicants from 60+ countries</p></div></div> */}
                                    {/* </div> */}
                                    {/* <div className="col-md-12 col-xl-10 col-12 col-sm-12 text-uppercase mt-2 mt-xxl-5 mb-5"> */}
                                    {/* <div className="col-md-12 col-xl-10 col-12 col-sm-12 text-uppercases mb-5">
                                        <div className="m-2 text-center"><p className="box-grey mb-0">Alumni include senators, bureaucrats, CEOs, entrepreneurs, winners of global awards & attendees of Ivy League universities</p></div>
                                    </div> */}
                                    <div className="col-12 col-md-12 col-sm-12 col-xl-12"><a href="https://admin.gosurvey.in/survey/SDG" target='_blank' rel="noreferrer"><div className="m-2"><p className="box-purple">APPLY TODAY<img className="header-image" alt="Apply Now" src={require('../../assets/images/fp/click.png')} title="Apply Now" /></p></div></a></div>
                                </div>
                            {/* </div> */}
                        </div>
                    </Col>
                </Row>
            </div>
            {/* <div className="ms-3 me-3"> */}
            <div className="container">
                <Row className="justify-content-center mt-5 fellow-feature">
                    <Col md="3" sm="6" xs="6" className="text-center">
                        <img src={require('../../assets/images/fp/fp1/Elevated-Profile.png')} alt="Elevated Profile & Global Credibility" />
                        <p className="f-title">Elevated Profile & Global Credibility</p>
                    </Col>
                    <Col md="3" sm="6" xs="6" className="text-center">
                        <img src={require('../../assets/images/fp/fp1/Premium-Certification.png')} alt="Premium Global Certification"/>
                        <p className="f-title">Premium Global Certification</p>
                    </Col>
                    <Col md="3" sm="6" xs="6" className="text-center">
                        <img src={require('../../assets/images/fp/fp1/Profile-Page.png')} alt="IFSPD Professional Profile Page & Alumni Status "/>
                        <p className="f-title">IFSPD Professional Profile Page & Alumni Status </p>
                    </Col>
                    <Col md="3" sm="6" xs="6" className="text-center">
                        <img src={require('../../assets/images/fp/fp1/Reference-Letter.png')} alt="Reference Letter from IFSPD President"/>
                        <p className="f-title">Reference Letter from IFSPD President</p>
                    </Col>
                    <Col md="3" sm="6" xs="6" className="text-center">
                        <img src={require('../../assets/images/fp/fp1/leadership.png')} alt="Foundation in United Nations SDGs"/>
                        {/* <p className="f-title">Reference Letter from IFSPD President</p> */}
                        <p className="f-title">Foundation in United Nations SDGs</p>
                    </Col>
                    <Col md="3" sm="6" xs="6" className="text-center">
                        <img src={require('../../assets/images/fp/fp1/Global-Issues.png')} alt="Socio-Economic Dimensions & Sustainability Leadership"/>
                        <p className="f-title">Socio-Economic Dimensions & Sustainability Leadership</p>
                    </Col>
                    <Col md="3" sm="6" xs="6" className="text-center">
                        <img src={require('../../assets/images/fp/fp1/Critical-Thinking.png')} alt="Entrepreneurial Thinking & Innovation Management"/>
                        <p className="f-title">Entrepreneurial Thinking & Innovation Management</p>
                    </Col>
                    <Col md="3" sm="6" xs="6" className="text-center">
                        <img src={require('../../assets/images/fp/fp1/Unique-Skills.png')} alt="Diplomacy, Collaboration & Change Management"/>
                        <p className="f-title">Diplomacy, Collaboration & Change Management</p>
                    </Col>
                {/* </Row> */}
                {/* <Row className="justify-content-center mt-3 mt-lg-5 fellow-feature"> */}
                </Row>
            </div>
            <div className="container compact">
                <Row className="justify-content-center text-center text-uppercase">
                    {/* <div className="col-md-12 col-12 col-sm-12 d-md-flex text-uppercase justify-content-center mt-2 mt-lg-4"> */}
                        <div className="col-12 col-md-6 col-sm-6 col-xl-6 pe-1 ps-1"><div><p className="box-blue1 mb-0 mt-2 mt-md-0">for professionals & entrepreneurs looking for career progress</p></div></div>
                        <div className="col-12 col-md-6 col-sm-6 col-xl-6 pe-1 ps-1"><div><p className="box-grey mb-0 mt-2 mt-md-0">for students & researchers for building careers in sustainability</p></div></div>
                    {/* </div> */}
                </Row>
                {/* fellowship details */}
                <Row className="justify-content-center mt-50 fellow-feature ps-3 pe-3 ps-sm-0 pe-sm-0">
                    <h3 className="heading">FELLOWSHIP DETAILS</h3>
                    <div className="plan-table p-0 table-responsive" style={{position: "relative"}}>
                        <table border="0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th><p className="bg-color-blue">weekend</p></th>
                                    <th><p className="bg-color-d-blue">fast track</p></th>
                                    <th><p className="bg-color-grey">intensive</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p className="t-title w-200p">Duration</p></td>
                                    <td className="content">1 Weekend Online</td>
                                    <td className="content">4 Weeks Online</td>
                                    <td className="content">8 Weeks Online</td>
                                </tr>
                                <tr>
                                    <td><p className="t-title">Benefits</p></td>
                                    <td className="content">
                                        <ol>
                                            <li>Premium Global Certificate (digital)</li>
                                            {/* <li>UN-SDG Foundation, Socio-Economic Analysis, Sustainability Leadership.</li> */}
                                            <li>Foundation in ESG, SDG, BRSR, CDP, GRI, SASB and TCFD. Socio-Economic Analysis and Sustainability Leadership.</li>
                                        </ol>
                                    </td>
                                    <td className="content">
                                        <ol>
                                            <li>Premium Global Certificate (digital)</li> 
                                            <li>Unique Skills Report</li>
                                            {/* <li>UN-SDG Foundation, Socio-Economic Analysis, Sustainability Leadership.</li> */}
                                            <li>Foundation in ESG, SDG, BRSR, CDP, GRI, SASB and TCFD. Socio-Economic Analysis and Sustainability Leadership.</li>
                                        </ol>
                                    </td>
                                    <td className="content">
                                        <ol>
                                            <li>Premium Global Certificate</li>
                                            <li>Unique Skills Report</li>
                                            {/* <li>UN-SDG Foundation, Socio-Economic Analysis, Entrepreneurial Thinking, Sustainability Leadership & Diplomacy.</li> */}
                                            <li>Foundation in ESG, SDG, BRSR, CDP, GRI, SASB and TCFD. Socio-Economic Analysis, Sustainability Leadership, Entrepreneurial Thinking and Diplomacy.</li>
                                            <li className="color-blue">Curated IFSPD Profile Page</li>
                                            <li className="color-blue">IFSPD Special Certification of Unique Skills</li>
                                            <li className="color-blue">Recommendation Letter from the IFSPD President</li>
                                        </ol>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-bottom-0"><p className="t-title">Actual Fees</p></td>
                                    <td className="content border-bottom-0 strike-price">Rs.30,000</td>
                                    <td className="content border-bottom-0 strike-price">Rs.60,000</td>
                                    <td className="content border-bottom-0 strike-price">Rs.100,000</td>
                                </tr>
                                <tr>
                                    <td className="border-top-0"><p className="t-title">you Pay</p></td>
                                    <td className="content border-top-0">Rs.19,800 / $250</td>
                                    <td className="content border-top-0">Rs.30,000 / $390</td>
                                    <td className="content border-top-0">Rs.49,800 / $590</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="tick-icon">
                            <img className="p-0 m-0" src={require("../../assets/images/tick.png")} alt="Tick" loading="lazy" />
                        </div>
                    </div>
                </Row>
                {/* methodology */}
                <Row className="justify-content-center mt-50 program-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                    <h3 className="heading">program methodology</h3>
                    <div className="col-12 col-md-6 ps-0">
                       <div className="content">
                            <p className="t-title">presidential briefings (PB)</p>
                            <p className="t-desc color-blue">Leading subject matter experts fuel introspection, investigation and influence - to spark innovative solutions for sustainability challenges.</p>
                            <img className="bottom" src={require("../../assets/images/fp/mike.jpg")} alt="Press conference" loading="lazy" />
                       </div>
                    </div>
                    <div className="col-12 col-md-6 pe-0 pt-5 pt-md-0">
                       <div className="content">
                            <img className="top d-none md-d-block" src={require("../../assets/images/fp/discussing.jpg")} alt="Online Video Conference Webinar Call" loading="lazy" />
                            <p className="t-title">global Strategy councils (GSC)</p>
                            <p className="t-desc color-blue">Discussing, debating, synthesising and reaching conclusions from multiple stakeholder perspectives on critical global sustainability issues.</p>
                            <img className="top d-block md-d-none" src={require("../../assets/images/fp/discussing.jpg")} alt="Online Video Conference Webinar Call" loading="lazy" />
                       </div>
                    </div>
                </Row>
                {/* button: Start */}
                <Row className="justify-content-center text-center text-uppercase week-btn-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                    {/* <div className="col-md-12 col-12 col-sm-12 d-md-flex text-uppercase justify-content-center mt-2 mt-lg-4"> */}
                        <div className="col-12 col-md-4 col-sm-6 col-xl-4 ps-0"><div className="bg-color-d-blue box-blue1 mb-0 mt-2"><p className="font-25">1 weekend</p><p>PB followed by debrief, gSc</p></div></div>
                        <div className="col-12 col-md-4 col-sm-6 col-xl-4 ps-0"><div className="bg-color-blue box-blue1 mb-0 mt-2"><p className="font-25">week 2 - 4</p><p>pb followed by debrief, gSc</p></div></div>
                        <div className="col-12 col-md-4 col-sm-6 col-xl-4 ps-0 pe-md-0"><div className="bg-color-grey2 box-grey mb-0 mt-2"><p className="font-25">week 4 - 8</p><p>pb followed by debrief, GSC, research paper</p></div></div>
                    {/* </div> */}
                </Row>
                <Row className="text-center text-uppercase week-btn-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                    <div className="col-4 col-md-4 col-sm-4 col-xl-4 ps-0"><div className="bg-color-d-blue c-box mb-0 mt-2"><p>1 weekend</p></div></div>
                </Row>
                <Row className="text-center text-uppercase week-btn-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                    <div className="col-8 col-md-8 col-sm-8 col-xl-8 ps-0"><div className="bg-color-blue c-box mb-0 mt-2"><p>fast-track</p></div></div>
                </Row>
                <Row className="text-center text-uppercase week-btn-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                    <div className="col-12 col-md-12 col-sm-12 col-xl-12 ps-0 pe-md-0"><div className="bg-color-grey2 c-box mb-0 mt-2"><p>intensive</p></div></div>
                </Row>
                {/* button: End */}
                {/* ALUMNI */}
                <Row className="justify-content-center mt-50 alumni-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                    <h3 className="heading">ALUMNI SPOTLIGHT</h3>
                    <div className="col-12 col-md-3 text-center">
                       <div className="content">
                            {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                                <img src={require("../../assets/images/fp/issa-conteh.jpg")} loading="lazy" alt="Issa Conteh" />
                            {/* </a> */}
                            <p className="t-name color-blue">Issa Conteh</p>
                            <p className="t-position">Office Head, UN</p>
                            <p className="t-country color-blue">Uganda</p>
                       </div>
                    </div>
                    <div className="col-12 col-md-3 mt-5 pt-5 mt-md-0 pt-md-0 text-center">
                       <div className="content">
                            {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                                <img src={require("../../assets/images/fp/1YOkcQEqnWnFZDHX9zQo7w==.png")} loading="lazy" alt="Nayab Zahra" />
                            {/* </a> */}
                            <p className="t-name color-blue">Nayab Zahra</p>
                            <p className="t-position">CEO, SD Nexus</p>
                            <p className="t-country color-blue">switzerland</p>
                       </div>
                    </div>
                    <div className="col-12 col-md-3 mt-5 pt-5 mt-md-0 pt-md-0 text-center">
                       <div className="content">
                            {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                                <img src={require("../../assets/images/fp/GaKhtUBwyt1uvZwYNhR2Lg.png")} loading="lazy" alt="Yash Singh Sisodia" />
                            {/* </a> */}
                            <p className="t-name color-blue">Yash Singh Sisodia</p>
                            <p className="t-position">Sr Researcher, LBSNAA</p>
                            <p className="t-country color-blue">INDIA</p>
                       </div>
                    </div>
                    <div className="col-12 col-md-3 mt-5 pt-5 mt-md-0 pt-md-0 text-center">
                       <div className="content">
                            {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                                <img src={require("../../assets/images/fp/Xnn9Ou2tExJf4U2fy4ZF4A==.png")} loading="lazy" alt="Etape Ngole" />
                            {/* </a> */}
                            <p className="t-name color-blue">Etape Ngole</p>
                            <p className="t-position">Senator, UN</p>
                            <p className="t-country color-blue">cameroon</p>
                       </div>
                    </div>
                </Row>
                {/* Apply Now */}
                <Row className="justify-content-center text-center week-btn-sec">
                    <div className="col-12 col-md-8 col-sm-6 col-xl-8 mt-4">
                    <p className="color-grey2 sus-heading-grey">emerge a sustainability champion</p>
                    <a href="https://admin.gosurvey.in/survey/SDG" target='_blank' rel="noreferrer"><div className="m-2"><p className="box-purple mb-3">APPLY TODAY<img className="header-image" alt="Apply Now" src={require('../../assets/images/fp/click.png')} title="Apply Now" /></p></div></a>
                    </div>
                    <p className="font-20">Rolling applications review.</p>
                    <a href="mailto:director@ifspd.eu" title="Email Address" className="font-20 f-weight-700 text-decoration-underline mb-4">Contact: director@ifspd.eu</a>
                </Row>
            </div>
            {/* </div> */}
        </div>
        </>
    )    
}

export default Sustainability;