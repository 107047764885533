import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/about.css';

function Committee(){
    SetMetaData("Presidential Committee", "", "");

    return (
        <div className="site hfeed grid-parent" id="page">
            <div className="site-content" id="content">

                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">

                        <article id="post-1533" className="post-1533 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope="">
                            <div className="inside-article">

                                <header className="entry-header" aria-label="Content">
                                    <h1 className="entry-title title" itemProp="headline">Presidential Committee</h1>
                                </header>

                                <div className="entry-content" itemProp="text">
                                    <div id="pl-1533" className="panel-layout">
                                        <div id="pg-1533-0" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-no-style">
                                            <div id="pgc-1533-0-0" className="panel-grid-cell">
                                                <div id="panel-1533-0-0-0" className="so-panel widget widget_sow-image panel-first-child panel-last-child" data-index="0">
                                                    <div className="so-widget-sow-image so-widget-sow-image-default-d6014b76747a-1533">

                                                        <div className="sow-image-container">
                                                            <img src={require("../../assets/images/main/body/inner-pages-grapshic-element.jpg")} width="1920" height="231" sizes="(max-width: 1920px) 100vw, 1920px" title="inner pages grapshic element" alt="" loading="lazy" className="so-widget-image" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1533-1" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="ifpresidents-page panel-row-style panel-row-style-for-1533-1">
                                                <div id="pgc-1533-1-0" className="panel-grid-cell">
                                                    <div id="panel-1533-1-0-0" className="so-panel widget widget_siteorigin-panels-builder panel-first-child" data-index="1">
                                                        <div id="pl-w61e55944b01d7" className="panel-layout">
                                                            <div id="pg-w61e55944b01d7-0" className="panel-grid panel-no-style">
                                                                <div id="pgc-w61e55944b01d7-0-0" className="panel-grid-cell">
                                                                    <div id="panel-w61e55944b01d7-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0">
                                                                        <div className="ifpresidents-photo panel-widget-style panel-widget-style-for-w61e55944b01d7-0-0-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><img decoding="async" loading="lazy" className="alignnone wp-image-7828" src={require("../../assets/images/main/committee/Sangeeth-Varghese.jpg")} alt="" width="180" height="168" /></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="pgc-w61e55944b01d7-0-1" className="panel-grid-cell">
                                                                    <div id="panel-w61e55944b01d7-0-1-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="1">
                                                                        <div className="ifpresidents-title panel-widget-style panel-widget-style-for-w61e55944b01d7-0-1-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <h3>CURRENT PRESIDENT</h3>
                                                                                    <h2>Sangeeth Varghese</h2>
                                                                                    <p>President of IFSPD since 2022; WEF Young Global Leader; Author: 2 Global bestsellers; Founder: LeadCap
                                                                                        Ventures &amp; Trust</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="panel-w61e55944b01d7-0-1-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                                                        <div className="ifpresidents-bio panel-widget-style panel-widget-style-for-w61e55944b01d7-0-1-1">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><strong>Brief biography:</strong></p>
                                                                                    <p>Sangeeth Varghese is a globally acknowledged leadership thinker, ranked among the top 10 in 2011, from the
                                                                                        Harvard and London School of Economics. He is a personal advisor to 3 prime ministers of the world on
                                                                                        matters of leadership and youth development. Varghese is an ambassador of Harvard and listed in the Who's
                                                                                        Who in the World, 2012-13. He has consulted for nations from Africa and Asia, institutions like UNO, World
                                                                                        Bank, and Fortune 500 corps like Microsoft, Intel &amp; Unilever.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-1533-1-0-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                                        <div className="ifpresidents-history panel-widget-style panel-widget-style-for-1533-1-0-1">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Varghese was nominated as the Young Global Leader 2010 by the World Economic Forum. Varghese is the author global
                                                                        bestsellers 'Open Source Leader' and 'Decide to Lead'. He was honoured as the first Asian Youth Ambassador by the
                                                                        government of Libya. Varghese is a board member of the Global Business Oath of Harvard University. He is a
                                                                        co-founder of Bangalore Biz Lit Fest and Future 2050 and a contributing editor for Forbes and Economic Times. He was
                                                                        chosen as the face of the annual report of the Centre for Creative Leadership, USA, considering his unique
                                                                        contributions to leadership development. Varghese was chosen to guest edit the New Year edition of Times of India.
                                                                        Prior to this, Varghese was a Principal Consultant for UNO in London.</p>
                                                                    <p>Varghese has the unique distinction of being part of the core teams that helped India's telecom and computer
                                                                        penetration grow from 1 to 50%. He researched leadership at Harvard and the London School of Economics with
                                                                        scholarships. He has also done his MBA and Bachelor's in Economics with gold medals.</p>
                                                                    <p>Emerald Insights recently published a case study about Varghese and his life story.</p>
                                                                    <p>“Varghese is changing the world we live in. He is empowering our world with millions of young leaders”
                                                                        <strong>Prof. John Kotter, Harvard Business School and No.1 Business Thinker</strong>
                                                                    </p>
                                                                    <p>“Varghese is driven by the core values of detachment and democratization, which is reflected throughout his life
                                                                        course” <strong>Emerald Insights</strong></p>
                                                                    <p>“Varghese is nurturing a culture of leadership mindset globally. He is one of the most authentic and powerful
                                                                        thinkers” <strong>Centre for Creative Leadership, Annual Report</strong></p>
                                                                    <p>“'Young Achiever Making a Difference to India's Future.” <strong>The Times of India</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1533-2" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="ifpresidents-page panel-row-style panel-row-style-for-1533-2">
                                                <div id="pgc-1533-2-0" className="panel-grid-cell">
                                                    <div id="panel-1533-2-0-0" className="so-panel widget widget_siteorigin-panels-builder panel-first-child" data-index="3">
                                                        <div id="pl-w616fde0090cdd" className="panel-layout">
                                                            <div id="pg-w616fde0090cdd-0" className="panel-grid panel-no-style">
                                                                <div id="pgc-w616fde0090cdd-0-0" className="panel-grid-cell">
                                                                    <div id="panel-w616fde0090cdd-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0">
                                                                        <div className="ifpresidents-photo panel-widget-style panel-widget-style-for-w616fde0090cdd-0-0-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><img decoding="async" loading="lazy" className="alignnone wp-image-4831" src={require("../../assets/images/main/committee/kostov_photo.jpg")} alt="Dimitar Kostov" width="180" height="261" /></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="pgc-w616fde0090cdd-0-1" className="panel-grid-cell">
                                                                    <div id="panel-w616fde0090cdd-0-1-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="1">
                                                                        <div className="ifpresidents-title panel-widget-style panel-widget-style-for-w616fde0090cdd-0-1-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <h3>VICE-PRESIDENT</h3>
                                                                                    <h2>Dimitar Kostov</h2>
                                                                                    <p>President of IFSPD since June 2017 till 2019.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="panel-w616fde0090cdd-0-1-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                                                        <div className="ifpresidents-bio panel-widget-style panel-widget-style-for-w616fde0090cdd-0-1-1">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><strong>Brief biography:</strong></p>
                                                                                    <p>Dimitar Kostov was born on September 8, 1932 in Staroselets, Pleven, Bulgaria.<br/>
                                                                                        Graduated from the Faculty of Law of Sofia University and the Diplomatic Academy in Moscow.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-1533-2-0-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="4">
                                                        <div className="ifpresidents-history panel-widget-style panel-widget-style-for-1533-2-0-1">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>In the Ministry of Foreign Affairs from 1964 to 1992</p>
                                                                    <p>1964-1968 – Member of the Bulgarian Delegation at the Conference on Disarmament in Geneva</p>
                                                                    <p>1968-1972 – Second Secretary in the Permanent Mission to the United Nations in New York</p>
                                                                    <p>1977-1982 – Ambassador, Deputy of the Permanent Representative to the United Nations in New York</p>
                                                                    <p>1982- 1988 – Director of the Department “UN and Disarmament” in the Ministry of Foreign Affairs</p>
                                                                    <p>1988-1992 – Ambassador, Permanent Representative to the United Nations in Geneva and New York.</p>
                                                                    <p>1995-1999 – Secretary of the Legislative Council of the People’s Assembly</p>
                                                                    <p>2002-2006 – Member of the Presidential Council for European Integration</p>
                                                                    <p>2002-2012 – Chairman of the Bulgarian Diplomatic Association</p>
                                                                    <p>Since 2012 the Honorary Chairman of the Bulgarian Diplomatic Association</p>
                                                                    <p>Chairman of the UN Commission on Disarmament</p>
                                                                    <p>Deputy of the Chairman of the UN Economic and Social Council and Chairman of the Social Committee</p>
                                                                    <p>Deputy of the Chairman of the UN Committee on the Non-Use of Force.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1533-3" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="ifpresidents-page panel-row-style panel-row-style-for-1533-3">
                                                <div id="pgc-1533-3-0" className="panel-grid-cell">
                                                    <div id="panel-1533-3-0-0" className="so-panel widget widget_siteorigin-panels-builder panel-first-child" data-index="5">
                                                        <div id="pl-w616fdebbda751" className="panel-layout">
                                                            <div id="pg-w616fdebbda751-0" className="panel-grid panel-no-style">
                                                                <div id="pgc-w616fdebbda751-0-0" className="panel-grid-cell">
                                                                    <div id="panel-w616fdebbda751-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0">
                                                                        <div className="ifpresidents-photo panel-widget-style panel-widget-style-for-w616fdebbda751-0-0-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><img decoding="async" loading="lazy" className="alignnone wp-image-4871" src={require("../../assets/images/main/committee/abdullayev_photo.jpg")} alt="" width="180" height="261" /></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="pgc-w616fdebbda751-0-1" className="panel-grid-cell">
                                                                    <div id="panel-w616fdebbda751-0-1-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="1">
                                                                        <div className="ifpresidents-title panel-widget-style panel-widget-style-for-w616fdebbda751-0-1-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <h2>Chingiz Abdullayev</h2>
                                                                                    <p>President of BSCSIF since May 2015.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="panel-w616fdebbda751-0-1-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                                                        <div className="ifpresidents-bio panel-widget-style panel-widget-style-for-w616fdebbda751-0-1-1">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><strong>Brief biography:</strong></p>
                                                                                    <p>Chingiz Abdullayev Akifovich was born on April 7, 1959, in Baku, Azerbaijan.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-1533-3-0-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="6">
                                                        <div className="ifpresidents-history panel-widget-style panel-widget-style-for-1533-3-0-1">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>Member of the USSR Union of Writers and a member of Union of Azerbaijani Writers since 1989. Vice-president of the
                                                                        Azerbaijan PEN-Club since 1990.</p>
                                                                    <p>In March 1991, at the IX congress of the Union of Azerbaijani Writers was elected as a Secretary of Board of the
                                                                        Union of Azerbaijani Writers.</p>
                                                                    <p>In October 1997, at the X Congress of the Union of Azerbaijani Writers was re-elected as a Secretary of Board of
                                                                        the Union of Azerbaijani Writers.</p>
                                                                    <p>In May 2004, at the XI Congress of the Union of Azerbaijani Writers was re-elected as a Secretary of Board of the
                                                                        Union of Azerbaijani Writers.</p>
                                                                    <p>The national writer of Azerbaijan – since July, 2005.</p>
                                                                    <p>Professor of the Department of Criminal Law of the Azerbaijan International University – since November, 2006.</p>
                                                                    <p>In March 2009 – one of the founders of the International Fund for Cooperation and Partnership of the Black Sea and
                                                                        the Caspian Sea.</p>
                                                                    <p>From February 2010 to December 2013 – Member of the Board of State Prizes of the Republic of Azerbaijan on science,
                                                                        culture and literature.</p>
                                                                    <p>In January 2011 was elected as a President of the PEN – Club of Azerbaijan.</p>
                                                                    <p>Since July 2011 – member of the Coordination Council of World Azerbaijanis.</p>
                                                                    <p>In 2011-2012, Chingiz Abdullayev acted as an Executive producer of the documentary film by Andrew Thompson and Lucy
                                                                        Bailey “The Waiting” / United Kingdom, 2012.</p>
                                                                    <p>Since September 2013 – Member of the Board of Directors of the International Fund for Cooperation and Partnership
                                                                        of the Black Sea and the Caspian Sea.</p>
                                                                    <p>Since December 2013 – Member of the Board of State Prizes of the Republic of Azerbaijan on science, technology,
                                                                        architecture, culture and literature.</p>
                                                                    <p>In June 2014 at the XII Congress of the Union of Azerbaijani Writers reconfirmed as a Secretary of the Union of
                                                                        Azerbaijani Writers.</p>
                                                                    <p>Since October 2014 – Honorary Doctor of the University of Library Science and Information Technology (Sofia,
                                                                        Bulgaria).</p>
                                                                    <p>Since March 31, 2015 – Chairman of the Supervisory Board of the professional football club “Neftchi” (Baku).</p>
                                                                    <p>Since May 23, 2015 President of the International Fund for Cooperation and Partnership of the Black Sea and the
                                                                        Caspian Sea.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1533-4" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="ifpresidents-page panel-row-style panel-row-style-for-1533-4">
                                                <div id="pgc-1533-4-0" className="panel-grid-cell">
                                                    <div id="panel-1533-4-0-0" className="so-panel widget widget_siteorigin-panels-builder panel-first-child" data-index="7">
                                                        <div id="pl-w5f3272345bad5" className="panel-layout">
                                                            <div id="pg-w5f3272345bad5-0" className="panel-grid panel-no-style">
                                                                <div id="pgc-w5f3272345bad5-0-0" className="panel-grid-cell">
                                                                    <div id="panel-w5f3272345bad5-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0">
                                                                        <div className="ifpresidents-photo panel-widget-style panel-widget-style-for-w5f3272345bad5-0-0-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><img decoding="async" loading="lazy" className="alignnone wp-image-4884" src={require("../../assets/images/main/committee/safi_photo-345x500.jpg")} alt="Ismail Safi" width="180" height="261" /></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="pgc-w5f3272345bad5-0-1" className="panel-grid-cell">
                                                                    <div id="panel-w5f3272345bad5-0-1-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="1">
                                                                        <div className="ifpresidents-title panel-widget-style panel-widget-style-for-w5f3272345bad5-0-1-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <h2>Dr. Ismail Safi</h2>
                                                                                    <p>President of the BSCSIF since September 2013.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="panel-w5f3272345bad5-0-1-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                                                        <div className="ifpresidents-bio panel-widget-style panel-widget-style-for-w5f3272345bad5-0-1-1">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><strong>Brief biography:</strong></p>
                                                                                    <p>Dr. Ismail Safi was born on January 20 in 1968 in Rize, Turkey.<br/>
                                                                                        He graduated from the Hacettepe University, the Department of Public Administration in 1994 and received his
                                                                                        Master’s and PhD degree from the University of Ankara in the field of Political Science.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-1533-4-0-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="8">
                                                        <div className="ifpresidents-history panel-widget-style panel-widget-style-for-1533-4-0-1">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>2002 – 2010 – Held office as the chief of Eurasian Democracy Association</p>
                                                                    <p>2010 – 2017 – Held office as the chief of Eurasian Civil Society Cooperation Association</p>
                                                                    <p>2013 – 2015 – Held office the President of the International Fund for Cooperation and Partnership of the Black Sea
                                                                        and the Caspian Sea</p>
                                                                    <p>2015 – 2017 – As the head of Advisory Committee of Turkish American Business Association</p>
                                                                    <p>2008 – 2016 – As OSCE Electoral Observer, he attended the elections in Azerbaijan, Kirghizstan, Georgia,
                                                                        Kazakhstan, Uzbekistan.</p>
                                                                    <p>2012 – He was invited to the United States within the framework of “Visitor Leadership” program hosted by the
                                                                        American Ministry of Foreign Affairs. He watched the American Presidential Elections and researched on American
                                                                        Electoral System</p>
                                                                    <p>2001 – He is a founding member of the Justice and Development Party (AK Party)</p>
                                                                    <p>2002 – 2007 – Held office as the vice chairman of AK Party Foreign Affairs</p>
                                                                    <p>2002 – 2010 – Member of the AK Party Foreign Affairs Advisory Committee</p>
                                                                    <p>2008 – 2011 – Assistant Secretary -General of AK Party</p>
                                                                    <p>2011 – 2015 – He was elected as Istanbul Member of Parliament in the Grand National Assembly of Turkey</p>
                                                                    <p>2011 – 2015 – He was assigned as the member of Foreign Affairs Commission of Grand National Assembly of Turkey</p>
                                                                    <p>2013 – 2015 – Member of NATO Parliamentary Assembly</p>
                                                                    <p>2011 – 2013 – chairman of the Turkish Group of Black Sea Economic Cooperation Parliamentary Assembly (KEİPA)</p>
                                                                    <p>2011 – 2015 – He was elected as the member of inter – parliamentary friendship group; and held office as the vice
                                                                        chairman of friendship groups of Russia, Kazakhstan, Holland and Italy</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1533-5" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="ifpresidents-page panel-row-style panel-row-style-for-1533-5">
                                                <div id="pgc-1533-5-0" className="panel-grid-cell">
                                                    <div id="panel-1533-5-0-0" className="so-panel widget widget_siteorigin-panels-builder panel-first-child" data-index="9">
                                                        <div id="pl-w5f3272345c136" className="panel-layout">
                                                            <div id="pg-w5f3272345c136-0" className="panel-grid panel-no-style">
                                                                <div id="pgc-w5f3272345c136-0-0" className="panel-grid-cell">
                                                                    <div id="panel-w5f3272345c136-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0">
                                                                        <div className="ifpresidents-photo panel-widget-style panel-widget-style-for-w5f3272345c136-0-0-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><img decoding="async" loading="lazy" className="alignnone wp-image-4890" src={require("../../assets/images/main/committee/hasanov_photo.jpg")} alt="Eldar Hasanov" width="180" height="260" /></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="pgc-w5f3272345c136-0-1" className="panel-grid-cell">
                                                                    <div id="panel-w5f3272345c136-0-1-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="1">
                                                                        <div className="ifpresidents-title panel-widget-style panel-widget-style-for-w5f3272345c136-0-1-0">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <h2>Prof. Dr. Eldar Hasanov</h2>
                                                                                    <p>Secretary-General, Vice-president of IFSPD since March 2013.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="panel-w5f3272345c136-0-1-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                                                        <div className="ifpresidents-bio panel-widget-style panel-widget-style-for-w5f3272345c136-0-1-1">
                                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                                    <p><strong>Brief biography:</strong></p>
                                                                                    <p>Prof. Dr. Eldar Hasanov was born on October 10, 1955 in Kelbajar, Azerbaijan Republic.<br/>
                                                                                        He graduated from the Azerbaijan Technical Institute in 1972-1977 and Academy of Ministry of Internal
                                                                                        Affairs of USSR, law faculty in 1978-1983.</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-1533-5-0-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="10">
                                                        <div className="ifpresidents-history panel-widget-style panel-widget-style-for-1533-5-0-1">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>1977-1990 – Ministry of Internal Affairs, inspector, chief inspector, chief of the Criminal Investigation
                                                                        department, head of polis department, head of criminal investigation department of Baku.</p>
                                                                    <p>1990-1992 – Head of Police Department of Ganja city (second city of Azerbaijan) of the Republic of Azerbaijan.</p>
                                                                    <p>1992-1993 – Head of National Bureau of INTERPOL in Azerbaijan.</p>
                                                                    <p>1993 – First Deputy Minister of Internal Affairs of the Republic of Azerbaijan.</p>
                                                                    <p>1993-1995 – General Prosecutor’s Office of the Republic of Azerbaijan, First Deputy of General Prosecutor.</p>
                                                                    <p>1995-2000 – General Prosecutor of the Republic of Azerbaijan.</p>
                                                                    <p>2000 – Azerbaijan Pedagogical University, assistant professor.</p>
                                                                    <p>January 1, 2001 – Coordinator of International Council on Alcohol and Addictions and UN programmes for CIS
                                                                        countries and Turkey.</p>
                                                                    <p>March 28, 2013 – Ambassador Extraordinary and Plenipotentiary of the Republic of Azerbaijan to Romania.</p>
                                                                    <p>September 14, 2009 – Ambassador Extraordinary and Plenipotentiary of the Republic of Azerbaijan to Republic of
                                                                        Serbia with residence in Bucharest.</p>
                                                                    <p>July 28, 2010 – Ambassador Extraordinary and Plenipotentiary of the Republic of Azerbaijan to Montenegro with
                                                                        residence in Bucharest.</p>
                                                                    <p>August 30, 2010 – Ambassador Extraordinary and Plenipotentiary of the Republic of Azerbaijan to Bosnia and
                                                                        Herzegovina with residence in Bucharest.</p>
                                                                    <p>2001-2011 – Official representative of the Republic of Azerbaijan to the South-Eastern Cooperation Initiative
                                                                        Regional Center for Trans-border Crimes (Bucharest, Romania).</p>
                                                                    <p>Since March 28, 2013 – Ambassador Extraordinary and Plenipotentiary of the Republic of Azerbaijan to the Republic
                                                                        of Serbia, Bosnia and Herzegovina, Montenegro with residence in Belgrade.</p>
                                                                    <p>Vice-Chairman of the International Union of Lawyers (Moscow, Russian Federation).</p>
                                                                    <p>Member of the European Association of Lawyers for Democracy and World Human Rights (Dusseldorf, Germany).</p>
                                                                    <p>Member of International Council on Alcohol and Addictions (ICAA) and Director of regional office of ICAA for
                                                                        Eastern Europe and Central Asia (Bucharest, Romania).</p>
                                                                    <p>Honorary member of the National Anti-Drugs Agency (Bucharest, Romania).</p>
                                                                    <p>Vice-President and Secretary-General of the International Foundation for Sustainable Peace and Development (central
                                                                        office Bucharest, Romania).</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </article>
                    </main>
                </div>


            </div>
        </div>
    )    
}

export default Committee;