import React from "react";
import {SetMetaData} from './../helper/page-title';
import Branches from "../components/home/branches";

function Contact(){
    SetMetaData("Contact", "", "");

    return (
        <div className="site hfeed grid-parent" id="page">
        <div className="site-content" id="content">
           <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
              <main className="site-main" id="main">
                 <article id="post-1750" className="post-1750 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope="">
                    <div className="inside-article">
                       <header className="entry-header" aria-label="Content">
                          <h1 className="entry-title title" itemProp="headline">Contact</h1>
                       </header>
                       <div className="entry-content" itemProp="text">
                          <div id="pl-1750" className="panel-layout">
                             <div id="pg-1750-0" className="panel-grid panel-no-style container-fluid container-lg pe-lg-5 ps-lg-5 justify-content-center pb-md-5 pb-4 d-block">
                                <div id="pgc-1750-0-0" className="panel-grid-cell">
                                   <div id="panel-1750-0-0-0" className="widget_text so-panel widget widget_custom_html panel-first-child panel-last-child" data-index="0">
                                        <div className="textwidget custom-html-widget">
                                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2932.3492944033196!2d23.314583614894175!3d42.69632422179281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa856bcecf2355%3A0xce11a116523320a5!2sul.+%22Pozitano%22+9%2C+1000+Sofia+Center%2C+Sofia%2C+Bulgaria!5e0!3m2!1str!2str!4v1520336150152" style={{width: '100%', height: '400px'}} allowfullscreen="" frameBorder="0"></iframe> */}
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d2932.341200795094!2d23.314269777523574!3d42.696495621163656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d42.6966409!2d23.3168158!4m5!1s0x40aa856bcecf2355%3A0xce11a116523320a5!2sul.%20%22Pozitano%22%209%201000%20Sofia%20Center%2C%20Sofia%20Bulgaria!3m2!1d42.6963187!2d23.316813099999997!5e0!3m2!1sen!2sin!4v1698557261512!5m2!1sen!2sin" style={{width: '100%', height: '400px'}} allowFullScreen="" frameBorder="0" title="Google Map"></iframe>
                                        </div>
                                   </div>
                                </div>
                             </div>
                             <div id="pg-1750-1" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                <div className="ifcontact-page-form panel-row-style panel-row-style-for-1750-1 justify-content-center">
                                   <div id="pgc-1750-1-0" className="panel-grid-cell">
                                      <div id="panel-1750-1-0-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="1">
                                         <div className="panel-widget-style panel-widget-style-for-1750-1-0-0">
                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                               <div className="siteorigin-widget-tinymce textwidget">
                                                    <h2 className="pb-4"><a href="mailto:director@ifspd.eu">Send us a message</a></h2>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                      {/* <div id="panel-1750-1-0-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                         <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                            <div className="siteorigin-widget-tinymce textwidget">
                                               <div role="form" className="wpcf7" id="wpcf7-f1800-p1750-o1" lang="en-GB" dir="ltr">
                                                  <div className="screen-reader-response">
                                                     <p role="status" aria-live="polite" aria-atomic="true"></p>
                                                     <ul></ul>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                      </div> */}
                                   </div>
                                </div>
                             </div>
                             <div id="pg-1750-2" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                <div className="ifcontact-page-creds panel-row-style panel-row-style-for-1750-2 justify-content-center">
                                   <div id="pgc-1750-2-0" className="panel-grid-cell">
                                      <div id="panel-1750-2-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="3">
                                         <div className="panel-widget-style panel-widget-style-for-1750-2-0-0">
                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                               <div className="siteorigin-widget-tinymce textwidget">
                                                  <h2 className="mb-md-5 mb-4 text-center title">Credentials and Branches Contacts</h2>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                             <Branches />
                          </div>
                       </div>
                    </div>
                 </article>
              </main>
           </div>
        </div>
     </div>
    )    
}

export default Contact;