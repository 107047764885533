import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/about.css';

function History(){
    SetMetaData("History", "", "");

    return (
        <div className="site hfeed grid-parent" id="page">
            <div className="site-content" id="content">
                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">
                        <article id="post-57" className="post-57 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope="">
                            <div className="inside-article">

                                <header className="entry-header" aria-label="Content">
                                    <h1 className="entry-title title" itemProp="headline">History</h1>
                                </header>

                                <div className="entry-content" itemProp="text">
                                    <div id="pl-57" className="panel-layout">
                                        <div id="pg-57-0" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="ifhistory-page panel-row-style panel-row-style-for-57-0">
                                                <div id="pgc-57-0-0" className="panel-grid-cell">
                                                    <div id="panel-57-0-0-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="0">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <h2>Who We Are</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-57-0-0-1" className="so-panel widget widget_sow-image" data-index="1">
                                                        <div className="so-widget-sow-image so-widget-sow-image-default-d6014b76747a-57">

                                                            <div className="sow-image-container">
                                                                <img src={require("../../assets/images/main/body/inner-pages-grapshic-element.jpg")} width="1920" height="231" sizes="(max-width: 1920px) 100vw, 1920px" title="inner pages grapshic element" alt="" loading="lazy" className="so-widget-image" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div id="panel-57-0-0-2" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                                        <div className="ifhightlight panel-widget-style panel-widget-style-for-57-0-0-2">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>The International Foundation for Sustainable Peace and Development (IFSPD), formerly known as the International
                                                                        Fund for Cooperation and Partnership of the Black Sea and the Caspian Sea (BSCSIF) is an international,
                                                                        non-governmental and non-profit organization, established on March 4, 2009, upon the initiative of distinguished
                                                                        intellectuals, scientific and public figures, diplomats, representatives of influential non-governmental
                                                                        organizations from Azerbaijan, Georgia, Kazakhstan, Kyrgyzstan, Republic of Moldova, Romania, Turkey and Ukraine.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-57-1" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="ifhistory-page panel-row-style panel-row-style-for-57-1">
                                                <div id="pgc-57-1-0" className="panel-grid-cell panel-grid-cell-empty"></div>
                                                <div id="pgc-57-1-1" className="panel-grid-cell">
                                                    <div id="panel-57-1-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="3">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>This initiative was unique due to the complementary approach of Black Sea and Caspian Sea regions and the attempt to
                                                                    harmonize the interests of the neighboring countries in order to identify common goals and common means for the
                                                                    integrated development. In order to help people belonging to this regions to enhance the cooperation between their
                                                                    countries and with international organizations; distinguished representatives of civil society, scientific
                                                                    researchers, academics and diplomats decided to establish an organism that would be an alternative to the rigidity of
                                                                    the official high level relations and to pledge for people-to-people contacts and the dialogue between experts in
                                                                    various fields of activity.</p>
                                                                <p>Later, in 2011-2015, BSCSIF was joined by the representatives from countries such as: Albania, Austria, Belarus,
                                                                    Bosnia and Herzegovina, Bulgaria, China, Croatia, Czech Republic, Estonia, Ghana, Greece, Hungary, Iran, Luxemburg,
                                                                    Macedonia, Montenegro, Russian Federation, Sao Tome and Principe, Republic of Serbia, Slovenia, Republic of South
                                                                    Africa, Sri Lanka, Swiss Confederation. Thus, the activities of the organization geographically moved beyond the
                                                                    regions of the Black Sea and Caspian Sea.</p>
                                                                <p>From this perspective on June 10, 2017, the members agreed during the 16th sessions of the General Assembly and the
                                                                    Board of Directors upon the idea of changing the name of the organization in order to fully reflect its objective
                                                                    reality.</p>
                                                                <p><strong>On this basis, and with due respect to its history and maintaining its continuity in accordance with its
                                                                        Charter, goals and objectives, BSCSIF was renamed to International Foundation for Sustainable Peace and Development
                                                                        (IFSPD).</strong></p>
                                                                <p>IFSPD as the successor of BSCSIF is in Special Consultative Status with the Economic and Social Council (ECOSOC),
                                                                    since August 1, 2013.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-57-2" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="ifhistory-page panel-row-style panel-row-style-for-57-2">
                                                <div id="pgc-57-2-0" className="panel-grid-cell">
                                                    <div id="panel-57-2-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="4">
                                                        <div className="ifquotmark panel-widget-style panel-widget-style-for-57-2-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><img decoding="async" loading="lazy" className="alignnone wp-image-7225 size-full" src={require("../../assets/images/main/body/img-quotmark.jpg")} alt="quot" width="50" height="46" /></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-57-2-1" className="panel-grid-cell panel-grid-cell-mobile-last ms-0 ms-md-5 pe-0 pe-md-5">
                                                    <div id="panel-57-2-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="5">
                                                        <div className="ifquot panel-widget-style panel-widget-style-for-57-2-1-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><em>Consultative status for an organization enables it to actively engage with ECOSOC and its subsidiary bodies, as
                                                                            well as with the United Nations Secretariat, programmes, funds and agencies in a number of ways.</em></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-57-2-2" className="panel-grid-cell panel-grid-cell-empty"></div>
                                            </div>
                                        </div>
                                        <div id="pg-57-3" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="ifhistory-page panel-row-style panel-row-style-for-57-3">
                                                <div id="pgc-57-3-0" className="panel-grid-cell panel-grid-cell-empty"></div>
                                                <div id="pgc-57-3-1" className="panel-grid-cell">
                                                    <div id="panel-57-3-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="6">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>As a think tank, IFSPD has collaborated with world-renowned consultants and experts from Canada, Great Britain,
                                                                    Italy, Romania, Belgium, Switzerland, Turkey, Russia, Serbia, Kazakhstan, Bulgaria, Azerbaijan, Ukraine and Montenegro
                                                                    on more than 60 research projects, such as:</p>
                                                                <p><strong><em>From the Caspian Sea to the Black Sea — A Strategic View; Multiculturalism in the Context of the Modern
                                                                            Globalizing World; European Identity; Abuse of the Principle of Self-Determination of Peoples and the Response of
                                                                            the International Community; Impact of Information Technology on Sustainable Development; Multiculturalism as a
                                                                            Necessary Element for the Cultural Component of the Project One Belt, One Road; Conflict Resolution, IFSPD Thought
                                                                            Leadership – Global Sustainability Impact Index; The Impact of Technology on Democracy in the 21st Century; The
                                                                            Role of Civil Society in Developing Countries; The Role of Mass Media in the Development of Society; The Role of
                                                                            Public Diplomacy in the Modern World; The Role and Place of Modern Youth in the Dialogue between Civilizations,
                                                                            etc., </em></strong></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    )    
}

export default History;