import React, {useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import { RSstandardStyle } from "../../config/react-select";
import {post_api_call, get_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
import {LoaderMedium} from './../../components/loader';
import { yesNoData } from "../../components/select/option";
const initialFormData = {
    desc: {
        problem: '',
        distance: '',
        positive: '',
        teamwork: '',
        measurable: ''
    }
};

function StepTwoForm(props){
    const [pageLoading, setPageLoader] = useState(true);
    const [domainList, setDomainList] = useState([]);
    const [formDataDomain, setFormDataDomain] = useState([]);
    const [formDataDesc, setFormDataDesc] = useState({...initialFormData.desc});
    const [errorData, setErrorData] = useState({Domain: [],
                                                Desc: [],
                                            });
    const [isProcess, setIsProcess] = useState({Domain: false,
                                                Desc: false,
                                            });
    const [isBtnDisabled, setBtnDisabled] = useState({Domain: true,
                                                Desc: true,
                                            });
    //Get Data from APIs
    const fetchSelectList = async(type, url) => {
        try{
            const selectApiData = await get_api_call(url, {});
            if(selectApiData.status === 200){
                switch (type) {
                    case 'domain':
                        setDomainList(selectApiData.data);
                        break;
                    default: 
                }
            }
        }catch(e){
			error_api_call({error: `social|step-two|fetchSelectList|${e}`});
        }
    }
    useEffect(() => {
        setPageLoader(false);
        //Get Portfolio Details
        // const fetchPortfolioData = async() => {
        //     try{
        //         const portfolioApiData = await post_api_call(`portfolio-details`, {});
        //         // const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
        //         if(portfolioApiData.status === 200){
        //             console.log(portfolioApiData, 'portfolioApiData')
        //             // if(portfolioApiData.data.headline){
        //             //     window.location.href = `/port/${portfolioApiData.data.url}`;
        //             // }else{
        //                 if(!portfolioApiData.data.headline){
        //                     setIsFirstEdit(true);
        //                 }
        //                 //GeneralDetails
        //                 const fieldKeys = ['first_name' , 'last_name', 'email', 'dob', 'language', 'mobile_number', 'logo'];
        //                 const generalFieldData = {...formDataGeneral};
        //                 fieldKeys.forEach(key => {
        //                     generalFieldData[key] = portfolioApiData.data[key] ? portfolioApiData.data[key] : '';
        //                 });
        //                 generalFieldData.country_id = portfolioApiData.data.country_id ? {id: portfolioApiData.data.country_id, name: portfolioApiData.data.country} : '';
        //                 generalFieldData.phone_code_id = portfolioApiData.data.mobile_code_id ? {id: portfolioApiData.data.mobile_code_id, phone_code: portfolioApiData.data.phone_code} : '';
        //                 generalFieldData.school_name_id = portfolioApiData.data.school_id ? {id: portfolioApiData.data.school_id, school_name: portfolioApiData.data.school_name} : '';
        //                 generalFieldData.cohort_name_id = portfolioApiData.data.cohort_id ? {id: portfolioApiData.data.cohort_id, cohort_name: portfolioApiData.data.cohort_name} : '';
        //                 setFormDataGeneral({...generalFieldData});
        //                 setUserUrl(portfolioApiData.data.user_name);
        //                 //Headline
        //                 setFormDataHeadline(portfolioApiData.data.position || '');
        //                 //Short Description
        //                 setFormDataDesc({short_description: portfolioApiData.data.headline || '',
        //                                 basic_achievement: portfolioApiData.data.achievement || '',
        //                                 basic_passion: portfolioApiData.data.passion || ''});
        //                 //Unique Skills
        //                 if(portfolioApiData.data.unique_skills.length > 0){
        //                     var uniqueData = [];
        //                     portfolioApiData.data.unique_skills.forEach(skill => {
        //                         uniqueData.push({passion_id : {id: skill.passion_id, title: `${skill.title}: ${skill.unique_ability}`}});
        //                     });
        //                     setFormDataUnique(uniqueData);
        //                 }
        //                 //Education
        //                 updateInpState('Edu', portfolioApiData.data.education_details);
        //                 //Achievements
        //                 if(portfolioApiData.data.glosco_data.length > 0){
        //                     var gloscoData = formDataAchieve;
        //                     portfolioApiData.data.glosco_data.forEach(glosco => {
        //                         gloscoData[glosco.level_id-1] = {...gloscoData[glosco.level_id-1],
        //                                                         id: glosco.level_id,
        //                                                         level: glosco.level,
        //                                                         year: {id: glosco.year, year: glosco.year},
        //                                                         indi_award: glosco.indi_award,
        //                                                         team_award: glosco.team_award};

        //                     });
        //                     setFormDataAchieve(gloscoData);
        //                 }
        //                 //Experience
        //                 updateInpState('Exp', portfolioApiData.data.experience_details);
        //                 //Research
        //                 updateInpState('Research', portfolioApiData.data.research_details);
        //                 //Sport
        //                 updateInpState('Sport', portfolioApiData.data.sport_details);
        //                 //Award
        //                 updateInpState('Award', portfolioApiData.data.award_details);
        //                 //Career Skills
        //                 if(portfolioApiData.data.carrer_skill_details.length > 0){
        //                     setFormDataCareer(portfolioApiData.data.carrer_skill_details);
        //                 }
        //                 //Personal Skills
        //                 if(portfolioApiData.data.personal_skill_details.length > 0){
        //                     setFormDataPersonal(portfolioApiData.data.personal_skill_details);
        //                 }
        //                 // setFormData({...fieldData});
        //                 setPageLoader(false);
        //             // }
        //         }
        //     }catch(e){
        //         console.log(e);
        //         alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
        //         error_api_call({error: `portfolio/edit|fetchPortfolioData|${e}`});
        //     }
        // }
        // fetchPortfolioData();
		//Initial API Data
        fetchSelectList('domain', 'domains');
	}, []);
    //Update Input
    const updateInput = (identifier_key, identifier_type, input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            console.log(identifier_key, input_type, field, value, field_type, form_field, index, 'inputtt')
            if(errorData[identifier_key].length){
                setErrorData({...errorData, [identifier_key]: []});
            }
            setBtnDisabled({...isBtnDisabled, [identifier_key]: false});
            if(input_type === 'input'
                || input_type === 'textarea'
            ){
                value = value.trimStart();
            }
            var fieldData = eval('formData'+identifier_key);
            console.log(fieldData, 'fieldDatafieldDatafieldData')
            if(identifier_type === 'object'){
                fieldData = {...fieldData}
            }else if(identifier_type === 'array'){
                fieldData = [...fieldData]
            }
            if(field_type === 'array'){
                fieldData[index][field] = value;
            }else{
                if(field === 'Domain'){
                    var domainData = [];
                    value.forEach(domain => {
                        var domainName = domain.domain.split('-');
                        domainData.push({id: domain.id, domain: domainName[0], example: ''});
                    });
                    value = domainData;
                }
                identifier_type === 'single_value' ? fieldData = value : fieldData[field] = value;
            }
            switch (identifier_key) {
                case 'Domain':
                    setFormDataDomain([...fieldData]);
                    break;
                case 'Desc':
                    setFormDataDesc({...fieldData});
                    break;
                default:
            }
        }catch(e){
            console.log(e);
            error_api_call({error: `social|step-two|updateInput|${e}`});
        }
    }
    //Add Fellow on Submit Click
    const editSocialTwo = async(e, form_type='') => {
        try{
            e.preventDefault();
            setErrorData({...errorData, [form_type]: []});
            setIsProcess({...isProcess, [form_type]: true});
            var errorDetails = {
                Domain: [],
                Desc: [],
            };
            var apiFormData = new FormData();
            //Validation
            var validation = [];
            var validationRules = {};
            switch (form_type) {
                case 'Domain':
                    if(formDataDomain.length > 2){
                        errorDetails[form_type].push(`Select only two Domains`);
                    }else{
                        const domainIds = [];
                        formDataDomain.forEach(award => {
                            domainIds.push(award.id);
                        });
                        apiFormData.append(`domain`, domainIds);
                    }
                    break;
                case 'Desc':
                    validationRules = {
                        problem: 'required||problem',
                        distance: 'required||distance',
                        positive: 'required||positive',
                        teamwork: 'required||teamwork',
                        measurable: 'required||measurable'
                    };
                    const fieldFormDataDesc = {...formDataDesc,
                        problem: formDataDesc.problem.id,
                        distance: formDataDesc.distance.id,
                        positive: formDataDesc.positive.id,
                        teamwork: formDataDesc.teamwork.id,
                        measurable: formDataDesc.measurable.id
                    };
                    validation = ValidationHelper.validation(fieldFormDataDesc, validationRules).error;
                    if(validation.length){
                        // errorDetails[form_type] = validation;
                        errorDetails[form_type] = ['Please Fill all the Select Boxes'];
                    }else{
                        if(fieldFormDataDesc.problem === 1
                            && fieldFormDataDesc.distance !== 3
                            && fieldFormDataDesc.positive === 1
                            && fieldFormDataDesc.teamwork === 1
                            && fieldFormDataDesc.measurable === 1
                        ){
                            apiFormData.append('desc_data', JSON.stringify(fieldFormDataDesc));
                        }else{
                            errorDetails[form_type] = ['Select a social start-up that confirms to the guidelines above.'];
                        }
                    }
                    break;
                default:
            }
            console.log(errorDetails[form_type], 'errorDetails[form_type]')
            if(errorDetails[form_type].length > 0){
                setErrorData({...errorData, [form_type]: errorDetails[form_type]});
                setIsProcess({...isProcess, [form_type]: false});
                setBtnDisabled({...isBtnDisabled, [form_type]: true});
            }else{
                //Delete Later
                if(form_type === 'Desc'){
                    props.updateFormStep({next_step: 3,
                        // social_id: socialId,
                        // url: socialUrl,
                        // url: 'demo',
                        stage_id: props.data.stage_id < 2 ? 2 : props.data.stage_id
                    });
                }
                return;
                //Send Data to Server
                apiFormData.append('update_type', form_type.toLowerCase());
                // const updateFellow = await post_api_call(`update-portfolio/${form_type.toLowerCase()}`, apiFormData);
                const updateFellow = await post_api_call(`update-portfolio`, apiFormData);
				if(updateFellow.status === 200){
                    // setIsSuccess(true);
                    alertResponseMessage({alert_type: 1, message: "Information Updated Successfully"});
                    setBtnDisabled({...isBtnDisabled, [form_type]: true});
                    // setViewDisabled({...isViewDisabled, [form_type]: false});
                    console.log("Information Updated Successfully", 'Information Updated Successfully')
                }else{
                    if(typeof updateFellow.message !== "undefined"){
                        setErrorData({...errorData, [form_type]: [updateFellow.message]});
                        console.log("Errorooo")
                    }
                }
                setIsProcess({...isProcess, [form_type]: false});
            }
            
        }catch(e){
            console.log(e);
            setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
            setIsProcess({...isProcess, [form_type]: false});
            error_api_call({error: `portfolio|edit|editSocialTwo|${e}`});
        }
    }
    return (
        <>
        {pageLoading
        ?
            <div className="text-center mt-5 mb-5">
                <LoaderMedium size={45} />
            </div>
        :
            <>
            <div className="text-center">
                <h2 className="mb-1 mt-4"><b>Step 2: SOCIAL START-UP IDENTIFICATION</b></h2>
                <p>Develop a social perspective and evaluate a possible solution</p>
            </div>
            <div className="col-md-10 col-12 col-sm-12 card p-3">
                Identify a small social problem around you based on following criteria:
                <ul className="mb-4 ms-3">
                    <li>Select from the below list of various domains.</li>
                    <li>With-in 1 km of your educational institution or neighborhood. Or it could be done online.</li>
                    <li>Select a small problem so that you can make an impact in 1 day (though of course you can choose to continue your social start-up).</li>
                    <li>If you have team members, all of you should be able to work together.</li>
                    <li>You need not solve the problem completely, but should be able to create a visible, measurable impact.</li>
                </ul>
                {/* Domain */}
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Select your social start-up domain</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                Click and Select max 2.
                                {/* {domainList.length && (
                                    <ul>
                                        {domainList.map((domain, domain_key) => (
                                            <li key={`domain_${domain_key}`}><b>{domain.domain}</b>- {domain.example}</li>
                                        ))}
                                    </ul>
                                )} */}
                                <br/><br/>
                                <div className="table-responsive">
                                <table className="border-0 table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="w-100">
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    // components={animatedComponents}
                                                    options={domainList} 
                                                    defaultValue={formDataDomain}
                                                    value={formDataDomain}
                                                    label={"Select Domain"}
                                                    onChange={(value) => updateInput('Domain', 'single_value', 'select-react', 'Domain', value, 'multi_select')}
                                                    getOptionLabel ={(option) => `${option.domain}${option.example}`}
                                                    getOptionValue ={(option) => option.id}
                                                    // menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                    styles={RSstandardStyle}
                                                    isMulti
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                    {!isProcess['Domain']
                                    ?
                                        isBtnDisabled['Domain']
                                            ?
                                                <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                            :
                                                <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialTwo(e, 'Domain')}>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                    }
                                
                                    <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['Domain'].length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    <p>Please enter/modify following Data:</p>
                                    <ol>
                                    {errorData['Domain'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* Details */}
                <Accordion className="pt-3" defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Define your social start-up</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        {/* Problem Selected */}
                                        <tr>
                                            <th>Have you already selected a problem that your social start-up would like to address?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataDesc.problem}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Desc', 'object', 'select-react', 'problem', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Distance */}
                                        <tr>
                                            <th>Is this problem within 1km of your educational institution/neighborhood or online?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={[{id: 1, distance: 'Within 1km'},
                                                            {id: 2, distance: 'Online'},
                                                            {id: 3, distance: 'None of the above'},
                                                        ]} 
                                                    defaultValue={formDataDesc.distance}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Desc', 'object', 'select-react', 'distance', value)}
                                                    getOptionLabel ={(option) => option.distance}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Impact */}
                                        <tr>
                                            <th>Through your social start-up, will you be able to create a positive impact within one day?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataDesc.positive}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Desc', 'object', 'select-react', 'positive', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Team Work */}
                                        <tr>
                                            <th>Can all your teammates work together?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataDesc.teamwork}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Desc', 'object', 'select-react', 'teamwork', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Measure */}
                                        <tr>
                                            <th>
                                                Will the final results of your social start-up be measurable in terms of a specific number?<br/><br/>
                                                <ul className="fst-italic fw-normal li-sty-none ms-0">
                                                    <li><b>Example 1:</b> Teach English alphabets to 10 poor kids in my neighborhood.</li>
                                                    <li><b>Example 2:</b> Clear 100% garbage outside my school.</li>
                                                    <li><b>Example 3:</b> Raise Rs.25,000 in donations to aid landslide victims.</li>
                                                </ul>
                                            </th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataDesc.measurable}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Desc', 'object', 'select-react', 'measurable', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['Desc']
                                ?
                                    isBtnDisabled['Desc']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialTwo(e, 'Desc')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['Desc'].length > 0 && (
                                <div className="alert alert-danger mt-4" role="alert">
                                    {/* <p>Please enter/modify following Data:</p> */}
                                    <ol className="li-sty-none ms-0">
                                    {errorData['Desc'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            </>
        }
        </>
    )    
}

export default StepTwoForm;