import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event2(){
    SetMetaData("18th IFSPD Board of Directors Meeting held in Belgrade Serbia", "", "");

    return (
        <div className="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
            <div className="site-content" id="content">
                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                        <article id="post-5217" className="post-5217 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news" itemType="https://schema.org/CreativeWork" itemScope="">
                        <div className="inside-article">
                            <header className="entry-header" aria-label="Content">
                                <h1 className="entry-title title" itemProp="headline">18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)</h1>
                                {/* <div className="entry-meta">
                                    <span className="posted-on"><time className="updated" dateTime="2020-10-19T15:36:41+03:00" itemProp="dateModified">October 19, 2020</time><time className="entry-date published" dateTime="2019-11-02T10:56:48+02:00" itemProp="datePublished">November 2, 2019</time></span> 		
                                </div> */}
                            </header>
                            <div className="featured-image  page-header-image-single ">
                                <img width="1024" height="683" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-026-1024x683.jpg")} className="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                            </div>
                            <div className="entry-content" itemProp="text">
                                <p>The 18<sup>th</sup> Board of Directors Meeting of International Foundation for Sustainable Peace and Development (IFSPD) was held in Belgrade (Serbia).</p>
                                {/* <p>On 26 October 2019, the 18<sup>th</sup> Board of Directors Meeting of International Foundation for Sustainable Peace and Development (IFSPD) was held in Belgrade (Serbia).</p> */}
                                <p>&nbsp;</p>
                                <p>The meeting was opened by IFSPD Vice President and Secretary-General, prof. Eldar Hasanov, Ph.D., who greeted the attendees and noted the IFSPD assignments that have been implemented since the last Board Meeting, namely the opening of the IFSPD branch in Bulgaria – the Bulgarian Foundation for Sustainable Peace and Development, as well as the opening of a new IFSPD Balkan branch office, signed partnership agreements, and research projects that were and will be implemented in 2019.</p>
                                <p>&nbsp;</p>
                                <p>The meeting was also attended by the official representative of the Republic of Serbia His Excellency DejanRalevic, Assistant Minister of Foreign Affairs of Republic of Serbia, who addressed the participants of the meeting with a welcoming speech, and extended warm regards of H.E. IvicaDacic, Minister of Foreign Affairs of Republic of Serbia. Mr.Ralevich also noted that IFSPD’s aims are highly appreciated and promoted, together with its activity as a think tank.</p>
                                <p>&nbsp;</p>
                                <p>During the Board of Directors meeting, individuals and legal entities were accepted by voting as new fully-fledged, namely:</p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li>Mrs. Christina Briggs (Great Britain)</li>
                                    <li>Mrs.LudmilaZhuravleva (Russian Federation)</li>
                                    <li>Mr.BesnikLeskai(Albania)</li>
                                    <li>Mrs.VefaVeysalova (Azerbaijan)</li>
                                    <li>Mr. Syed AdilGilani (Pakistan)</li>
                                    <li>Prof. John Maresca, Ph.D. (USA)</li>
                                    <li>Ms. Aleksandra Manasijevich (Serbia)</li>
                                    <li>Law Firm “Krivtsov&amp; Partners” (Russian Federation)</li>
                                </ol>
                                <p>&nbsp;</p>
                                <p>In addition to the new fully-fledged members, a new honorary member, Mr. Philip Vujanovic (Montenegro), and a new member of the Board of Directors, prof. SabahudinHadzialic, Ph.D. were elected. (Bosnia And Herzegovina).</p>
                                <p>Issues in the field of peace, dialogue, education, science, innovation, the activities of the Foundation’s branches, etc. were also discussed at the meeting. Furthermore, decision has been made to open the South Asian branch of IFSPD in India and, in this way, to intensify the activities of IFSPD in Asia. Prof. Sangeeth Varghese, Ph.D. was appointed as the Director of the branch, as well as the new IFSPD Vice-president.</p>
                                <p>&nbsp;</p>
                                <p>The presentation and launch of the research project “IFSPD Global Sustainability Index” took place during the meeting and the participants noted the importance of the implementation of such strategic projects by IFSPD.</p>
                                <p>&nbsp;</p>
                                <p>International Foundation’s President, Mr.DimitarKostov, Ph.D., presented the report on the activities of the International Foundation during its two-year mandate, noting the successfully completed international conferences, strategically important research projects, humanitarian projects, signed agreements, and other IFSPD activities aimed atachieving goals of the International Foundation. Mr.Kostov, Ph.D. thanked the IFSPD Board of Directors for the comprehensive assistance and support provided during his mandate as International Foundation’s President and expressed strong confidence in the future success of IFSPD.</p>
                                <p>&nbsp;</p>
                                <p>During the meeting, a new IFSPD President was elected. Guided by the principle of respect for gender equality, the leadership nominated female members of the Board of Directors, namely Mrs.ZhyldyzOskonbaeva (USA), Prof. MananaSanadze, Ph.D. (Georgia), Mrs.BiserskaJevtimijevic (Serbia). By democratic, open and transparent voting, Mrs.BiserkaJevtimijevic was elected as the new President of the International Foundation for Sustainable Peace and Development (IFSPD). The new IFSPD President Mrs.Jevtimijevic thanked all the members of the Board of Directors for the trust placed in her and noted that she would continue to lead the International Foundation for the benefit of the goals and objectives of IFSPD, as well as the further development and expansion of its activities in accordance with its Statute.</p>
                                <p>&nbsp;</p>
                                <p>The meeting was successfully completed and the Belgrade Declaration on Education was adopted.</p>
                                <p>&nbsp;</p>
                                <div id="gallery-1" className="gallery galleryid-5217 gallery-columns-4 gallery-size-medium">
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5219" fetchpriority="high" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5219">
                                        1 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-018-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-018-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5220"/></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5220">
                                        2 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-019-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-019-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5221" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5221">
                                        3 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-020-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-020-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5222" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5222">
                                        4 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-021-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-021-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5223" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5223">
                                        5 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-022-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-022-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5224" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5224">
                                        6 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-023-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-023-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5225" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5225">
                                        7 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-024-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-024-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5226" loading="lazy"/></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5226">
                                        8 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-025-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-025-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5227" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5227">
                                        9 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-027-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-027-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5228" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5228">
                                        10 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-028-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-028-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5229" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5229">
                                        11 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-032-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-032-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5233" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5233">
                                        15 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-033-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-033-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5234" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5234">
                                        16 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-035-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-035-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5236" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5236">
                                        18 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-036-768x512.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-036-768x512.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5237" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5237">
                                        19 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-037-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-037-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5238" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5238">
                                        20 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-039-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-039-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5240" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5240">
                                        22 OK
                                    </figcaption>
                                    </figure>
                                    <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                        <a href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-040-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-040-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" aria-describedby="gallery-1-5241" loading="lazy" /></a>
                                    </div>
                                    <figcaption className="wp-caption-text gallery-caption" id="gallery-1-5241">
                                        23 OK
                                    </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        </article>
                    
                        <a className="singlepostlinkback" href="/board-meetings">← Back to the list of all Board Meetings</a>		
                    </main>
                </div>
            </div>
        </div> 
    )
}

export default Event2;