import React, {useState, useEffect} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const divScrollCount = window.innerWidth < 575
						? 1
						: (window.innerWidth > 574 && window.innerWidth < 768) 
							? 2
							: window.innerWidth > 767 && window.innerWidth < 992
							? 4
							: 6;
var slideSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: divScrollCount,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true
    };
function Partner() {
    const [sliderSetting, setSliderSetting] = useState({...slideSettings});

    useEffect(() => {
        //Initial API Data       
        const handleResize = () => {
          const setScrollCount = window.innerWidth < 575
                                ? 1
                                : (window.innerWidth > 574 && window.innerWidth < 768) 
                                  ? 2
                                  : window.innerWidth > 767 && window.innerWidth < 992
                                  ? 4
                                  : 6;
  
          setSliderSetting({...sliderSetting, slidesToScroll: setScrollCount, slidesToShow: setScrollCount});
        }
      
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
        // return () => console.log('my effect is destroying');
      }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="partner-section">
        <Slider {...sliderSetting}>
            <div><a  href="https://www.leadcap.net/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/leadcap-ventures-4.PNG')} alt="LeadCap Ventures" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.teatrulioncreanga.ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/teatrulioncreanga.png')} alt="teatrulioncreanga" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.coca-cola.ro/ro/home/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/cocacola.png')} alt="cocacola" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.romenergo.ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/romenergo.png')} alt="romenergo" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.upetrom1mai.com/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/upetrom.png')} alt="upetrom" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.spiruharet.ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/spiruharet.png')} alt="spiruharet" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.minac.ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/minac.png')} alt="minac" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.siveco.ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/siveco.png')} alt="siveco" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.hmultiplex.ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/hmultiplex.png')} alt="hmultiplex" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.rompetrol.ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/rompetrol.png')} alt="rompetrol" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://oztasar.ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/oztasar.png')} alt="oztasar" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://nirogroup.ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/niro.png')} alt="niro" decoding="async" loading="lazy" /></a></div>
            <div><a  href="http://www.ubbcluj.ro/ro/" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/babes.png')} alt="ubbcluj" decoding="async" loading="lazy" /></a></div>
            <div><a  href="index.html#0a" target="_blank" rel="noreferrer"><img src={require('../../assets/images/main/partner/logo-AEDIDH.gif')} alt="AEDIDH" decoding="async" loading="lazy" /></a></div>
        </Slider>
        </div>
    )
}

export default Partner;