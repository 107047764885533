const RSstandardStyle = {
    menu: (base) => ({
        ...base,
        // width: "200px",
        // minWidth: "100%"
    }),
    // control: (provided, state) => ({
    //     ...provided,
    //     color: "red",
    // }),
    option: (provided, state) => ({
        ...provided,
        color: "black",
    }),
    singleValue: provided => ({
        ...provided,
        color: 'black',
        fontSize: '1rem'
    })
};

export {RSstandardStyle};