import React, {useState, useEffect} from "react";
import {LoaderMedium} from './../../components/loader';
import './../../assets/css/pages/team.css';
import {SetMetaData} from './../../helper/page-title';
import {get_api_call, error_api_call} from './../../actions/action-creators/common';
import { alertResponseMessage } from "../../actions/action-creators/response";

function PortfolioMain(){
    SetMetaData("PORT – Student Portfolio Management System", "", "");
    const [pageLoading, setPageLoader] = useState(true);
    //Get Portfolio Details
    const fetchPortfolioData = async() => {
        try{
            const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
            if(portfolioApiData.status === 200){
                if(portfolioApiData.data.headline){
                    window.location.href = `/port/${portfolioApiData.data.url}`;
                }else{
                    window.location.href = `/port/edit`;
                }
                setPageLoader(false);
            }
        }catch(e){
            console.log(e);
            alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
            error_api_call({error: `portfolio/landing|fetchPortfolioData|${e}`});
        }
    }
    useEffect(() => {
        fetchPortfolioData();
	}, []);

    return (
        pageLoading 
        ? 
            <div className="text-center mt-5 mb-5">
                <LoaderMedium size={45} />
            </div>
        :               
            <></>
    )   
}

export default PortfolioMain;