/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Fancybox from '../components/plugin/fancyox';
import Carousel from '../components/plugin/carousel';
import Branches from "../components/home/branches";
import Countries from "../components/home/countries";
import {SetMetaData} from './../helper/page-title';

function Home(){
    SetMetaData("International Foundation for Sustainable Peace and Development", "", "");

    return (
        <>
		{/* <div className="site grid-container container hfeed grid-parent" id="page"> */}
		<div className="site hfeed grid-parent" id="page">
			<div className="site-content" id="content">
				<div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
					<main className="site-main" id="main">
					<article id="post-5574" className="post-5574 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope>
						<div className="inside-article">
							<div className="entry-content" itemProp="text">
								<div id="pl-5574"  className="panel-layout">
									{/* Logo */}
									{/* <div id="pg-5574-0"  className="panel-grid panel-has-style" >
										<div className="panel-row-style panel-row-style-for-5574-0" >
											<div id="pgc-5574-0-0"  className="panel-grid-cell" >
												<div id="panel-5574-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0" >
												<div className="panel-widget-style panel-widget-style-for-5574-0-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
															<img
																decoding="async"
																className="wp-image-4149 aligncenter"
																src= {require('../assets/images/main/logo/ifspd_logo_m-500x294.png')}
																alt="IFSPD"
																width="200"
																height="118"
																srcSet={`
																	${require('../assets/images/main/logo/ifspd_logo_m-500x294.png')} 500w, 
																	${require('../assets/images/main/logo/ifspd_logo_m-768x451.png')} 768w,
																	${require('../assets/images/main/logo/ifspd_logo_m.png')} 800w
																`}															
																sizes="(max-width: 200px) 100vw, 200px" />
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div> */}
									{/* Banner */}
									<div id="pg-5574-1"  className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5" >
										<div className="ifhomepageslider panel-row-style panel-row-style-for-5574-1" id="ifhomepageslider" >
											<div id="pgc-5574-1-0"  className="panel-grid-cell" >
												<div id="panel-5574-1-0-0" className="so-panel widget widget_sow-layout-slider panel-first-child panel-last-child" data-index="1" >
												<div className="so-widget-sow-layout-slider so-widget-sow-layout-slider-default-75c7a2390bed-5574">
													<div className="sow-slider-base" style={{display: 'none'}}>
														<ul className="sow-slider-images" data-settings="{&quot;pagination&quot;:true,&quot;speed&quot;:600,&quot;timeout&quot;:5000,&quot;paused&quot;:false,&quot;pause_on_hover&quot;:false,&quot;swipe&quot;:true,&quot;nav_always_show_desktop&quot;:&quot;&quot;,&quot;nav_always_show_mobile&quot;:true,&quot;breakpoint&quot;:&quot;780px&quot;,&quot;unmute&quot;:false}">
															<li className="sow-slider-image  sow-slider-image-cover" style={{backgroundColor: '#ffffff',backgroundImage: `url(${require('../assets/images/main/home/ifspd_home_sl_01.jpg')})`}} >
															<div className="sow-slider-image-container">
																<div className="sow-slider-image-wrapper">
																	<div id="pl-w50dccc41"  className="panel-layout" >
																		<div id="pg-w50dccc41-0"  className="panel-grid panel-has-style" >
																		<div id="ifhomepageslide1" className="panel-row-style panel-row-style-for-w50dccc41-0" >
																			<div id="pgc-w50dccc41-0-0"  className="panel-grid-cell" >
																				<div id="panel-w50dccc41-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0" >
																					<div className="panel-widget-style panel-widget-style-for-w50dccc41-0-0-0" >
																					<div className="so-widget-sow-editor so-widget-sow-editor-base">
																						<div className="siteorigin-widget-tinymce textwidget">
																							<h2>A GREATER EFFORT</h2>
																							<p>IFSPD - Pre-eminent International Institution Represented by 50 Nations.<br />
																								Analysing Global Security. Facilitating Diplomacy. Averting Conflicts.<br />
																								<a className="smooth-scroll" href="#ifspd-members"><strong>READ MORE...</strong></a>
																							</p>
																						</div>
																					</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		</div>
																	</div>
																</div>
															</div>
															</li>
															<li className="sow-slider-image  sow-slider-image-cover" style={{backgroundColor: '#ffffff',backgroundImage: `url(${require('../assets/images/main/home/ifspd_home_sl_02.jpg')})`}} >
															<div className="sow-slider-image-container">
																<div className="sow-slider-image-wrapper">
																	<div id="pl-w91ff1e9c"  className="panel-layout" >
																		<div id="pg-w91ff1e9c-0"  className="panel-grid panel-has-style" >
																		<div id="ifhomepageslide1" className="panel-row-style panel-row-style-for-w91ff1e9c-0" >
																			<div id="pgc-w91ff1e9c-0-0"  className="panel-grid-cell" >
																				<div id="panel-w91ff1e9c-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0" >
																					<div className="panel-widget-style panel-widget-style-for-w91ff1e9c-0-0-0" >
																					<div className="so-widget-sow-editor so-widget-sow-editor-base">
																						<div className="siteorigin-widget-tinymce textwidget">
																							<h2>Greater Sustainability</h2>
																							<p>The Global Sustainability Report Tracking Four Pillars of Impact - Environment, Economy, Society &amp; Governance.<br />
																								<a href={require("../assets/doc/IFSPD-Sustainability-2.pdf")} target="_blank" rel="noreferrer" download=""><strong>DOWNLOAD REPORT</strong></a>
																							</p>
																						</div>
																					</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		</div>
																	</div>
																</div>
															</div>
															</li>
															<li className="sow-slider-image  sow-slider-image-cover" style={{backgroundColor: '#ffffff',backgroundImage: `url(${require('../assets/images/main/home/ifspd_home_sl_03.jpg')})`}} >
															<div className="sow-slider-image-container">
																<div className="sow-slider-image-wrapper">
																	<div id="pl-w3492c364"  className="panel-layout" >
																		<div id="pg-w3492c364-0"  className="panel-grid panel-has-style" >
																		<div id="ifhomepageslide1" className="panel-row-style panel-row-style-for-w3492c364-0" >
																			<div id="pgc-w3492c364-0-0"  className="panel-grid-cell" >
																				<div id="panel-w3492c364-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0" >
																					<div className="panel-widget-style panel-widget-style-for-w3492c364-0-0-0" >
																					<div className="so-widget-sow-editor so-widget-sow-editor-base">
																						<div className="siteorigin-widget-tinymce textwidget">
																							<h2>Greater Peace</h2>
																							<p>Facilitating Peace Conversations & Negotiations. Contributing to the Global Fight Against Terrorism & Organized Crime.<br />
																								{/* <a href="list/statements/index.html"><strong>READ MORE...</strong></a> */}
																							</p>
																						</div>
																					</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		</div>
																	</div>
																</div>
															</div>
															</li>
															<li className="sow-slider-image  sow-slider-image-cover" style={{backgroundColor: '#ffffff',backgroundImage: `url(${require('../assets/images/main/home/ifspd_home_sl_04.jpg')})`}} >
															<div className="sow-slider-image-container">
																<div className="sow-slider-image-wrapper">
																	<div id="pl-wa0156187"  className="panel-layout" >
																		<div id="pg-wa0156187-0"  className="panel-grid panel-has-style" >
																		<div id="ifhomepageslide1" className="panel-row-style panel-row-style-for-wa0156187-0" >
																			<div id="pgc-wa0156187-0-0"  className="panel-grid-cell" >
																				<div id="panel-wa0156187-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0" >
																					<div className="panel-widget-style panel-widget-style-for-wa0156187-0-0-0" >
																					<div className="so-widget-sow-editor so-widget-sow-editor-base">
																						<div className="siteorigin-widget-tinymce textwidget">
																							<h2>Greater Development</h2>
																							<p>Distinguished Intellectuals, Diplomats & World Leaders, Analysing & Solving the Most Pressing Global Challenges.<br />
																								<a href="/history"><strong>READ MORE...</strong></a>
																							</p>
																						</div>
																					</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		</div>
																	</div>
																</div>
															</div>
															</li>
														</ul>
														<ol className="sow-slider-pagination">
															<li><a href="#" data-goto="0" aria-label="display slide 1"> </a></li>
															<li><a href="#" data-goto="1" aria-label="display slide 2"> </a></li>
															<li><a href="#" data-goto="2" aria-label="display slide 3"> </a></li>
															<li><a href="#" data-goto="3" aria-label="display slide 4"> </a></li>
														</ol>
														<div className="sow-slide-nav sow-slide-nav-next">
															<a href="#" data-goto="next" aria-label="next slide" data-action="next">
															<em className="sow-sld-icon-thin-right"></em>
															</a>
														</div>
														<div className="sow-slide-nav sow-slide-nav-prev">
															<a href="#" data-goto="previous" aria-label="previous slide" data-action="prev">
															<em className="sow-sld-icon-thin-left"></em>
															</a>
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									{/* History-Founder */}
									<div id="pg-5574-2"  className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
										<div className="ifhomepagebuttons panel-row-style panel-row-style-for-5574-2" id="ifhomepagebuttons" >
											<div id="pgc-5574-2-0"  className="panel-grid-cell" >
												<div id="panel-5574-2-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="2" >
												<div className="ifhomepagebutton panel-widget-style panel-widget-style-for-5574-2-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
															<h2><a href="/history">OUR HISTORY</a></h2>
														</div>
													</div>
												</div>
												</div>
											</div>
											<div id="pgc-5574-2-1"  className="panel-grid-cell" >
												<div id="panel-5574-2-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="3" >
												<div className="ifhomepagebutton panel-widget-style panel-widget-style-for-5574-2-1-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
															<h2><a href="/founders">OUR FOUNDERS</a></h2>
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									{/* Hr:Horizontal Line */}
									<div id="pg-5574-3"  className="panel-grid panel-has-style" >
										<div className="siteorigin-panels-stretch panel-row-style panel-row-style-for-5574-3" data-stretch-type="full" >
											<div id="pgc-5574-3-0"  className="panel-grid-cell" >
												<div id="panel-5574-3-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="4" >
												<div className="panel-widget-style panel-widget-style-for-5574-3-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									{/* News */}
									{/* <div id="pg-5574-4"  className="panel-grid panel-has-style" >
										<div className="ifhomenews panel-row-style panel-row-style-for-5574-4" id="ifhomenews" >
											<div id="pgc-5574-4-0"  className="panel-grid-cell" >
												<div id="panel-5574-4-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="5" >
												<div className="panel-widget-style panel-widget-style-for-5574-4-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
															<h2>NEWS</h2>
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									<div id="pg-5574-5"  className="panel-grid panel-has-style" >
										<div className="ifhomenews panel-row-style panel-row-style-for-5574-5" >
											<div id="pgc-5574-5-0"  className="panel-grid-cell" >
												<div id="panel-5574-5-0-0" className="widget_text so-panel widget widget_custom_html panel-first-child panel-last-child" data-index="6" >
												<div className="textwidget custom-html-widget">
													<div className="ifnewswidgetwrapper">
														<div className="ifnewswidgetdate">January 30, 2023</div>
														<div className="ifnewswidgetthumbnail"><a href="the-role-of-youths-in-peacebuilding-and-sustainable-development/index.html" ><img width="500" height="334" src="wp-content/uploads/2023/01/Peacebuilding-and-sustainable-development-naslovna-500x334.jpg" className="attachment-medium size-medium wp-post-image" alt="" decoding="async" srcSet="http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Peacebuilding-and-sustainable-development-naslovna-500x334.jpg 500w, http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Peacebuilding-and-sustainable-development-naslovna-768x513.jpg 768w, http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Peacebuilding-and-sustainable-development-naslovna-272x182.jpg 272w, http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Peacebuilding-and-sustainable-development-naslovna.jpg 1024w" sizes="(max-width: 500px) 100vw, 500px" /></a></div>
														<div className="ifnewswidgettitle">
															<h2 className="entry-title" itemProp="headline"><a href="the-role-of-youths-in-peacebuilding-and-sustainable-development/index.html" rel="bookmark">The role of Youths in Peacebuilding and sustainable development</a></h2>
														</div>
													</div>
												</div>
												</div>
											</div>
											<div id="pgc-5574-5-1"  className="panel-grid-cell" >
												<div id="panel-5574-5-1-0" className="widget_text so-panel widget widget_custom_html panel-first-child panel-last-child" data-index="7" >
												<div className="textwidget custom-html-widget">
													<div className="ifnewswidgetwrapper">
														<div className="ifnewswidgetdate">January 23, 2023</div>
														<div className="ifnewswidgetthumbnail"><a href="ifspd-project-on-menstrual-health-and-cervical-cancer-awareness/index.html" ><img width="500" height="334" src="wp-content/uploads/2023/01/Project-on-Menstrual-Health-and-Cervical-Cancer-Awareness-02-500x334.jpg" className="attachment-medium size-medium wp-post-image" alt="" decoding="async" srcSet="http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Project-on-Menstrual-Health-and-Cervical-Cancer-Awareness-02-500x334.jpg 500w, http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Project-on-Menstrual-Health-and-Cervical-Cancer-Awareness-02-768x513.jpg 768w, http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Project-on-Menstrual-Health-and-Cervical-Cancer-Awareness-02-272x182.jpg 272w, http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Project-on-Menstrual-Health-and-Cervical-Cancer-Awareness-02.jpg 1024w" sizes="(max-width: 500px) 100vw, 500px" /></a></div>
														<div className="ifnewswidgettitle">
															<h2 className="entry-title" itemProp="headline"><a href="ifspd-project-on-menstrual-health-and-cervical-cancer-awareness/index.html" rel="bookmark">IFSPD Project on Menstrual Health and Cervical Cancer Awareness</a></h2>
														</div>
													</div>
												</div>
												</div>
											</div>
											<div id="pgc-5574-5-2"  className="panel-grid-cell" >
												<div id="panel-5574-5-2-0" className="widget_text so-panel widget widget_custom_html panel-first-child panel-last-child" data-index="8" >
												<div className="textwidget custom-html-widget">
													<div className="ifnewswidgetwrapper">
														<div className="ifnewswidgetdate">January 16, 2023</div>
														<div className="ifnewswidgetthumbnail"><a href="climate-change-adaptation-strategies-perception-of-nigerian-youths/index.html" ><img width="500" height="334" src="wp-content/uploads/2023/01/Taiwo-Quadri-twitter-IFSPD-500x334.jpg" className="attachment-medium size-medium wp-post-image" alt="" decoding="async" srcSet="http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Taiwo-Quadri-twitter-IFSPD-500x334.jpg 500w, http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Taiwo-Quadri-twitter-IFSPD-768x513.jpg 768w, http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Taiwo-Quadri-twitter-IFSPD-272x182.jpg 272w, http://ifspd.butasbureau.nl/wp-content/uploads/2023/01/Taiwo-Quadri-twitter-IFSPD.jpg 1024w" sizes="(max-width: 500px) 100vw, 500px" /></a></div>
														<div className="ifnewswidgettitle">
															<h2 className="entry-title" itemProp="headline"><a href="climate-change-adaptation-strategies-perception-of-nigerian-youths/index.html" rel="bookmark">Climate Change Adaptation Strategies: Perception of Nigerian Youths</a></h2>
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									<div id="pg-5574-6"  className="panel-grid panel-has-style" >
										<div className="ifhomenews panel-row-style panel-row-style-for-5574-6" >
											<div id="pgc-5574-6-0"  className="panel-grid-cell" >
												<div id="panel-5574-6-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="9" >
												<div className="panel-widget-style panel-widget-style-for-5574-6-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
															<p><a href="list/news/index.html"><button className="ifhomebutton">READ ALL NEWS</button></a></p>
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div> */}
									{/* Gallery */}									
									<div id="pg-5574-7"  className="panel-grid panel-has-style" >
										<div className="ifhomegallery siteorigin-panels-stretch panel-row-style panel-row-style-for-5574-7" id="ifhomegallery" data-stretch-type="full" >
											<div id="pgc-5574-7-0"  className="panel-grid-cell container-fluid container-lg pe-lg-5 ps-lg-5" >
												<div id="panel-5574-7-0-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="10" >
												<div className="panel-widget-style panel-widget-style-for-5574-7-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
															<h2 className="title">GALLERY</h2>
															<p>Take a closer look to our board meetings, events and projects.</p>
														</div>
													</div>
												</div>
												</div>
												<div id="ifhomegallery mb-5">
												<Fancybox
													// Sample options
													options={{
													Carousel: {
														infinite: false,
													},
													// Toolbar: {
													// 	display: {
													// 	left: [],
													// 	middle: [],
													// 	right: ["slideshow", "close"],
													// 	},
													// },
													//   Slideshow: {
													//     playOnStart: true,
													//     timeout: 3000,
													//   },
													}}
												>
												<Carousel
												// Sample options
												
												options={{
													
													infinite: true,
													Navigation: false,
													// Autoplay: {
													//   timeout: 3000,
													// },
													
												}}
												>
													{/* ifhomegalleryimg */}
												<div className="row f-carousel__slide">
													<div className="col-12 col-md-6">
														<div data-fancybox="gallery"
															data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-017.jpg')}
															data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-017.jpg')}
															className="mb-3"
															// 	href={require('../assets/images/main/gallery/2019-10-31-Press-Release-017.jpg')
															// }
															// href="wp-content/uploads/2019/11/2019-10-31-Press-Release-017.jpg"
															// title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
														>
															<img
																alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																src={require('../assets/images/main/gallery/2019-10-31-Press-Release-017-1024x683.jpg')}
																// width="200"
																// height="150"
															/>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="row">
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-018.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-018.jpg')}
																	className="mb-3"
																>
																{/* <a data-fancybox="gallery" href="wp-content/uploads/2019/11/2019-10-31-Press-Release-018.jpg" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"> */}
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-018-1024x683.jpg')}
																		// width="200"
																		// height="150"
																	/>
																{/* </a> */}
																</div>
															</div>
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-019.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-019.jpg')}
																	className="mb-3"
																>
																{/* <a data-fancybox="gallery" href="wp-content/uploads/2019/11/2019-10-31-Press-Release-019.jpg" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"> */}
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-019-1024x683.jpg')}
																		// width="200"
																		// height="150"
																	/>
																{/* </a> */}
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-020.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-020.jpg')}
																	className="mb-3"
																>
																{/* <a data-fancybox="gallery" href="wp-content/uploads/2019/11/2019-10-31-Press-Release-020.jpg" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"> */}
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-020-1024x683.jpg')}
																		// width="200"
																		// height="150"
																	/>
																{/* </a> */}
																</div>
															</div>
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-021.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-021.jpg')}
																>
																{/* <a data-fancybox="gallery" href="wp-content/uploads/2019/11/2019-10-31-Press-Release-021.jpg" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"> */}
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-021-1024x683.jpg')}
																		// width="200"
																		// height="150"
																	/>
																{/* </a> */}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="row f-carousel__slide">
													<div className="col-12 col-md-6">
														<div data-fancybox="gallery"
															data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-022.jpg')}
															data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-022.jpg')}
															className="mb-3"
														>
															<img
																alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																src={require('../assets/images/main/gallery/2019-10-31-Press-Release-022-1024x683.jpg')}
															/>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="row">
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-023.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-023.jpg')}
																	className="mb-3"
																>
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-023-1024x683.jpg')}
																	/>
																</div>
															</div>
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-024.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-024.jpg')}
																	className="mb-3"
																>
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-024-1024x683.jpg')}
																	/>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-025.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-025.jpg')}
																	className="mb-3"
																>
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-025-1024x683.jpg')}
																	/>
																</div>
															</div>
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-027.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-027.jpg')}
																	className="mb-3"
																>
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-027-1024x683.jpg')}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="row f-carousel__slide">
													<div className="col-12 col-md-6">
														<div data-fancybox="gallery"
															data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-028.jpg')}
															data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-028.jpg')}
															className="mb-3"
														>
															<img
																alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																src={require('../assets/images/main/gallery/2019-10-31-Press-Release-028-1024x683.jpg')}
															/>															
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="row">
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-032.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-032.jpg')}
																	className="mb-3"
																>
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-032-1024x683.jpg')}
																	/>
																</div>
															</div>
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-033.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-033.jpg')}
																	className="mb-3"
																>
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-033-1024x683.jpg')}
																	/>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-035.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-035.jpg')}
																	className="mb-3"
																>
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-035-1024x683.jpg')}
																	/>
																</div>
															</div>
															<div className="col-12 col-md-6">
																<div data-fancybox="gallery"
																	data-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-036.jpg')}
																	data-thumb-src={require('../assets/images/main/gallery/2019-10-31-Press-Release-036.jpg')}
																	className="mb-3"
																>
																	<img
																		alt="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"
																		src={require('../assets/images/main/gallery/2019-10-31-Press-Release-036-1024x683.jpg')}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
												</Carousel>
												</Fancybox>
												</div>
												{/* <div id="panel-5574-7-0-1" className="so-panel widget widget_sow-layout-slider" data-index="11" >
												<div className="panel-widget-style panel-widget-style-for-5574-7-0-1" >
													<div className="so-widget-sow-layout-slider so-widget-sow-layout-slider-default-dd3ad9a5dddc-5574">
														<div className="sow-slider-base" style={{display: 'none'}}>
															<ul className="sow-slider-images" data-settings="{&quot;pagination&quot;:true,&quot;speed&quot;:700,&quot;timeout&quot;:6000,&quot;paused&quot;:false,&quot;pause_on_hover&quot;:false,&quot;swipe&quot;:false,&quot;nav_always_show_desktop&quot;:&quot;&quot;,&quot;nav_always_show_mobile&quot;:&quot;&quot;,&quot;breakpoint&quot;:&quot;780px&quot;,&quot;unmute&quot;:false}">
																	<li className="sow-slider-image">
																		<div className="sow-slider-image-container">
																			<div className="sow-slider-image-wrapper">
																				<div id="pl-w79125a7b"  className="panel-layout" >
																				<div id="pg-w79125a7b-0"  className="panel-grid panel-no-style" >
																					<div id="pgc-w79125a7b-0-0"  className="panel-grid-cell" >
																						<div id="panel-w79125a7b-0-0-0" className="widget_text so-panel widget widget_custom_html panel-first-child panel-last-child" data-index="0" >
																							<div className="widget_text panel-widget-style panel-widget-style-for-w79125a7b-0-0-0" >
																							<div className="textwidget custom-html-widget">
																								<div  className="galleryoff">
																									<div className="ifhomegalleryimg">
																										<a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-017.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)">
																											<img src={require('../assets/images/main/gallery/2019-10-31-Press-Release-017-1024x683.jpg')}
																											className="attachment-large size-large" alt="" />
																										</a>
																									</div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-018.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src={require('../assets/images/main/gallery/2019-10-31-Press-Release-018-1024x683.jpg')} className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-019.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src={require('../assets/images/main/gallery/2019-10-31-Press-Release-019-1024x683.jpg')} className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-020.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src={require('../assets/images/main/gallery/2019-10-31-Press-Release-020-1024x683.jpg')} className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-021.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src={require('../assets/images/main/gallery/2019-10-31-Press-Release-021-1024x683.jpg')} className="attachment-large size-large" alt="" /></a></div>
																								</div>
																							</div>
																							</div>
																						</div>
																					</div>
																				</div>
																				</div>
																			</div>
																		</div>
																	</li>
																	<li className="sow-slider-image">
																		<div className="sow-slider-image-container">
																			<div className="sow-slider-image-wrapper">
																				<div id="pl-wbd397d7b"  className="panel-layout" >
																				<div id="pg-wbd397d7b-0"  className="panel-grid panel-no-style" >
																					<div id="pgc-wbd397d7b-0-0"  className="panel-grid-cell" >
																						<div id="panel-wbd397d7b-0-0-0" className="widget_text so-panel widget widget_custom_html panel-first-child panel-last-child" data-index="0" >
																							<div className="widget_text panel-widget-style panel-widget-style-for-wbd397d7b-0-0-0" >
																							<div className="textwidget custom-html-widget">
																								<div  className="galleryoff">
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-022.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-022-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-023.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-023-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-024.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-024-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-025.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-025-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-027.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-027-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																								</div>
																							</div>
																							</div>
																						</div>
																					</div>
																				</div>
																				</div>
																			</div>
																		</div>
																	</li>
																	<li className="sow-slider-image">
																		<div className="sow-slider-image-container">
																			<div className="sow-slider-image-wrapper">
																				<div id="pl-wece09bb6"  className="panel-layout" >
																				<div id="pg-wece09bb6-0"  className="panel-grid panel-no-style" >
																					<div id="pgc-wece09bb6-0-0"  className="panel-grid-cell" >
																						<div id="panel-wece09bb6-0-0-0" className="widget_text so-panel widget widget_custom_html panel-first-child panel-last-child" data-index="0" >
																							<div className="widget_text panel-widget-style panel-widget-style-for-wece09bb6-0-0-0" >
																							<div className="textwidget custom-html-widget">
																								<div  className="galleryoff">
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-028.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-028-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-032.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-032-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-033.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-033-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-035.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" ><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-035-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																									<div className="ifhomegalleryimg"><a href="wp-content/uploads/2019/11/2019-10-31-Press-Release-036.jpg" className="image fancybox" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)"><img src="wp-content/uploads/2019/11/2019-10-31-Press-Release-036-1024x683.jpg" className="attachment-large size-large" alt="" /></a></div>
																								</div>
																							</div>
																							</div>
																						</div>
																					</div>
																				</div>
																				</div>
																			</div>
																		</div>
																	</li>
															</ul>
															<ol className="sow-slider-pagination">
																<li><a href="#" data-goto="0" aria-label="display slide 1"> </a></li>
																<li><a href="#" data-goto="1" aria-label="display slide 2"> </a></li>
																<li><a href="#" data-goto="2" aria-label="display slide 3"> </a></li>
															</ol>
															<div className="sow-slide-nav sow-slide-nav-next">
																<a href="#" data-goto="next" aria-label="next slide" data-action="next">
																<em className="sow-sld-icon-thin-right"></em>
																</a>
															</div>
															<div className="sow-slide-nav sow-slide-nav-prev">
																<a href="#" data-goto="previous" aria-label="previous slide" data-action="prev">
																<em className="sow-sld-icon-thin-left"></em>
																</a>
															</div>
														</div>
													</div>
												</div>
												</div> */}
												<div id="panel-5574-7-0-2" className="so-panel widget widget_sow-editor panel-last-child" data-index="12" >
												<div className="panel-widget-style panel-widget-style-for-5574-7-0-2" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
															<a href="/gallery"><button className="ifhomebutton">Visit the gallery with all images</button></a>
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									{/* Hr:Horizontal Line */}
									<div id="pg-5574-8"  className="panel-grid panel-has-style" >
										<div className="siteorigin-panels-stretch panel-row-style panel-row-style-for-5574-8" data-stretch-type="full" >
											<div id="pgc-5574-8-0"  className="panel-grid-cell" >
												<div id="panel-5574-8-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="13" >
												<div className="panel-widget-style panel-widget-style-for-5574-8-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									{/* Branches */}
									<div id="pg-5574-9"  className="panel-grid panel-has-style" >
										<div className="ifhomebranches panel-row-style panel-row-style-for-5574-9" id="ifhomebranches" >
											<div id="pgc-5574-9-0"  className="panel-grid-cell" >
												<div id="panel-5574-9-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="14" >
												<div className="panel-widget-style panel-widget-style-for-5574-9-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
															<h1 className="title">IFSPD BRANCHES</h1>
															{/* <h2> BRANCHES</h2> */}
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									<Branches />
									{/* Hr:Horizontal Line */}
									<div id="pg-5574-11"  className="panel-grid panel-has-style" >
										<div className="siteorigin-panels-stretch panel-row-style panel-row-style-for-5574-11" id="ifspd-members" data-stretch-type="full" >
											<div id="pgc-5574-11-0"  className="panel-grid-cell" >
												<div id="panel-5574-11-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="16" >
												<div className="panel-widget-style panel-widget-style-for-5574-11-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									{/* IFSPD MEMBER COUNTRIES */}
									<div id="pg-5574-12"  className="panel-grid panel-has-style" >
										<div className="ifhomemembers panel-row-style panel-row-style-for-5574-12" id="ifhomemembers" >
											<div id="pgc-5574-12-0"  className="panel-grid-cell" >
												<div id="panel-5574-12-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="17" >
												<div className="panel-widget-style panel-widget-style-for-5574-12-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
															<h2 className="title">IFSPD MEMBER COUNTRIES</h2>
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
									<Countries />
									{/* Hr:Horizontal Line */}
									{/* <div id="pg-5574-8"  className="panel-grid panel-has-style" >
										<div className="siteorigin-panels-stretch panel-row-style panel-row-style-for-5574-8" data-stretch-type="full" >
											<div id="pgc-5574-8-0"  className="panel-grid-cell" >
												<div id="panel-5574-8-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="13" >
												<div className="panel-widget-style panel-widget-style-for-5574-8-0-0" >
													<div className="so-widget-sow-editor so-widget-sow-editor-base">
														<div className="siteorigin-widget-tinymce textwidget">
														</div>
													</div>
												</div>
												</div>
											</div>
										</div>
									</div> */}

								</div>
                     		</div>
                  		</div>
               		</article>
           			 </main>
        		</div>
      		</div>
   		</div>
		</>
    )    
}

export default Home;