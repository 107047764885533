import {
    USER_INFO,
    // TOKEN
} from "../action-types/auth";

const initialState = {
  user_info: null,
  // user_info: {first_name: '',
  //           is_verified: 0,
  //           is_email_verified: 0},
  // token: ''
}

const ReducerAuth = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_INFO:
      return {
        ...state,
        user_info: payload ? JSON.stringify(payload) : null
      };
    // case TOKEN:
    //   return {
    //     ...state,
    //     token: payload
    //   };
    default:
      return state;
  }
};

export default ReducerAuth;