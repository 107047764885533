import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Events(){
    SetMetaData("Board Meetings", "", "");

    return (
        <div className="site hfeed grid-parent archive event-list" id="page">
            <div className="site-content" id="content">
                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">
                        <div className="generate-columns-container ">
                            <header className="page-header" aria-label="Page">
                                <h1 className="page-title title">Board Meetings</h1>
                            </header>
                            <div className="container-fluid container-lg pe-lg-5 ps-lg-5 ps-4">
                                <div className="row">
                                    <article id="post-5302" className="col-12 col-md-6 post-5302 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/resolutions-of-18th-board-of-directors-meeting" rel="bookmark">Resolutions of 18th Board of Directors Мeeting</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2020-07-30T03:24:22+03:00" itemProp="dateModified">July 30,
                                                            2020</time><time className="entry-date published" dateTime="2019-11-02T11:04:38+02:00" itemProp="datePublished">November 2,
                                                            2019</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">
                                                <a href="/board-meetings/resolutions-of-18th-board-of-directors-meeting">
                                                    <img width="768" height="512" src={require("../../assets/images/main/event/2019-10-31-Press-Release-037-768x512.jpg")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>Venue: Belgrade, Serbia.&nbsp; QUORUM FOR THE BOARD MEETING HAS BEEN ESTABLISHED. FOLLOWING THE
                                                    COMPREHENSIVE DISCUSSION OF THE AGENDA, THE BOARD OF</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                    <article id="post-5217" className="col-12 col-md-6 post-5217 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/18th-ifspd-board-of-directors-meeting-held-in-belgrade-serbia" rel="bookmark">18th IFSPD Board of
                                                        Directors Meeting held in Belgrade (Serbia)</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2020-10-19T15:36:41+03:00" itemProp="dateModified">October 19,
                                                            2020</time><time className="entry-date published" dateTime="2019-11-02T10:56:48+02:00" itemProp="datePublished">November 2,
                                                            2019</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">

                                                <a href="/board-meetings/18th-ifspd-board-of-directors-meeting-held-in-belgrade-serbia">
                                                    <img width="768" height="512" src={require("../../assets/images/main/event/2019-10-31-Press-Release-026-768x512.jpg")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" loading="lazy" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>The 18th Board of Directors Meeting of International Foundation for Sustainable Peace and Development (IFSPD) was held in Belgrade (Serbia). &nbsp;</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                </div>
                                <div className="row">
                                    <article id="post-5022" className="col-12 col-md-6 post-5022 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/ifspd-17th-extended-board-of-directors-and-general-assembly-meetings" rel="bookmark">IFSPD 17th
                                                        Extended Board of Directors and General Assembly Meetings</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2022-01-11T11:15:00+02:00" itemProp="dateModified">January 11,
                                                            2022</time><time className="entry-date published" dateTime="2019-03-10T09:02:48+02:00" itemProp="datePublished">March 10,
                                                            2019</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">

                                                <a href="/board-meetings/ifspd-17th-extended-board-of-directors-and-general-assembly-meetings">
                                                    <img width="768" height="512" src={require("../../assets/images/main/event/Meeting-024-768x512.jpg")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" loading="lazy" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>17th Extended Board of Directors and General Assembly Meetings of the International Foundation for Sustainable Peace and
                                                    Development (IFSPD) &nbsp; On 3 March 2019, the</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                    <article id="post-509" className="col-12 col-md-6 post-509 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/resolution-of-16th-general-assembly-meeting" rel="bookmark">Resolution of 16th General Assembly meeting</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2018-08-23T12:10:11+03:00" itemProp="dateModified">August 23,
                                                            2018</time><time className="entry-date published" dateTime="2017-06-20T10:03:00+03:00" itemProp="datePublished">June 20,
                                                            2017</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">

                                                <a href="/board-meetings/resolution-of-16th-general-assembly-meeting">
                                                    <img width="210" height="130" src={require("../../assets/images/main/event/eskilogo.png")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" loading="lazy" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>QUORUM FOR THE BOARD MEETING HAS BEEN ESTABLISHED. FOLLOWING THE COMPREHENSIVE DISCUSSION OF THE AGENDA, THE GENERAL ASSEMBLY HAS
                                                    ADOPTED THE FOLLOWING DECISIONS: QUESTION №1:</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                </div>
                                <div className="row">
                                    <article id="post-511" className="col-12 col-md-6 post-511 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/resolution-of-16th-board-of-directors-meeting" rel="bookmark">Resolution of 16th Board of Directors meeting</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2018-08-12T10:14:36+03:00" itemProp="dateModified">August 12,
                                                            2018</time><time className="entry-date published" dateTime="2017-06-20T08:13:15+03:00" itemProp="datePublished">June 20,
                                                            2017</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">

                                                <a href="/board-meetings/resolution-of-16th-board-of-directors-meeting">
                                                    <img width="210" height="130" src={require("../../assets/images/main/event/eskilogo.png")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" loading="lazy" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>QUORUM FOR THE BOARD MEETING HAS BEEN ESTABLISHED. FOLLOWING THE COMPREHENSIVE DISCUSSION OF THE AGENDA, THE BOARD OF DIRECTORS
                                                    HAS ADOPTED THE FOLLOWING RESOLUTIONS: QUESTION</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                    <article id="post-513" className="col-12 col-md-6 post-513 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/16th-board-of-directors-and-general-assembly-meeting" rel="bookmark">16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of
                                                        the Black Sea and Caspian Sea</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2018-08-23T12:12:30+03:00" itemProp="dateModified">August 23,
                                                            2018</time><time className="entry-date published" dateTime="2017-06-10T10:16:06+03:00" itemProp="datePublished">June 10,
                                                            2017</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">

                                                <a href="/board-meetings/16th-board-of-directors-and-general-assembly-meeting">
                                                    <img width="768" height="512" src={require("../../assets/images/main/event/f408384f-8a8d-405a-8dea-de5535cbf8c6_konferans_Blacksea_8-768x512.jpg")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" loading="lazy" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>Press release, Istanbul, 10th of June, 2017 On June 10th in Istanbul, Turkey the Meeting of the General Assembly and Board of
                                                    Directors of the</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                </div>
                                <div className="row">
                                    <article id="post-516" className="col-12 col-md-6 post-516 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/the-anniversary-general-assembly-and-12th-board-meeting" rel="bookmark">The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership
                                                        of the Black Sea and Caspian Sea. Istanbul, Turkey</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2018-08-23T22:33:18+03:00" itemProp="dateModified">August 23,
                                                            2018</time><time className="entry-date published" dateTime="2017-04-25T10:24:15+03:00" itemProp="datePublished">April 25,
                                                            2017</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">

                                                <a href="/board-meetings/the-anniversary-general-assembly-and-12th-board-meeting">
                                                    <img width="768" height="510" src={require("../../assets/images/main/event/f17aabc9-d9f7-478a-8a3d-12f91c1c346e_DSC_0007-768x510.jpg")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" loading="lazy" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>On the 9th of March, 2014, the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea (BSCSIF)
                                                    celebrated five years since</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                    <article id="post-519" className="col-12 col-md-6 post-519 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/the-15th-board-of-directors-and-general-assembly-meeting" rel="bookmark">The 15th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and
                                                        Partnership of the Black Sea and Caspian Sea</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2018-08-12T10:30:26+03:00" itemProp="dateModified">August 12,
                                                            2018</time><time className="entry-date published" dateTime="2016-07-18T10:27:58+03:00" itemProp="datePublished">July 18,
                                                            2016</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">

                                                <a href="/board-meetings/the-15th-board-of-directors-and-general-assembly-meeting">
                                                    <img width="210" height="130" src={require("../../assets/images/main/event/eskilogo.png")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" loading="lazy" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>On June 17, 2016 in Bucharest, Romania was held the 15th meeting of the Board of Directors and General Assembly of the
                                                    International Foundation for</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                </div>
                                <div className="row">
                                    <article id="post-521" className="col-12 col-md-6 post-521 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/the-15th-meeting-of-the-board-of-directors-and-general-assembly-of-the-bscsif/" rel="bookmark">The 15th
                                                        meeting of the Board of Directors and General Assembly of the BSCSIF</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2018-08-12T10:31:35+03:00" itemProp="dateModified">August 12,
                                                            2018</time><time className="entry-date published" dateTime="2016-05-23T10:30:58+03:00" itemProp="datePublished">May 23,
                                                            2016</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">

                                                <a href="/board-meetings/the-15th-meeting-of-the-board-of-directors-and-general-assembly-of-the-bscsif/">
                                                    <img width="210" height="130" src={require("../../assets/images/main/event/eskilogo.png")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" loading="lazy" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>The 15th meeting of the Board of Directors and General Assembly of theInternational Fund for the cooperation and partnership of
                                                    the Black sea and Caspian</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                    <article id="post-523" className="col-12 col-md-6 post-523 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings generate-columns tablet-grid-50 mobile-grid-100 grid-parent grid-50 resize-featured-image" itemType="https://schema.org/CreativeWork" itemScope="">
                                        <div className="inside-article">
                                            <header className="entry-header" aria-label="Content">
                                                <h2 className="entry-title" itemProp="headline"><a href="/board-meetings/the-14th-general-assembly-and-board-meeting-of-the-bscsif-baku-azerbaijan" rel="bookmark">The 14th
                                                        General Assembly and Board Meeting of the BSCSIF, Baku, Azerbaijan</a></h2>
                                                {/* <div className="entry-meta">
                                                    <span className="posted-on"><time className="updated" dateTime="2018-08-16T20:11:56+03:00" itemProp="dateModified">August 16,
                                                            2018</time><time className="entry-date published" dateTime="2015-05-23T10:31:49+03:00" itemProp="datePublished">May 23,
                                                            2015</time></span>
                                                </div> */}
                                            </header>
                                            <div className="post-image">

                                                <a href="/board-meetings/the-14th-general-assembly-and-board-meeting-of-the-bscsif-baku-azerbaijan">
                                                    <img width="300" height="198" src={require("../../assets/images/main/event/naslovna.jpg")} className="attachment-medium_large size-medium_large wp-post-image" alt="" decoding="async" loading="lazy" itemProp="image" />
                                                </a>
                                            </div>
                                            <div className="entry-summary" itemProp="text">
                                                <p>On May 23, 2015, the capital of the “Land of Fire”, Baku, hosted with the traditional hospitality the 14th Meeting of the General
                                                    Assembly and</p>
                                            </div>

                                            <footer className="entry-meta" aria-label="Entry meta">
                                            </footer>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            {/* <!-- <nav id="nav-below" className="paging-navigation" aria-label="Archive Page">
                                <span className="screen-reader-text">Post navigation</span>

                                <div className="nav-previous">
                                    <span className="prev" title="Previous"><a href="https://ifspd.org/list/board-meetings/page/2/">Older posts</a></span>
                                </div>
                                <div className="nav-links"><span aria-current="page" className="page-numbers current"><span className="screen-reader-text">Page</span>1</span>
                                    <a className="page-numbers" href="https://ifspd.org/list/board-meetings/page/2/"><span className="screen-reader-text">Page</span>2</a>
                                    <a className="next page-numbers" href="https://ifspd.org/list/board-meetings/page/2/">Next <span aria-hidden="true">→</span></a>
                                </div>
                            </nav> --> */}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )    
}

export default Events;