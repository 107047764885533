import React from "react";
import Fancybox from '../../components/plugin/fancyox';

function Gallery1(){
    return (
        <Fancybox
            // Sample options
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-018-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-018-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-019-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-019-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-020-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-020-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-021-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-021-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-022-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-022-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-023-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-023-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-024-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-024-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-025-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-025-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-027-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-027-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-028-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-028-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-032-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-032-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-033-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-033-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-035-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-035-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-036-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-036-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-037-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-037-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-039-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-039-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-040-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-040-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
        </Fancybox>
    )
}

export default Gallery1;