import React from 'react';
import {
    Col,
    Row
  } from 'react-bootstrap';
//   import { useCallback, useLayoutEffect, useState } from 'react';
import {SetMetaData} from './../../../helper/page-title';
import './../../../assets/css/pages/fellow.css';
import './../../../assets/css/pages/certification.css';

function YoungLeader(props){
    SetMetaData("Young Leader Certification", "", "");

    return (
        <>
        {/* <div className="container-fluid container-xl pe-lg-5 ps-lg-5 mt-60 fellow-overview"> */}
        <div className={`fellow-overview fellow-campaign fellow-cert ${props.showHeader===true ? "mt-50": ""}`}>
            <div className="container-fluid container-xl pe-lg-5 ps-lg-5">
                <Row className="justify-content-center">
                    <Col md="12" sm="12" className="p-0">
                        <div className="card fellow-sus">
                            {/* <img src={require("../../../assets/images/fp/bg-fellowship-1.png") } className="card-img d-none d-sm-block" alt="Fellowship"/> */}
                            {/* <div className="card-img-overlay" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}> */}
                            {/* <div className="card-img-overlay" style={{backgroundColor: "#ffffff61"}}> */}
                                <div className="row mt-2 mb-2">
                                    <div className="col-md-3 col-12 col-sm-12 text-center">
                                        <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../../../assets/images/main/logo/ifspd_logo_m.png')} title="International Foundation for Sustainable Peace and Development" />
                                    </div>
                                    {/* <div className="col-md-9 col-8 col-sm-9">
                                        <div className="float-sm-end text-center text-sm-start">
                                            <p className="banner-head">LeadCap Ventures</p>
                                            <p className="banner-title mb-3">Research | Analytics | Consulting</p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row ps-3 pe-3 banner">
                                    <div className="col-md-8 col-12 col-sm-12 mb-sm-4 mb-0">
                                        <h1 className="head">This Summer, Invest in Your Teenager’s Credentials</h1>
                                        <ul>
                                            <li>Students with leadership, team work & critical thinking credentials stand out - in class & top college admissions</li>
                                            <li className="highlight">ifspd young leader certification.</li>
                                            <li>one week online program. for students of 6 grade upwards.</li>
                                            <li>limited seats. discounted fees of Rs.9800</li>
                                        </ul>
                                        <div className="pt-3 m-2 d-flex">
                                            <a href="https://wa.me/+919663764536?text=I%20wish%20to%20in%20enrol%20my%20child%20for%20the%20IFSPD%20Young%20Leader%20Certification%20during%20this%20summer%20holidays.%20Please%20send%20me%20more%20details" target='_blank' rel="noreferrer">
                                                <div className="">
                                                    <p className="box-purple text-center">ONLY 100 SEATS.<br/>JOIN TODAY</p>
                                                </div>
                                            </a>
                                            <a href="https://wa.me/+919663764536?text=I%20wish%20to%20in%20enrol%20my%20child%20for%20the%20IFSPD%20Young%20Leader%20Certification%20during%20this%20summer%20holidays.%20Please%20send%20me%20more%20details" target='_blank' rel="noreferrer">
                                                <div className="ms-3">
                                                    <img src={require('../../../assets/images/whatsapp.png')} alt="Whatsapp" />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 col-sm-12 mb-sm-4 mb-0 d-none d-md-block">
                                        <img className="student" src={require('../../../assets/images/student.png')} alt="Student" />
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>
                    </Col>
                </Row>
            </div>
            {/* <div className="ms-3 me-3"> */}
            <div className="container-fluid container-xl pe-lg-5 ps-lg-5">
                <h3 className="title-head">Benefits For Your Teenager</h3>
                <Row className="justify-content-center fellow-feature">
                    <Col md="4" sm="6" xs="6" className="text-center">
                        <img src={require('../../../assets/images/fp/fp1/Premium-Certification.png')} alt="Premium Global Certification"/>
                        <p className="f-title">Premium Global Certification</p>
                    </Col>
                    <Col md="4" sm="6" xs="6" className="text-center">
                        <img src={require('../../../assets/images/fp/fp1/Reference-Letter.png')} alt="Unique Competencies Report"/>
                        <p className="f-title">Unique Competencies Report</p>
                    </Col>
                    <Col md="4" sm="6" xs="6" className="text-center">
                        <img src={require('../../../assets/images/fp/fp1/Elevated-Profile.png')} alt="Leadership & Global Analysis" />
                        <p className="f-title">Leadership & Global Analysis</p>
                    </Col>
                    <Col md="4" sm="6" xs="6" className="text-center">
                        <img src={require('../../../assets/images/fp/fp1/leadership.png')} alt="Confidence & Public Speaking"/>
                        <p className="f-title">Confidence & Public Speaking</p>
                    </Col>
                    <Col md="4" sm="6" xs="6" className="text-center">
                        <img src={require('../../../assets/images/fp/fp1/Global-Issues.png')} alt="Research & Critical Thinking "/>
                        <p className="f-title">Research & Critical Thinking </p>
                    </Col>
                    <Col md="4" sm="6" xs="6" className="text-center">
                        <img src={require('../../../assets/images/fp/fp1/Unique-Skills.png')} alt="Teamwork & Collaboration"/>
                        <p className="f-title">Teamwork & Collaboration</p>
                    </Col>
                {/* </Row> */}
                </Row>
            </div>
            <div className="container-fluid container-xl pe-lg-5 ps-lg-5 pb-5">
                <Row className="justify-content-center text-center text-uppercase">
                        <div className="col-12 col-md-4 col-sm-4 col-xl-4 pe-1 ps-1 ps-md-0"><div><p className="bg-color-purple box mb-0 mt-2 mt-md-0">early preparation for college admissions.</p></div></div>
                        <div className="col-12 col-md-4 col-sm-4 col-xl-4 pe-1 ps-1"><div><p className="bg-color-purple1 box mb-0 mt-2 mt-md-0">get ahead in school. headstart for future success.</p></div></div>
                        <div className="col-12 col-md-4 col-sm-4 col-xl-4 pe-1 ps-1"><div><p className="bg-color-lg-purple box mb-0 mt-2 mt-md-0">Alumni include  young Awardees & Ivy League College Attendees.</p></div></div>
                </Row>
            </div>
            <div style={{background: 'rgb(226, 226, 235)'}}>
                <div className="container-fluid container-xl pe-lg-5 ps-lg-5 pb-5">
                    <Row className="justify-content-center text-center">
                        <div className="col-12 col-md-4 col-sm-4 col-xl-4 pe-0 d-none d-md-block">
                            <img className="student" src={require('../../../assets/images/student-boy.png')} alt="Boy Student" />
                        </div>
                        <div className="col-12 col-md-8 col-sm-12 col-xl-8">
                            <Row className="cert-feature">
                                <div className="col-12 col-md-6 col-sm-6 col-xl-6 pt-4 bg-color-lg-purple">
                                    <h4>Competency & Career Guidance</h4>
                                    <p>Expert sessions for insights on unique competencies, academic and career choices, preparing for a high growth path.</p>
                                </div>
                                <div className="col-12 col-md-6 col-sm-6 col-xl-6 pt-4 bg-color-purple">
                                    <h4>Leadership & Defining s</h4>
                                    <p>Empowering students to introspect, identify, face and take advantage of life’s inflection points, in order to succeed in life.</p>
                                </div>
                                <div className="col-12 col-md-6 col-sm-6 col-xl-6 pt-4 bg-color-purple2">
                                    <h4>Global Strategy Councils</h4>
                                    <p>Group discussions and data analysis on global issues, to expose students to UN and World Bank styles of work.</p>
                                </div>
                                <div className="col-12 col-md-6 col-sm-6 col-xl-6 pt-4 bg-color-blue2">
                                    <h4>Stand up & Speak Out</h4>
                                    <p>Building confidence in formulating and expressing ideas, fostering effective communication and leadership qualities.</p>
                                </div>
                                <div className="col-12 col-md-6 col-sm-6 col-xl-6 pt-4 bg-color-purple1">
                                    <h4>Critical Thinking & Debriefings</h4>
                                    <p>Developing self-expression and critical thinking on global issues, to encourages self-reflection and articulation.</p>
                                </div>
                                <div className="col-12 col-md-6 col-sm-6 col-xl-6 pt-4 bg-color-purple3">
                                    <h4>Professional Profile</h4>
                                    <p>Foundation for a compelling portfolio showcasing academics, extracurriculars, and growth - for admission to best colleges.</p>
                                </div>
                            </Row>
                        </div>
                    </Row>
                </div>
                <div className="container-fluid container-xl pe-lg-5 ps-lg-5 cert-benefit">
                    <h3 className="title-head text-center mb-4">Why Enroll in Young Leader Certification</h3>
                    <Row className="justify-content-center pb-4">
                        <Col md="4" sm="12" xs="12" className="card pt-2 pb-2 mb-4 mb-md-0">
                            <div className="row">
                                <div className="col-3 col-md-3 col-sm-3 col-xl-3">
                                    <img src={require('../../../assets/images/student/student-1.jpg')} alt="Student"/>
                                </div>
                                <div className="col-9 col-md-9 col-sm-9 col-xl-9">
                                    <p className="mb-0">Because normal schools & subjects don’t prepare students for future careers & admissions.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4" sm="12" xs="12" className="card pt-2 pb-2 offset-md-1">
                            <div className="row">
                                <div className="col-3 col-md-3 col-sm-3 col-xl-3">
                                    <img src={require('../../../assets/images/student/student-2.jpg')} alt="Student"/>
                                </div>
                                <div className="col-9 col-md-9 col-sm-9 col-xl-9">
                                    <p className="mb-0">Because 98% of teenagers waste their time on games and gadgets during summer holidays.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center pb-4">
                        <Col md="4" sm="12" xs="12" className="card pt-2 pb-2 mb-4 mb-md-0">
                            <div className="row">
                                <div className="col-3 col-md-3 col-sm-3 col-xl-3">
                                    <img src={require('../../../assets/images/student/student-3.jpg')} alt="Student"/>
                                </div>
                                <div className="col-9 col-md-9 col-sm-9 col-xl-9">
                                    <p className="mb-0"> Because many students are distracted & confused - due to lack of adequate support.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4" sm="12" xs="12" className="card pt-2 pb-2 offset-md-1">
                            <div className="row">
                                <div className="col-3 col-md-3 col-sm-3 col-xl-3">
                                    <img src={require('../../../assets/images/student/student-4.jpg')} alt="Student"/>
                                </div>
                                <div className="col-9 col-md-9 col-sm-9 col-xl-9">
                                    <p className="mb-0">Because students require global outlook, leadership skills & a portfolio to compete.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center pb-5">
                        <Col md="4" sm="12" xs="12" className="card pt-2 pb-2 mb-4 mb-md-0">
                            <div className="row">
                                <div className="col-3 col-md-3 col-sm-3 col-xl-3">
                                    <img src={require('../../../assets/images/student/student-5.jpg')} alt="Student"/>
                                </div>
                                <div className="col-9 col-md-9 col-sm-9 col-xl-9">
                                    <p className="mb-0">Because even average students can get top colleges admissions on portfolio credentials.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md="4" sm="12" xs="12" className="card pt-2 pb-2 offset-md-1">
                            <div className="row">
                                <div className="col-3 col-md-3 col-sm-3 col-xl-3">
                                    <img src={require('../../../assets/images/student/student-6.jpg')} alt="Student"/>
                                </div>
                                <div className="col-9 col-md-9 col-sm-9 col-xl-9">
                                    <p className="mb-0">Because best colleges admissions demand group discussions, team activities & portfolio credentials.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="container-fluid container-xl pe-lg-5 ps-lg-5 pb-5">
                    <h3 className="title-head mb-4">We Empower Students to Excel</h3>
                    <Row className="justify-content-center alumni-sec leader-sec text-center">
                    <div className="col-12 col-md-4">
                       <div className="content">
                            {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                                <img src={require("../../../assets/images/fp/Sara-Jacson.png")} loading="lazy" alt="Sara Jacson, Columbia & Ashoka" />
                            {/* </a> */}
                            <p className="t-name">Sara Jacson, Columbia & Ashoka</p>
                            <p className="t-position">“Global Agenda Councils, case analysis & social start-up helped me”</p>
                       </div>
                    </div>
                    <div className="col-12 col-md-4 mt-5 mt-md-0 pt-md-0">
                       <div className="content">
                            {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                                <img src={require("../../../assets/images/fp/Krishna-Inpurapu.png")} loading="lazy" alt="Krishna Inpurapu, MIT" />
                            {/* </a> */}
                            <p className="t-name">Krishna Inpurapu, MIT</p>
                            <p className="t-position">“The portfolio credentials I built helped me standout from the crowd”</p>
                       </div>
                    </div>
                    <div className="col-12 col-md-4 mt-5 mt-md-0 pt-md-0">
                       <div className="content">
                            {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                                <img src={require("../../../assets/images/fp/Aswini-Ojas.png")} loading="lazy" alt="Aswini Ojas, Warwick" />
                            {/* </a> */}
                            <p className="t-name">Aswini Ojas, Warwick</p>
                            <p className="t-position">“This certification helped me focus. It built my credentials ground up”</p>
                       </div>
                    </div>
                </Row>
                </div>
                <div className="container-fluid container-xl pe-lg-5 ps-lg-5 pb-3">
                    <Row className="justify-content-center">
                        <div className="col-12 col-md-3">
                            <div className="fee-structure pb-3 pt-3 flex-middle">
                                <h5>Do Not Miss Out!</h5>
                                <p className="mb-0 d-flex"><p className="mb-0 fee"><b>Actual fee:</b></p> &nbsp;<span className="strike-price">Rs.50,000</span></p>
                                <p className="mb-0 d-flex"><p className="mb-0 fee"><b>Summer fee:</b></p> &nbsp;Rs.9,800</p>
                            </div>
                            {/* <table>
                                <tbody>
                                    <tr>
                                        <th>Actual fee:</th>
                                        <td>Rs.50,000</td>
                                    </tr>
                                    <tr>
                                        <th>Summer fee:</th>
                                        <td>Rs.9,800</td>
                                    </tr>
                                </tbody>
                            </table> */}
                        </div>
                        <div className="col-12 col-md-6 banner flex-middle">
                            <div className="d-flex">
                                <a href="https://wa.me/+919663764536?text=I%20wish%20to%20in%20enrol%20my%20child%20for%20the%20IFSPD%20Young%20Leader%20Certification%20during%20this%20summer%20holidays.%20Please%20send%20me%20more%20details" target='_blank' rel="noreferrer">
                                    <div className="">
                                        <p className="box-purple text-center">Starting April 1<br/>ONLY 100 SEATS.<br/>JOIN TODAY</p>
                                    </div>
                                </a>
                                <a href="https://wa.me/+919663764536?text=I%20wish%20to%20in%20enrol%20my%20child%20for%20the%20IFSPD%20Young%20Leader%20Certification%20during%20this%20summer%20holidays.%20Please%20send%20me%20more%20details" target='_blank' rel="noreferrer" className="whatsapp">
                                    <div className="ms-3">
                                        <img src={require('../../../assets/images/whatsapp.png')} alt="Whatsapp" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        </div>
        </>
    )    
}

export default YoungLeader;