import React from "react";
import Fancybox from '../../components/plugin/fancyox';

function Gallery6(){
    return (
        <Fancybox
            // Sample options
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/2cab2bd1-b107-400b-9552-3361f008fca3_IMG_5043-500x290.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/2cab2bd1-b107-400b-9552-3361f008fca3_IMG_5043-500x290.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/6a5f1675-f084-4ba7-aafd-f7794a651c07_IMG_4889-500x271.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/6a5f1675-f084-4ba7-aafd-f7794a651c07_IMG_4889-500x271.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/7cbbf334-f593-4b08-bb40-e2a188e792f8_IMG_5007-500x333.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/7cbbf334-f593-4b08-bb40-e2a188e792f8_IMG_5007-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/7d71803b-8de5-4770-af39-be13580171ac_Picture-026-500x428.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/7d71803b-8de5-4770-af39-be13580171ac_Picture-026-500x428.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/9dc9ffaa-257b-45bb-abfc-641800756665_IMG_5108-500x401.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/9dc9ffaa-257b-45bb-abfc-641800756665_IMG_5108-500x401.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/33f6b90b-e5aa-46ad-a705-8334a4526602_Siim-KallasVice-president-of-the-EUCommission-and-EU-Commissioner-for-Transport-500x333.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/33f6b90b-e5aa-46ad-a705-8334a4526602_Siim-KallasVice-president-of-the-EUCommission-and-EU-Commissioner-for-Transport-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/34fc0f0d-d4f9-4028-b577-dbafa7dde2ff_IMG_5142-478x500.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/34fc0f0d-d4f9-4028-b577-dbafa7dde2ff_IMG_5142-478x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/37b726e8-0050-4837-b162-3af504770925_IMG_5080-500x384.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/37b726e8-0050-4837-b162-3af504770925_IMG_5080-500x384.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/692c17cb-7a25-45fd-ab62-cddbc9755b13_Award-for-Martin-Schulz-accepted-by-Jacek-Protasiewicz-.-Vice-President-of-the-European-Parliament-500x333.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/692c17cb-7a25-45fd-ab62-cddbc9755b13_Award-for-Martin-Schulz-accepted-by-Jacek-Protasiewicz-.-Vice-President-of-the-European-Parliament-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/878cb3c3-5277-477d-83d5-4579896e66d4_IMG_5124-433x500.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/878cb3c3-5277-477d-83d5-4579896e66d4_IMG_5124-433x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/267415dd-4a92-48e4-a1c0-fa47c1b85ee4_IMG_4999-500x333.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/267415dd-4a92-48e4-a1c0-fa47c1b85ee4_IMG_4999-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/989423d0-d60e-4364-8071-a72e250699dd_IMG_4955-500x334.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/989423d0-d60e-4364-8071-a72e250699dd_IMG_4955-500x334.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/b5f67079-c4b0-42af-8e9a-05ebd7e0b426_IMG_5025-500x311.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/b5f67079-c4b0-42af-8e9a-05ebd7e0b426_IMG_5025-500x311.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/b4691ce3-0d4f-43c7-8772-d12d9a495b24_IMG_5146-500x348.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/b4691ce3-0d4f-43c7-8772-d12d9a495b24_IMG_5146-500x348.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/c4db869a-78f2-482d-895d-330d7b605b25_Fuad-Iskandarov-is-the-Ambassador-Extraordinary-and-Plenipotentiary-of-the-Republic-of-Azerbaijan-to-the-Kingdom-of-Belgium-500x376.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/c4db869a-78f2-482d-895d-330d7b605b25_Fuad-Iskandarov-is-the-Ambassador-Extraordinary-and-Plenipotentiary-of-the-Republic-of-Azerbaijan-to-the-Kingdom-of-Belgium-500x376.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/c86031b7-9e0d-4a8f-8e17-a9c423f2bc6d_IMG_4972-500x333.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/c86031b7-9e0d-4a8f-8e17-a9c423f2bc6d_IMG_4972-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/dba3e1ba-f2d8-4c8e-967b-04fe20bc306a_Picture-053-500x333.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/dba3e1ba-f2d8-4c8e-967b-04fe20bc306a_Picture-053-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/ec75bcd2-b6f6-4e9a-8270-b72774efe7d3_IMG_4866-500x334.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/ec75bcd2-b6f6-4e9a-8270-b72774efe7d3_IMG_4866-500x334.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/f1731b94-fbfa-4982-b62a-61a51c1f012e_Picture-040-500x440.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/f1731b94-fbfa-4982-b62a-61a51c1f012e_Picture-040-500x440.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/9/fa394daf-057d-468e-ae6d-143a3e7a74fe_IMG_4843-500x450.jpg")} className="fancybox image gallery-item" title="BSCSIF Gala Dinner in Brussels" rel="gallery-5"><img src={require("../../assets/images/main/event/9/fa394daf-057d-468e-ae6d-143a3e7a74fe_IMG_4843-500x450.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
        </Fancybox>
    )
}

export default Gallery6;