import React from "react";
import Fancybox from '../../components/plugin/fancyox';

function Gallery9(){
    return (
        <Fancybox
            // Sample options
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/12/X13b196bb17-6e70-4b01-90f3-22bc6f3e16ca_iva_0056-500x311.jpg")} className="fancybox image gallery-item" title="The 7th board meeting of the International Fund for Cooperation and Partenership of the Black Sea and the Caspian Sea" rel="gallery-8"><img src={require("../../assets/images/main/event/12/X13b196bb17-6e70-4b01-90f3-22bc6f3e16ca_iva_0056-500x311.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/12/X12a7130a4bf-f880-4322-b427-678cc16c9814_iva_0103-500x424.jpg")} className="fancybox image gallery-item" title="The 7th board meeting of the International Fund for Cooperation and Partenership of the Black Sea and the Caspian Sea" rel="gallery-8"><img src={require("../../assets/images/main/event/12/X12a7130a4bf-f880-4322-b427-678cc16c9814_iva_0103-500x424.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/12/X11a6179c-c591-46e9-8bec-bea5d96e8098_iva_0041-1-500x271.jpg")} className="fancybox image gallery-item" title="The 7th board meeting of the International Fund for Cooperation and Partenership of the Black Sea and the Caspian Sea" rel="gallery-8"><img src={require("../../assets/images/main/event/12/X11a6179c-c591-46e9-8bec-bea5d96e8098_iva_0041-1-500x271.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/12/X02d3ebeb09-946c-4589-b46c-0cdc4c949a09_dscX0204227-500x375.jpg")} className="fancybox image gallery-item" title="The 7th board meeting of the International Fund for Cooperation and Partenership of the Black Sea and the Caspian Sea" rel="gallery-8"><img src={require("../../assets/images/main/event/12/X02d3ebeb09-946c-4589-b46c-0cdc4c949a09_dscX0204227-500x375.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/12/X00a33dd0c94-4eaa-410c-952d-e68cc8a35dad_iva_9657-500x350.jpg")} className="fancybox image gallery-item" title="The 7th board meeting of the International Fund for Cooperation and Partenership of the Black Sea and the Caspian Sea" rel="gallery-8"><img src={require("../../assets/images/main/event/12/X00a33dd0c94-4eaa-410c-952d-e68cc8a35dad_iva_9657-500x350.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
        </Fancybox>
    )
}

export default Gallery9;