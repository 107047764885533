import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event1(){
    SetMetaData("Resolutions of 18th Board of Directors Мeeting", "", "");

    return (
        <div className="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
            <div className="site-content" id="content">
            <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                <main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                    <article id="post-5302" className="post-5302 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news" itemType="https://schema.org/CreativeWork" itemScope="">
                        <div className="inside-article">
                        <header className="entry-header" aria-label="Content">
                            <h1 className="entry-title title" itemProp="headline">Resolutions of 18th Board of Directors Мeeting</h1>
                            {/* <div className="entry-meta">
                                <span className="posted-on"><time className="updated" dateTime="2020-07-30T03:24:22+03:00" itemProp="dateModified">July 30, 2020</time><time className="entry-date published" dateTime="2019-11-02T11:04:38+02:00" itemProp="datePublished">November 2, 2019</time></span> 		
                            </div> */}
                        </header>
                        <div className="featured-image  page-header-image-single ">
                            <img width="1024" height="683" src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-037-1024x683.jpg")} className="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                        </div>
                        <div className="entry-content" itemProp="text">
                            <p><strong><u>Venue</u></strong><strong>: </strong>Belgrade, Serbia</p>
                            {/* <p><strong><u>Date</u></strong><strong>: </strong>26 October 2019<strong>&nbsp;</strong></p> */}
                            <p><strong>QUORUM FOR THE BOARD MEETING HAS BEEN ESTABLISHED.</strong></p>
                            <p><strong>FOLLOWING THE COMPREHENSIVE DISCUSSION OF THE AGENDA, THE BOARD OF DIRECTORS HAS ADOPTED THE FOLLOWING DECISIONS:</strong><strong>&nbsp;</strong></p>
                            <p><strong>QUESTION №1: </strong><strong>Consideration of applications and approval of new fully-fledged members (individuals and legal entities) of IFSPD in accordance with its Statute. Report by Ms. Esma Sirbubalo, IFSPD Executive Director:</strong></p>
                            <ol>
                                <li><em> Christina Briggs (Great Britain)</em></li>
                                <li><em> Ludmila Zhuravleva (Russian Federation)</em></li>
                                <li><em> Besnik Leskai(Albania)</em></li>
                                <li><em> Vefa Veysalova (Azerbaijan)</em></li>
                                <li><em> Syed Adil Gilani (Pakistan)</em></li>
                                <li><em> John Maresca, Ph.D. (USA)</em></li>
                                <li><em> Aleksandra Manasijevich (Serbia)</em></li>
                                <li><em>Law Firm “Krivtsov &amp; Partners” (Russian Federation)</em></li>
                            </ol>
                            <p><strong>RESOLUTION: </strong>The above-mentioned candidates (individuals and legal entities) are approved as fully-fledged members of IFSPD. Voted – unanimously.</p>
                            <p><strong>QUESTION №2</strong><strong>:</strong> <strong>Report on the work of the International Foundation during the mandate of the current IFSPD President. Report by Mr. Dimiter Kostov, Ph.D., IFSPD President.</strong></p>
                            <p><strong>RESOLUTION: </strong>Gratitude is expressed to Mr. Dimiter Kostov,Ph.D. for his great contribution to the development of IFSPD during his 2-year mandate, and the activities of the Foundation during this period are &nbsp;highly appreciated. Voted – unanimously.</p>
                            <p><strong>QUESTION № 3: Proposal to elect Prof. Sabahudin Hadzialic, Ph.D. (Bosnia and Herzegovina) as a Member of the Board of Directors of the International Foundation for Sustainable Peace and Development.</strong></p>
                            <p><strong>RESOLUTION</strong>: Prof. Hadzialic, Ph.D. is elected and approved as a Member of the Board of Directors of the International Foundation for Sustainable Peace and Development.</p>
                            <p>Voted – the majority</p>
                            <p><strong>QUESTION №4</strong><strong>: </strong><strong>Approval of the new IFSPD honorary member. Report by Prof. Eldar Hasanov, Ph.D., IFSPD Vice-President and Secretary-General.</strong></p>
                            <ol>
                                <li><em> Mr. Filip Vujanovic (Montenegro</em><strong><em>)</em></strong></li>
                            </ol>
                            <p><strong>RESOLUTION: </strong>Mr. Filip Vujanovic is approved as an honorary member of IFSPD. Voted – unanimously.</p>
                            <p><strong>QUESTION №</strong><strong>5: </strong><strong>Consideration of the election of Prof. Sangeeth Varghese, Ph.D., (India) on a post of IFSPD Vice-President.</strong></p>
                            <p><strong>RESOLUTION: </strong>Prof. Sangeeth Varghese, Ph.D. is elected and approved as a Vice-President of IFSPD.</p>
                            <p>Voted – unanimously.</p>
                            <p><strong>QUESTION №</strong><strong>6</strong><strong>: Report on the work and action plan of the IFSPD branch in Bulgaria. Report by IFSPD Vice-President, Prof. Zdravko Popov, Ph.D.</strong></p>
                            <p><strong>RESOLUTION: </strong>Gratitude is expressed to Prof. Popov, Ph.D., for the presented report, as well as the support to the activities of the IFSPD Bulgaria branch. Voted – unanimously.</p>
                            <p><strong>QUESTION №7:</strong> <strong>Consideration of opening the IFSPD branch in India. Report by Prof. Sangeeth Varghese, Ph.D., IFSPD Board Member.</strong></p>
                            <p><strong>RESOLUTION: </strong>Given the vigorous activity and contribution of Prof.&nbsp;Vargese, Ph.D., in the work of IFSPD, it is decided to open an IFSPD branch in India, and appoint Prof. Varghese, Ph.D. as the director of the branch. According to IFSPD practice, Prof. Vargese, Ph.D. is entrusted to provide branch’s financing. Voted – unanimously.</p>
                            <p><strong>QUESTION №8: Presentation and release of the „Global Sustainability Index of IFSPD“ project. Report by Prof. Sangeeth Varghese, Ph.D., IFSPD Board Member.</strong></p>
                            <p><strong>RESOLUTION:</strong> The appreciation is expressed to Prof. Vargese, PhD., for his active work and implementation of the strategically important project “IFSPD Global Sustainable Development Index”. Voted – unanimously.</p>
                            <p><strong>QUESTION </strong><strong>№ 9</strong><strong>: Discussion of re-registration of the IFSPD branch in the Russian Federation. Report by IFSPD Vice-President, Mr. Mikhail Zernov.</strong></p>
                            <p><strong>RESOLUTION: </strong>To establish the Branch of the Organization (as in the Statute) “International Foundation for Sustainable Peace and Development” in the Russian Federation, to appoint Mr. Zernov Mikhail Sergeyevich as the Director of the Branch of the Organization “International Foundation for Sustainable Peace and Development”, and to authorize Mr. Zernov Mikhail Sergeyevich to register the branch of the “International Foundation for Sustainable Peace and Development” in the Russian Federation at the Ministry of Justice of the Russian Federation. Voted – unanimously.</p>
                            <p><strong>QUESTION № 10: Promising environmental technologies recommended by the IFSPD Innovation and Investment Commission. Report by IFSPD Vice-President, Mr. Mikhail Zernov.</strong></p>
                            <p><strong>RESOLUTION: </strong>Gratitude is expressed to Mr. Zernov for the report, as well as the support to the work of the IFSPD Innovation and Investment Commission. Voted – the majority.</p>
                            <p><strong>QUESTION № 11:</strong> <strong>Presentation of the International Law Center (IPC), created on the basis of the IFSPD branch in the Russian Federation and the Krivtsov &amp; Partners Law Firm. Report by the Director of the IPC, Mr. Vladimir Krivtsov.</strong></p>
                            <p><strong>RESOLUTION: </strong>To minutely consider the plan for the establishment and activities of the International Legal Center (ILC), created on the basis of the IFSPD branch in the Russian Federation and the “Krivtsov &amp; Partners” law firm, and provide support to the IFSPD branch in the Russian Federation on this issue. Voted – the majority.</p>
                            <p><strong>QUESTION № 12: Report on the latest events in South African and African community and development in cultural and economic relations between Eastern Europe and Sub-Saharan Africa. Report by Mr. Zarko Taric, IFSPD Board Member.</strong></p>
                            <p><strong>RESOLUTION: </strong>Gratitude is expressed to Mr. Taric for the report on this topic. Voted – the majority.</p>
                            <p><strong>QUESTION № 13: Reflection on the alarm repeatedly launched by several authoritative Western magazines on the alleged current crisis of the “Liberal International Order”. Report by Prof. AntonGiulio de’Robertis, Ph.D.</strong></p>
                            <p><strong>RESOLUTION: </strong>Gratitude is expressed to Prof. de Robertis, Ph.D., for his report on this topic, stressing that this presentation was very brave. Members of the IFSPD Board agreed that in discussions of such topics, everyone should a primarily adhere to the mutual respect. Voted – the majority.</p>
                            <p><strong>QUESTION № 14: Election of the new IFSPD President in accordance with its Statute.</strong></p>
                            <p><strong>RESOLUTION: </strong>Taking into consideration Article 35. of IFSPD Statute, namely the clause (2): <em>When the mandate of the President, a Vice-President or one of the members of the Board of Directors expires, a representative from the country whose name starts with a letter that follows the letter “B” (from Bulgaria) of Latin alphabet is elected for a President, a Vice-President or a member of the Board of Directors, according to the rotation principle.<strong> As an exception, if necessary, when it is in the interest of the Association, a person different from the one determined under the above principle could be elected for a President, a Vice-President or a member of the Board of Directors, </strong></em>and also, adhering to the principle of gender equality, in accordance with the XVII UN Sustainable Development Goals (SDGs) (V Goal – Gender Equality), and taking into account that 36.2% of the IFSPD members are women, the following candidates have been put forward:</p>
                            <ul>
                                <li>Member of the Board of Directors, Mr. Ilija Kazic, proposed Mrs. Biserka Jevtimijevic (Serbia);</li>
                                <li>Vice-president Mikhail Zernov (Russian Federation) proposed Mrs. Zhyldyz Oskonbaeva (USA);</li>
                                <li>Vice-president Zdravko Popov (Bulgaria) proposed Mrs. Manana Sanadze (Georgia).</li>
                            </ul>
                            <p>Mrs. Sanadze, Mrs. Oskonbaeva and Mrs. Jevtimijevic extended their sincere gratitude and honor to be nominated. However, given Mrs. Sanadze’s busy schedule as a Univeristy’s Rector, as well as Mrs. Oskonbaeva’s permenant residence in the USA, which makes it difficult for Mrs. Oskonbaeva to manage IFSPD’s activity in a dynamic and efficient manner, both candidates expressed their desire to decline the given candidacy proposal. Thus, by open and transparent voting, Mrs. Biserka JEVTIMIJEVIC (Serbia) is elected as the new president of IFSPD. Voted – unanimously.</p>
                            <p><strong>QUESTION № 15: Adoption of the IFSPD Belgrade Declaration</strong></p>
                            <p><strong>RESOLUTION: </strong>Belgrade Declaration (text attached) of the International Foundation is adopted. It is decided to circulate the Declaration to all influential international organizations, including the United Nations, the European Union, the Council of Europe, the OSCE, governmental bodies of countries represented in the International Foundation, international and national NGOs. Voted – unanimously.</p>
                            <p><strong>QUESTION № 16:</strong> <strong>Discussion of the venue for the next IFSPD General Assembly and Board of Directors Meeting</strong></p>
                            <p><strong>RESOLUTION:</strong> To instruct the Secretary-General and the Secretariat of the International Foundation to consider proposals for holding the next meeting in Ohrid, North Macedonia in the first half of September 2020. All members of the Board of Directors are ought to be informed about results. Voted – unanimously.</p>
                            <p><strong>Since there are no other issues for discussion, the Secretary-General Prof. Eldar Hasanov,</strong> <strong>Ph.D., having the full consent of all the attendees, declares the meeting closed.</strong></p>
                            <p><strong>Signed by:</strong></p>
                        </div>
                        </div>
                    </article>
                    <a className="singlepostlinkback" href="/board-meetings">← Back to the list of all Board Meetings</a>		
                </main>
            </div>
            </div>
        </div>   
    )
}

export default Event1;