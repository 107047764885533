import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/about.css';

function LegalEntity(){
    SetMetaData("Legal Entity", "", "");

    return (
        <div className="site hfeed grid-parent" id="page">
            <div className="site-content" id="content">

                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">

                        <article id="post-1591" className="post-1591 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope="">
                            <div className="inside-article">

                                <header className="entry-header" aria-label="Content">
                                    <h1 className="entry-title title" itemProp="headline">Legal Entity</h1>
                                </header>


                                <div className="entry-content" itemProp="text">
                                    <div id="pl-1591" className="panel-layout">
                                        <div id="pg-1591-0" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-no-style">
                                            <div id="pgc-1591-0-0" className="panel-grid-cell">
                                                <div id="panel-1591-0-0-0" className="so-panel widget widget_sow-image panel-first-child panel-last-child" data-index="0">
                                                    <div className="so-widget-sow-image so-widget-sow-image-default-d6014b76747a-1591">

                                                        <div className="sow-image-container">
                                                            <img src={require("../../assets/images/main/body/inner-pages-grapshic-element.jpg")} width="1920" height="231" sizes="(max-width: 1920px) 100vw, 1920px" title="inner pages grapshic element" alt="" loading="lazy" className="so-widget-image" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1591-1" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-1591-1">
                                                <div id="pgc-1591-1-0" className="panel-grid-cell">
                                                    <div id="panel-1591-1-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="1">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1591-1-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>AGAT</strong></p>
                                                                    <p><strong>AZVIRT LLC</strong></p>
                                                                    <p><strong>BelIOD / Belarusian Society of Friendship and Cultural Relations with Foreign Countries</strong></p>
                                                                    <p><strong>Bilateral Chamber of Commerce and Industry "Romania-Azerbaijan"</strong></p>
                                                                    <p><strong>Bilateral Chamber of Commerce and Industry "Romania-Turkey"</strong></p>
                                                                    <p><strong>CallTurk Telekomunikasyon</strong></p>
                                                                    <p><strong>Celebic Group</strong></p>
                                                                    <p><strong>EURINVEST</strong></p>
                                                                    <p><strong>Gaz-InvestFlot Company</strong></p>
                                                                    <p><strong>Global Energy Trading SA</strong></p>
                                                                    <p><strong>Infoline, Turkey</strong></p>
                                                                    <p><strong>JPK Parking Service</strong></p>
                                                                    <p><strong>K GROUP</strong></p>
                                                                    <p><strong>Law Firm “Krivtsov &amp; Partners”</strong></p>
                                                                    <p><strong>Millennium Tourism &amp; Congress DMC LLD</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1591-1-1" className="panel-grid-cell">
                                                    <div id="panel-1591-1-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="2">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-1591-1-1-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>Montecorp Montenegro</strong></p>
                                                                    <p><strong>NIRO INVESTMENT GROUP</strong></p>
                                                                    <p><strong>Oasis Hungary KFT</strong></p>
                                                                    <p><strong>Opal.F</strong></p>
                                                                    <p><strong>OTP Company</strong></p>
                                                                    <p><strong>Quantum Energy LTD</strong></p>
                                                                    <p><strong>Ro Eye Center</strong></p>
                                                                    <p><strong>SA "Global Energy Trading"</strong></p>
                                                                    <p><strong>SC OZTASAR SRL</strong></p>
                                                                    <p><strong>SC ROMENERGO SRL</strong></p>
                                                                    <p><strong>SIVECO ROMANIA</strong></p>
                                                                    <p><strong>STROYCONSTRUCTIA</strong></p>
                                                                    <p><strong>TEPLOCENTRSTROY</strong></p>
                                                                    <p><strong>Total Trade International DOO</strong></p>
                                                                    <p><strong>UPETROM</strong></p>
                                                                    <p><strong>VİZYON Constructing, Consulting, Foreign Trade Company</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </article>
                    </main>
                </div>


            </div>
        </div>
    )
}

export default LegalEntity;