import React from "react";
// import { SpinnerRoundFilled } from 'spinners-react';
import loaderPic from '../assets/images/Image-Loader.gif';

function LoaderMedium(props){
    return (
        // <SpinnerRoundFilled color={props.color ? props.color: '#d2342d'} size={props.size ? props.size: 45} />
        <>
            <img src={loaderPic} alt="Loading..." style={{width: props.width ? props.width: 45, height: props.width ? props.width: 45}}/>
        </>
    )
}

export {LoaderMedium};