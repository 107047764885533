import React, {useState, useEffect, useRef} from "react";
import { useLocation } from "react-router-dom";
import {SetMetaData} from './../../helper/page-title';
import Select from 'react-select';
import './../../assets/css/pages/team.css';
import {get_api_call, post_api_call, error_api_call} from '../../actions/action-creators/common';
import {alertResponseMessage} from '../../actions/action-creators/response';
import { RSstandardStyle } from "../../config/react-select";
import {toggleBodyLoader} from '../../helper/custom-function';
import {LoaderMedium} from './../../components/loader';
import noFellow from '../../assets/images/oops.png';
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
// const startYear = currentYear - 100;
const selectOptionLabel = {ifspd_program_id: 'program_name', ifspd_program_year: 'year', country_id: 'name'};

function FellowProfile(){
    SetMetaData("Fellow Profile", "", "");
    const location = useLocation();
    const [countryData, setCountryData] = useState([]);
    const [programData, setProgramData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [filterData, setFilterData] = useState({name: '', ifspd_program_id: '', ifspd_program_year: '', country_id: ''});
    const [fellowData, setFellowData] = useState([]);
    // const [fellowTotal, setFellowTotal] = useState(0);
    const [fellowPage, setFellowPage] = useState(0);
    const [callFellowAPI, setCallFellowAPI] = useState(0);
	//Loading
	const [showFellowLoader, setFellowLoader] = useState(true);
	const [fellowLoading, setFellowLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const limit = 15;
	const loadMoreFellow= useRef(null);//Button Click
	const fellowLoader = useRef(null);
    // Update Filter Input
    const updateInput = (input_type, field, value, select_option='') => {
        try{
            if(field === 'name'){
                value = value.replace(/[^a-zA-Z. ]/g, '')
                            .replace('  ', ' ');
            }
            const filledFilterData = {...filterData, [field]: value};
            // setFilterData({...filterData, [field]: value});
            setFilterData({...filledFilterData});
            if(field !== 'name'){
                console.log('yes')
                // searchFellow();
                // setFellowPage(1);
                toggleBodyLoader('load');
                if(filledFilterData[field].id === ''){
                    modifyAdBarUrl(field, '');
                }else{
                    modifyAdBarUrl(field, `${filledFilterData[field].id},${filledFilterData[field][select_option]}`);
                }
                setPageLoading(true);
                setFellowPage(fellowPage === 1 ? 0 : 1);
            }
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
            // console.log(e)
            error_api_call({error: `fellow/profile|updateInput|${e}`});
            return false;
        }
    }
    //Search by Filter Data
    const searchFellow = (e) => {
        try{
            e.preventDefault();
            toggleBodyLoader('load');
            modifyAdBarUrl('name', filterData.name);
            setPageLoading(true);
            setFellowPage(fellowPage === 1 ? 0 : 1);
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
            // console.log(e)
            error_api_call({error: `fellow/profile|searchFellow|${e}`});
        }
    }
    //Get Data for Country & Program
    const fetchSelectList = async(type) => {
        try{
			const listApiData = type === 'program'
                                    ? await get_api_call('ifspd-program', {})
                                    : await get_api_call('countries', {});
			if(listApiData.status === 200){
				type === 'program'
                    ? setProgramData(listApiData.data)
                    : setCountryData(listApiData.data);
			}
        }catch(e){
			error_api_call({error: `fellow|profile|fetchSelectList|${e}`});
        }
    }
    //Creating Year Array
    const yearDataList = (type) => {
        const yearItems = [];
        // for (let i = currentYear; i >= startYear; i--) {
        for (let i = currentYear; i >= 2020; i--) {
        //   yearItems.push(<option key={`${type}_${i}`} value={i}>{i}</option>);
            yearItems.push({id: i, year: i});
        }
        setYearData(yearItems);
        // return yearItems;
    };
    //Modify URL
	const modifyAdBarUrl = (key, value) => {
		try{
			// if(typeof value === 'array'){
			// if(value instanceof Array){
				const url = new URL(window.location.href);
				if(!value){//value.length === 0
					url.searchParams.delete(key);
				}else{
					url.searchParams.set(key, value);
				}
				
				window.history.pushState({}, '', url.toString());
			// }
		}catch(e){
			// console.log(e)
			error_api_call({error: `fellow/profile|modifyAdBarUrl|${e}`});
			return false;
        }
	};
	//Load More Data when loader is in focus
	const infiniteCustomScroll = () => {
		if(loadMoreFellow.current){
			loadMoreFellow.current.click();
		}
	}
	//Initial Load & Read More Implementation
	useEffect(() => {
        const options = {
           root: null,
           rootMargin: "20px",
           threshold: 1.0
        };
       // initialize IntersectionObserver
       // and attaching to Load More div
        //For All
		const observerAll = new IntersectionObserver(infiniteCustomScroll, options);
        if (fellowLoader.current) {
            observerAll.observe(fellowLoader.current)
        }
		const clean = fellowLoader.current;
        return () => {
            if (clean) {
                observerAll.disconnect(clean);
            }
        }
    }, [fellowLoader.current]);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Filter Data
	useEffect(() => {
        console.log('urlHandling')
		const urlHandling = () => {
			try{
				//URL Param type Handling
				const urlQuryString = location.search;
				const urlParam = new URLSearchParams(urlQuryString);
				if(urlParam.size > 0){
					// const filterKeys = ['name', 'status', 'year', 'program', 'sector', 'country'];
                    const filterKeys = ['name', 'ifspd_program_id', 'ifspd_program_year', 'country_id'];
					var filterDataNew = {...filterData};
					urlParam.forEach((value, key) => {
						if(filterKeys.indexOf(key) > -1){
                            if(key !== 'name'){
                                var urlParamData = value.split(',');
                                filterDataNew[key] = {id: parseInt(urlParamData[0]), [selectOptionLabel[key]]: urlParamData[1]};
                            }else{
                                filterDataNew[key] = value;
                            }
							console.log(value, key);
						}
					});
                    console.log(filterDataNew, 'filterDataNewfilterDataNewfilterDataNew')
					// setFilterData({...filterDataNew});
                    // setTimeout(() => {
                        setFilterData({...filterDataNew});
                    // }, 13000)
                    
                    console.log(filterData, 'filterDatafilterDatafilterData')
				}
				setCallFellowAPI(1);
				setFellowPage(fellowPage === 1 ? 0 : 1);
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Fellows! Check back after some times"});
				error_api_call({error: `fellow/profile|urlHandling|${e}`});
			}
		}
        // fetchAPIList('category');
		// fetchAPIList('main-category');
        fetchSelectList('program');
        fetchSelectList('country');
        yearDataList();
        urlHandling();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Fellow Filter API
	useEffect(() => {
		//Get Filtered Fellow
		const fetchData = async() => {
			try{
				//APi Call
				if(!fellowLoading){
					setFellowLoading(true);
					const fellowCurrentPage = fellowPage < 2 ? 1 : fellowPage;
					if(fellowCurrentPage === 1){
						setFellowData([]);
					}
					const newFellowList = await post_api_call('fellows', 
																{limit,
																page: fellowCurrentPage,
																filter: {name: filterData.name,
                                                                        country_id: filterData.country_id !== '' ? filterData.country_id.id : '',
                                                                        ifspd_program_id: filterData.ifspd_program_id !== '' ? filterData.ifspd_program_id.id : '',
                                                                        ifspd_program_year: filterData.ifspd_program_year !== '' ? filterData.ifspd_program_year.id : ''},
																// keyword: queryKeyword,
															});
					if(newFellowList.status === 200){
						const newFellowData = fellowCurrentPage < 2
												? newFellowList.data 
												: fellowData.concat(newFellowList.data);
						setFellowData(newFellowData);
						if(fellowCurrentPage === 1){
							// setFellowTotal(newFellowList.total);
							setPageLoading(false);
						}
					}
					setFellowLoading(false);
					toggleLoader(fellowCurrentPage, newFellowList.total);
					if(fellowCurrentPage === 1){
						toggleBodyLoader('remove');
					}
				}
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
				// console.log(e);
				setFellowLoading(false);
				error_api_call({error: `fellow/profile|fetchData|${e}`});
			}
		}
		if(callFellowAPI === 1){
			fetchData();
		}
	}, [fellowPage]);// eslint-disable-line react-hooks/exhaustive-deps
	//Load More Fellow
	const loadMore = () => {
		setFellowPage(fellowPage < 2 ? 2 : fellowPage+1);
	}
	//Show-Hide Item Loader
	const toggleLoader = (current_page, total_page) => {
        try{
            var response = {page_count: 0, is_show: false};
			const totalPage = total_page <= limit
							? 1
							: total_page % limit === 0 
							? total_page/limit 
							: parseInt((total_page/limit))+1;
							// console.log(current_page, 'current_page')
							// console.log(totalPage, 'totalPage')
			if(current_page < totalPage){
				response.is_show = true;
				setFellowLoader(true);
			}else{
				setFellowLoader(false);
			}
			response.page_count = totalPage;
            return response;
        }catch(e){
            return response;
        }
    }
    return (
        <main id="main">
        <section className="team-sec pt-0">
          <div className="container-fluid container-lg pe-lg-5 ps-lg-5">
              <div className="section-header section-header-1">
                  <h1 className="title">Fellows</h1>
              </div>
              <div className="mb-5 p-3" style={{backgroundColor: '#f8f9fa', borderRadius: 5,position: 'relative', zIndex: 99}}>
              {/* ms-3 */}
                {/* <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="input-group mb-3">
                            <input className="form-control" placeholder="Search by Name"/>
                            <div className="input-group-append">
                                <span className="input-group-text" style={{height: 38}}><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="input-group">
                            <input className="form-control" placeholder="Search by Name" value={filterData.name} onChange={(e) => updateInput('input', 'name', e.target.value)}/>
                            <div className="input-group-append">
                                <button className="input-group-text" style={{height: 38}} onClick={(e) => searchFellow(e)}><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 ps-1 pe-1">
                        <Select
                            options={[{id: '', program_name: 'ALL'}].concat(programData)} 
                            // defaultValue={filterData.ifspd_program_id}
                            value={filterData.ifspd_program_id}
                            placeholder={"Program"}
                            onChange={(value) => updateInput('select-react', 'ifspd_program_id', value, 'program_name')}
                            getOptionLabel ={(option) => option.program_name}
                            getOptionValue ={(option) => option.id}
                            menuPlacement="auto"
                            menuPosition={'fixed'}
                            styles={RSstandardStyle}
                        />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        {/* <div className="w-110p"> */}
                            <Select
                                options={[{id: '', year: 'ALL'}].concat(yearData)}
                                value={filterData.ifspd_program_year}
                                defaultValue={filterData.ifspd_program_year}
                                placeholder={"Year"}
                                onChange={(value) => updateInput('select-react', 'ifspd_program_year', value, 'year')}
                                getOptionLabel ={(option) => option.year}
                                getOptionValue ={(option) => option.id}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                                styles={RSstandardStyle}
                            />
                        {/* </div> */}
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                        {/* <div className="w-200p"> */}
                            <Select
                                options={[{id: '', name: 'ALL'}].concat(countryData)}
                                value={filterData.country_id}
                                defaultValue={filterData.country_id}
                                placeholder={"Country"}
                                onChange={(value) => updateInput('select-react', 'country_id', value, 'name')}
                                getOptionLabel ={(option) => option.name}
                                getOptionValue ={(option) => option.id}
                                styles={RSstandardStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                            />
                        {/* </div> */}
                    </div>
                    {/* <div className="col-lg-3 col-md-3 col-sm-12">
                    <button className="search-btn" onClick={(event) => searchFellow(event)}>Search</button>
                    </div> */}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col-md-12 col-12 col-sm-12`}>
					{pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
						fellowData.length > 0 ?
							<>
                            <div className="row">
								{fellowData.map((fellow, fellow_indx) => (
                                     <div className="col-md-3 col-12 col-sm-6" key={`fellow-data${fellow_indx}`}>
                                        <div className="team-card">
                                            <div className="team-detail">   
                                                <h2>
                                                <a href={`/fellow-profile/${fellow.url}`} target="_blank" rel="noopener noreferrer">
                                                    <strong>{fellow.first_name} {fellow.last_name || ''}</strong>
                                                </a>
                                                {/* <span className="position">{fellow.position}, {fellow.designation}</span> */}
                                                <span className="position">{fellow.position}</span>
                                                {/* <!-- <ul className="d-flex">
                                                <li><a href={team_d.facebook} className="facebook"><i className="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href={team_d.twitter} className="twitter"><i className="fa-brands fa-twitter"></i></a></li>
                                                <li><a href={team_d.instagram} className="instagram"><i className="fa-brands fa-instagram"></i></a></li>
                                                <li><a href={team_d.behance} className="linkedin"><i className="fa-brands fa-linkedin"></i></a></li>
                                                </ul> --> */}
                                                </h2>
                                                <div className="img-wrapper">
                                                <a href={`/fellow-profile/${fellow.url}`} target="_blank" rel="noopener noreferrer">
                                                    <div className="img-wrap card"><img src={fellow.logo} alt={`${fellow.first_name} ${fellow.last_name || ''}`}/></div>
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
								{showFellowLoader && 
									<div className="text-center">
										<div ref={fellowLoader}>
											<LoaderMedium />
										</div>
										<button className="btn btn-primary" ref={loadMoreFellow} onClick={() => {loadMore()}}>Load More</button>
									</div>
								}
							</>
						: 
						<div className="row">
							<div className="no-fellow mt-4">
								<img src={noFellow} alt="No Matching Fellow Found" />
								<p className="pt-5 head">Sorry, no matching Fellow found!</p>
								<p className="sub-title ml-2 mr-2">Please try with different filter</p>
							</div>
						</div>
					}
				</div>


  
                {/* <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Issa-Conteh" target="_blank" rel="noopener noreferrer">
                                <strong>Issa Conteh</strong>
                            </a>
                            <span className="position">Head of Office, UN Resident Coordinator’s Office, Uganda.</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Issa-Conteh" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Issa-Conteh.png")} alt="Issa Conteh" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Vamuyan-A-Sesay" target="_blank" rel="noopener noreferrer">
                                <strong>Vamuyan A. Sesay, FMVA</strong>
                            </a>
                            <span className="position">Office of the Vice President of the Republic of Liberia.Senior Economist</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Vamuyan-A-Sesay" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Vamuyan-A--Sesay.png")} alt="Vamuyan A. Sesay" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Etape-Ngole" target="_blank" rel="noopener noreferrer">
                                <strong>Etape Ngole</strong>
                            </a>
                            <span className="position">Junior Senator of Cameroon for the United Nations</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Etape-Ngole" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Etape-Ngole.png")} alt="Etape Ngole" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Charles-Chimdiya-Asiegbu" target="_blank" rel="noopener noreferrer">
                                <strong>Charles Chimdiya Asiegbu</strong>
                            </a>
                            <span className="position">Research Lead | Fellow, Friedrich Ebert Stiftung OMYV | Bridge Fellow, Nigeria Economic Summit Group (NESG) | YIL Fellow</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Charles-Chimdiya-Asiegbu" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Charles-Chimdiya-Asiegbu.png")} alt="Charles-Chimdiya-Asiegbu" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Nayab-Zahra" target="_blank" rel="noopener noreferrer">
                                <strong>Nayab Zahra</strong>
                            </a>
                            <span className="position">CEO. Entrepreneur.</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Nayab-Zahra" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Nayab-Zahra.png")} alt="Nayab-Zahra" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Yash-Singh-Sisodiya" target="_blank" rel="noopener noreferrer">
                                <strong>Yash Singh Sisodiya</strong>
                            </a>
                            <span className="position">Social Scientist. Senior Researcher for IAS Officer Training.</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Yash-Singh-Sisodiya" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Yash-Singh-Sisodiya.png")} alt="Yash Singh Sisodiya" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Zuliehat-Abdullahi" target="_blank" rel="noopener noreferrer">
                                <strong>Zuliehat Abdullahi</strong>
                            </a>
                            <span className="position">Researcher at Youth of Enterprise Nigeria</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Zuliehat-Abdullahi" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Zuliehat-Abdullahi.png")} alt="Zuliehat Abdullahi" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Nabila-Arab" target="_blank" rel="noopener noreferrer">
                                <strong>Nabila Arab</strong>
                            </a>
                            <span className="position">Advisory Board Member, UNDP</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Nabila-Arab" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Nabila-Arab.png")} alt="Nabila Arab" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Anam-Zahra" target="_blank" rel="noopener noreferrer">
                                <strong>Anam Zahra</strong>
                            </a>
                            <span className="position">United People Global Sustainability Leader</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Anam-Zahra" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Anam-Zahra.png")} alt="Anam Zahra" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div> */}
  
                {/* <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Esther-Mundia" target="_blank" rel="noopener noreferrer">
                                <strong>Esther Mundia</strong>
                            </a>
                            <span className="position">Associate at Global Alliance</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Esther-Mundia" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Esther-Mundia.png")} alt="Esther Mundia" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Faisal-Al-Islam" target="_blank" rel="noopener noreferrer">
                                <strong>Faisal Al Islam</strong>
                            </a>
                            <span className="position">GDSC Team Lead</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Faisal-Al-Islam" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Faisal-Al-Islam.png")} alt="Faisal Al Islam" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Quadri-Olatunbosun-Taiwo" target="_blank" rel="noopener noreferrer">
                                <strong>Quadri Olatunbosun Taiwo</strong>
                            </a>
                            <span className="position">Geospatial Planner</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Quadri-Olatunbosun-Taiwo" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Quadri-Olatunbosun-Taiwo.png")} alt="Quadri Olatunbosun Taiwo" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Xolani-Sydwel-Mnisi" target="_blank" rel="noopener noreferrer">
                                <strong>Xolani Sydwel Mnisi</strong>
                            </a>
                            <span className="position">Policy Analyst</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Xolani-Sydwel-Mnisi" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Xolani-Sydwel-Mnisi.png")} alt="Xolani Sydwel Mnisi" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Mohsin-Ali-Baig" target="_blank" rel="noopener noreferrer">
                                <strong>Mohsin Ali Baig</strong>
                            </a>
                            <span className="position">Researcher</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Mohsin-Ali-Baig" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Mohsin-Ali-Baig.png")} alt="Mohsin Ali Baig" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Mohamed-Faraban-Diakite" target="_blank" rel="noopener noreferrer">
                                <strong>Mohamed Faraban Diakite</strong>
                            </a>
                            <span className="position">Doctor of Medicine from Gamal Abdel Nasser University</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Mohamed-Faraban-Diakite" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Mohamed-Faraban-Diakite.png")} alt="Mohamed Faraban Diakite" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Hasibul-Hasan" target="_blank" rel="noopener noreferrer">
                                <strong>Hasibul Hasan</strong>
                            </a>
                            <span className="position">Youth Peacebuilder & Environmental Activist</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Hasibul-Hasan" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Hasibul-Hasan.png")} alt="Hasibul Hasan" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div> */}
  
                {/* <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Sakib-U-Sourav" target="_blank" rel="noopener noreferrer">
                                <strong>Sakib U Sourav</strong>
                            </a>
                            <span className="position">PhD student at Concordia in Montreal</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Sakib-U-Sourav" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Sakib-U-Sourav.png")} alt="Sakib U Sourav" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/SHAKEEL-AHMED" target="_blank" rel="noopener noreferrer">
                                <strong>SHAKEEL AHMED</strong>
                            </a>
                            <span className="position">Founder GREENV | Climate Activist | Entrepreneur</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/SHAKEEL-AHMED" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/SHAKEEL-AHMED.png")} alt="SHAKEEL AHMED" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Oluwaseun-Atoyebi" target="_blank" rel="noopener noreferrer">
                                <strong>Oluwaseun Atoyebi</strong>
                            </a>
                            <span className="position">Media Founder and Host</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Oluwaseun-Atoyebi" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Oluwaseun-Atoyebi.png")} alt="Oluwaseun Atoyebi" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="col-md-3 col-12 col-sm-6">
                    <div className="team-card ">
                        <div className="team-detail">   
                            <h2>
                            <a href="/fellow-profile/Zahid-Hussain" target="_blank" rel="noopener noreferrer">
                                <strong>Zahid Hussain</strong>
                            </a>
                            <span className="position">National Executive G17 Global</span>
                            </h2>
                            <div className="img-wrapper">
                            <a href="/fellow-profile/Zahid-Hussain" target="_blank" rel="noopener noreferrer">
                                <div className="img-wrap card"><img src={require("../../assets/images/fellow/Zahid-Hussain.png")} alt="Zahid Hussain" /></div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div> */}
  
              </div>
          </div>
        </section>
      </main>
    )    
}

export default FellowProfile;