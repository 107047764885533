import React, {useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import { RSstandardStyle } from "../../config/react-select";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {post_api_call, get_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
import {update_user_info} from './../../actions/action-creators/state-update';
import { yesNoData } from "../../components/select/option";
import {LoaderMedium} from './../../components/loader';
import { CharacterCount } from "../../components/form/count";
import picIcon from "./../../assets/images/user.png";

const initialFormData = {
    present: {
        teamwork: '',
        ontime: '',
        before_pic: '',
        after_pic: '',
        kpi: '',
        observers: ''
    },
    past: {
        permissions: '',
        resources: '',
        invited: '',
        reminded : ''
    },
    post: {
        achieve: '',
        ontime: '',
        satisfied: '',
        why_satisfied: ''
    }
};

function StepFiveForm(props){
    const cropperRef = React.createRef(null);
    const [pageLoading, setPageLoader] = useState(true);
    const [formDataPast, setFormDataPast] = useState({...initialFormData.past});
    const [formDataPost, setFormDataPost] = useState({...initialFormData.post});
    const [formDataPresent, setFormDataPresent] = useState({...initialFormData.present});
    // Image
    const [previewPicImg, setPreviewPicImg] = useState(null);
    const [croppedImg, setCroppedImg] = useState(null);
    const [previewPicBlob, setPreviewPicBlob] = useState(null);
    //Forms
    const [formDataGeneral, setFormDataGeneral] = useState({...initialFormData.general});
    const [errorData, setErrorData] = useState({Post: [],
                                                Past: [],
                                                Present: [],
                                                Image: []
                                            });
    const [isProcess, setIsProcess] = useState({Post: false,
                                                Past: false,
                                                Present: false,
                                                Image: false
                                            });
    const [isBtnDisabled, setBtnDisabled] = useState({Post: true,
                                                Past: true,
                                                Present: true,
                                                Image: true
                                            });
    //Upload Profile Image Preview
    const previewProfilePic = (event) => {
        try{
            const files = event.target.files;
            if(files.length > 0){
                const documetAllowedExt = ["png", "jpg", "jpeg"];
                const allowedSize = 200000;
                const imgFile = files[0];
            // for(let i=0; i<files.length; i++){
                var ext = imgFile.name.slice((imgFile.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
                //Check for file size and extension
                console.log('imgFile.size', imgFile.size)
                if(imgFile.size <= allowedSize && documetAllowedExt.includes(ext)){
                    const imgData = {data: imgFile,
                                    src: URL.createObjectURL(imgFile),
                                    // title: '',
                                    // is_image: imageExt.includes(imgFile.type.toLowerCase()) ? true : false,
                                    ext
                                };
                    setPreviewPicImg({...imgData});
                    setBtnDisabled({...isBtnDisabled, Pic: false});
                }else{
                    setPreviewPicImg(null);
                    alertResponseMessage({alert_type: 2, message: "Photograph allowed size is 200KB and it must be an image."});
                }
            }
        }catch(e){
            setPreviewPicImg(null);
            error_api_call({error: `portfolio|edit|previewProfilePic|${e}`});
        }
    }
    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        setCroppedImg(cropper.getCroppedCanvas().toDataURL());
        // console.log(cropper.getCroppedCanvas().toDataURL());
        cropper.getCroppedCanvas().toBlob((blob) => {
            console.log(blob, 'blobbb');
            setPreviewPicBlob(blob);
        });
    };
    useEffect(() => {
        //Get Portfolio Details
        // const fetchPortfolioData = async() => {
        //     try{
        //         const portfolioApiData = await post_api_call(`portfolio-details`, {});
        //         // const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
        //         if(portfolioApiData.status === 200){
        //             console.log(portfolioApiData, 'portfolioApiData')
        //             // if(portfolioApiData.data.headline){
        //             //     window.location.href = `/port/${portfolioApiData.data.url}`;
        //             // }else{
        //                 if(!portfolioApiData.data.headline){
        //                     setIsFirstEdit(true);
        //                 }
        //                 //GeneralDetails
        //                 const fieldKeys = ['first_name' , 'last_name', 'email', 'dob', 'language', 'mobile_number', 'logo'];
        //                 const generalFieldData = {...formDataGeneral};
        //                 fieldKeys.forEach(key => {
        //                     generalFieldData[key] = portfolioApiData.data[key] ? portfolioApiData.data[key] : '';
        //                 });
        //                 generalFieldData.country_id = portfolioApiData.data.country_id ? {id: portfolioApiData.data.country_id, name: portfolioApiData.data.country} : '';
        //                 generalFieldData.phone_code_id = portfolioApiData.data.mobile_code_id ? {id: portfolioApiData.data.mobile_code_id, phone_code: portfolioApiData.data.phone_code} : '';
        //                 generalFieldData.school_name_id = portfolioApiData.data.school_id ? {id: portfolioApiData.data.school_id, school_name: portfolioApiData.data.school_name} : '';
        //                 generalFieldData.cohort_name_id = portfolioApiData.data.cohort_id ? {id: portfolioApiData.data.cohort_id, cohort_name: portfolioApiData.data.cohort_name} : '';
        //                 setFormDataGeneral({...generalFieldData});
        //                 setUserUrl(portfolioApiData.data.user_name);
        //                 //Headline
        //                 setFormDataHeadline(portfolioApiData.data.position || '');
        //                 //Short Description
        //                 setFormDataDesc({short_description: portfolioApiData.data.headline || '',
        //                                 basic_achievement: portfolioApiData.data.achievement || '',
        //                                 basic_passion: portfolioApiData.data.passion || ''});
        //                 //Unique Skills
        //                 if(portfolioApiData.data.unique_skills.length > 0){
        //                     var uniqueData = [];
        //                     portfolioApiData.data.unique_skills.forEach(skill => {
        //                         uniqueData.push({passion_id : {id: skill.passion_id, title: `${skill.title}: ${skill.unique_ability}`}});
        //                     });
        //                     setFormDataUnique(uniqueData);
        //                 }
        //                 //Education
        //                 updateInpState('Edu', portfolioApiData.data.education_details);
        //                 //Achievements
        //                 if(portfolioApiData.data.glosco_data.length > 0){
        //                     var gloscoData = formDataAchieve;
        //                     portfolioApiData.data.glosco_data.forEach(glosco => {
        //                         gloscoData[glosco.level_id-1] = {...gloscoData[glosco.level_id-1],
        //                                                         id: glosco.level_id,
        //                                                         level: glosco.level,
        //                                                         year: {id: glosco.year, year: glosco.year},
        //                                                         indi_award: glosco.indi_award,
        //                                                         team_award: glosco.team_award};

        //                     });
        //                     setFormDataAchieve(gloscoData);
        //                 }
        //                 //Experience
        //                 updateInpState('Exp', portfolioApiData.data.experience_details);
        //                 //Research
        //                 updateInpState('Research', portfolioApiData.data.research_details);
        //                 //Sport
        //                 updateInpState('Sport', portfolioApiData.data.sport_details);
        //                 //Award
        //                 updateInpState('Award', portfolioApiData.data.award_details);
        //                 //Career Skills
        //                 if(portfolioApiData.data.carrer_skill_details.length > 0){
        //                     setFormDataCareer(portfolioApiData.data.carrer_skill_details);
        //                 }
        //                 //Personal Skills
        //                 if(portfolioApiData.data.personal_skill_details.length > 0){
        //                     setFormDataPersonal(portfolioApiData.data.personal_skill_details);
        //                 }
        //                 // setFormData({...fieldData});
                        setPageLoader(false);
        //             // }
        //         }
        //     }catch(e){
        //         console.log(e);
        //         alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
        //         error_api_call({error: `portfolio/edit|fetchPortfolioData|${e}`});
        //     }
        // }
        // fetchPortfolioData();
	}, []);
    //Update Input
    const updateInput = (identifier_key, identifier_type, input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            console.log(identifier_key, input_type, field, value, field_type, form_field, index, 'inputtt')
            if(errorData[identifier_key].length){
                setErrorData({...errorData, [identifier_key]: []});
            }
            setBtnDisabled({...isBtnDisabled, [identifier_key]: false});
            if(input_type === 'input'
                || input_type === 'textarea'
            ){
                value = value.trimStart();
            }
            var fieldData = eval('formData'+identifier_key);
            if(identifier_type === 'object'){
                fieldData = {...fieldData}
            }else if(identifier_type === 'array'){
                fieldData = [...fieldData]
            }
            console.log(fieldData, 'fieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldData')
            if(field_type === 'array'){
                console.log('fieldData[form_field][index][field] = value', form_field,',', index,',', field,',',  value)
                fieldData[index][field] = value;
            }else{
                identifier_type === 'single_value' ? fieldData = value : fieldData[field] = value;
            }
            switch (identifier_key) {
                case 'Post':
                    setFormDataPost({...fieldData});
                    break;
                case 'Past':
                    setFormDataPast(fieldData);
                    break;
                case 'Present':
                    setFormDataPresent({...fieldData});
                    break;
                default:
            }
        }catch(e){
            console.log(e);
            error_api_call({error: `social|step-five|updateInput|${e}`});
        }
    }
     //Edit Form data
     const editSocialFive = async(e, form_type='') => {
        try{
            e.preventDefault();
            setErrorData({...errorData, [form_type]: []});
            setIsProcess({...isProcess, [form_type]: true});
            var errorDetails = {
                Post: [],
                Past: [],
                Present: [],
                Image: []
            };
            var apiFormData = new FormData();
            //Validation
            var validation = [];
            var validationRules = {};
            switch (form_type) {
                case 'Post':
                    validationRules = {
                        achieve: 'required||achieve',
                        ontime: 'required||ontime',
                        satisfied: 'required||satisfied',
                        why_satisfied: 'required|max:250',
                    };
                    validation = ValidationHelper.validation(formDataPost, validationRules).error;
                    if(validation.length){
                        // errorDetails[form_type] = validation;
                        errorDetails[form_type] = ['Please Fill all the Select Boxes'];
                    }else{
                        const fieldFormDataPost = {...formDataPost,
                            achieve: formDataPost.achieve.id,
                            ontime: formDataPost.ontime.id,
                            satisfied: formDataPost.satisfied.id,
                        };
                        apiFormData.append('desc_data', JSON.stringify(fieldFormDataPost));
                    }
                    break;
                case 'Present':
                    validationRules = {
                        teamwork: 'required||teamwork',
                        ontime: 'required||ontime',
                        before_pic: 'required||before_pic',
                        after_pic: 'required||after_pic',
                        kpi: 'required||kpi',
                        observers: 'required||observers',
                    };
                    
                    validation = ValidationHelper.validation(formDataPresent, validationRules).error;
                    if(validation.length){
                        // errorDetails[form_type] = validation;
                        errorDetails[form_type] = ['Please Fill all the Select Boxes'];
                    }else{
                        const fieldFormDataPresent = {...formDataPresent,
                            teamwork: formDataPresent.teamwork.id,
                            ontime: formDataPresent.ontime.id,
                            before_pic: formDataPresent.before_pic.id,
                            after_pic: formDataPresent.after_pic.id,
                            kpi: formDataPresent.kpi.id,
                            observers: formDataPresent.observers.id,
                        };
                        apiFormData.append('desc_data', JSON.stringify(fieldFormDataPresent));
                    }
                    break;
                case 'Past':
                    validationRules = {
                        permissions: 'required||permissions',
                        resources: 'required||resources',
                        invited: 'required||invited',
                        reminded: 'required||reminded',
                    };
                    console.log(formDataPast, 'formDataPastformDataPast')
                    validation = ValidationHelper.validation(formDataPast, validationRules).error;
                    console.log(validation, 'validationvalidation')
                    if(validation.length){
                        // errorDetails[form_type] = validation;
                        errorDetails[form_type] = ['Please Fill all the Select Boxes'];
                    }else{
                        const fieldFormDataPast = {...formDataPast,
                            permissions: formDataPast.permissions.id,
                            resources: formDataPast.resources.id,
                            invited: formDataPast.invited.id,
                            reminded: formDataPast.reminded.id,
                        };
                        apiFormData.append('desc_data', JSON.stringify(fieldFormDataPast));
                    }
                    break;
                default:
            }
            console.log(errorDetails[form_type], 'errorDetails[form_type]')
            if(errorDetails[form_type].length > 0){
                setErrorData({...errorData, [form_type]: errorDetails[form_type]});
                setIsProcess({...isProcess, [form_type]: false});
                setBtnDisabled({...isBtnDisabled, [form_type]: true});
            }else{
                //Delete Later
                props.updateFormStep({next_step: 6,
                    // social_id: socialId,
                    // url: socialUrl,
                    // url: 'demo',
                    stage_id: props.data.stage_id < 5 ? 5 : props.data.stage_id
                });
                return;
                //Send Data to Server
                apiFormData.append('update_type', form_type.toLowerCase());
                // const updateFellow = await post_api_call(`update-portfolio/${form_type.toLowerCase()}`, apiFormData);
                const updateFellow = await post_api_call(`update-portfolio`, apiFormData);
				if(updateFellow.status === 200){
                    // setIsSuccess(true);
                    alertResponseMessage({alert_type: 1, message: "Information Updated Successfully"});
                    setBtnDisabled({...isBtnDisabled, [form_type]: true});
                    // setViewDisabled({...isViewDisabled, [form_type]: false});
                    console.log("Information Updated Successfully", 'Information Updated Successfully')
                }else{
                    if(typeof updateFellow.message !== "undefined"){
                        setErrorData({...errorData, [form_type]: [updateFellow.message]});
                        console.log("Errorooo")
                    }
                }
                setIsProcess({...isProcess, [form_type]: false});
            }
            
        }catch(e){
            console.log(e);
            setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
            setIsProcess({...isProcess, [form_type]: false});
            error_api_call({error: `social|step-five|editSocialFive|${e}`});
        }
    }
    return (
        <>
        {pageLoading
        ?
            <div className="text-center mt-5 mb-5">
                <LoaderMedium size={45} />
            </div>
        :
            <>
            <div className="col-md-10 col-12 col-sm-12">
            <div className="text-center">
                <h2 className="mb-1 mt-4"><b>Step 5: SOCIAL START-UP ROLL OUT</b></h2>
                <p>Create excitement, motivate yourself towards action and achieve results</p>
            </div>
            <div className="card p-3">
                Identify a small social problem around you based on following criteria:
                <ul className="mb-4 ms-3">
                    <li>Your social start-up will have to be completed within a single day.</li>
                    <li>If you have a team, each member should be given specific responsibilities to be completed by specific time.</li>
                    <li>Invite people who can be observers – your teachers, newspaper reporters, local authorities etc.</li>
                    <li>If you have a team, before start, gather the team together. Leader to remind the team the KPI they are planning to achieve.</li>
                    <li>Capture photos before, during and after the project.</li>
                </ul>
                {/* Confirmations */}
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Confirmations – To be completed the day before the social start-up</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        {/* Any Permissions */}
                                        <tr>
                                            <th className="w-75">If you require any permissions, have you received those?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataPast.permissions}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Past', 'object', 'select-react', 'permissions', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Any Resources */}
                                        <tr>
                                            <th>If you require any resources, assistance, equipment etc., have you arranged those?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()}
                                                    defaultValue={formDataPast.resources}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Past', 'object', 'select-react', 'resources', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Observer */}
                                        <tr>
                                            <th>Have you invited any important people to observe your project – like teachers, local authorities, media reporters, influencers etc.</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataPast.invited}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Past', 'object', 'select-react', 'invited', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Reminder */}
                                        <tr>
                                            <th>Have you reminded yourself as well as your team members about the KPIs that you have to achieve to make your social start-up a success?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataPast.reminded}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Past', 'object', 'select-react', 'reminded', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['Past']
                                ?
                                    isBtnDisabled['Past']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialFive(e, 'Past')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['Past'].length > 0 && (
                                <div className="alert alert-danger mt-4" role="alert">
                                    {/* <p>Please enter/modify following Data:</p> */}
                                    <ol className="li-sty-none ms-0">
                                    {errorData['Past'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* Confirmations: On the day*/}
                <Accordion className="pt-3" defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Confirmations – On the day of the social start-up</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        <tr>
                                            <th className="w-75">If you are working in a team, did all your team members join?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataPresent.teamwork}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Present', 'object', 'select-react', 'teamwork', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Did you start on time?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()}
                                                    defaultValue={formDataPresent.ontime}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Present', 'object', 'select-react', 'ontime', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Have you captured some ‘before project’ pictures?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataPresent.before_pic}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Present', 'object', 'select-react', 'before_pic', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Have you captured some ‘during and after project’ pictures?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataPresent.after_pic}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Present', 'object', 'select-react', 'after_pic', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Did you focus on achieving the KPI during the project?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataPresent.kpi}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Present', 'object', 'select-react', 'kpi', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Did some of your invitees turn up as observers?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()} 
                                                    defaultValue={formDataPresent.observers}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Present', 'object', 'select-react', 'observers', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['Present']
                                ?
                                    isBtnDisabled['Present']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialFive(e, 'Present')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['Present'].length > 0 && (
                                <div className="alert alert-danger mt-4" role="alert">
                                    {/* <p>Please enter/modify following Data:</p> */}
                                    <ol className="li-sty-none ms-0">
                                    {errorData['Present'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* Confirmations: post completed */}
                <Accordion className="pt-3" defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Confirmations – After social start-up is completed</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        <tr>
                                            <th className="w-75">Did you achieve, fall short or exceed the KPI that you had fixed for the social start-up?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={[{id: 1, title: 'Achieved the KPI'},
                                                                {id: 2, title: 'Fell short of the KPI'},
                                                                {id: 3, title: 'Exceeded the KPI'},
                                                            ]}
                                                    defaultValue={formDataPost.achieve}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Post', 'object', 'select-react', 'achieve', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Did you wind up on time?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={yesNoData()}
                                                    defaultValue={formDataPost.ontime}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Post', 'object', 'select-react', 'ontime', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>How satisfied are you with the results achieved by your social start-up?</th>
                                            <td>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={[{id: 1, title: 'Very satisfied'},
                                                            {id: 2, title: 'Somewhat satisfied'},
                                                            {id: 3, title: 'Neither satisfied nor dissatisfied'},
                                                            {id: 4, title: 'Somewhat dissatisfied'},
                                                            {id: 5, title: 'Not at all satisfied'}
                                                        ]} 
                                                    defaultValue={formDataPost.satisfied}
                                                    label={"Select"}
                                                    onChange={(value) => updateInput('Post', 'object', 'select-react', 'satisfied', value)}
                                                    getOptionLabel ={(option) => option.title}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Why were you satisfied or dissatisfied with the results achieved by your social start-up? In below 250 characters</th>
                                            <td>
                                                <textarea className="form-control" name="why_satisfied" autoComplete="off" value={formDataPost.why_satisfied} onChange={(e) => updateInput('Post', 'object', 'input', 'why_satisfied', e.target.value)} rows={5}/>
                                                <CharacterCount
                                                    max={250}
                                                    string={formDataPost.why_satisfied}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['Post']
                                ?
                                    isBtnDisabled['Post']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialFive(e, 'Post')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['Post'].length > 0 && (
                                <div className="alert alert-danger mt-4" role="alert">
                                    {/* <p>Please enter/modify following Data:</p> */}
                                    <ol className="li-sty-none ms-0">
                                    {errorData['Post'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* Photos */}
                <Accordion className="pt-3" defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Upload a few pictures from your social start-up project</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        <tr>
                                            <td>
                                                {previewPicImg !== null &&
                                                    <>
                                                    <div className="d-flex">
                                                    <Cropper
                                                        src={previewPicImg.src}
                                                        style={{ height: 200, width: 200 }}
                                                        // Cropper.js options
                                                        initialAspectRatio={1 / 1}
                                                        aspectRatio={1/1}
                                                        guides={false}
                                                        crop={onCrop}
                                                        ref={cropperRef}
                                                        dragMode="move"
                                                        // cropBoxResizable={false}
                                                        background={false}
                                                        // minContainerWidth={"100%"}
                                                        minContainerWidth={200}
                                                        zoomable={false}
                                                        highlight={false}
                                                    />
                                                    <div className="ms-4">
                                                        <img src={croppedImg} className="img-thumbnail cropper-preview-img" alt={"Post Picture"} />
                                                    </div>
                                                    </div>
                                                    </>
                                                }
                                                <div className="upload-img text-center">
                                                    <div className="m-3">
                                                        {/* <img src={require("./../../assets/images/user.png")} alt="User Logo" className="img-thumbnail" /> */}
                                                        {/* {formDataGeneral.logo ? */}
                                                        {previewPicImg !== null
                                                            ?
                                                            //  <img src={previewPicImg.src} alt={formDataGeneral.first_name} className="img-thumbnail cropper-preview-img" />
                                                            <></>
                                                            :
                                                            <img src={picIcon} alt={"Post Image"} className="img-thumbnail cropper-preview-img" />
                                                        }
                                                    
                                                        {/* :
                                                            <img src={previewPicImg !== null ? previewPicImg.src : picIcon} alt={formDataGeneral.first_name} className="img-thumbnail" />
                                                        } */}
                                                    </div>
                                                    
                                                    <div className="btn btn-light btn-outline-primary">
                                                        <span>Upload Your photo</span>
                                                        <input type="file" className="logo-input" name="profile_pic" onChange={(e) => previewProfilePic(e, 'Pic')} accept="image/x-png,image/jpg,image/jpeg" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['Image']
                                ?
                                    isBtnDisabled['Image']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialFive(e, 'Image')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['Image'].length > 0 && (
                                <div className="alert alert-danger mt-4" role="alert">
                                    {/* <p>Please enter/modify following Data:</p> */}
                                    <ol className="li-sty-none ms-0">
                                    {errorData['Image'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            </div>
            </>
        }
        </>
    )    
}

export default StepFiveForm;