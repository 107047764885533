import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';
import Gallery1 from "./1";
import Gallery2 from "./2";
import Gallery3 from "./3";
import Gallery4 from "./4";
import Gallery5 from "./5";
import Gallery6 from "./6";
import Gallery7 from "./7";
import Gallery8 from "./8";
import Gallery9 from "./9";

function Gallery(){
    SetMetaData("Gallery", "", "");

    return (
        <div className="site hfeed grid-parent gallery-list" id="page">
			<div className="site-content" id="content">

				<div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
					<main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">

						<article id="post-2578" className="post-2578 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope="">
							<div className="inside-article">

								<header className="entry-header" aria-label="Content">
									<h1 className="entry-title title mb-0" itemProp="headline">Gallery</h1>
								</header>


								<div className="entry-content" itemProp="text">
									<div id="pl-2578" className="panel-layout">
										<div id="pg-2578-0" className="panel-grid panel-has-style">
											<div className="ifgallery-page panel-row-style panel-row-style-for-2578-0">
												<div id="pgc-2578-0-0" className="panel-grid-cell">
													<div id="panel-2578-0-0-0" className="so-panel widget widget_sow-editor panel-first-child" data-index="0">
														<div className="panel-widget-style panel-widget-style-for-2578-0-0-0">
															<div className="so-widget-sow-editor so-widget-sow-editor-base">
															</div>
														</div>
													</div>
													<div id="panel-2578-0-0-1" className="so-panel widget widget_sow-editor panel-last-child" data-index="1">
														<div className="so-widget-sow-editor so-widget-sow-editor-base">
															<div className="siteorigin-widget-tinymce textwidget">
																<h2><strong>18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)</strong></h2>
																<div className="gallery gallery-columns-3 gallery-size-medium">
																	<Gallery1 />
																</div>
																<hr/>
																<h2><strong>IFSPD 17th Extended Board of Directors and General Assembly Meetings</strong></h2>
																<div className="gallery gallery-columns-3 gallery-size-medium">
																	<Gallery2 />
																</div>
																<hr/>
																<h2><strong>16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea</strong></h2>
																<div className="gallery gallery-columns-3 gallery-size-medium">
																	<Gallery3 />
																</div>
																<hr/>
																<h2><strong>The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey</strong></h2>
																<div className="gallery gallery-columns-3 gallery-size-medium">
																	<Gallery4 />
																</div>
																
																<hr/>
																<h2><strong>The 13th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea Minsk, Republic of Belarus</strong></h2>
																<div className="gallery gallery-columns-3 gallery-size-medium">
																	<Gallery5 />
																</div>
																<hr/>
																<h2><strong>BSCSIF Gala Dinner in Brussels</strong></h2>
																<div className="gallery gallery-columns-3 gallery-size-medium">
																	<Gallery6 />
																</div>
													
																<hr/>
																<h2><strong>The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea</strong></h2>
																<div className="gallery gallery-columns-3 gallery-size-medium">
																	<Gallery7 />
																</div>
								
																<hr/>
																<h2><strong>VIII SESSION OF THE BOARD OF DIRECTORS IN ISTANBUL, TURKEY</strong></h2>
																<div className="gallery gallery-columns-3 gallery-size-medium">
																	<Gallery8 />
																</div>
																
																<hr/>
																<h2><strong>The 7th board meeting of the International Fund for Cooperation and Partenership of the Black Sea and the Caspian Sea</strong></h2>
																<div className="gallery gallery-columns-3 gallery-size-medium">
																	<Gallery9 />
																</div>
																
																<hr/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</article>
					</main>
				</div>


			</div>
		</div>
    )    
}

export default Gallery;