import React from "react";
import Fancybox from '../../components/plugin/fancyox';

function Gallery8(){
    return (
        <Fancybox
            // Sample options
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/11/ef73fbae-c592-401f-9e38-44d29034990d_8bm2-500x333.jpg")} className="fancybox image gallery-item" title="VIII SESSION OF THE BOARD OF DIRECTORS IN ISTANBUL, TURKEY" rel="gallery-7"><img src={require("../../assets/images/main/event/11/ef73fbae-c592-401f-9e38-44d29034990d_8bm2-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/11/53a7a464-5184-48e9-af6e-fa092a543df6_8bm3-500x376.jpg")} className="fancybox image gallery-item" title="VIII SESSION OF THE BOARD OF DIRECTORS IN ISTANBUL, TURKEY" rel="gallery-7"><img src={require("../../assets/images/main/event/11/53a7a464-5184-48e9-af6e-fa092a543df6_8bm3-500x376.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/11/ac93a04e-438e-48be-bc03-48bedccfb97d_8bm4-500x376.jpg")} className="fancybox image gallery-item" title="VIII SESSION OF THE BOARD OF DIRECTORS IN ISTANBUL, TURKEY" rel="gallery-7"><img src={require("../../assets/images/main/event/11/ac93a04e-438e-48be-bc03-48bedccfb97d_8bm4-500x376.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/11/3824e864-9452-4f65-abdd-221d70f34914_8bm5-500x345.jpg")} className="fancybox image gallery-item" title="VIII SESSION OF THE BOARD OF DIRECTORS IN ISTANBUL, TURKEY" rel="gallery-7"><img src={require("../../assets/images/main/event/11/3824e864-9452-4f65-abdd-221d70f34914_8bm5-500x345.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
        </Fancybox>
    )
}

export default Gallery8;