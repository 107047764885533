import React from 'react';
import {
    Col,
    Row
  } from 'react-bootstrap';
  
function FellowAboutCom(props) {
    return (
      <> 
        <Row className="justify-content-center">
            <Col md="12" sm="12" className={props.containerCSS || ""}>
                <div className="card">
                    <img src={require("../../assets/images/fp/bg-fellowship.png") } className="card-img d-none d-sm-block" alt="Fellowship"/>
                    {/* <div className="card-img-overlay" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}> */}
                    <div className="card-img-overlay" style={{backgroundColor: "#ffffff61"}}>
                        <div className="row">
                            <div className="col-md-3 col-4 col-sm-3 text-center text-sm-start">
                                <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../../assets/images/main/logo/ifspd_logo_m.png')} title="International Foundation for Sustainable Peace and Development" />
                            </div>
                            <div className="col-md-9 col-8 col-sm-9">
                                <div className="float-sm-end text-center text-sm-start">
                                    <p className="banner-head">LeadCap Ventures</p>
                                    <p className="banner-title mb-3">Research | Analytics | Consulting</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-12 col-sm-12 text-center mt-2 mt-lg-5">
                                <p className="page-title">Elevate your profile</p>
                                <p className="page-sub-title">ifspd fellowships</p>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center text-uppercase justify-content-center mt-2 mt-md-3 mt-xl-5 fellow-f-btn">
                            {/* <div className="col-md-12 col-12 col-sm-12 d-md-flex text-uppercase justify-content-center mt-2 mt-lg-5"> */}
                                <div className="col-12 col-md-6 col-sm-6 col-xl-5"><div className="m-2"><p className="box-blue1 mb-0">prestigious global fellowship</p></div></div>
                                <div className="col-12 col-md-6 col-sm-6 col-xl-5"><div className="m-2"><p className="box-blue2 mb-0">20,000 applicants from 60+ countries</p></div></div>
                            {/* </div> */}
                            {/* <div className="col-md-12 col-xl-10 col-12 col-sm-12 text-uppercase mt-2 mt-xxl-5 mb-5"> */}
                            <div className="col-md-12 col-xl-10 col-12 col-sm-12 text-uppercases mb-5">
                                <div className="m-2 text-center"><p className="box-grey mb-0">Alumni include senators, bureaucrats, CEOs, entrepreneurs, winners of global awards & attendees of Ivy League universities</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        <Row className="justify-content-center mt-3 mt-lg-5 fellow-feature">
            <Col md="1" sm="12" xs="12" className="d-none d-md-block">
            </Col>
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/Elevated-Profile.jpg')} alt="Elevated Profile & Global Credibility" />
                <p className="f-title">Elevated Profile & Global Credibility</p>
            </Col>
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/Premium-Certification.jpg')} alt="Premium-Certification"/>
                <p className="f-title">Premium-Certification</p>
            </Col>
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/Differentiated-Portfolio.jpg')} alt="Differentiated Portfolio"/>
                <p className="f-title">Differentiated Portfolio</p>
            </Col>
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/Profile-Page.jpg')} alt="IFSPD Profile Page & Email ID"/>
                <p className="f-title">IFSPD Profile Page & Email ID</p>
            </Col>
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/Reference-Letter.jpg')} alt="Letter of Recommendation from IFSPD President"/>
                {/* <p className="f-title">Reference Letter from IFSPD President</p> */}
                <p className="f-title">Letter of Recommendation from IFSPD President</p>
            </Col>
            <Col md="1" sm="12" xs="12" className="d-none d-md-block">
            </Col>
        {/* </Row> */}
        {/* <Row className="justify-content-center mt-3 mt-lg-5 fellow-feature"> */}
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/Strategy-Skills.jpg')} alt="Leadership & Strategy Skills"/>
                <p className="f-title">Leadership & Strategy Skills</p>
            </Col>
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/Global-Issues.jpg')} alt="Global Issues Analysis"/>
                <p className="f-title">Global Issues Analysis</p>
            </Col>
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/Critical-Thinking.jpg')} alt="Critical Thinking & Diplomacy"/>
                <p className="f-title">Critical Thinking & Diplomacy</p>
            </Col>
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/Stakeholder.jpg')} alt="Stakeholder Management"/>
                <p className="f-title">Stakeholder Management</p>
            </Col>
            <Col md="2" sm="6" xs="6" className="text-center">
                <img src={require('../../assets/images/fp/VUCA.jpg')} alt="VUCA World Management Skill"/>
                <p className="f-title">VUCA World Management Skill</p>
            </Col>
        </Row>
        <Row className="justify-content-center text-center text-uppercase justify-content-center mt-2 mt-lg-4">
            {/* <div className="col-md-12 col-12 col-sm-12 d-md-flex text-uppercase justify-content-center mt-2 mt-lg-4"> */}
                <div className="col-12 col-md-6 col-sm-6 col-xl-5"><div className="mt-2"><p className="box-blue1 mb-0">accelerated career path</p></div></div>
                <div className="col-12 col-md-6 col-sm-6 col-xl-5"><div className="mt-2"><p className="box-grey mb-0">improved college admission chances</p></div></div>
            {/* </div> */}
        </Row>
        <Row className="justify-content-center text-center text-uppercase justify-content-center mt-0 mt-lg-2">
            <div className="col-md-12 col-12 col-sm-12 col-xl-10 text-uppercase justify-content-center">
                <Row>
                <div className="col-12 col-md-3 col-sm-6 col-xl-3"><div className="box-blue1 mt-2"><span>Students</span></div></div>
                <div className="col-12 col-md-3 col-sm-6 col-xl-3"><div className="box-grey mt-2"><span>professionals</span></div></div>
                <div className="col-12 col-md-3 col-sm-6 col-xl-3"><div className="box-blue2 mt-2"><span>bureaucrats</span></div></div>
                <div className="col-12 col-md-3 col-sm-6 col-xl-3"><div className="box-grey2 mt-2"><span>researchers</span></div></div>
                </Row>
            </div>
        </Row>
      </>
    );
}
  
export default FellowAboutCom;