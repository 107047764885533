import React, {useEffect, useState} from "react";
import moment from "moment";
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import { useParams } from "react-router-dom";
import {SetMetaData} from './../../helper/page-title';
import NotFound from "../404";
import {LoaderMedium} from './../../components/loader';
import {post_api_call, error_api_call, get_api_call} from '../../actions/action-creators/common';
import {alertResponseMessage} from '../../actions/action-creators/response';
import {linkHttp} from '../../helper/custom-function';

function PortfolioDetail(){
    const { profile_id } = useParams();
    SetMetaData("Portfolio", "", "");
    // let params = useParams();
    // const productIdParam = params.id || '';
    // var pageTitle = params.url;
	// pageTitle = urlToString(pageTitle);
	// SetMetaData(pageTitle, "", "");
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [pageLoading, setPageLoader] = useState(true);
    //Main Product Data
    const [mPortfolioData, setPortfolioData] = useState({});
    // const [portfolioLogo, setPortfolioLogo] = useState("");
    SetMetaData(typeof mPortfolioData.first_name !== "undefined" ? `${mPortfolioData.first_name} ${mPortfolioData.last_name ? mPortfolioData.last_name : ""}` : "", "", "");
    //Loader
    // const [tabKey, setTabKey] = useState("compo");
    const [showNotFoundPage, setShowNotFoundPage] = useState(false);

	//Get Portfolio Details
    const fetchPortfolioData = async() => {
        try{
            console.log(profile_id, 'profile_id')
            const portfolioApiData = await post_api_call(`portfolio/${profile_id}`, {});
            if(portfolioApiData.status === 200){                    
                setPortfolioData(portfolioApiData.data);
                // if(portfolioApiData.data.logo){
                    // console.log(portfolioApiData.data.logo, 'portfolioApiData.data.logo')
                    // setPortfolioLogo(portfolioApiData.data.image);
                    // try{
                       //let blobt = await fetch(portfolioApiData.data.logo, { mode: 'no-cors',}).then(r => r.blob());
                        // console.log('blob', blobt)
                        // // setPortfolioLogo(blob);
                        // // let fileName = imageUrl.substring(blobt.lastIndexOf("/") + 1);
                        //                             let file = new File([blobt], "fileName", { type: blobt.type });
                        //                             setPortfolioLogo(URL.createObjectURL(file));
                        // const base64Logo = await fetch(portfolioApiData.data.logo, {
                        //                             mode: 'no-cors',
                        //                         })
                        //                         .then(response => response.blob())
                        //                         .then(blob => {
                        //                             const reader = new FileReader();
                        //                             reader.readAsDataURL(blob);
                        //                             return new Promise((res) => {
                        //                                 reader.onloadend = () => {
                        //                                     console.log(reader.result);
                        //                                     res(reader.result);
                        //                                 }
                        //                             });
                        //                         });
                        // setPortfolioLogo(base64Logo);
                        // console.log(base64Logo, 'base64Logobase64Logo')
                    // }catch(e){
                    //     console.log(e);
                    // }
                // }
                // SetMetaData(`${portfolioApiData.data.first_name} ${portfolioApiData.data.last_name}`, "", "");
            }else if(portfolioApiData.status === 404){
                setShowNotFoundPage(true);
            }
            setPageLoader(false);
        }catch(e){
            console.log(e);
            alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
            error_api_call({error: `portfolio/detail|fetchPortfolioData|${e}`});
        }
    }
	//Intial Fetching
	useEffect(() => {
        fetchPortfolioData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Download Portfolio
    const downloadPortfolio = async(e) => {
        try{
            e.preventDefault();
            // e.target.disabled = true;
            setGeneratingPdf(true);
            // await get_api_call('download/portfolio', {});
            // setGeneratingPdf(false);
            // return;
            const response = await fetch(`${process.env.REACT_APP_API_URL}/download/portfolio`, {
                method: 'GET',
                type: 'no-cors',
                headers: {
                    Authorization: localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "",
                    Accept: 'application/pdf'
                },
                responseType: 'blob'
            });
            console.log(response, 'downloddddd');
            if(response.status === 200){
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${mPortfolioData.first_name}${mPortfolioData.last_name ? `-${mPortfolioData.last_name}` : ""}.pdf`;
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            }else{
                alertResponseMessage({alert_type: 2, message: "Error downloading Portfolio! Please try after some times."});
            }
            setGeneratingPdf(false);
            // e.target.disabled = false;
            return;
            // setGeneratingPdf(true);
            // const pdfSection = document.getElementById('portfolio');
            // html2canvas(pdfSection, {useCORS: true}, {
            //     scrollY: -window.scrollY
            //   })
            // .then((canvas) => {
            //     const imgData = canvas.toDataURL('image/jpeg');
            //     const pdf = new jsPDF();
            //     pdf.setFontSize(16);
            //     pdf.setTextColor(80, 77, 78);
            //     let width = pdf.internal.pageSize.getWidth();
            //     let height = 0;
            //     let x = 0;
            //     const { clientHeight: h, clientWidth: w } = pdfSection;
            //     if(h > (w * 1.414)) {
            //         height = pdf.internal.pageSize.getHeight();
            //         x = (width - (width*(w/h))) / 4;
            //         width = (width - (x * 2));
            //     }
            //     pdf.addImage(imgData, 'png', x, 0, width, height);
            //     pdf.save("portfolio.pdf");
            //     setGeneratingPdf(false);
            // }).catch(err => {
            //     // console.log("Error in generating pdf: ", err);
            //     setGeneratingPdf(false);
            // });

            // var doc = new jsPDF('p', 'pt','a4',true);
            // doc.setFontSize(16);
            // doc.setTextColor(80, 77, 78);
            // html2canvas(pdfSection, {
            //     useCORS : true,
            //     onrendered: function(canvas) {
            //        var imgData = canvas.toDataURL('image/jpeg');
            
            //        doc.addImage(imgData, 'JPEG', 15, 0, 34, 37);
            //        console.log(imgData);
            //        pdfSection.append(canvas);
            //        doc.save('portfolio.pdf');
            //     }
            // });
        }catch(e){
            console.log(e)
            // e.target.disabled = false;
        }
    }

    return (
        <main id="main">
            <section className="team-sec pt-0">
                {/* <div className="container-fluid container-lg pe-lg-5 ps-lg-5"> */}
                <div className="container-fluid container-lg">
                    <div className="section-header section-header-1">
                        <h1 className="title">Portfolio</h1>
                    </div>

                    <div className="row justify-content-center team-detail m-1">
                        {!pageLoading && (
                            <div className="col-md-9 col-12 col-sm-12 p-3 pe-0">
                                <div className="text-end mb-0">
                                    <a href="/port/edit" target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue me-sm-3 mb-1"><i className="fa fa-edit"></i> Edit Portfolio</button></a>
                                    <button className="btn btn-primary bg-color-blue mb-1" disabled={generatingPdf ? true : false} onClick={(e) => downloadPortfolio(e)}><i className="fa fa-download"></i> Download Portfolio</button>
                                </div>
                            </div>
                        )}
                        <div className="col-md-9 col-12 col-sm-12 card">
                            {/* Show Loader */}
                            {pageLoading 
                                ?
                                <div className="text-center mt-5 mb-5">
                                        <LoaderMedium size={45} />
                                </div>
                                :
                                <>
                                    {mPortfolioData && typeof mPortfolioData.first_name !== "undefined" && mPortfolioData.first_name !== '' && (
                                        <>
                                        <div id="portfolio">
                                        <div className="d-flex m-2 mt-3">
                                            <div className="p-0">
                                                <img src={mPortfolioData.logo} className="img-thumbnail" alt={`${mPortfolioData.first_name} ${mPortfolioData.last_name}`} />
                                                {/* <img src={portfolioLogo} className="img-thumbnail" alt={`${mPortfolioData.first_name} ${mPortfolioData.last_name}`} /> */}
                                            </div>
                                            <div className="ps-0 mt-1">
                                                <h2 className="ms-3 mb-1">
                                                {/* <a href="/portfolio-profile/Anam-Zahra.html" target="_blank" rel="noopener noreferrer"> */}
                                                    <strong style={{fontSize: 21}}>{mPortfolioData.first_name} {mPortfolioData.last_name}</strong>
                                                {/* </a> */}
                                                <p className="position">{mPortfolioData.position}</p>
                                                {/* {mPortfolioData.designation} */}
                                                <p className="country">{mPortfolioData.country}</p>
                                                {/* {mPortfolioData.gender}, */}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="p-2">
                                            {(mPortfolioData.headline || mPortfolioData.achievement || mPortfolioData.passion)
                                                &&
                                                <div className="mb-4">
                                                    <h3>About:</h3>
                                                    <div>
                                                        {mPortfolioData.headline} {mPortfolioData.achievement} {mPortfolioData.passion}
                                                    </div>
                                                </div>
                                            }
                                            {/* Experience */}
                                            {(mPortfolioData.experience_details && mPortfolioData.experience_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Experience:</h3>
                                                <ol>
                                                {mPortfolioData.experience_details.map((exp_d, index) => (
                                                    <li key={`experience_${index}`}>
                                                        {exp_d.designation} - <b>{exp_d.organization}</b>{exp_d.achievement ? ` (${exp_d.achievement})` : ''}.
                                                        {(exp_d.start_date !== null)
                                                            && <> {moment(exp_d.start_date).format("MMMM YYYY")} - {exp_d.end_date === null ? 'Present' : moment(exp_d.end_date).format("MMMM YYYY")}</>
                                                        }
                                                    </li>
                                                ))}
                                                </ol>
                                            </div>
                                            )}
                                            {/* Education */}
                                            {(mPortfolioData.education_details && mPortfolioData.education_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Education:</h3>
                                                <ol>
                                                {mPortfolioData.education_details.map((edu_d, edu_index) => (
                                                    <li key={`education${edu_index}`}>
                                                        <b>{edu_d.institute_name}</b>, {edu_d.program_name}{edu_d.achievement ? ` (${edu_d.achievement})` : ''}.
                                                        {(edu_d.start_year !== null)
                                                            && <> {edu_d.start_year} - {edu_d.end_year === null ? 'Present' : edu_d.end_year}</>
                                                        }
                                                    </li>
                                                ))}
                                                </ol>
                                            </div>
                                            )}
                                            {/* Research */}
                                            {(mPortfolioData.research_details && mPortfolioData.research_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Projects, Assignments , Papers, Essays, Researches, Stories, Poems, Videos, Podcasts:</h3>
                                                <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-13vw">Project Title</th>
                                                            <th className="w-13vw">Where was it published</th>
                                                            <th>Link</th>
                                                            <th>Achievement or accolade</th>
                                                            <th className="last-col">Year</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {mPortfolioData.research_details.map((research_d, research_index) => (
                                                        <tr key={`research${research_index}`}>
                                                            <td>{research_d.title}</td>
                                                            <td>{research_d.domain}</td>
                                                            <td>{research_d.link ? <a href={linkHttp(research_d.link)} target="_blank" rel="noreferrer">{linkHttp(research_d.link)}</a>: ''}</td>
                                                            <td>{research_d.achievement}</td>
                                                            <td>{research_d.year}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {/* Sport */}
                                            {(mPortfolioData.sport_details && mPortfolioData.sport_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Sports, Games, Art, Design, Tech, Science, Humanities, and all other Competitions:</h3>
                                                <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th className="w-13vw">Competition Name</th>
                                                            <th className="w-13vw">Where did you participate</th>
                                                            <th>Achievement or accolade</th>
                                                            <th className="last-col">Year</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mPortfolioData.sport_details.map((sport_d, sport_index) => (
                                                            <tr key={`sport${sport_index}`}>
                                                                <td>{sport_d.name}</td>
                                                                <td>{sport_d.venue}</td>
                                                                <td>{sport_d.achievement}</td>
                                                                <td>
                                                                    {(sport_d.start_year !== null)
                                                                        && <> {sport_d.start_year} - {sport_d.end_year === null ? 'Present' : sport_d.end_year}</>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {/* Honours and Awards */}
                                            {(mPortfolioData.award_details && mPortfolioData.award_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Leadership, Honours and Awards:</h3>
                                                {/* <ol>
                                                {mPortfolioData.award_details.map((award_d, award_index) => (
                                                <li key={`award${award_index}`}>
                                                    {award_d.institute_name}, <b>{award_d.award_name}</b>{award_d.why_awarded ? ` (${award_d.why_awarded})` : ''}{award_d.year ? ` - ${award_d.year}` : ''}
                                                </li>
                                                ))}
                                                </ol> */}
                                                <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                        <thead>
                                                            <tr>
                                                                {/* <th style={{width: "10px"}}></th> */}
                                                                <th className="w-13vw">Institution Name</th>
                                                                <th className="w-13vw">Award Name</th>
                                                                <th>Why was this awarded to you?</th>
                                                                <th className="last-col">Year</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mPortfolioData.award_details.map((award_d, award_index) => (
                                                            <tr key={`award${award_index}`}>
                                                                {/* <td><b>{award_index+1}</b></td> */}
                                                                <td>{award_d.institute_name}</td>
                                                                <td>{award_d.award_name}</td>
                                                                <td>{award_d.why_awarded}</td>
                                                                <td>{award_d.year}</td>
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {/* IFSPD Europe Global Sustainability Council Participation and Achievements */}
                                            {(mPortfolioData.glosco_data && mPortfolioData.glosco_data.length > 0) && (
                                            <div className="mb-4">
                                                <h3>IFSPD Europe Global Sustainability Council Participation and Achievements:</h3>
                                                <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-13vw">Glosco Level Completed</th>
                                                            <th className="w-13vw">Individual Awards</th>
                                                            <th>Team Awards</th>
                                                            <th className="last-col">Year</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {mPortfolioData.glosco_data.map((achievement_d, achieve_index) => (
                                                        <tr key={`achieve${achieve_index}`}>
                                                            <td>{achievement_d.level}</td>
                                                            <td>
                                                                {achievement_d.indi_award.map((indi_d, indi_index) => (
                                                                    <li key={`indi_award${indi_index}`}>
                                                                        {indi_index !== achievement_d.indi_award.length - 1 ? `${indi_d.award_name},` : indi_d.award_name}
                                                                    </li>
                                                                ))}
                                                            </td>
                                                            <td>
                                                                {achievement_d.team_award.map((team_d, team_index) => (
                                                                    <li key={`team_award${team_index}`}>
                                                                        {team_index !== achievement_d.team_award.length - 1 ? `${team_d.award_name},` : team_d.award_name}
                                                                    </li>
                                                                ))}
                                                            </td>
                                                            <td>{achievement_d.year}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {/* Unique Skills */}
                                            {(mPortfolioData.unique_skills && mPortfolioData.unique_skills.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Unique Skills - Based on IFSPD Europe Competency & Career Mapping:</h3>
                                                <div className="table-responsive ms-2">
                                                    <table className="border-0 table-bordered table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="w-13vw">Skill</th>
                                                                {/* <th>Defining Attributes</th> */}
                                                                <th>Unique Abilities</th>
                                                                {/* <th>Main Motivation</th>
                                                                <th>Potential Skills</th>
                                                                <th>Suitable Role</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {mPortfolioData.unique_skills.map((passion_d, passion_index) => (
                                                            <tr key={`passion${passion_index}`}>
                                                                <td><b>{passion_d.title}</b></td>
                                                                {/* <td>{passion_d.defining_attribute}</td> */}
                                                                <td>{passion_d.unique_ability}</td>
                                                                {/* <td>{passion_d.main_motivation}</td>
                                                                <td>{passion_d.potential_skill}</td>
                                                                <td>{passion_d.suitable_role}</td> */}
                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            )}
                                            {/* Career Skills */}
                                            {(mPortfolioData.carrer_skill_details && mPortfolioData.carrer_skill_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Career Skills:</h3>
                                                <ul>
                                                    {/* <li> */}
                                                        {mPortfolioData.carrer_skill_details.map((c_skill_d, career_index) => (
                                                        <li key={`career_skill${career_index}`}>
                                                            {/* {career_index === 0 ? c_skill_d.skill_name : `, ${c_skill_d.skill_name}`} */}
                                                            {c_skill_d.skill_name}
                                                        </li>
                                                        ))}
                                                    {/* </li> */}
                                                </ul>
                                            </div>
                                            )}
                                            {/* Personal Skills */}
                                            {(mPortfolioData.personal_skill_details && mPortfolioData.personal_skill_details.length > 0) && (
                                            <div className="mb-4">
                                                <h3>Personal Skills:</h3>
                                                <ul>
                                                    {/* <li> */}
                                                        {mPortfolioData.personal_skill_details.map((p_skill_d, personal_index) => (
                                                        <li key={`personal_skill${personal_index}`}>
                                                            {/* {personal_index === 0 ? p_skill_d.skill_name : `, ${p_skill_d.skill_name}`} */}
                                                            {p_skill_d.skill_name}
                                                        </li>
                                                        ))}
                                                    {/* </li> */}
                                                </ul>
                                            </div>
                                            )}
                                            {/* Languages */}
                                            {(mPortfolioData.language) && (
                                            <div className="mb-4">
                                                <h3>Languages Known: <span>{mPortfolioData.language}</span></h3>
                                            </div>
                                            )}
                                            {/* Email */}
                                            {(mPortfolioData.email) && (
                                            <div className="mb-4">
                                                <h3>Email: <span>{mPortfolioData.email}</span></h3>
                                            </div>
                                            )}
                                            {/* Mobile Number */}
                                            {(mPortfolioData.mobile_number) && (
                                            <div className="mb-4">
                                                <h3>Mobile Number: <span className="text-black">+ {mPortfolioData.phone_code} {mPortfolioData.mobile_number}</span></h3>
                                            </div>
                                            )}
                                        </div>
                                        {/* <div className="p-2" dangerouslySetInnerHTML={ { __html: portfolioData[profile_id].detail}}> */}
                                            {/* {portfolioData[profile_id].detail} */}
                                        {/* </div> */}
                                        </div>
                                        </>
                                    )}
                                    {showNotFoundPage && (
                                        <NotFound type="portfolio" />
                                    )}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )    
}

export default PortfolioDetail;