import React, {useState, useEffect, useRef} from "react";
import { useLocation } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {SetMetaData} from './../../helper/page-title';
import Select from 'react-select';
import './../../assets/css/pages/team.css';
import './../../assets/css/pages/social.css';
import {get_api_call, post_api_call, error_api_call} from '../../actions/action-creators/common';
import {alertResponseMessage} from '../../actions/action-creators/response';
import { RSstandardStyle } from "../../config/react-select";
import {toggleBodyLoader} from '../../helper/custom-function';
import {LoaderMedium} from './../../components/loader';
import noFellow from '../../assets/images/oops.png';
// import Fancybox from '../../components/plugin/fancyox';
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import Fancybox from '../../components/plugin/fancyox';
import HorziLine from "../../components/common/hr";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import SocialListComp from "../../components/social/list";
import picIcon from "./../../assets/images/user.png";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
// const startYear = currentYear - 100;
const selectOptionLabel = {ifspd_program_id: 'program_name', ifspd_program_year: 'year', country_id: 'name'};

function SocialDetails(){
    SetMetaData("Social Start-ups", "", "");
    const location = useLocation();
    const [countryData, setCountryData] = useState([]);
    const [programData, setProgramData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [filterData, setFilterData] = useState({name: '',
                                                ifspd_program_id: '',
                                                creation_date: '',
                                                ifspd_program_year: '',
                                                country_id: ''});
    const [fellowData, setFellowData] = useState([{image: '', title: 'Title 1', id: 1},
                                                    {image: '', title: 'Title 2', id: 2}
                                                ]);
    // const [fellowTotal, setFellowTotal] = useState(0);
    const [fellowPage, setFellowPage] = useState(0);
    const [callFellowAPI, setCallFellowAPI] = useState(0);
	//Loading
	const [showFellowLoader, setFellowLoader] = useState(true);
	const [fellowLoading, setFellowLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const limit = 15;
	const loadMoreFellow= useRef(null);//Button Click
	const fellowLoader = useRef(null);
    // Update Filter Input
    const updateInput = (input_type, field, value, select_option='') => {
        try{
            if(field === 'name'){
                value = value.replace(/[^a-zA-Z. ]/g, '')
                            .replace('  ', ' ');
            }
            const filledFilterData = {...filterData, [field]: value};
            // setFilterData({...filterData, [field]: value});
            setFilterData({...filledFilterData});
            if(field !== 'name'){
                console.log('yes')
                // searchFellow();
                // setFellowPage(1);
                toggleBodyLoader('load');
                if(filledFilterData[field].id === ''){
                    modifyAdBarUrl(field, '');
                }else{
                    modifyAdBarUrl(field, `${filledFilterData[field].id},${filledFilterData[field][select_option]}`);
                }
                setPageLoading(true);
                setFellowPage(fellowPage === 1 ? 0 : 1);
            }
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
            // console.log(e)
            error_api_call({error: `fellow/profile|updateInput|${e}`});
            return false;
        }
    }
    //Search by Filter Data
    const searchFellow = (e) => {
        try{
            e.preventDefault();
            toggleBodyLoader('load');
            modifyAdBarUrl('name', filterData.name);
            setPageLoading(true);
            setFellowPage(fellowPage === 1 ? 0 : 1);
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
            // console.log(e)
            error_api_call({error: `fellow/profile|searchFellow|${e}`});
        }
    }
    //Get Data for Country & Program
    const fetchSelectList = async(type) => {
        try{
			const listApiData = type === 'program'
                                    ? await get_api_call('ifspd-program', {})
                                    : await get_api_call('countries', {});
			if(listApiData.status === 200){
				type === 'program'
                    ? setProgramData(listApiData.data)
                    : setCountryData(listApiData.data);
			}
        }catch(e){
			error_api_call({error: `fellow|profile|fetchSelectList|${e}`});
        }
    }
    //Creating Year Array
    const yearDataList = (type) => {
        const yearItems = [];
        // for (let i = currentYear; i >= startYear; i--) {
        for (let i = currentYear; i >= 2020; i--) {
        //   yearItems.push(<option key={`${type}_${i}`} value={i}>{i}</option>);
            yearItems.push({id: i, year: i});
        }
        setYearData(yearItems);
        // return yearItems;
    };
    //Modify URL
	const modifyAdBarUrl = (key, value) => {
		try{
			// if(typeof value === 'array'){
			// if(value instanceof Array){
				const url = new URL(window.location.href);
				if(!value){//value.length === 0
					url.searchParams.delete(key);
				}else{
					url.searchParams.set(key, value);
				}
				
				window.history.pushState({}, '', url.toString());
			// }
		}catch(e){
			// console.log(e)
			error_api_call({error: `fellow/profile|modifyAdBarUrl|${e}`});
			return false;
        }
	};
	//Load More Data when loader is in focus
	const infiniteCustomScroll = () => {
		if(loadMoreFellow.current){
			loadMoreFellow.current.click();
		}
	}
	//Initial Load & Read More Implementation
	useEffect(() => {
        const options = {
           root: null,
           rootMargin: "20px",
           threshold: 1.0
        };
       // initialize IntersectionObserver
       // and attaching to Load More div
        //For All
		const observerAll = new IntersectionObserver(infiniteCustomScroll, options);
        if (fellowLoader.current) {
            observerAll.observe(fellowLoader.current)
        }
		const clean = fellowLoader.current;
        return () => {
            if (clean) {
                observerAll.disconnect(clean);
            }
        }
    }, [fellowLoader.current]);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Filter Data
	useEffect(() => {
        console.log('urlHandling')
		const urlHandling = () => {
			try{
				//URL Param type Handling
				const urlQuryString = location.search;
				const urlParam = new URLSearchParams(urlQuryString);
				if(urlParam.size > 0){
					// const filterKeys = ['name', 'status', 'year', 'program', 'sector', 'country'];
                    const filterKeys = ['name', 'ifspd_program_id', 'ifspd_program_year', 'country_id'];
					var filterDataNew = {...filterData};
					urlParam.forEach((value, key) => {
						if(filterKeys.indexOf(key) > -1){
                            if(key !== 'name'){
                                var urlParamData = value.split(',');
                                filterDataNew[key] = {id: parseInt(urlParamData[0]), [selectOptionLabel[key]]: urlParamData[1]};
                            }else{
                                filterDataNew[key] = value;
                            }
							console.log(value, key);
						}
					});
                    console.log(filterDataNew, 'filterDataNewfilterDataNewfilterDataNew')
					// setFilterData({...filterDataNew});
                    // setTimeout(() => {
                        setFilterData({...filterDataNew});
                    // }, 13000)
                    
                    console.log(filterData, 'filterDatafilterDatafilterData')
				}
				setCallFellowAPI(1);
				setFellowPage(fellowPage === 1 ? 0 : 1);
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Fellows! Check back after some times"});
				error_api_call({error: `fellow/profile|urlHandling|${e}`});
			}
		}
        // fetchAPIList('category');
		// fetchAPIList('main-category');
        fetchSelectList('program');
        fetchSelectList('country');
        yearDataList();
        urlHandling();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Fellow Filter API
	useEffect(() => {
		//Get Filtered Fellow
		const fetchData = async() => {
			try{
				//APi Call
				if(!fellowLoading){
					setFellowLoading(true);
					const fellowCurrentPage = fellowPage < 2 ? 1 : fellowPage;
					if(fellowCurrentPage === 1){
						setFellowData([]);
					}
					const newFellowList = await post_api_call('fellows', 
																{limit,
																page: fellowCurrentPage,
																filter: {name: filterData.name,
                                                                        country_id: filterData.country_id !== '' ? filterData.country_id.id : '',
                                                                        ifspd_program_id: filterData.ifspd_program_id !== '' ? filterData.ifspd_program_id.id : '',
                                                                        ifspd_program_year: filterData.ifspd_program_year !== '' ? filterData.ifspd_program_year.id : ''},
																// keyword: queryKeyword,
															});
					if(newFellowList.status === 200){
						const newFellowData = fellowCurrentPage < 2
												? newFellowList.data 
												: fellowData.concat(newFellowList.data);
						setFellowData(newFellowData);
						if(fellowCurrentPage === 1){
							// setFellowTotal(newFellowList.total);
							setPageLoading(false);
						}
					}
					setFellowLoading(false);
					toggleLoader(fellowCurrentPage, newFellowList.total);
					if(fellowCurrentPage === 1){
						toggleBodyLoader('remove');
					}
				}
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
				// console.log(e);
				setFellowLoading(false);
				error_api_call({error: `fellow/profile|fetchData|${e}`});
			}
		}
		if(callFellowAPI === 1){
			// fetchData();
		}
	}, [fellowPage]);// eslint-disable-line react-hooks/exhaustive-deps
	//Load More Fellow
	const loadMore = () => {
		setFellowPage(fellowPage < 2 ? 2 : fellowPage+1);
	}
	//Show-Hide Item Loader
	const toggleLoader = (current_page, total_page) => {
        try{
            var response = {page_count: 0, is_show: false};
			const totalPage = total_page <= limit
							? 1
							: total_page % limit === 0 
							? total_page/limit 
							: parseInt((total_page/limit))+1;
							// console.log(current_page, 'current_page')
							// console.log(totalPage, 'totalPage')
			if(current_page < totalPage){
				response.is_show = true;
				setFellowLoader(true);
			}else{
				setFellowLoader(false);
			}
			response.page_count = totalPage;
            return response;
        }catch(e){
            return response;
        }
    }
    
    const test = () => {
        console.log('fancybox')
        new NativeFancybox(
            // Array containing gallery items
            [
              // Gallery item
              {
                src: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
                thumb: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
              },
              {
                src: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
                thumb: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
              },
              {
                src: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
                thumb: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
              },
              {
                src: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
                thumb: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
              },
              {
                src: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
                thumb: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
              },
              {
                src: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
                thumb: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
              },
              {
                src: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
                thumb: "https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg",
              },
            ],
            // Gallery options
            {
              hideScrollbar: false,
            }
          );
    }

    return (
        <main id="main">
        <section className="team-sec pt-0 startup-sec mt-60">
          <div className="container-fluid container-lg">
              <div className="section-header section-header-1">
                  <h1 className="title pt-4">Social Start-up Details</h1>
              </div>
              {/* Details */}
              <div className="justify-content-center row">
                <div className="col-lg-9 col-md-9 col-sm-12">
                    {/* Top */}
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12">
                            <img className="card img-fluid h-100p w-100p" src={picIcon}/>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 ps-md-0 pe-md-0">
                            <div className="card h-100 p-2 bg-light topic-desc">
                                <p className="mb-0">Ahhan Vaidya. Jalandhar, Punjab. Clean 100% of garbage in Satrapur junction. 28 August 2024. </p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12">
                            <div className="bg-success card color-white h-100p justify-content-around p-1 text-center w-100p float-end">
                                <p>Stage 6</p>
                                <span>Success!</span>
                            </div>
                        </div>
                    </div>
                    {/* Details Tab */}
                    <div className="row mt-4 startup-tab">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <Tabs
                                defaultActiveKey="detail"
                                id="start-up-tab"
                                className="ms-0"
                            >
                                <Tab eventKey="detail" title="Details" className="p-3 bg-color-ligblue1">
                                    <div className="row">
                                        {/* Images */}
                                        <div className="col-lg-4 col-md-4 col-sm-12 pe-md-0">
                                            <div className="img-container single">
                                                <img src={"https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg"} width="200" height="185" onClick={() => test()}/>
                                                {/* <span className="count"><i className="fa fa-images"></i> &nbsp;+2 Photos</span> */}
                                            </div>
                                            <div className="sub-img-container">
                                                <ul>
                                                    <li><img src={"https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg"} width="200" height="185" onClick={() => test()}/></li>
                                                    <li><img src={"https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg"} width="200" height="185" onClick={() => test()}/></li>
                                                    <li>
                                                        <span className="count" onClick={() => test()}><i className="fa fa-images text-center pb-1"></i>+4 Photos</span >
                                                        <img src="https://ifspd.eu/static/media/2019-10-31-Press-Release-017-500x333.088a6472f0c8a8b9f2e4.jpg" width="200" height="185"/>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Details */}
                                        <div className="col-lg-8 col-md-8 col-sm-12">
                                            <div className="table-responsive">
                                            <table className="card">
                                                <tbody>
                                                    <tr>
                                                        <td className="d-flex">Social start up name and motto</td>
                                                        <td><b>Clean India. Healthy India.</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">Domain</td>
                                                        <td><b>Health and Wellness</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">Location</td>
                                                        <td><b>Jalandhar, Punjab</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">Current situation</td>
                                                        <td><b>In XYZ corner outside my school, garbage is piling up. It is attracting flies, and hazardous to the health.</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">KPI and date</td>
                                                        <td><b>Clear 100% garbage outside my school. 28 August 2024.</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">Leader name</td>
                                                        <td><b>Ahhan Vaidya</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">Key learnings</td>
                                                        <td><b>Work Smart than Hard Work</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">Innovation</td>
                                                        <td><b>Innovation 1</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">Challenges</td>
                                                        <td><b>Hard to convince local people</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">Future help</td>
                                                        <td><b>Initiative from Government to educate people</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="d-flex">Leadership</td>
                                                        <td><b>Leadership</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                {/* Pics */}
                                <Tab eventKey="report" title="Pics & Reports" className="p-3 bg-color-ligblue1">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            {/* <div className="row"> */}
                                                <Fancybox
                                                    // Sample options
                                                    options={{
                                                        Carousel: {
                                                            infinite: false,
                                                        },
                                                    }}
                                                    className="row"
                                                >
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-017-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <a data-fancybox="gallery" href={require("../../assets/images/main/event/content/2019-10-31-Press-Release-040-500x333.jpg")} className="fancybox image gallery-item" title="18th IFSPD Board of Directors Meeting held in Belgrade (Serbia)" rel="gallery-0"><img src={require("../../assets/images/main/event/content/2019-10-31-Press-Release-040-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
                                                    </div>
                                                </Fancybox>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 ps-4">
                    {/* Star Rating */}
                    <div className="star pb-4 text-center">
                        {/* <i className="fa fa-2x fa-star-o"></i> */}
                        <i className="fa fa-2x fa-star"></i>
                        <i className="fa fa-2x fa-star"></i>
                        <i className="fa fa-2x fa-star"></i>
                        <i className="fa fa-2x fa-star-half-alt"></i>
                    </div>
                    {/* Members */}
                    {/* <div className="col-lg-12 col-md-12 col-sm-12"> */}
                        <div className="row card-footer">
                            {/* <div className="card"> */}
                            <p className="pt-2 text-center">Members of this Cause</p>
                            <div className="col-lg-6 col-md-6 col-sm-12 pb-4">
                                <img className="card img-fluid h-100p w-100p" src={picIcon}/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 pb-4">
                                <img className="card img-fluid h-100p w-100p" src={picIcon}/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 pb-4">
                                <img className="card img-fluid h-100p w-100p" src={picIcon}/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 pb-4">
                                <img className="card img-fluid h-100p w-100p" src={picIcon}/>
                            </div>
                            <p className="text-center"><button className="text-center btn btn-outline-primary">View All</button></p>
                            {/* </div> */}
                        </div>
                    {/* </div> */}
                </div>
              </div>
            </div>
        </section>
        {/* Related */}
        <HorziLine />
        <section className="team-sec pt-0 startup-sec">
        <div className="container-fluid container-lg">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h2 className="fs-4 fw-bold pb-3">Related Social Start-ups</h2>
					{pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
						fellowData.length > 0 && (
							<>
                            <div className="row">
								{fellowData.map((fellow, fellow_indx) => (
                                     <div className="col-md-2 col-12 col-sm-6" key={`fellow-data${fellow_indx}`}>
                                        <SocialListComp 
                                            data={fellow}
                                        />
                                        {/* <div className="team-card">
                                            <div className="team-detail topic-detail mb-5">   
                                                <h2>
                                                <a href={`/fellow-profile/${fellow.url}`} target="_blank" rel="noopener noreferrer">
                                                    <strong className="fs-6">{fellow.title} {fellow.last_name || ''}</strong>
                                                </a>
                                                </h2>
                                                <div className="img-wrapper">
                                                <a href={`/fellow-profile/${fellow.url}`} target="_blank" rel="noopener noreferrer">
                                                    <div className="img-wrap card"><img src={fellow.logo} alt={`${fellow.first_name} ${fellow.last_name || ''}`}/></div>
                                                </a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                ))}
                            </div>
							</>
                        )
                    }
                </div>
            </div>
          </div>
        </section>
        {/* Visited */}
        <HorziLine />
        <section className="team-sec pt-0 startup-sec">
        <div className="container-fluid container-lg">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h2 className="fs-4 fw-bold pb-3">Others You Visited Recently</h2>
					{pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
						fellowData.length > 0 && (
							<>
                            <div className="row">
								{fellowData.map((fellow, fellow_indx) => (
                                     <div className="col-md-2 col-12 col-sm-6" key={`fellow-data${fellow_indx}`}>
                                        <SocialListComp 
                                            data={fellow}
                                        />
                                        {/* <div className="team-card">
                                            <div className="team-detail topic-detail mb-5">   
                                                <h2>
                                                <a href={`/fellow-profile/${fellow.url}`} target="_blank" rel="noopener noreferrer">
                                                    <strong className="fs-6">{fellow.title} {fellow.last_name || ''}</strong>
                                                </a>
                                            
                                                </h2>
                                                <div className="img-wrapper">
                                                <a href={`/fellow-profile/${fellow.url}`} target="_blank" rel="noopener noreferrer">
                                                    <div className="img-wrap card"><img src={fellow.logo} alt={`${fellow.first_name} ${fellow.last_name || ''}`}/></div>
                                                </a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                ))}
                            </div>
							</>
                        )
                    }
                </div>
            </div>
          </div>
        </section>
      </main>
    )    
}

export default SocialDetails;