import { Footer } from '../footer';
import Header from '../header';

function WrapMaster(props) {
    return (
      <>
        {(typeof props.showHeader === "undefined" || props.showHeader === true) && (
            <Header />
        )}
        <div className="wrapper" style={{minHeight: '70vh'}}>
        {(typeof props.showLogo === "undefined" || props.showLogo === true) && (
        <section>
        <div className="container">
            <div className="row mt-4 mb-4">
            <div className="col-md-3 col-12 col-sm-12">
                <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../../assets/images/main/logo/ifspd_logo_m.png')} title="International Foundation for Sustainable Peace and Development" />
            </div>
            <div className="col-md-9 col-12 col-sm-12">
                <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../../assets/images/main/logo/img-ifslogan-eng.png')} title="International Foundation for Sustainable Peace and Development" />
            </div>
            </div>
        </div>
        </section>)}
        {/* <div className="site grid-container container hfeed grid-parent" id="page"> */}
        {/* <div className="site grid-container hfeed grid-parent" id="page"> */}
        <div className="site hfeed grid-parent" id="page">
            <div className="site-content" id="content">

                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">
                        {/* <Navbar /> */}
                        {/* <div className="container"> */}
                            {props.children}
                        {/* </div> */}
                    </main>
			    </div>
		    </div>
	    </div>
        </div>
        {(typeof props.showFooter === "undefined" || props.showFooter === true) && (
            <Footer showPartner={props.showPartner} />
        )}
      </>
    );
  }
  
  export default WrapMaster;