import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event10(){
    SetMetaData("The 14th General Assembly and Board Meeting of the BSCSIF, Baku, Azerbaijan", "", "");

    return (
        <div className="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
        <div className="site-content" id="content">
           <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
              <main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                 <article id="post-523" className="post-523 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings" itemType="https://schema.org/CreativeWork" itemScope="">
                    <div className="inside-article">
                       <header className="entry-header" aria-label="Content">
                          <h1 className="entry-title title" itemProp="headline">The 14th General Assembly and Board Meeting of the BSCSIF, Baku, Azerbaijan</h1>
                          {/* <div className="entry-meta">
                             <span className="posted-on"><time className="updated" dateTime="2018-08-16T20:11:56+03:00" itemProp="dateModified">August 16, 2018</time><time className="entry-date published" dateTime="2015-05-23T10:31:49+03:00" itemProp="datePublished">May 23, 2015</time></span> 		
                          </div> */}
                       </header>
                       <div className="featured-image  page-header-image-single ">
                          <img width="300" height="198" src={require("../../assets/images/main/event/naslovna.jpg")} className="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                       </div>
                       <div className="entry-content" itemProp="text">
                           <p>The capital of the “Land of Fire”, Baku, hosted with the traditional hospitality the 14th Meeting of the General Assembly and Board of Directors of the International Fund for cooperation and partnership of the Black Sea and Caspian Sea (International Fund).</p>
                          {/* <p>On May 23, 2015, the capital of the “Land of Fire”, Baku, hosted with the traditional hospitality the 14th Meeting of the General Assembly and Board of Directors of the International Fund for cooperation and partnership of the Black Sea and Caspian Sea (International Fund).</p> */}
                          <p>The event was attended by the distinguished guests from Azerbaijan, including the Minister of Foreign Affairs, Mr. Elmar Mammadyarov, representatives from member countries of the International Fund, influential international organizations, ambassadors and representatives of the diplomatic corps accredited in Azerbaijan, non-governmental organizations and mass-media.</p>
                          <p>At the beginning of the meeting the participants honored with a minute of silence the memory of the victims of a terrible fire occurred in the city of Baku.</p>
                          <p>During the meeting the ceremony of the presidency transfer of the International Fund was held, by the principle of rotation, from the former President, Mr. Ismail Safi (Turkey) to the current one, Mr. Chingiz Abdullayev (Azerbaijan); the agenda also included topics of great significance for the region and the future of the organization, such as the constant and quality enlargement and increasing the efficiency of the International Fund activity by establishing cooperation with international organizations and strategic think-tanks and enhancement of the regional economic cooperation.</p>
                          <p>Guests focused their attention on the presentation of the research publication titled “Path to Reconciliation”, which was the result of an extensive research &nbsp;project <strong>“The economic, social and political consequences of the protracted conflicts in the Black Sea-Caspian Sea region and the prospects for their settlement”&nbsp;</strong>realized by the group of young experts under the aegis of the International Fund -Julia Akhunova, Valentina Albu, Luiza Nita, Lilia Ovanesov, Orhan Mammadov, Tudor Cojocariu, Irmak Pool. The research was implemented with the participation of internationally recognized consultants -Adrian Severin, Scott Taylor and Iulian Chifu. The recommendations of the authors are based on the basic provisions of the universally recognized norms and principles of international law which are stipulated by the UN Charter and the Helsinki Final Act, as well as by other resolutions and documents, namely the principles of territorial integrity, inviolability of borders and non-interference in the internal affairs of the state.</p>
                          <p>Working sessions were held by the President of the International Fund and famous writer, Mr. Chingiz Abdullayev, who delivered to the guests a welcome speech and a presentation on the strategy of further development of International Fund, and also by the Vice-President, Mr. Victor Khmarin, Mr. Ismail Safi and by the Secretary General, Mr. Eldar Hasanov.</p>
                          <p>During the meeting, the Board of Directors welcomed new members: individuals and legal persons from Turkey, Russia, Slovenia, Serbia, Malta. Two new members from Romania and Republic of Moldova were accepted in the Board of Directors. The issues regarding the opening of branches of the International Fund in South African Republic and Ukraine were also considered within the meeting.</p>
                          <p>There was also a presentation of the Global Fund of Resources for Development “Children of the Sun” and the International Peacekeeping Assembly “Birth of the World”, guided by Mr. Mihail Zernov and Mrs. Natalina Litvinova, deputy director of the International Fund’s branch in Russia, and President of the Global Fund of Resources for Development “Children of the Sun”, the initiative which was realized with the support of many members of the International Fund.</p>
                          <p>Her Royal Highness, Princess Dlamini Celenhle, briefed the guests of the meeting with the results of the Delphic Games in South Africa, which were organized in cooperation with the International Fund.</p>
                          <p>The most important event during the meeting was the adoption of the Baku Declaration of the International Fund, which aims to encourage states, international organizations and civil society to join efforts in the fight against global threats to peace, stability and security. The full text of the Declaration can be found in the Section <em>Statements</em> of our website.</p>
                       </div>
                    </div>
                 </article>
                
                 <a className="singlepostlinkback" href="/board-meetings">← Back to the list of all Board Meetings</a>		
              </main>
           </div>
        </div>
     </div>
    )
}

export default Event10;