import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event8(){
    SetMetaData("The 15th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea", "", "");

    return (
        <div className="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
        <div className="site-content" id="content">
           <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
              <main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                 <article id="post-519" className="post-519 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news" itemType="https://schema.org/CreativeWork" itemScope="">
                    <div className="inside-article">
                       <header className="entry-header" aria-label="Content">
                          <h1 className="entry-title title" itemProp="headline">The 15th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea</h1>
                          {/* <div className="entry-meta">
                             <span className="posted-on"><time className="updated" dateTime="2018-08-12T10:30:26+03:00" itemProp="dateModified">August 12, 2018</time><time className="entry-date published" dateTime="2016-07-18T10:27:58+03:00" itemProp="datePublished">July 18, 2016</time></span> 		
                          </div> */}
                       </header>
                       <div className="featured-image  page-header-image-single ">
                          <img width="210" height="130" src={require("../../assets/images/main/event/eskilogo.png")} className="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                       </div>
                       <div className="entry-content" itemProp="text">
                           <p>In Bucharest, Romania was held the 15<sup>th</sup> meeting of the Board of Directors and General Assembly of the International Foundation for Cooperation and Partnership of the Black Sea and the Caspian Sea (International Fund). Romania, representatives of which are among the founders of the International Fund and in the capital of which the organization was established seven years ago, extended its hospitality for members and guests of the organization.</p>
                          {/* <p>On June 17, 2016 in Bucharest, Romania was held the 15<sup>th</sup> meeting of the Board of Directors and General Assembly of the International Foundation for Cooperation and Partnership of the Black Sea and the Caspian Sea (International Fund). Romania, representatives of which are among the founders of the International Fund and in the capital of which the organization was established seven years ago, extended its hospitality for members and guests of the organization.</p> */}
                          <p>Senior officials from Romania, honorable guests from the countries in the International Fund, representatives of influential international organizations, ambassadors and representatives of diplomatic corps accredited in Romania, non-governmental organizations and the media attended the event.</p>
                          <p>Meetings of the Board of Directors and the General Assembly were chaired by the President of the International Fund and the outstanding writer Chingiz Abdullayev, who made a presentation on the activities of the International Fund for the past period and shared his vision on the future development of the organization.</p>
                          <p>At the meeting individuals and legal entities from different countries were admitted as new full members of the International Fund.</p>
                          <p>Participants of the event stressed a steady growth of the international authority of the organization, which demonstrates importance and effectiveness of the work of the International Fund.</p>
                          <p>At the meetings along with the topical issues related to development of the International Fund there were also discussions on the currently implemented and proposed research projects aimed at promoting peace, security and economic cooperation in the regions of the Black Sea and Caspian Sea.</p>
                          <p>The Board of Directors and the General Assembly reaffirmed that the International Fund in its activities, as it noted in its Charter, is guided by the norms and principles of international law, primarily those related to the respect of sovereignty, territorial integrity and inviolability of state borders. These principles constitute the values adherence to which is a foundation of lasting peace, security and stability in the regions of the Black Sea and Caspian Sea and beyond its borders.</p>
                          <p>During the discussion of processes in the regions of the Black Sea and Caspian Sea a particular interest sparked the presentation by Ismail Safi, a member of the Board of Director on “The efforts aimed at development of relations between Turkey and Russia”.</p>
                          <p>Mikhail Zernov, a Director of the Branch of the International Fund in the Russian Federation shared with the participants of the event the information on the first Forum “Russian world and the Turkic world in the context of the geopolitical challenges of the XXI century”.</p>
                          <p>All members of the General Assembly expressed support for the efforts of restoration and development of relations between Russia and Turkey.</p>
                          <p>Particular attention in the course of the meeting was paid to issues of economic cooperation between the countries of the Black and Caspian Seas. Notably, there was a presentation of the project entitled “Tourism as a factor of cooperation and rapprochement”.</p>
                          <p>It was also presented an international youth project «Global Emerging Leaders Community» which supposes engagement of the International Fund.</p>
                          <p>Eldar Hasanov, Secretary General of the International Fund, made a review of the important developments that take place in the regions of the Black and Caspian Seas including terrorism, migration, multiculturalism, silk road and, in this context, presented strategic research projects which are proposed for implementation by the International Fund. All members of the General Assembly expressed support for the proposed projects.</p>
                          <p>At the meeting it was also positively assessed the proposal to hold the next, 16<sup>th</sup> meeting of the Board of Directors in Johannesburg, South Africa.</p>
                          <p><em>The International Fund for Cooperation and Partnership of the Black Sea and the Caspian Sea is an international non-governmental organization, founded on March 4, 2009 upon the initiative of outstanding scientific and public figures, well-known intellectuals, influential non-governmental organizations from 8 countries: Azerbaijan, Georgia, Kazakhstan, Kyrgyzstan, Moldova, Romania, Turkey and Ukraine. In 2011-2015 representatives of other countries joined the Fund: Russia, Bulgaria, Serbia, Montenegro, Bosnia and Herzegovina, Macedonia, Belarus, Croatia, Iran, Albania, Switzerland, Austria, Luxembourg, South Africa, the Republic of San Sao Tome and Principe, China, Sri Lanka, Ghana, Estonia, Czech Republic, Slovenia, Greece, Hungary. In 2016, representatives from France and Italy joined the International Fund. The Central Office of the International Foundation is located in Bucharest.&nbsp;</em><em>Its six branches actively work in Istanbul, Moscow, Belgrade, Varna, Minsk and<strong>&nbsp;</strong>Johannesburg.</em><strong><em>&nbsp;</em></strong></p>
                          <p><em>In August 2013, the International Fund was awarded special consultative status with the Economic and Social Council of the United Nations (ECOSOC). The International Fund has also established cooperation with international organizations such as the OSCE, the Organization of Islamic Cooperation, the European Union institutions, the European Centre for Policy.</em></p>
                          <p align="right"><strong>Bucharest, Romania</strong></p>
                          {/* <p align="right"><strong>17 June 2016</strong></p> */}
                       </div>
                    </div>
                 </article>
                 
                 <a className="singlepostlinkback" href="/board-meetings">← Back to the list of all Board Meetings</a>		
              </main>
           </div>
        </div>
     </div>
    )
}

export default Event8;