import React from "react";
import Fancybox from '../../components/plugin/fancyox';

function Gallery5(){
    return (
        <Fancybox
            // Sample options
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/8/18e9a6ff-8d73-4f67-ab14-3ddcc5891983_IMG_6910-500x333.jpg")} className="fancybox image gallery-item" title="The 13th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea Minsk, Republic of Belarus" rel="gallery-4"><img src={require("../../assets/images/main/event/8/18e9a6ff-8d73-4f67-ab14-3ddcc5891983_IMG_6910-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/8/83cd1b6f-a1ed-419a-8fd9-4ddd67016c71_IMG_5991-500x333.jpg")} className="fancybox image gallery-item" title="The 13th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea Minsk, Republic of Belarus" rel="gallery-4"><img src={require("../../assets/images/main/event/8/83cd1b6f-a1ed-419a-8fd9-4ddd67016c71_IMG_5991-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
        </Fancybox>
    )
}

export default Gallery5;