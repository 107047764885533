import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
const isAuth = localStorage.getItem("token") ? true : false;

const RouteAuth = () => {
    const location = useLocation();
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return !isAuth
            ? <Outlet />
            : <Navigate to={{
                pathname: '/port',
                state: {from: location.pathname}
            }} />;
}

export default RouteAuth;

// import {Navigate, Route } from 'react-router-dom';

// const isAuth = localStorage.getItem("token") ? true : false;

// export default ({component: Component, ...rest}) => (
//     <Route {...rest} render={props => (
//     //   window.globalState.isAuthenticated() ? (
//     !isAuth ? (
//         <Component {...props} />
//       ) : (
//         <Navigate to={{
//           pathname: '/',
//           state: {from: props.location}
//         }} />
//       )
//     )} />
// )