import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event9(){
    SetMetaData("The 15th meeting of the Board of Directors and General Assembly of the BSCSIF", "", "");

    return (
        <div class="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
        <div class="site-content" id="content">
           <div class="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
              <main class="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                 <article id="post-521" class="post-521 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news" itemType="https://schema.org/CreativeWork" itemScope="">
                    <div class="inside-article">
                       <header class="entry-header" aria-label="Content">
                          <h1 class="entry-title title" itemProp="headline">The 15th meeting of the Board of Directors and General Assembly of the BSCSIF</h1>
                          {/* <div class="entry-meta">
                             <span class="posted-on"><time class="updated" dateTime="2018-08-12T10:31:35+03:00" itemProp="dateModified">August 12, 2018</time><time class="entry-date published" dateTime="2016-05-23T10:30:58+03:00" itemProp="datePublished">May 23, 2016</time></span> 		
                          </div> */}
                       </header>
                       <div class="featured-image  page-header-image-single ">
                          <img width="210" height="130" src={require("../../assets/images/main/event/eskilogo.png")} class="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                       </div>
                       <div class="entry-content" itemProp="text">
                           <p>The 15th meeting of the Board of Directors and General Assembly of the<strong>International Fund for the cooperation and partnership of the Black sea and Caspian sea</strong> is scheduled to take place in Bucharest, the headquarters of the BSCSIF.</p>
                          {/* <p>The 15th meeting of the Board of Directors and General Assembly of the<strong>International Fund for the cooperation and partnership of the Black sea and Caspian sea</strong> is scheduled to take place in Bucharest, the headquarters of the BSCSIF, on June 16-18, 2016 where (June 16- arrival, June 17- meeting, June 18-departure of participants).</p> */}
                          <p>At the meeting, in a traditionally friendly atmosphere characteristic to our organization, &nbsp;current and strategic objectives related to the development of BSCSIF will be discussed.</p>
                       </div>
                    </div>
                 </article>
                
                 <a class="singlepostlinkback" href="/board-meetings">← Back to the list of all Board Meetings</a>		
              </main>
           </div>
        </div>
     </div>
    )
}

export default Event9;