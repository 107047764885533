import React from 'react';

function Branches() {
    return (
      <> 
        <div id="pg-5574-10"  className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5" >
            <div className="ifhomebranchesflags panel-row-style panel-row-style-for-5574-10" id="ifhomebranchesflags" >
                <div id="pgc-5574-10-0"  className="panel-grid-cell" >
                    <div id="panel-5574-10-0-0" className="so-panel widget widget_sow-accordion panel-first-child panel-last-child" data-index="15" >
                    <div className="so-widget-sow-accordion so-widget-sow-accordion-default-a923ec766d9f-5574">
                        <div>
                            <div className="sow-accordion">
                                <div className="sow-accordion-panel"
                                data-anchor="central-office">
                                    <div className="sow-accordion-panel-header-container" role="heading" aria-level="2">
                                        <div className="sow-accordion-panel-header" tabIndex="0" role="button" id="accordion-label-central-office" aria-controls="accordion-content-central-office" aria-expanded="false">
                                            <div className="sow-accordion-title sow-accordion-title-icon-left">
                                                <img src={require('../../assets/images/main/flag/flag-bulgaria.png')} alt="flag-bulgaria" /> Central Office													
                                            </div>
                                            <div className="sow-accordion-open-close-button">
                                                <div className="sow-accordion-open-button">
                                                <span className="sow-icon-ionicons" data-sow-icon="&#xf218;"
                                                    ></span>							
                                                </div>
                                                <div className="sow-accordion-close-button">
                                                <span className="sow-icon-ionicons" data-sow-icon="&#xf209;"
                                                    ></span>							
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="sow-accordion-panel-content"
                                        role="region"
                                        aria-labelledby="accordion-label-central-office"
                                        id="accordion-content-central-office"
                                        style={{display: 'none'}}			>
                                        <div className="sow-accordion-panel-border" tabIndex="0">
                                            <div>Bulgaria, Sofia, 9 Positano Street,</div>
                                            <div>floor 6, office 19A</div>
                                            <div><strong>E-mail</strong>: <a href="mailto:director@ifspd.eu">director@ifspd.eu</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sow-accordion-panel me-0 me-md-1 ms-0 ms-md-1"
                                data-anchor="istanbul-icscec">
                                <div className="sow-accordion-panel-header-container" role="heading" aria-level="2">
                                    <div className="sow-accordion-panel-header" tabIndex="0" role="button" id="accordion-label-istanbul-icscec" aria-controls="accordion-content-istanbul-icscec" aria-expanded="false">
                                        <div className="sow-accordion-title sow-accordion-title-icon-left">
                                            <img src={require('../../assets/images/main/flag/flag-turkey.png')} alt="flag-turkey" /> Istanbul ICSCEC													
                                        </div>
                                        <div className="sow-accordion-open-close-button">
                                            <div className="sow-accordion-open-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf218;"
                                                ></span>							
                                            </div>
                                            <div className="sow-accordion-close-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf209;"
                                                ></span>							
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="sow-accordion-panel-content"
                                    role="region"
                                    aria-labelledby="accordion-label-istanbul-icscec"
                                    id="accordion-content-istanbul-icscec"
                                    style={{display: 'none'}}			>
                                    <div className="sow-accordion-panel-border" tabIndex="0">
                                        <div>Vizyon Merkez Plaza Yenibosna Distr.</div>
                                        <div>Kuyumcular Str. 4 Bloc C2, Office No:501</div>
                                        <div>34197 Bahcelievler, Istanbul/Turkey</div>
                                    </div>
                                </div>
                                </div>
                                <div className="sow-accordion-panel"
                                data-anchor="brussels-branch">
                                <div className="sow-accordion-panel-header-container" role="heading" aria-level="2">
                                    <div className="sow-accordion-panel-header" tabIndex="0" role="button" id="accordion-label-brussels-branch" aria-controls="accordion-content-brussels-branch" aria-expanded="false">
                                        <div className="sow-accordion-title sow-accordion-title-icon-left">
                                            <img src={require('../../assets/images/main/flag/flag-belgium.png')} alt="flag-belgium" /> Brussels Branch													
                                        </div>
                                        <div className="sow-accordion-open-close-button">
                                            <div className="sow-accordion-open-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf218;"
                                                ></span>							
                                            </div>
                                            <div className="sow-accordion-close-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf209;"
                                                ></span>							
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="sow-accordion-panel-content"
                                    role="region"
                                    aria-labelledby="accordion-label-brussels-branch"
                                    id="accordion-content-brussels-branch"
                                    style={{display: 'none'}}			>
                                    <div className="sow-accordion-panel-border" tabIndex="0">
                                        <div>Accredited to EU institutions,</div>
                                        <div>NATO and Benelux countries</div>
                                        <div>1030 Brussels, Kingdom of Belgium,</div>
                                        <div>85 Blvd. Auguste Reyers</div>
                                    </div>
                                </div>
                                </div>
                                <div className="sow-accordion-panel"
                                data-anchor="moscow-branch">
                                <div className="sow-accordion-panel-header-container" role="heading" aria-level="2">
                                    <div className="sow-accordion-panel-header" tabIndex="0" role="button" id="accordion-label-moscow-branch" aria-controls="accordion-content-moscow-branch" aria-expanded="false">
                                        <div className="sow-accordion-title sow-accordion-title-icon-left">
                                            <img src={require('../../assets/images/main/flag/flag-russia.png')} alt="flag-russia" /> Moscow Branch													
                                        </div>
                                        <div className="sow-accordion-open-close-button">
                                            <div className="sow-accordion-open-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf218;"
                                                ></span>							
                                            </div>
                                            <div className="sow-accordion-close-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf209;"
                                                ></span>							
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="sow-accordion-panel-content"
                                    role="region"
                                    aria-labelledby="accordion-label-moscow-branch"
                                    id="accordion-content-moscow-branch"
                                    style={{display: 'none'}}>
                                    <div className="sow-accordion-panel-border" tabIndex="0">
                                        <div>Moscow, Russian Federation</div>
                                    </div>
                                </div>
                                </div>
                                <div className="sow-accordion-panel me-0 me-md-1 ms-0 ms-md-1"
                                data-anchor="varna-branch">
                                <div className="sow-accordion-panel-header-container" role="heading" aria-level="2">
                                    <div className="sow-accordion-panel-header" tabIndex="0" role="button" id="accordion-label-varna-branch" aria-controls="accordion-content-varna-branch" aria-expanded="false">
                                        <div className="sow-accordion-title sow-accordion-title-icon-left">
                                            <img src={require('../../assets/images/main/flag/flag-bulgaria.png')} alt="flag-bulgaria" /> Varna Branch													
                                        </div>
                                        <div className="sow-accordion-open-close-button">
                                            <div className="sow-accordion-open-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf218;"
                                                ></span>							
                                            </div>
                                            <div className="sow-accordion-close-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf209;"
                                                ></span>							
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="sow-accordion-panel-content"
                                    role="region"
                                    aria-labelledby="accordion-label-varna-branch"
                                    id="accordion-content-varna-branch"
                                    style={{display: 'none'}}>
                                    <div className="sow-accordion-panel-border" tabIndex="0">
                                        <div>Varna, Bulgaria</div>
                                    </div>
                                </div>
                                </div>
                                <div className="sow-accordion-panel"
                                data-anchor="balkan-branch">
                                <div className="sow-accordion-panel-header-container" role="heading" aria-level="2">
                                    <div className="sow-accordion-panel-header" tabIndex="0" role="button" id="accordion-label-balkan-branch" aria-controls="accordion-content-balkan-branch" aria-expanded="false">
                                        <div className="sow-accordion-title sow-accordion-title-icon-left">
                                            <img src={require('../../assets/images/main/flag/flag-serbia.png')} alt="flag-serbia" /> Balkan Branch													
                                        </div>
                                        <div className="sow-accordion-open-close-button">
                                            <div className="sow-accordion-open-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf218;"
                                                ></span>							
                                            </div>
                                            <div className="sow-accordion-close-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf209;"
                                                ></span>							
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="sow-accordion-panel-content"
                                    role="region"
                                    aria-labelledby="accordion-label-balkan-branch"
                                    id="accordion-content-balkan-branch"
                                    style={{display: 'none'}}>
                                    <div className="sow-accordion-panel-border" tabIndex="0">
                                        <div>Branch of the Balkan Region,</div>
                                        <div>Serbia, Belgrade</div>
                                        <div>47 Kneza Miloša, 11000 Belgrade</div>
                                        <div>The Republic of Serbia</div>
                                    </div>
                                </div>
                                </div>
                                <div className="sow-accordion-panel"
                                data-anchor="south-africa-branch">
                                <div className="sow-accordion-panel-header-container" role="heading" aria-level="2">
                                    <div className="sow-accordion-panel-header" tabIndex="0" role="button" id="accordion-label-south-africa-branch" aria-controls="accordion-content-south-africa-branch" aria-expanded="false">
                                        <div className="sow-accordion-title sow-accordion-title-icon-left">
                                            <img src={require('../../assets/images/main/flag/flag-south-africa.png')} alt="flag-south-africa" /> South Africa Branch													
                                        </div>
                                        <div className="sow-accordion-open-close-button">
                                            <div className="sow-accordion-open-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf218;"
                                                ></span>							
                                            </div>
                                            <div className="sow-accordion-close-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf209;"
                                                ></span>							
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="sow-accordion-panel-content"
                                    role="region"
                                    aria-labelledby="accordion-label-south-africa-branch"
                                    id="accordion-content-south-africa-branch"
                                    style={{display: 'none'}}>
                                    <div className="sow-accordion-panel-border" tabIndex="0">
                                        <div>164 Katherine Street C/O Ubuntu</div>
                                        <div>Institute Sandton South Africa</div>
                                    </div>
                                </div>
                                </div>
                                <div className="sow-accordion-panel me-0 me-md-1 ms-0 ms-md-1"
                                data-anchor="minsk-branch">
                                <div className="sow-accordion-panel-header-container" role="heading" aria-level="2">
                                    <div className="sow-accordion-panel-header" tabIndex="0" role="button" id="accordion-label-minsk-branch" aria-controls="accordion-content-minsk-branch" aria-expanded="false">
                                        <div className="sow-accordion-title sow-accordion-title-icon-left">
                                            <img src={require('../../assets/images/main/flag/flag-belarus.png')} alt="flag-belarus" /> Minsk Branch													
                                        </div>
                                        <div className="sow-accordion-open-close-button">
                                            <div className="sow-accordion-open-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf218;"
                                                ></span>							
                                            </div>
                                            <div className="sow-accordion-close-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf209;"
                                                ></span>							
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="sow-accordion-panel-content"
                                    role="region"
                                    aria-labelledby="accordion-label-minsk-branch"
                                    id="accordion-content-minsk-branch"
                                    style={{display: 'none'}}			>
                                    <div className="sow-accordion-panel-border" tabIndex="0">
                                        <div>Sq. Svoboda 4, off.424</div>
                                        <div>220030 Minsk, Belarus</div>
                                    </div>
                                </div>
                                </div>
                                <div className="sow-accordion-panel"
                                data-anchor="india-branch">
                                <div className="sow-accordion-panel-header-container" role="heading" aria-level="2">
                                    <div className="sow-accordion-panel-header" tabIndex="0" role="button" id="accordion-label-india-branch" aria-controls="accordion-content-india-branch" aria-expanded="false">
                                        <div className="sow-accordion-title sow-accordion-title-icon-left">
                                            <img src={require('../../assets/images/main/flag/flag-india.png')} alt="flag-india" /> India Branch													
                                        </div>
                                        <div className="sow-accordion-open-close-button">
                                            <div className="sow-accordion-open-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf218;"
                                                ></span>							
                                            </div>
                                            <div className="sow-accordion-close-button">
                                            <span className="sow-icon-ionicons" data-sow-icon="&#xf209;"
                                                ></span>							
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="sow-accordion-panel-content"
                                    role="region"
                                    aria-labelledby="accordion-label-india-branch"
                                    id="accordion-content-india-branch"
                                    style={{display: 'none'}}			>
                                    <div className="sow-accordion-panel-border" tabIndex="0">
                                        <div>India Branch - Bangalore</div>
                                        <div>&nbsp;</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}
  
export default Branches;