import React from "react";
import Fancybox from '../../components/plugin/fancyox';

function Gallery7(){
    return (
        <Fancybox
            // Sample options
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/3b047eb4-4e54-45aa-b7c8-98ef3c572475_IMG_4139-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/3b047eb4-4e54-45aa-b7c8-98ef3c572475_IMG_4139-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/3f5cc20f-6b4b-48d8-816a-b17c9058f5a2_IMG_4252-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/3f5cc20f-6b4b-48d8-816a-b17c9058f5a2_IMG_4252-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/5cd79040-8f02-4857-8c26-481a27f69cae_IMG_4111-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/5cd79040-8f02-4857-8c26-481a27f69cae_IMG_4111-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/5e4951ac-6b19-4d4d-a91e-d5e30a6462a4_IMG_4256-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/5e4951ac-6b19-4d4d-a91e-d5e30a6462a4_IMG_4256-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/09ac0b2d-f674-4398-9530-dca8a77a8aa1_IMG_4229-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/09ac0b2d-f674-4398-9530-dca8a77a8aa1_IMG_4229-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/029e595b-2e48-49cd-924d-e592e9b7c1db_IMG_4115-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/029e595b-2e48-49cd-924d-e592e9b7c1db_IMG_4115-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/99ef5fd2-ef02-4da4-88fb-49eb1799cc67_IMG_4180-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/99ef5fd2-ef02-4da4-88fb-49eb1799cc67_IMG_4180-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/260ca3d6-ab11-42b6-9149-6c2fe22eb868_IMG_4106-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/260ca3d6-ab11-42b6-9149-6c2fe22eb868_IMG_4106-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/487e724d-9558-4fa2-afc8-8886d5741b38_IMG_4217-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/487e724d-9558-4fa2-afc8-8886d5741b38_IMG_4217-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/7792f88a-c058-4041-a0a7-6435ed28529c_IMG_4227-500x375.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/7792f88a-c058-4041-a0a7-6435ed28529c_IMG_4227-500x375.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/9740ef14-8f00-4e3f-abf9-e700ea7a7b96_IMG_4158-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/9740ef14-8f00-4e3f-abf9-e700ea7a7b96_IMG_4158-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/406166ab-6672-4070-99a5-19a0cbd67f5e_IMG_4069-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/406166ab-6672-4070-99a5-19a0cbd67f5e_IMG_4069-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/801920fb-9d77-4dc0-8418-da685423d8f3_IMG_4047-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/801920fb-9d77-4dc0-8418-da685423d8f3_IMG_4047-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/e69910bc-162f-4c7a-a4fe-7bb4522e25f5_IMG_4151-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/e69910bc-162f-4c7a-a4fe-7bb4522e25f5_IMG_4151-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/feb83e8e-03f0-4b30-a0c6-347c53af77a9_IMG_4146-500x333.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/feb83e8e-03f0-4b30-a0c6-347c53af77a9_IMG_4146-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/46b9d121-845f-47f1-bbc6-cd4b2e869a8d_IMG_4089-332x500.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/46b9d121-845f-47f1-bbc6-cd4b2e869a8d_IMG_4089-332x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/X01dadbdda6-850a-4504-8235-a4cd0d354de6_IMG_4054-333x500.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/X01dadbdda6-850a-4504-8235-a4cd0d354de6_IMG_4054-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/X02a2308a96-f392-4821-9266-293c902cc908_IMG_4055-333x500.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/X02a2308a96-f392-4821-9266-293c902cc908_IMG_4055-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/X0346b9d121-845f-47f1-bbc6-cd4b2e869a8d_IMG_4089-332x500.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/X0346b9d121-845f-47f1-bbc6-cd4b2e869a8d_IMG_4089-332x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/10/X04372b77eb-1241-4d9a-9483-1251fbd2dd6b_IMG_4052-333x500.jpg")} className="fancybox image gallery-item" title="The General Assembly and 10th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-6"><img src={require("../../assets/images/main/event/10/X04372b77eb-1241-4d9a-9483-1251fbd2dd6b_IMG_4052-333x500.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
        </Fancybox>
    )
}

export default Gallery7;
																	