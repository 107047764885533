import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event7(){
    SetMetaData("The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey", "", "");

    return (
        <div className="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
        <div className="site-content" id="content">
           <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
              <main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                 <article id="post-516" className="post-516 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings" itemType="https://schema.org/CreativeWork" itemScope="">
                    <div className="inside-article">
                       <header className="entry-header" aria-label="Content">
                          <h1 className="entry-title title" itemProp="headline">The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey</h1>
                          {/* <div className="entry-meta">
                             <span className="posted-on"><time className="updated" dateTime="2018-08-23T22:33:18+03:00" itemProp="dateModified">August 23, 2018</time><time className="entry-date published" dateTime="2017-04-25T10:24:15+03:00" itemProp="datePublished">April 25, 2017</time></span> 		
                          </div> */}
                       </header>
                       <div className="featured-image  page-header-image-single ">
                          <img width="800" height="531" src={require("../../assets/images/main/event/7/f17aabc9-d9f7-478a-8a3d-12f91c1c346e_DSC_0007.jpg")} className="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                       </div>
                       <div className="entry-content" itemProp="text">
                           <p>The International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea (BSCSIF) celebrated five years since its establishment.</p>
                          {/* <p>On the 9<sup>th</sup> of March, 2014, the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea (BSCSIF) celebrated five years since its establishment.</p> */}
                          <p>The city of Istanbul hosted the General Assembly and 12<sup>th</sup> extended Board Meeting of the BSCSIF as well as the Awards Gala organized on the occasion of the Fund’s Anniversary. The events were attended by the BSCSIF full members and distinguished guests from the BSCSIF member countries, regional and international organizations, well-known think-tanks, with the purpose of strengthening &nbsp;dialogue, peace and cooperation in the wider Black Sea region and worldwide.</p>
                          <p>Among the prominent invitees were Mr. Andrei Abramov, Head of the NGO Section/UNDESA (ECOSOC), Mr. Mustapha Sinaceur, Subregional Coordinator at FAO/UN, Mr. Mahir Aliyev, UNEP Regional Office for Europe, H.E. Emil Constantinescu, former President of Romania (1996-2000), Ms. Amanda Paul, European Policy Center (Brussels), Ambassador Arif Mammadov, OIC Permanent Observer to the EU, Mr. Thomas Grunert, Head of unit, Europe Enlargement and European Economic Area, Mr. Vuk Jeremić , &nbsp;President of the 67th session of the United Nations General Assembly, Mr. Alaattin Buyukkaya, Deputy Minister for EU Affairs, &nbsp; &nbsp; &nbsp; &nbsp;Mr. Huseyin Avni Mutlu, Governor of Istanbul Province, ambassadors and representatives of the diplomatic corps accredited in Turkey, representatives of public authorities, non-governmental organizations and mass-media.</p>
                          <p>The agenda included topics of great significance for the region and the future of the organization, such as the constant enlargement and increasing the efficiency of the BSCSIF activity by signing new cooperation protocols with international organizations and think-tanks, enhancement of the regional economic cooperation, establishment of a common platform for implementing transnational joint projects. As well, a special attention during the Board Meeting was devoted to the active cooperation of the Fund within the UN Economic and Social Committee, European Policy Center, Eastern Partnership Program, Organization of Islamic Cooperation and other international structures.</p>
                          <p>The work sessions were chaired by the BSCSIF President, Dr. Ismail Safi (Turkey), who also held the welcoming speech and presented the BSCSIF activity, objectives and strategy.</p>
                          <p>The 12<sup>th</sup> Board Meeting marked an important stage of acceptance of Mr. Wang Hexiang (China) as a new Board member and Vice-president and Mr. Vehalla Kankanamge Indika Jamindra (Sri Lanka) as a new Board member of BSCSIF. As well, new full members from the region and other countries outside the region were accepted in BSCSIF thus contributing to the development and enlargement of the Fund as an international organization. During the Board Meeting the new full members were accepted from the Republic of Estonia (Mr. Fred Kaasik), Czeck Republic (Ms. Julia Hrebenyk), Republic of Serbia (Mr. Dragan Vujovic, Mr. Dusan Stosic, Ms. Biserka Jevtimijevic), Bosnia and Herzegovina (Mr. Zoran Puljic), Slovenia (Mr. Renato Krajnc), Macedonia (Sasko Staninov), Bulgaria (Mr. Yani Yanev, Mr. Philip Bokov) and Ivana Jovovic (Montenegro).</p>
                          <p>During the Anniversary Meeting the Fund signed new partnership agreements with the Center for International Relations and Sustainable Development (Serbia) and the Organization of Integration of <em>Azerbaijani</em> Youth to Europe (Azerbaijan).</p>
                          <p>An important decision on the enlargement of the Fund was adopted during the Board meeting regarding the establishment of a new BSCSIF branch in Minsk, Republic of Belarus.</p>
                          <p>On the occasion of the 5<sup>th</sup> Anniversary celebration of the Fund, during the Gala dinner, the awards were granted to the founders and personalities who supported the initiative of its creation and contributed to the development of the Fund’s activity during its existence. At the beginning of the ceremony the awards were granted to the President of Azerbaijan, H.E. Mr. Ilham Aliyev and President of Romania, H.E. Mr. Traian Basescu <strong><em>“for the initiative of creation and outstanding contribution to the BSCSIF activity”</em></strong>. On the occasion of the BSCSIF Anniversary, the congratulation message was transmitted on behalf of the President of Turkey, H.E. Mr. <em>Abdullah Gül who underlined the strategic importance of the Fund and gave a positive evaluation of its activity and results achieved in five years.</em></p>
                          <p>The International Fund for Cooperation and Partnership of the Black Sea and the Caspian Sea is an international non-governmental organization, founded on the 4<sup>th</sup> of March, 2009, by distinguished representatives of civil society and public life from eight countries: Azerbaijan, Georgia, Kazakhstan, Kyrgyzstan, Moldova, Romania, Turkey and Ukraine. Later on, representatives from 14 more countries have joined the Fund: Albania, Belarus, Bosnia and Herzegovina, Bulgaria, Croatia, Iran, &nbsp;Montenegro, Russian Federation, Serbia, South Africa, Switzerland, the Republic of Sao Tome and Principe and during the last Board Meeting Czeck Republic and China.</p>
                          <p>The BSCSIF central office is located in Bucharest, and 6 branches actively operate in Brussels, Istanbul, Moscow, Belgrade, Varna and Minsk.</p>
                          <p>In August 2013, the BSCSIF was granted the Special Consultative Status within the Economic and Social Council of the United Nations Organizations (ECOSOC), an achievement highly appreciated by the BSCSIF members and strategic partners.<br/></p>
                          <div id="gallery-1" className="gallery galleryid-516 gallery-columns-3 gallery-size-medium">
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/0c9ba151-4182-4564-8d40-29f828f41fc4_DSC_0225-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/0c9ba151-4182-4564-8d40-29f828f41fc4_DSC_0225-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" fetchpriority="high" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/6fef831c-cc4b-4a0f-83fc-092a50bdf73c_DSC_0966-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/6fef831c-cc4b-4a0f-83fc-092a50bdf73c_DSC_0966-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/8e3255e3-33e8-4bb0-b9d0-de69b64f6fd3_DSC_0094-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/8e3255e3-33e8-4bb0-b9d0-de69b64f6fd3_DSC_0094-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/23d02a1f-2fd7-40e8-b6d8-1b8b8a56718e_DSC_0988-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/23d02a1f-2fd7-40e8-b6d8-1b8b8a56718e_DSC_0988-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/33d5f8a7-0750-45c3-8d5d-2d4fe5d4532d_DSC_0077-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/33d5f8a7-0750-45c3-8d5d-2d4fe5d4532d_DSC_0077-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/69da1af9-2d16-4a09-8db0-5f51edab11f8_DSC_0223-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/69da1af9-2d16-4a09-8db0-5f51edab11f8_DSC_0223-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/80b93ff3-21b9-4edd-b29c-3cef0a9e7ac2_DSC_0936-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/80b93ff3-21b9-4edd-b29c-3cef0a9e7ac2_DSC_0936-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/363b9f7d-f7df-49f7-896c-decc9c79cc32_DSC_0051-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/363b9f7d-f7df-49f7-896c-decc9c79cc32_DSC_0051-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/7981ad82-b727-4c27-b87f-c129b9bb45bb_DSC_0221-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/7981ad82-b727-4c27-b87f-c129b9bb45bb_DSC_0221-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/32157052-8524-497b-8038-24370b8d86f7_DSC_0023-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/32157052-8524-497b-8038-24370b8d86f7_DSC_0023-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/97550368-fe0f-44f1-99f2-598afe0aa2b7_DSC_0136-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/97550368-fe0f-44f1-99f2-598afe0aa2b7_DSC_0136-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/bcc4e60f-5e6f-4ca0-984a-1c45c16a4a32_DSC_0931-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/bcc4e60f-5e6f-4ca0-984a-1c45c16a4a32_DSC_0931-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/cd8b3be6-d1f2-4e70-ab71-dc7061be6b68_DSC_0913-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/cd8b3be6-d1f2-4e70-ab71-dc7061be6b68_DSC_0913-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/dca7b3f4-099a-4ad0-9f19-aa38528b6e91_DSC_0965-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/dca7b3f4-099a-4ad0-9f19-aa38528b6e91_DSC_0965-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                             <figure className="gallery-item">
                                <div className="gallery-icon landscape">
                                   <a href={require("../../assets/images/main/event/7/edaed590-6a3b-45fe-b474-f8aea8320709_DSC_0012-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/7/edaed590-6a3b-45fe-b474-f8aea8320709_DSC_0012-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                </div>
                             </figure>
                          </div>
                          <p></p>
                       </div>
                    </div>
                 </article>
                 
                 <a className="singlepostlinkback" href="/board-meetings">← Back to the list of all Board Meetings</a>		
              </main>
           </div>
        </div>
     </div>
    )
}

export default Event7;