import React from "react";
import {SetMetaData} from './../../helper/page-title';

function OurPartner(){
    SetMetaData("OUR PARTNERS", "", "");

    return (
        <div className="site hfeed grid-parent" id="page">
            <div className="site-content" id="content">

                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">

                        <article id="post-2789" className="post-2789 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope="">
                            <div className="inside-article">

                                <header className="entry-header" aria-label="Content">
                                    <h1 className="entry-title title" itemProp="headline">Our Partners</h1>
                                </header>


                                <div className="entry-content" itemProp="text">
                                    <div id="pl-2789" className="panel-layout">
                                        <div id="pg-2789-0" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-no-style">
                                            <div id="pgc-2789-0-0" className="panel-grid-cell">
                                                <div id="panel-2789-0-0-0" className="so-panel widget widget_sow-image panel-first-child panel-last-child" data-index="0">
                                                    <div className="so-widget-sow-image so-widget-sow-image-default-d6014b76747a-2789">

                                                        <div className="sow-image-container">
                                                            <img src={require("../../assets/images/main/body/inner-pages-grapshic-element.jpg")} width="1920" height="231" sizes="(max-width: 1920px) 100vw, 1920px" title="inner pages grapshic element" alt="" loading="lazy" className="so-widget-image" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-2789-1" className="container-fluid container-lg pe-lg-5 ps-lg-5 panel-grid panel-has-style">
                                            <div className="panel-row-style panel-row-style-for-2789-1">
                                                <div id="pgc-2789-1-0" className="panel-grid-cell">
                                                    <div id="panel-2789-1-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="1">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-2789-1-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>AEDIDH</strong></p>
                                                                    <p><strong>АZVIRT</strong></p>
                                                                    <p><strong>Butasbureau</strong></p>
                                                                    <p><strong>HOLLYWOOD MULTIPLEX.RO</strong></p>
                                                                    <p><strong>Muzeul de Istorie Națională și Arheologie Constanța</strong></p>
                                                                    <p><strong>NIROGROUP</strong></p>
                                                                    <p><strong>OZTASAR</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-2789-1-1" className="panel-grid-cell">
                                                    <div id="panel-2789-1-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="2">
                                                        <div className="ifnameslist panel-widget-style panel-widget-style-for-2789-1-1-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p><strong>ROMENERGO</strong></p>
                                                                    <p><strong>ROMPETROL</strong></p>
                                                                    <p><strong>SIVECO</strong></p>
                                                                    <p><strong>Teatrul Ion Creangă</strong></p>
                                                                    <p><strong>Universitatea Babeş-Bolyai</strong></p>
                                                                    <p><strong>UNIVERSITATEA SPIRU HARET</strong></p>
                                                                    <p><strong>Viena Economic Forum</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </article>
                    </main>
                </div>


            </div>
        </div>
    )    
}

export default OurPartner;