import React, {useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import moment from "moment";
// import Cropper, { ReactCropperElement } from "react-cropper";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {LoaderMedium} from './../../components/loader';
// import makeAnimated from 'react-select/animated';
import './../../assets/css/pages/team.css';
import {SetMetaData} from './../../helper/page-title';
import {post_api_call, get_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
// import picIcon from "./../../assets/images/user.png";
import { RSstandardStyle } from "../../config/react-select";
import {update_user_info} from './../../actions/action-creators/state-update';
// const animatedComponents = makeAnimated();
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const startYear = currentYear - 102;
const cMonth = currentDate.getMonth()+1;
const currentMMYYYY = `${currentDate.getFullYear()}-${cMonth < 10 ? 0 : ""}${cMonth}`;
// const genderData = [
//     {id: 'male', name: 'Male'},
//     {id: 'female', name: 'Female'},
//     {id: 'others', name: 'Others'},
// ];
// const initialFormData = {
//     basic_desc: {
//         short_description: '',
//         basic_passion: '',
//         basic_achievement: '',
//     },
//     general: {
//         first_name: '',
//         last_name: '',
//         country_id: '',
//         school_name_id: '',
//         cohort_name_id: '',
//         dob: '',
//         email: '',
//         phone_code_id: '',
//         mobile_number: '',
//         language: '',
//         logo: ''
//     },
//     first_name: 'Ritika',
//     last_name: '',
//     country_id: {id: 101, name: 'India', phone_code: '91'},
//     school_name_id: {id: 1, school_name: 'School 1'},
//     cohort_name_id: {id: 1, cohort_name: 'Cohort 1'},
//     dob: '2024-02-14',
//     email: 'ririkasingh171@gmail.com',
//     phone_code_id: {id: 101, name: 'India', phone_code: '91'},
//     mobile_number: '9804018840',
//     language: 'Hindi, English',
//     position: 'Peace Builder',
//     short_description: 'Jane Doe is a 10th grade student at the Links Global School.',
//     basic_passion: 'She is an ace debater who has won prizes in several interschool competitions.',
//     basic_achievement: 'She is passionate about sustainable development and has participated in primary research on the topic ‘Improving Sustainable Living in Indian Villages’.',
//     experience: [
//         {designation: 'Head, Strategy', organization: 'Sealand Corp', achievement: 'Enabled the organization to grow 100% year on year.', start_month: '', start_year : {id: 2017, year: 2017}, end_month: '', end_year: {id: 2020, year: 2020}, is_present: false},
//         {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
//         {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
//         {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
//         {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false}
//     ],
//     education: [
//         {institute_name: 'Yokohama University.', program_name: 'Masters in Economics.', achievement: 'Distinction. Among top 1 percentile', start_month: '', start_year : {id: 2022, year: 2022}, end_month: '', end_year: '', is_present: true},
//         {institute_name: 'Yokohama University 2', program_name: 'Masters in Economics. 2', achievement: '', start_month: '', start_year : {id: 2020, year: 2020}, end_month: '', end_year: {id: 2023, year: 2023}, is_present: false},
//         {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
//     ],
//     award: [
//         {institute_name: 'Government of Libya.', award_name: 'Youth Ambassador. ', why_awarded: 'For upliftment and empowerment of youth.', year: {id: 2023, year: 2023}},
//         {institute_name: '', award_name: '', why_awarded: '', year: ''},
//         {institute_name: '', award_name: '', why_awarded: '', year: ''},
//     ],
//     passion: [
//         {passion_id: {id: 9, title: 'Creative'}},
//         {passion_id: {id: 7, title: 'Practical'}},
//         {passion_id: {id: 8, title: 'Analytical'}}
//     ],
//     career_skills: [{id: 1, skill_name: 'Skill 1'}, {id: 2, skill_name: 'Skill 2'}, {id: 3, skill_name: 'Skill 3'}],
//     personal_skills: [{id: 22, skill_name: 'Skill 10'}, {id: 23, skill_name: 'Skill 11'}, {id: 24, skill_name: 'Skill 12'}],
//     achievement: [
//         {id: 1, level: 'Beginner', year: {id: 2020, year: 2020}, indi_award: [{id: 1, award_name: 'Award 1'}], team_award: [{id: 5, award_name: 'Award 5'}]},
//         {id: 2, level: 'Foundation', year: {id: 2022, year: 2022}, indi_award: '', team_award: [{id: 5, award_name: 'Award 5'}, {id: 6, award_name: 'Award 6'}]},
//         {id: 3, level: 'Intermediate', year: '', indi_award: '', team_award: ''},
//         {id: 4, level: 'Advanced', year: '', indi_award: '', team_award: ''},
//         {id: 5, level: 'Mastery', year: '', indi_award: '', team_award: ''},
//         {id: 6, level: 'Grand Mastery', year: '', indi_award: '', team_award: ''},
//     ],
//     research: [
//         {title: 'Research 1', domain: 'Research Domain', link: '', year: {id: 2011, year: 2011},  achievement: 'Research achievement'},
//         {title: '', domain: '', link: '', year: '',  achievement: ''},
//         {title: '', domain: '', link: '', year: '',  achievement: ''},
//         {title: '', domain: '', link: '', year: '',  achievement: ''},
//         {title: '', domain: '', link: '', year: '',  achievement: ''},
//     ],
//     sport: [
//         {name: 'Sport 1', venue: 'Sport Venue', achievement: 'sport achievement', start_year: {id: 2022, year: 2022}, end_year: {id: 2023, year: 2023}, is_present: false},
//         {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
//         {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
//         {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
//         {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
//     ],
// };
const initialFormData = {
    position: '',
    basic_desc: {
        short_description: '',
        basic_passion: '',
        basic_achievement: '',
    },
    general: {
        first_name: '',
        last_name: '',
        country_id: '',
        school_name_id: '',
        cohort_name_id: '',
        dob: '',
        email: '',
        phone_code_id: '',
        mobile_number: '',
        language: '',
        logo: ''
    },
    experience: [
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false}
    ],
    education: [
        {institute_name: '', program_name: '', achievement: '', start_year : '', end_year: '', is_present: false},
        {institute_name: '', program_name: '', achievement: '', start_year : '', end_year: '', is_present: false},
        {institute_name: '', program_name: '', achievement: '', start_year : '', end_year: '', is_present: false},
        // {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
    ],
    award: [
        {institute_name: '', award_name: '', why_awarded: '', year: ''},
        {institute_name: '', award_name: '', why_awarded: '', year: ''},
        {institute_name: '', award_name: '', why_awarded: '', year: ''},
    ],
    passion: [
        {passion_id: ''},
        {passion_id: ''},
        {passion_id: ''}
    ],
    career_skills: [],
    personal_skills: [],
    achievement: [
        {id: 1, level: 'Beginner', year: '', indi_award: '', team_award: '', disabled: false},
        {id: 2, level: 'Foundation', year: '', indi_award: '', team_award: '', disabled: true},
        {id: 3, level: 'Intermediate', year: '', indi_award: '', team_award: '', disabled: true},
        {id: 4, level: 'Advanced', year: '', indi_award: '', team_award: '', disabled: true},
        {id: 5, level: 'Mastery', year: '', indi_award: '', team_award: '', disabled: true},
        {id: 6, level: 'Grand Mastery', year: '', indi_award: '', team_award: '', disabled: true},
    ],
    research: [
        {title: '', domain: '', link: '', year: '',  achievement: ''},
        {title: '', domain: '', link: '', year: '',  achievement: ''},
        {title: '', domain: '', link: '', year: '',  achievement: ''},
        {title: '', domain: '', link: '', year: '',  achievement: ''},
        {title: '', domain: '', link: '', year: '',  achievement: ''},
    ],
    sport: [
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
    ],
};

function PortfolioEditForm(){
    SetMetaData("PORT – Student Portfolio Management System", "", "");
    const cropperRef = React.createRef(null);
    const [pageLoading, setPageLoader] = useState(true);
    const [passionData, setPassionData] = useState([]);
    const [careerSkillData, setCareerSkillData] = useState([]);
    const [personalSkillData, setPersonalSkillData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [schoolData, setSchoolData] = useState([]);
    const [cohortData, setCohortData] = useState([]);
    const [indiAwardData, setIndiAwardData] = useState([]);
    const [teamAwardData, setTeamAwardData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [userUrl, setUserUrl] = useState("");
    // const [futureYearDataAsc, setFutureYearAsc] = useState([]);
    // const [futureYearDataDsc, setFutureYearDsc] = useState([]);
    const [previewPicImg, setPreviewPicImg] = useState(null);
    const [croppedImg, setCroppedImg] = useState(null);
    const [previewPicBlob, setPreviewPicBlob] = useState(null);
    // const cropperRef = useRef<ReactCropperElement>(null);
    //Forms
    const [formDataGeneral, setFormDataGeneral] = useState({...initialFormData.general});
    // const [formDataPic, setFormDataPic] = useState(null);
    const [formDataHeadline, setFormDataHeadline] = useState(initialFormData.position);
    const [formDataDesc, setFormDataDesc] = useState({...initialFormData.basic_desc});
    const [formDataUnique, setFormDataUnique] = useState(initialFormData.passion);
    const [formDataEdu, setFormDataEdu] = useState(initialFormData.education);
    const [formDataAchieve, setFormDataAchieve] = useState(initialFormData.achievement);
    const [formDataExp, setFormDataExp] = useState(initialFormData.experience);
    const [formDataResearch, setFormDataResearch] = useState(initialFormData.research);
    const [formDataSport, setFormDataSport] = useState(initialFormData.sport);
    const [formDataAward, setFormDataAward] = useState(initialFormData.award);
    const [formDataCareer, setFormDataCareer] = useState(initialFormData.career_skills);
    const [formDataPersonal, setFormDataPersonal] = useState(initialFormData.personal_skills);
    const [errorData, setErrorData] = useState({General: [],
                                                Headline: [],
                                                Pic: [],
                                                Desc: [],
                                                Unique: [],
                                                Edu: [],
                                                Exp: [],
                                                Achieve: [],
                                                Research: [],
                                                Sport: [],
                                                Award: [],
                                                Career: [],
                                                Personal: []
                                            });
    const [isProcess, setIsProcess] = useState({General: false,
                                                Headline: false,
                                                Pic: false,
                                                Desc: false,
                                                Unique: false,
                                                Edu: false,
                                                Exp: false,
                                                Achieve: false,
                                                Research: false,
                                                Sport: false,
                                                Award: false,
                                                Career: false,
                                                Personal: false
                                            });
    const [isBtnDisabled, setBtnDisabled] = useState({General: true,
                                                Headline: true,
                                                Pic: true,
                                                Desc: true,
                                                Unique: true,
                                                Edu: true,
                                                Exp: true,
                                                Achieve: true,
                                                Research: true,
                                                Sport: true,
                                                Award: true,
                                                Career: true,
                                                Personal: true
                                            });
    const [isFirstEdit, setIsFirstEdit] = useState(false);
    // const [isViewDisabled, setViewDisabled] = useState({General: true,
    //                                             Headline: true,
    //                                             Pic: true,
    //                                             Desc: true,
    //                                             Unique: true,
    //                                             Edu: true,
    //                                             Exp: true,
    //                                             Achieve: true,
    //                                             Research: true,
    //                                             Sport: true,
    //                                             Award: true,
    //                                             Career: true,
    //                                             Personal: true
    //                                         });
    const dispatch = useDispatch();
    // const [formData, setFormData] = useState({...initialFormData});
    // const [errorData, setErrorData] = useState([]);
    // const [isSuccess, setIsSuccess] = useState(false);
    // Get Year List
    const yearDataList = (type) => {
        const yearItems = [];
        // const startingYear = startYear;
        // const endingYear = currentYear;
        for (let i = currentYear; i >= startYear; i--) {
            yearItems.push({id: i, year: i});
        }
        setYearData(yearItems);
        // return yearItems;
    };
    // Get Future Year List in Asc & Desc
    // const futureYearDataList = (type) => {
    //     const yearItems = [];
    //     const startingYear = 2020;
    //     const endingYear = 2030;
    //     if(type === "asc"){
    //         for (let i = startingYear; i <= endingYear; i++) {
    //             yearItems.push({id: i, year: i});
    //         }
    //     }else{
    //         for (let i = endingYear; i >= startingYear; i--) {
    //             yearItems.push({id: i, year: i});
    //         }
    //     }
    //     type === 'asc' ? setFutureYearAsc(yearItems) : setFutureYearDsc(yearItems);
    //     // return yearItems;
    // };
    //Get Data from APIs
    const fetchSelectList = async(type, url) => {
        try{
            const selectApiData = await get_api_call(url, {});
            if(selectApiData.status === 200){
                switch (type) {
                    case 'passion':
                        setPassionData(selectApiData.data);
                        break;
                    case 'career_skill':
                        setCareerSkillData(selectApiData.data);
                        break;
                    case 'personal_skill':
                        setPersonalSkillData(selectApiData.data);
                        break;
                    case 'indi_award':
                        setIndiAwardData(selectApiData.data);
                        break;
                    case 'team_award':
                        setTeamAwardData(selectApiData.data);
                        break;
                    case 'school':
                        setSchoolData(selectApiData.data);
                        break;
                    case 'cohort':
                        setCohortData(selectApiData.data);
                        break;
                    default: 
                }
            }
        }catch(e){
			error_api_call({error: `portfolio|edit|fetchSelectList|${e}`});
        }
    }
    //Get Data for Country
    const fetchCountryList = async() => {
        try{
			const countryApiData = await get_api_call('countries', {});
			if(countryApiData.status === 200){
				setCountryData(countryApiData.data);
			}
        }catch(e){
			error_api_call({error: `portfolio|edit|fetchCountryList|${e}`});
        }
    }
    //Upload Profile Image Preview
    const previewProfilePic = (event) => {
        try{
            const files = event.target.files;
            if(files.length > 0){
                const documetAllowedExt = ["png", "jpg", "jpeg"];
                const allowedSize = 200000;
                const imgFile = files[0];
            // for(let i=0; i<files.length; i++){
                var ext = imgFile.name.slice((imgFile.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
                //Check for file size and extension
                console.log('imgFile.size', imgFile.size)
                if(imgFile.size <= allowedSize && documetAllowedExt.includes(ext)){
                    const imgData = {data: imgFile,
                                    src: URL.createObjectURL(imgFile),
                                    // title: '',
                                    // is_image: imageExt.includes(imgFile.type.toLowerCase()) ? true : false,
                                    ext
                                };
                    setPreviewPicImg({...imgData});
                    setBtnDisabled({...isBtnDisabled, Pic: false});
                }else{
                    setPreviewPicImg(null);
                    alertResponseMessage({alert_type: 2, message: "Photograph allowed size is 200KB and it must be an image."});
                }
            }
        }catch(e){
            setPreviewPicImg(null);
            error_api_call({error: `portfolio|edit|previewProfilePic|${e}`});
        }
    }
    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        setCroppedImg(cropper.getCroppedCanvas().toDataURL());
        // console.log(cropper.getCroppedCanvas().toDataURL());
        cropper.getCroppedCanvas().toBlob((blob) => {
            console.log(blob, 'blobbb');
            setPreviewPicBlob(blob);
        });
    };
    //Add More Row
    const addMoreRow = async(e, field) => {
        try{
            e.preventDefault();
            const defaultVal = {
                education: [{institute_name: '', program_name: '', achievement: '', start_year : '', end_year: '', is_present: false}],
                experience: [{designation: '', organization: '', achievement: '', start_year : '', end_year: '', is_present: false}],
                research: [{title: '', domain: '', link: '', year: '',  achievement: ''}],
                sport: [{name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false}],
                award: [{institute_name: '', award_name: '', why_awarded: '', year: ''}],
            };
            switch (field) {
                case 'education':
                    // setFormDataEdu({...formDataEdu, ...defaultVal[field]});
                    const data = formDataEdu.concat(defaultVal[field]);
                    setFormDataEdu(data);
                    break;
                case 'experience':
                    setFormDataExp(formDataExp.concat(defaultVal[field]));
                    break;
                case 'research':
                    setFormDataResearch(formDataResearch.concat(defaultVal[field]));
                    break;
                case 'sport':
                    setFormDataSport(formDataSport.concat(defaultVal[field]));
                    break;
                case 'award':
                    setFormDataAward(formDataAward.concat(defaultVal[field]));
                    break;
                default: 
            }
        }catch(e){
			error_api_call({error: `portfolio|edit|addMoreRow|${e}`});
        }
    }
    //Modify Input
    const updateInpState = (identifier_key, data) => {
        try{
            switch (identifier_key) {
                case 'Edu':
                    if(data.length > 0){
                        var eduData = [];
                        data.forEach(edu => {
                            eduData.push({institute_name: edu.institute_name || '',
                                            program_name: edu.program_name || '',
                                            achievement: edu.achievement || '',
                                            start_year : {id: edu.start_year, year: edu.start_year},
                                            end_year: edu.end_year ? {id: edu.end_year, year: edu.end_year}: '',
                                            is_present: edu.end_year ? false : true,
                                            id: edu.user_education_id
                                        });
                        });
                        setFormDataEdu(eduData);
                    }
                    break;
                case 'Exp':
                    if(data.length > 0){
                        var expData = [];
                        data.forEach(exp => {
                            console.log('exp.designation', exp, 'exp')
                            expData.push({designation: exp.designation || '',
                                            organization: exp.organization || '',
                                            achievement: exp.achievement || '',
                                            // start_year : {id: exp.start_year, year: exp.start_year},
                                            // end_year: exp.end_year ? {id: exp.end_year, year: exp.end_year}: '',
                                            start_year: moment(exp.start_date).format("YYYY-MM"),
                                            end_year: moment(exp.end_date).format("YYYY-MM"),
                                            is_present: exp.end_date ? false : true,
                                            id: exp.user_experience_id
                                        });
                        });
                        setFormDataExp(expData);
                    }
                    break;
                case 'Research':
                    if(data.length > 0){
                        var researchData = [];
                        data.forEach(research => {
                            researchData.push({title: research.title || '',
                                            domain: research.domain || '',
                                            link: research.link || '',
                                            year: {id: research.year, year: research.year},
                                            achievement: research.achievement || '',
                                            id: research.user_research_id
                                        });
                        });
                        setFormDataResearch(researchData);
                    }
                    break;
                case 'Sport':
                    if(data.length > 0){
                        var sportData = [];
                        data.forEach(sport => {
                            sportData.push({name: sport.name || '',
                                            venue: sport.venue || '',
                                            achievement: sport.achievement || '',
                                            start_year : {id: sport.start_year, year: sport.start_year},
                                            end_year: sport.end_year ? {id: sport.end_year, year: sport.end_year}: '',
                                            is_present: sport.end_year ? false : true,
                                            id: sport.user_sport_id
                                        });
                        });
                        setFormDataSport(sportData);
                    }
                    break;
                case 'Award':
                    if(data.length > 0){
                        var awardData = [];
                        data.forEach(award => {
                            awardData.push({institute_name: award.institute_name || '',
                                            award_name: award.award_name || '',
                                            why_awarded: award.why_awarded || '',
                                            year : {id: award.year, year: award.year},
                                            id: award.user_award_id
                                        });
                        });
                        
                        setFormDataAward(awardData);
                    }
                    break;
                default: 
            }
        }catch(e){
            console.log(e);
        }
    }
    useEffect(() => {
        //Get Portfolio Details
        const fetchPortfolioData = async() => {
            try{
                const portfolioApiData = await post_api_call(`portfolio-details`, {});
                // const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
                if(portfolioApiData.status === 200){
                    console.log(portfolioApiData, 'portfolioApiData')
                    // if(portfolioApiData.data.headline){
                    //     window.location.href = `/port/${portfolioApiData.data.url}`;
                    // }else{
                        if(!portfolioApiData.data.headline){
                            setIsFirstEdit(true);
                        }
                        //GeneralDetails
                        const fieldKeys = ['first_name' , 'last_name', 'email', 'dob', 'language', 'mobile_number', 'logo'];
                        const generalFieldData = {...formDataGeneral};
                        fieldKeys.forEach(key => {
                            generalFieldData[key] = portfolioApiData.data[key] ? portfolioApiData.data[key] : '';
                        });
                        generalFieldData.country_id = portfolioApiData.data.country_id ? {id: portfolioApiData.data.country_id, name: portfolioApiData.data.country} : '';
                        generalFieldData.phone_code_id = portfolioApiData.data.mobile_code_id ? {id: portfolioApiData.data.mobile_code_id, phone_code: portfolioApiData.data.phone_code} : '';
                        generalFieldData.school_name_id = portfolioApiData.data.school_id ? {id: portfolioApiData.data.school_id, school_name: portfolioApiData.data.school_name} : '';
                        generalFieldData.cohort_name_id = portfolioApiData.data.cohort_id ? {id: portfolioApiData.data.cohort_id, cohort_name: portfolioApiData.data.cohort_name} : '';
                        setFormDataGeneral({...generalFieldData});
                        setUserUrl(portfolioApiData.data.user_name);
                        //Headline
                        setFormDataHeadline(portfolioApiData.data.position || '');
                        //Short Description
                        setFormDataDesc({short_description: portfolioApiData.data.headline || '',
                                        basic_achievement: portfolioApiData.data.achievement || '',
                                        basic_passion: portfolioApiData.data.passion || ''});
                        //Unique Skills
                        if(portfolioApiData.data.unique_skills.length > 0){
                            var uniqueData = [];
                            portfolioApiData.data.unique_skills.forEach(skill => {
                                uniqueData.push({passion_id : {id: skill.passion_id, title: `${skill.title}: ${skill.unique_ability}`}});
                            });
                            setFormDataUnique(uniqueData);
                        }
                        //Education
                        updateInpState('Edu', portfolioApiData.data.education_details);
                        //Achievements
                        if(portfolioApiData.data.glosco_data.length > 0){
                            var gloscoData = formDataAchieve;
                            portfolioApiData.data.glosco_data.forEach(glosco => {
                                gloscoData[glosco.level_id-1] = {...gloscoData[glosco.level_id-1],
                                                                id: glosco.level_id,
                                                                level: glosco.level,
                                                                year: {id: glosco.year, year: glosco.year},
                                                                indi_award: glosco.indi_award,
                                                                team_award: glosco.team_award};

                            });
                            setFormDataAchieve(gloscoData);
                        }
                        //Experience
                        updateInpState('Exp', portfolioApiData.data.experience_details);
                        //Research
                        updateInpState('Research', portfolioApiData.data.research_details);
                        //Sport
                        updateInpState('Sport', portfolioApiData.data.sport_details);
                        //Award
                        updateInpState('Award', portfolioApiData.data.award_details);
                        //Career Skills
                        if(portfolioApiData.data.carrer_skill_details.length > 0){
                            setFormDataCareer(portfolioApiData.data.carrer_skill_details);
                        }
                        //Personal Skills
                        if(portfolioApiData.data.personal_skill_details.length > 0){
                            setFormDataPersonal(portfolioApiData.data.personal_skill_details);
                        }
                        // setFormData({...fieldData});
                        setPageLoader(false);
                    // }
                }
            }catch(e){
                console.log(e);
                alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
                error_api_call({error: `portfolio/edit|fetchPortfolioData|${e}`});
            }
        }
        fetchPortfolioData();
		//Initial API Data
        fetchCountryList();
        fetchSelectList('passion', 'passion-scores?fetch_type=2');
        fetchSelectList('career_skill', 'skills/career');
        fetchSelectList('personal_skill', 'skills/personal');
        fetchSelectList('school', 'schools');
        fetchSelectList('cohort', 'cohorts');
        fetchSelectList('indi_award', 'awards/individual');
        fetchSelectList('team_award', 'awards/team');
        // fetchSelectList('program');
        yearDataList('year');
        // yearDataList('future');
        // futureYearDataList('asc');
        // futureYearDataList('dsc');
	}, []);
    //Update Input
    const updateInput = (identifier_key, identifier_type, input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            console.log(identifier_key, input_type, field, value, field_type, form_field, index, 'inputtt')
            if(errorData[identifier_key].length){
                setErrorData({...errorData, [identifier_key]: []});
            }
            setBtnDisabled({...isBtnDisabled, [identifier_key]: false});
            // setViewDisabled({...isViewDisabled, [identifier_key]: true});
            if(field === 'mobile_number'){
                if(value.length < 16){
                    value = value.replace(/[^0-9]/g, '');
                }else{
                    return false;
                }
            }else if(input_type === 'input'
                || input_type === 'textarea'
            ){
                // value = value.replace(/ /g, '');
                value = value.trimStart();
                // value = value.replace(/  /g, '');
                if(field === 'first_name'
                    || field === 'last_name'
                ){
                    value = value.replace(/[^a-zA-Z. ]/g, '')
                      .replace('  ', ' ');
                }
            }
            // const fieldData = {...formData};
            // var fieldData = identifier_type === 'object' ? {...eval('formData'+identifier_key)} : eval('formData'+identifier_key);
            var fieldData = eval('formData'+identifier_key);
            if(identifier_type === 'object'){
                fieldData = {...fieldData}
            }else if(identifier_type === 'array'){
                fieldData = [...fieldData]
            }
            console.log(fieldData, 'fieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldData')
            if(field_type === 'array'){
                // const fieldData = eval('formData'+identifier_key);
                console.log('fieldData[form_field][index][field] = value', form_field,',', index,',', field,',',  value)
                // fieldData[form_field][index][field] = value;
                fieldData[index][field] = value;
                // setFormData({...fieldData});
            }else{
                identifier_type === 'single_value' ? fieldData = value : fieldData[field] = value;
                // setFormData({
                //     ...formData,
                //     [field]: value
                // });
            }
            switch (identifier_key) {
                case 'General':
                    setFormDataGeneral({...fieldData});
                    break;
                case 'Headline':
                    setFormDataHeadline(fieldData);
                    break;
                case 'Desc':
                    setFormDataDesc({...fieldData});
                    break;
                case 'Unique':
                    setFormDataUnique([...fieldData]);
                    break;
                case 'Edu':
                    setFormDataEdu([...fieldData]);
                    break;
                case 'Achieve':
                    setFormDataAchieve([...fieldData]);
                    break;
                case 'Exp':
                    setFormDataExp([...fieldData]);
                    break;
                case 'Research':
                    setFormDataResearch([...fieldData]);
                    break;
                case 'Sport':
                    setFormDataSport([...fieldData]);
                    break;
                case 'Award':
                    setFormDataAward([...fieldData]);
                    break;
                case 'Career':
                    setFormDataCareer([...fieldData]);
                    break;
                case 'Personal':
                    setFormDataPersonal([...fieldData]);
                    break;
                default:
            }
        }catch(e){
            console.log(e);
            error_api_call({error: `portfolio|edit|updateInput|${e}`});
        }
    }
    //Check if Url is Valid
    const isValidUrl = (string) => {
        try {
            console.log(string, 'stringstringstringstringstring')
        //   new URL(string);
            // const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
            if(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi.test(string)) {
                return true;
            }else{
                return false;
            }
        } catch (err) {
          return false;
        }
      }
    //Add Fellow on Submit Click
    const editPortfolio = async(e, form_type='') => {
        try{
            e.preventDefault();
            setErrorData({...errorData, [form_type]: []});
            setIsProcess({...isProcess, [form_type]: true});
            var errorDetails = {
                General: [],
                Headline: [],
                Pic: [],
                Desc: [],
                Unique: [],
                Edu: [],
                Exp: [],
                Achieve: [],
                Research: [],
                Sport: [],
                Award: [],
                Career: [],
                Personal: []
            };
            var apiFormData = new FormData();
            //Validation
            var validation = [];
            var validationRules = {};
            switch (form_type) {
                case 'General':
                    validationRules = {
                        first_name: 'required|max:100',
                        last_name: 'optional|max:100',
                        school_name_id: 'required||School Name',
                        cohort_name_id: 'required||Glosco Cohort Name',
                        country_id: 'required||Country',
                        dob: 'required||Date of Birth',
                        // email: 'required|email|max:150',
                        phone_code_id: 'required||Mobile Country Code',
                        // mobile_number: 'required|digit:|min:6|max:15',
                        language: 'optional|max:250||Languages Known'
                    };
                    const fieldFormDataGeneral = {...formDataGeneral,
                        country_id: formDataGeneral.country_id.id,
                        phone_code_id: formDataGeneral.phone_code_id.id,
                        school_name_id: formDataGeneral.school_name_id.id,
                        cohort_name_id: formDataGeneral.cohort_name_id.id
                    };
                    validation = ValidationHelper.validation(fieldFormDataGeneral, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        // apiFormData.append('general_data', fieldFormDataGeneral);
                        const basicDataKeys = ['first_name',
                            'last_name',
                            'country_id',
                            'school_name_id',
                            'cohort_name_id',
                            'dob',
                            'email',
                            'phone_code_id',
                            // 'mobile_number',
                            'language'];
                        for (var key in basicDataKeys) {
                            if (fieldFormDataGeneral.hasOwnProperty(basicDataKeys[key])) {
                                apiFormData.append(`general_data[${basicDataKeys[key]}]`, fieldFormDataGeneral[basicDataKeys[key]]);
                            }
                        }
                    }
                    break;
                case 'Pic':
                    //Image
                    if(previewPicImg === null){
                        errorDetails[form_type] = ['Photograph is required'];
                    }else{
                        // apiFormData.append('profile_pic', previewPicImg.data, 'Profile Pic');
                        apiFormData.append('profile_pic', previewPicBlob, 'Profile Pic');
                        // const cropper = cropperRef.current?.cropper;
                        // await cropper.getCroppedCanvas().toBlob((blob) => {
                        //     console.log(blob)
                        //     // Pass the image file name as the third parameter if necessary.
                        //     // apiFormData.append('profile_pic', blob, 'profile-pic.png');
                        //     apiFormData.append('profile_pic', blob, 'profile-pic');
                        // });
                        console.log('skipped')
                    }
                    break;
                case 'Headline':
                    validationRules = {
                        position: 'required|max:150||Who you are',
                    };
                    const fieldFormDataHeadline = {
                        position: formDataHeadline
                    };
                    validation = ValidationHelper.validation(fieldFormDataHeadline, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        apiFormData.append('headline_data', formDataHeadline);
                    }
                    break;
                case 'Desc':
                    validationRules = {
                        short_description: 'required|max:250||Your name, grade and school',
                        basic_achievement: 'optional|max:250||Your academic and extracurricular achievements, leadership, awards, research and any internships',
                        basic_passion: 'required|max:250||Your passion and future goal',
                    };
                    validation = ValidationHelper.validation(formDataDesc, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        // apiFormData.append(`desc_data[short_description]`, formDataDesc.short_description);
                        // apiFormData.append(`desc_data[basic_achievement]`, formDataDesc.basic_achievement);
                        // apiFormData.append(`desc_data[basic_passion]`, formDataDesc.basic_passion);
                        apiFormData.append('desc_data', JSON.stringify(formDataDesc));
                        // formData.append(`${key}.${subKey}`, data[key][subKey]);
                    }
                    break;
                case 'Unique':
                    // Unique Skills
                    var passionData = [];
                    formDataUnique.forEach((passion, passion_indx) => {
                        var passionDetails = formDataUnique[passion_indx];
                        if(passionDetails.passion_id !== ''){
                            if(passionData.indexOf(passionDetails.passion_id.id) !== -1){
                                errorDetails[form_type] = [`2 Passion Scores cannot be same`];
                            }
                            passionData.push(passionDetails.passion_id.id);
                            apiFormData.append(`passion[${passion_indx}]`, passionDetails.passion_id.id);
                        }
                    });
                    // if(passionData.length > 0 && passionData.length < 3){
                    //     errorDetails[form_type] = [`All 3 Passion Scores Required`];
                    // }
                    if(passionData.length < 3){
                        errorDetails[form_type] = [`All 3 Passion Scores Required`];
                    }
                    // else{
                    //     apiFormData.append('unique_data', passionData);
                    // }
                    break;
                case 'Edu':
                    //Education
                    // var isEduData = false;
                    // var eduData = [];
                    formDataEdu.forEach((edu, edu_indx) => {
                        var eduDetails = edu;
                        if(eduDetails.institute_name || eduDetails.program_name || eduDetails.achievement){
                            // isEduData = true;
                            const eduValidationRules = {
                                institute_name: `required|max:150||Education, Diploma... ${edu_indx + 1}: Institution Name`,
                                program_name: `required|max:100||Education, Diploma... ${edu_indx + 1}: Name of the Program`,
                                achievement: `optional|max:250||Education, Diploma... ${edu_indx + 1}: Key Achievement or Grade`,
                                start_year: `required||Education, Diploma... ${edu_indx + 1}: Start Year`
                            };
                            const validationEdu = ValidationHelper.validation(eduDetails, eduValidationRules).error;
                            if(validationEdu.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationEdu);
                            }
                            //End Data
                            if(eduDetails.is_present === false){
                                if(eduDetails.end_year === ''){
                                    errorDetails[form_type].push(`Education, Diploma... ${edu_indx + 1}: End Year is Required`);
                                }else if (eduDetails.start_year !== '' && eduDetails.start_year.id > eduDetails.end_year.id){
                                    errorDetails[form_type].push(`Education, Diploma... ${edu_indx + 1}: Start Year cannot be greater than End Year`);
                                }
                            }
                            if(!errorDetails[form_type].length){
                                // var eduEndYear = eduDetails.is_present === true ? null : eduDetails.end_year.id;
                                var userEduId = typeof eduDetails.id !== "undefined" ? eduDetails.id : '';
                                apiFormData.append(`education[${edu_indx}][id]`, userEduId);
                                apiFormData.append(`education[${edu_indx}][institute_name]`, eduDetails.institute_name);
                                apiFormData.append(`education[${edu_indx}][program_name]`, eduDetails.program_name);
                                apiFormData.append(`education[${edu_indx}][achievement]`, eduDetails.achievement);
                                apiFormData.append(`education[${edu_indx}][start_year]`, eduDetails.start_year.id);
                                apiFormData.append(`education[${edu_indx}][end_year]`, eduDetails.is_present === true ? null : eduDetails.end_year.id);
                                apiFormData.append(`education[${edu_indx}][is_present]`, eduDetails.is_present);
                            }
                        }else if(typeof eduDetails.id !== "undefined"){//To delete from database
                            var eduEndYear = eduDetails.is_present === true ? null : eduDetails.end_year.id;
                            apiFormData.append(`education[${edu_indx}][id]`, eduDetails.id);
                            apiFormData.append(`education[${edu_indx}][institute_name]`, eduDetails.institute_name);
                            apiFormData.append(`education[${edu_indx}][program_name]`, eduDetails.program_name);
                            apiFormData.append(`education[${edu_indx}][achievement]`, eduDetails.achievement);
                            apiFormData.append(`education[${edu_indx}][start_year]`, eduDetails.start_year.id);
                            apiFormData.append(`education[${edu_indx}][end_year]`, eduEndYear);
                            apiFormData.append(`education[${edu_indx}][is_present]`, eduDetails.is_present);
                        }
                    });
                    // if(!isEduData){
                    //     errorDetails.push(`Atleast 1 Education, Diploma... (Point 6) Detail Required`);
                    // }
                    // if(isEduData){
                    //     apiFormData.append('edu_data', eduData);
                    // }
                    break;
                case 'Achieve':
                    // Achievements
                    // var isAchieveData = false;
                    // var achieveData = [];
                    formDataAchieve.forEach((achieve, achieve_indx) => {
                        var achieveDetails = achieve;
                        if(achieveDetails.year || achieveDetails.team_award || achieveDetails.indi_award){
                            // isAchieveData = true;
                            // const achieveValidationRules = {
                            //     year: `required|max:150||IFSPD Europe Glo... (Point 7) ${achieveDetails.level}: Year of completion`,
                            //     indi_award: `required|max:100||IFSPD Europe Glo... (Point 7) ${achieveDetails.level}: Individual Awards`,
                            //     team_award: `required||IFSPD Europe Glo... (Point 7) ${achieveDetails.level}: Team Awards`
                            // };
                            // const validationAchieve = ValidationHelper.validation(achieveDetails, achieveValidationRules).error;
                            var validationAchieve = [];
                            if(achieveDetails.year === ''){
                                validationAchieve.push(`IFSPD Europe Glo... ${achieveDetails.level}: Year of completion is Required`);
                            }else if(achieveDetails.indi_award === '' && achieveDetails.team_award === ''){
                                validationAchieve.push(`IFSPD Europe Glo... ${achieveDetails.level}: Individual/Team Awards is Required`);
                            }
                            if(validationAchieve.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationAchieve);
                            }
                            if(!errorDetails[form_type].length){
                                const indiAwardIds = [];
                                const teamAwardIds = [];
                                if(achieveDetails.indi_award.length){
                                    const indiAward = achieveDetails.indi_award;
                                    indiAward.forEach(award => {
                                        indiAwardIds.push(award.id);
                                    });
                                }
                                if(achieveDetails.team_award.length){
                                    const teamAward = achieveDetails.team_award;
                                    teamAward.forEach(award => {
                                        teamAwardIds.push(award.id);
                                    });
                                }
                                apiFormData.append(`achievement[${achieve_indx}][id]`, achieveDetails.id);
                                apiFormData.append(`achievement[${achieve_indx}][year]`, achieveDetails.year.id);
                                apiFormData.append(`achievement[${achieve_indx}][indi_award]`, indiAwardIds);
                                apiFormData.append(`achievement[${achieve_indx}][team_award]`, teamAwardIds);
                                // achieveData.push({
                                //     'id' : achieveDetails.id,
                                //     'year' : achieveDetails.year.id,
                                //     'indi_award' : indiAwardIds,
                                //     'team_award' : teamAwardIds
                                // });
                            }
                        }
                    });
                    
                    // if(!isAchieveData){
                    //     errorDetails.push(`Atleast 1 IFSPD Europe Global Sustainabili... (Point 7) Detail Required`);
                    // }
                    break;
                case 'Exp':
                    // var expData = [];
                    // Work Experience
                    // var isExpData = false;
                    formDataExp.forEach((exp, exp_indx) => {
                        var expDetails = exp;
                        if(expDetails.designation || expDetails.organization || expDetails.achievement){
                            // isExpData = true;
                            const expValidationRules = {
                                designation: `required|max:100||Work Experience, Volunteer... ${exp_indx + 1}: Designation`,
                                organization: `required|max:100||Work Experience, Volunteer... ${exp_indx + 1}: Organization/Passion Project Name`,
                                achievement: `optional|max:250||Work Experience, Volunteer... ${exp_indx + 1}: Key Responsibilities or Achievements`,
                                start_year: `required||Work Experience, Volunteer... ${exp_indx + 1}: Start Date`
                            };
                            const validationExp = ValidationHelper.validation(expDetails, expValidationRules).error;
                            if(validationExp.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationExp);
                            }
                            //End Data
                            if(expDetails.is_present === false){
                                if(expDetails.end_year === ''
                                    || expDetails.end_year === "Invalid date"
                                ){
                                    errorDetails[form_type].push(`Work Experience, Volunteer... ${exp_indx + 1}: End Date is Required`);
                                }else if(expDetails.start_year !== '' && expDetails.start_year > expDetails.end_year){
                                    errorDetails[form_type].push(`Work Experience, Volunteer... ${exp_indx + 1}: Start Date cannot be greater than End Date`);
                                }
                                // else if(expDetails.start_year !== '' && expDetails.start_year.id > expDetails.end_year.id){
                                //     errorDetails[form_type].push(`Work Experience, Volunteer... ${exp_indx + 1}: Start Year cannot be greater than End Year`);
                                // }
                            }
                            if(!errorDetails[form_type].length){
                                var expEndYear = expDetails.is_present === true ? null : expDetails.end_year;
                                var userExpId = typeof expDetails.id !== "undefined" ? expDetails.id : '';
                                apiFormData.append(`experience[${exp_indx}][id]`, userExpId);
                                apiFormData.append(`experience[${exp_indx}][designation]`, expDetails.designation);
                                apiFormData.append(`experience[${exp_indx}][organization]`, expDetails.organization);
                                apiFormData.append(`experience[${exp_indx}][achievement]`, expDetails.achievement);
                                apiFormData.append(`experience[${exp_indx}][start_year]`, expDetails.start_year);//parseInt(expDetails.start_year.id))
                                apiFormData.append(`experience[${exp_indx}][end_year]`, expEndYear);
                                apiFormData.append(`experience[${exp_indx}][is_present]`, expDetails.is_present);
                            }
                        }else if(typeof expDetails.id !== "undefined"){//To delete from database
                            // var expEndYear = expDetails.is_present === true ? null : expDetails.end_year.id;
                            apiFormData.append(`experience[${exp_indx}][id]`, expDetails.id);
                            apiFormData.append(`experience[${exp_indx}][designation]`, expDetails.designation);
                            apiFormData.append(`experience[${exp_indx}][organization]`, expDetails.organization);
                            apiFormData.append(`experience[${exp_indx}][achievement]`, expDetails.achievement);
                            apiFormData.append(`experience[${exp_indx}][start_year]`, expDetails.start_year);//parseInt(expDetails.start_year.id))
                            apiFormData.append(`experience[${exp_indx}][end_year]`, "");
                            apiFormData.append(`experience[${exp_indx}][is_present]`, expDetails.is_present);
                        }
                    });
                    // if(!isExpData){
                    //     errorDetails.push(`Atleast 1 Work Experience, Volunteer... (Point 8) Detail Required`);
                    // }
                    break;
                case 'Research':
                    //Research
                    // var isResearchData = false;
                    formDataResearch.forEach((research, research_indx) => {
                        // var researchDetails = formDataResearch[research_indx];
                        var researchDetails = research;
                        if(researchDetails.title
                            || researchDetails.domain
                            || researchDetails.link
                            || researchDetails.achievement
                        ){
                            // isResearchData = true;
                            const researchValidationRules = {
                                title: `required|max:150||Projects, Assign... ${research_indx + 1}: Title of the Projects, Assignm...`,
                                domain: `required|max:100||Projects, Assign... ${research_indx + 1}: Where was it published`,
                                link: `optional|max:250||Projects, Assign... ${research_indx + 1}: Link`,
                                achievement: `optional|max:250||Projects, Assign... ${research_indx + 1}: Any key achievement or accolade`,
                                year: `required||Projects, Assign... ${research_indx + 1}: Year of publishing`
                            };
                            const validationEdu = ValidationHelper.validation(researchDetails, researchValidationRules).error;
                            if(validationEdu.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationEdu);
                            }else if(researchDetails.link && !isValidUrl(researchDetails.link)){
                                errorDetails[form_type] = errorDetails[form_type].concat([`Projects, Assign... ${research_indx + 1}: Link is Invalid`]);
                            }

                            if(!errorDetails[form_type].length){
                                var userResearchId = typeof researchDetails.id !== "undefined" ? researchDetails.id : '';
                                apiFormData.append(`research[${research_indx}][id]`, userResearchId);
                                apiFormData.append(`research[${research_indx}][title]`, researchDetails.title);
                                apiFormData.append(`research[${research_indx}][domain]`, researchDetails.domain);
                                apiFormData.append(`research[${research_indx}][achievement]`, researchDetails.achievement);
                                apiFormData.append(`research[${research_indx}][year]`, researchDetails.year.id);
                                apiFormData.append(`research[${research_indx}][link]`, researchDetails.link);
                            }
                        }else if(typeof researchDetails.id !== "undefined"){//To delete from database
                            apiFormData.append(`research[${research_indx}][id]`, researchDetails.id);
                            apiFormData.append(`research[${research_indx}][title]`, researchDetails.title);
                            apiFormData.append(`research[${research_indx}][domain]`, researchDetails.domain);
                            apiFormData.append(`research[${research_indx}][achievement]`, researchDetails.achievement);
                            apiFormData.append(`research[${research_indx}][year]`, researchDetails.year.id);
                            apiFormData.append(`research[${research_indx}][link]`, researchDetails.link);
                        }
                    });
                    // if(!isResearchData){
                    //     errorDetails.push(`Atleast 1 Projects, Assign... (Point 9) Detail Required`);
                    // }
                    break;
                case 'Sport':
                    //Sports
                    // var isSportData = false;
                    // var eduData = [];
                    formDataSport.forEach((sport, sport_indx) => {
                        var sportDetails = sport;
                        if(sportDetails.name || sportDetails.venue  || sportDetails.achievement){
                            // isSportData = true;
                            const sportValidationRules = {
                                name: `required|max:150||Sports, Games, A... ${sport_indx + 1}: Sports, Games, Ath...`,
                                venue : `required|max:100||Sports, Games, A... ${sport_indx + 1}: Where did you participate?`,
                                achievement: `optional|max:250||Sports, Games, A... ${sport_indx + 1}: Any key achievement or accolade`,
                                start_year: `required||Sports, Games, A... ${sport_indx + 1}: Start Year`
                            };
                            const validationSport = ValidationHelper.validation(sportDetails, sportValidationRules).error;
                            if(validationSport.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationSport);
                            }
                            //End Data
                            if(sportDetails.is_present === false){
                                if(sportDetails.end_year === ''){
                                    errorDetails[form_type].push(`Sports, Games, A... ${sport_indx + 1}: End Year is Required`);
                                }else if (sportDetails.start_year !== '' && sportDetails.start_year.id > sportDetails.end_year.id){
                                    errorDetails[form_type].push(`Sports, Games, A... ${sport_indx + 1}: Start Year cannot be greater than End Year`);
                                }
                            }
                            if(!errorDetails[form_type].length){
                                var sportEndYear = sportDetails.is_present === true ? null : sportDetails.end_year.id;
                                var userSportId = typeof sportDetails.id !== "undefined" ? sportDetails.id : '';
                                apiFormData.append(`sport[${sport_indx}][id]`, userSportId);
                                apiFormData.append(`sport[${sport_indx}][name]`, sportDetails.name);
                                apiFormData.append(`sport[${sport_indx}][venue]`, sportDetails.venue);
                                apiFormData.append(`sport[${sport_indx}][achievement]`, sportDetails.achievement);
                                apiFormData.append(`sport[${sport_indx}][start_year]`, sportDetails.start_year.id);
                                apiFormData.append(`sport[${sport_indx}][end_year]`, sportEndYear);
                                apiFormData.append(`sport[${sport_indx}][is_present]`, sportDetails.is_present);
                            }
                        }else if(typeof sportDetails.id !== "undefined"){//To delete from database
                            apiFormData.append(`sport[${sport_indx}][id]`, sportDetails.id);
                            apiFormData.append(`sport[${sport_indx}][name]`, sportDetails.name);
                            apiFormData.append(`sport[${sport_indx}][venue]`, sportDetails.venue);
                            apiFormData.append(`sport[${sport_indx}][achievement]`, sportDetails.achievement);
                            apiFormData.append(`sport[${sport_indx}][start_year]`, sportDetails.start_year.id);
                            apiFormData.append(`sport[${sport_indx}][end_year]`, sportEndYear);
                            apiFormData.append(`sport[${sport_indx}][is_present]`, sportDetails.is_present);
                        }
                    });
                    // if(!isSportData){
                    //     errorDetails.push(`Atleast 1 Sports, Games, A... (Point 10) Detail Required`);
                    // }
                    break;
                case 'Award':
                    //Award
                    // var awardData = [];
                    formDataAward.forEach((award, award_indx) => {
                        var awardDetails = award;
                        if(awardDetails.institute_name || awardDetails.award_name || awardDetails.why_awarded){
                            const awardValidationRules = {
                                institute_name: `required|max:100||Honours and Awards ${award_indx + 1}: Institution Name`,
                                award_name: `required|max:100||Honours and Awards ${award_indx + 1}: Name of the Award`,
                                why_awarded: `optional|max:250||Honours and Awards ${award_indx + 1}: Why was this awarded to you?`,
                                year: `required||Honours and Awards ${award_indx + 1}: Year`
                            };
                            const validationAward = ValidationHelper.validation(awardDetails, awardValidationRules).error;
                            if(validationAward.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationAward);
                            }

                            if(!errorDetails[form_type].length){
                                var userAwardId = typeof awardDetails.id !== "undefined" ? awardDetails.id : '';
                                apiFormData.append(`award[${award_indx}][id]`, userAwardId);
                                apiFormData.append(`award[${award_indx}][institute_name]`, awardDetails.institute_name);
                                apiFormData.append(`award[${award_indx}][award_name]`, awardDetails.award_name);
                                apiFormData.append(`award[${award_indx}][why_awarded]`, awardDetails.why_awarded);
                                apiFormData.append(`award[${award_indx}][year]`, awardDetails.year.id);
                            }
                        }else if(typeof awardDetails.id !== "undefined"){//To delete from database
                            apiFormData.append(`award[${award_indx}][id]`, awardDetails.id);
                            apiFormData.append(`award[${award_indx}][institute_name]`, awardDetails.institute_name);
                            apiFormData.append(`award[${award_indx}][award_name]`, awardDetails.award_name);
                            apiFormData.append(`award[${award_indx}][why_awarded]`, awardDetails.why_awarded);
                            apiFormData.append(`award[${award_indx}][year]`, awardDetails.year.id);
                        }
                    });
                    break;
                case 'Career':
                    //Career Skills
                    if(formDataCareer.length > 3){
                        errorDetails[form_type].push(`Select only top three Career Skills`);
                    }else{
                        const careerSkillIds = [];
                        formDataCareer.forEach(award => {
                            careerSkillIds.push(award.id);
                        });
                        apiFormData.append(`career_skills`, careerSkillIds);
                    }
                    break;
                case 'Personal':
                    //Personal Skills
                    if(formDataPersonal.length > 3){
                        errorDetails[form_type].push(`Select only top three Personal Skills`);
                    }else{
                        const personalSkillIds = [];
                        formDataPersonal.forEach(award => {
                            personalSkillIds.push(award.id);
                        });
                        apiFormData.append(`personal_skills`, personalSkillIds);
                    }
                    break;
                default:
            }
            console.log(errorDetails[form_type], 'errorDetails[form_type]')
            console.log('formDataEduformDataEdu', formDataEdu)
            if(errorDetails[form_type].length > 0){
                setErrorData({...errorData, [form_type]: errorDetails[form_type]});
                setIsProcess({...isProcess, [form_type]: false});
                setBtnDisabled({...isBtnDisabled, [form_type]: true});
            }else{
                //Send Data to Server
                apiFormData.append('update_type', form_type.toLowerCase());
                // const updateFellow = await post_api_call(`update-portfolio/${form_type.toLowerCase()}`, apiFormData);
                const updateFellow = await post_api_call(`update-portfolio`, apiFormData);
				if(updateFellow.status === 200){
                    // setIsSuccess(true);
                    if(form_type === 'Pic'
                        || form_type === 'General'
                    ){
                        // Dispatch
                        dispatch(update_user_info(updateFellow.data, async cb_data => {
                            console.log('cb_data', cb_data)
                        }));
                    }else if(form_type === 'Edu'
                        || form_type === 'Exp'
                        || form_type === 'Research'
                        || form_type === 'Award'
                        || form_type === 'Sport'
                    ){
                        updateInpState(form_type, updateFellow.data);
                    }
                    alertResponseMessage({alert_type: 1, message: "Information Updated Successfully"});
                    setBtnDisabled({...isBtnDisabled, [form_type]: true});
                    // setViewDisabled({...isViewDisabled, [form_type]: false});
                    console.log("Information Updated Successfully", 'Information Updated Successfully')
                }else{
                    if(typeof updateFellow.message !== "undefined"){
                        setErrorData({...errorData, [form_type]: [updateFellow.message]});
                        console.log("Errorooo")
                    }
                }
                setIsProcess({...isProcess, [form_type]: false});
            }
            
        }catch(e){
            console.log(e);
            setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
            setIsProcess({...isProcess, [form_type]: false});
            error_api_call({error: `portfolio|edit|editPortfolio|${e}`});
        }
    }

    return (
        <main id="main">
            <section className="team-sec pt-0 portfolio-edit">
                {/* <div className="container-fluid container-lg pe-lg-5 ps-lg-5"> */}
                <div className="container-fluid container-lg">
                    <div className="section-header section-header-1">
                        <h1 className="title">Edit Portfolio</h1>
                    </div>
                    
                    <div className="row justify-content-center team-detail m-1">
                    {pageLoading 
                    ? 
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                    :
                    // !isSuccess
                    // ?
                        <div className="col-md-10 col-12 col-sm-12 card p-3">
                            {isFirstEdit && (
                                <>
                                    <p>Admissions to top colleges with scholarships and career progress demand a finely curated portfolio. Hence, your IFSPD Europe portfolio has two purposes. First, a track record of all your achievements to show consistency, and second, a showcase of your growth, to differentiate and elevate you from the crowd.</p>
                                    <p>If you do not have enough to fill up all the sections, do not worry. This is a continuous process, and you would build up enough credentials through years of your engagement with IFSPD Europe. Fill up the following details with this context in mind.</p>
                                </>
                            )}
                            
                            <p>This should be ideally filled up by the student, alongside a parent.</p>
                            {/* <p><b>PROFILE</b></p> */}
                            {/* <form className="profile-add-form" autoComplete="off"> */}
                            {/* General Details */}
                            <Accordion>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;General Details</Accordion.Header>
                                    <Accordion.Body>
                                        <form autoComplete="off">
                                            {/* <b>General Details:</b><br/><br/> */}
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th>First Name:</th>
                                                        <td><input className="form-control" placeholder="First Name" name="first_name" autoComplete="off" value={formDataGeneral.first_name} onChange={(e) => updateInput('General', 'object', 'input', 'first_name', e.target.value)}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Last Name:</th>
                                                        <td><input className="form-control" placeholder="Last Name" name="last_name" autoComplete="off" value={formDataGeneral.last_name} onChange={(e) => updateInput('General', 'object', 'input', 'last_name', e.target.value)}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>School Name:</th>
                                                        <td>
                                                            <div className="w-200p">
                                                            <Select
                                                                // isDisabled={true}
                                                                options={schoolData} 
                                                                defaultValue={formDataGeneral.school_name_id}
                                                                label={"Select School Name"}
                                                                onChange={(value) => updateInput('General', 'object', 'select-react', 'school_name_id', value)}
                                                                getOptionLabel ={(option) => option.school_name}
                                                                getOptionValue ={(option) => option.id}
                                                                styles={RSstandardStyle}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                // menuPortalTarget={document.querySelector('body')}
                                                                // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                                // className="is-valid"
                                                            />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Glosco Cohort Name:</th>
                                                        <td>
                                                            <div className="w-200p">
                                                            <Select
                                                                // isDisabled={true}
                                                                options={cohortData} 
                                                                defaultValue={formDataGeneral.cohort_name_id}
                                                                label={"Select Glosco Cohort Name"}
                                                                onChange={(value) => updateInput('General', 'object', 'select-react', 'cohort_name_id', value)}
                                                                getOptionLabel ={(option) => option.cohort_name}
                                                                getOptionValue ={(option) => option.id}
                                                                styles={RSstandardStyle}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                // menuPortalTarget={document.querySelector('body')}
                                                                // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                                // className="is-valid"
                                                            />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Country:</th>
                                                        <td>
                                                            <div className="w-200p">
                                                            <Select
                                                                // isDisabled={true}
                                                                options={countryData} 
                                                                defaultValue={formDataGeneral.country_id}
                                                                label={"Select Country"}
                                                                onChange={(value) => updateInput('General', 'object', 'select-react', 'country_id', value)}
                                                                getOptionLabel ={(option) => option.name}
                                                                getOptionValue ={(option) => option.id}
                                                                styles={RSstandardStyle}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                // menuPortalTarget={document.querySelector('body')}
                                                                // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                                // className="is-valid"
                                                            />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Date of Birth:</th>
                                                        <td><input className="form-control w-200p" type="date" name="dob" max={`${currentYear-10}-12-31`} value={formDataGeneral.dob} onChange={(e) => updateInput('General', 'object', 'input', 'dob', e.target.value)}/></td>
                                                    </tr>
                                                    {/* <tr>
                                                        <th>Email:</th>
                                                        <td><input className="form-control" name="email" autoComplete="off" value={formDataGeneral.email} onChange={(e) => updateInput('General', 'object', 'input', 'email', e.target.value)}/></td>
                                                    </tr> */}
                                                    {/*<tr>
                                                        <th>Mobile with Country Code:</th>
                                                        <td className="d-flex">

                                                            <div className="w-200p">
                                                            <Select
                                                                // isDisabled={true}
                                                                options={countryData} 
                                                                defaultValue={formDataGeneral.phone_code_id}
                                                                label={"Select Country Code"}
                                                                onChange={(value) => updateInput('General', 'object', 'select-react', 'phone_code_id', value)}
                                                                getOptionLabel ={(option) => `+${option.phone_code}`}
                                                                getOptionValue ={(option) => option.id}
                                                                menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                            />
                                                            </div>
                                                            <input className="form-control ms-1" name="mobile_number" placeholder="Mobile Number" value={formDataGeneral.mobile_number} onChange={(e) => updateInput('General', 'object', 'input', 'mobile_number', e.target.value)}/>
                                                        </td>
                                                    </tr>*/}
                                                    <tr>
                                                        <th>Languages Known:</th>
                                                        <td><input className="form-control" name="language" value={formDataGeneral.language} onChange={(e) => updateInput('General', 'object', 'input', 'language', e.target.value)}/></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                            {!isProcess['General']
                                            ?
                                                isBtnDisabled['General']
                                                ?
                                                    <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'General')}>Submit</button>
                                            :
                                                <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                            }
                                            
                                            <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['General'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>{}
                                                {errorData['General'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* profile photo */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="0">
                                <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Photograph</Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        {/* <b>Note:</b> Passport size, with your face making 70-80% of the photo against a plain or white background. Max 200 KB<br/> */}
                                        <form autoComplete="off">
                                        <b>Upload your Photograph</b> (Passport size, with your face making 70-80% of the photo against a plain or white background. Max 200 KB):<br/>
                                        {previewPicImg !== null &&
                                            <>
                                            <div className="d-flex">
                                            <Cropper
                                                src={previewPicImg.src}
                                                style={{ height: 200, width: 200 }}
                                                // Cropper.js options
                                                initialAspectRatio={1 / 1}
                                                aspectRatio={1/1}
                                                guides={false}
                                                crop={onCrop}
                                                ref={cropperRef}
                                                dragMode="move"
                                                // cropBoxResizable={false}
                                                background={false}
                                                // minContainerWidth={"100%"}
                                                minContainerWidth={200}
                                                zoomable={false}
                                                highlight={false}
                                            />
                                            <div className="ms-4">
                                                <img src={croppedImg} className="img-thumbnail cropper-preview-img" alt={formDataGeneral.first_name} />
                                            </div>
                                            </div>
                                            </>
                                        }
                                        <div className="upload-img text-center">
                                            <div className="m-3">
                                                {/* <img src={require("./../../assets/images/user.png")} alt="User Logo" className="img-thumbnail" /> */}
                                                {/* {formDataGeneral.logo ? */}
                                                {previewPicImg !== null
                                                    ?
                                                    //  <img src={previewPicImg.src} alt={formDataGeneral.first_name} className="img-thumbnail cropper-preview-img" />
                                                    <></>
                                                    :
                                                    <img src={formDataGeneral.logo} alt={formDataGeneral.first_name} className="img-thumbnail cropper-preview-img" />
                                                }
                                               
                                                {/* :
                                                    <img src={previewPicImg !== null ? previewPicImg.src : picIcon} alt={formDataGeneral.first_name} className="img-thumbnail" />
                                                } */}
                                            </div>
                                            
                                            <div className="btn btn-light btn-outline-primary">
                                                <span>Upload Your photo</span>
                                                <input type="file" className="logo-input" name="profile_pic" onChange={(e) => previewProfilePic(e, 'Pic')} accept="image/x-png,image/jpg,image/jpeg" />
                                            </div>
                                        </div>
                                        <p className="text-center mb-2">
                                            {!isProcess['Pic']
                                                ?
                                                    isBtnDisabled['Pic']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Pic')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                            }
                                        
                                            <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                        </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Pic'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Pic'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* short_description */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Headline</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> Maximum 15 words, that will capture who you are.</p> */}
                                        <form autoComplete="off">
                                            <b>Your Portfolio Headline:</b> Maximum 15 words, that will capture who you are.<br/><br/>
                                            <span><i>Example 1: Passionate Sustainability Champion and History Enthusiast</i></span><br/>
                                            <span><i>Example 2: Class Topper, Ace Debater and Hobby Baker</i></span><br/>
                                            <span><i>Example 3: Top Percentile in Science and Coding. Swimming Regional Champion</i></span><br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th>Who you are:</th>
                                                        <td><input className="form-control" name="position" value={formDataHeadline} onChange={(e) => updateInput('Headline', 'single_value', 'input', 'position', e.target.value)}/></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Headline']
                                                ?
                                                    isBtnDisabled['Headline']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Headline')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Headline'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Headline'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Description: short profile */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Short Profile</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> In 3 to 5 Sentences</p> */}
                                        <form autoComplete="off">
                                            <b>Your Short Profile:</b> In 3 to 5 short sentences by filling the following boxes:<br/><br/>
                                            <span><i>Example 1: <b>Your name, grade and school:</b> Jane Doe. 10th grade. Bucharest Global School.
                                            <b>Your academic and extracurricular achievements, leadership, awards, research and any internships:</b> Ace debater. Won prizes in several interschool competitions. Passionate about sustainable development. Has conducted a primary research on the topic ‘Improving Sustainable Living in Romanian cities’.
                                            <b>Your passion and future goal:</b> Working in an NGO dedicated to sustainable practices.</i></span><br/><br/>
                                            <span><i>Example 2: <b>Your name, grade and school:</b> John Doe. 8th grade. International Links School.
                                            <b>Your academic and extracurricular achievements, leadership, awards, research and any internships:</b> Top 1% percentile in school for Mathematics and Science. Selected for the first cohort of Glosco in India. Awarded the Ace Speaker and Ace Chair badges. Completed 3 months of internship in an NGO on ‘Empowering village women’.
                                            <b>Your passion and future goal:</b> Coding enthusiast with a future goal to create a successful start-up.</i></span><br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th>Your name, grade and school:</th>
                                                        <td className="w-50"><textarea className="form-control" name="short_description" value={formDataDesc.short_description} onChange={(e) => updateInput('Desc', 'object', 'textarea', 'short_description', e.target.value)}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Your academic and extracurricular achievements, leadership, awards, research and any internships:</th>
                                                        <td><textarea className="form-control" name="imp_exp" value={formDataDesc.basic_achievement} onChange={(e) => updateInput('Desc', 'object', 'textarea', 'basic_achievement', e.target.value)}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Your passion and future goal:</th>
                                                        <td><textarea className="form-control" name="imp_edu" value={formDataDesc.basic_passion} onChange={(e) => updateInput('Desc', 'object', 'textarea', 'basic_passion', e.target.value)}/></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Desc']
                                                ?
                                                    isBtnDisabled['Desc']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Desc')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                           
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Desc'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Desc'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Unique Skills */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Unique Skills</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> Complete your IFSPD Europe Competency & Career Mapping at <a href="https://admin.gosurvey.in/survey/FP" title="Unique Skills" target="_blank" rel="noreferrer">https://admin.gosurvey.in/survey/FP</a>. Once you receive your detailed report, select your top 3 passion scores.</p> */}
                                        <form autoComplete="off">
                                            <b>Your Unique Skills:</b> Complete your IFSPD Europe Competency & Career Mapping at <a href="https://admin.gosurvey.in/survey/CC" title="Unique Skills" target="_blank" rel="noreferrer">https://admin.gosurvey.in/survey/CC</a>. Once you receive your detailed report, select your top 3 passion scores. <br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <tbody>
                                                    {initialFormData.passion.map((passion_d, passion_index) => (
                                                    <tr key={`passion${passion_index}`}>
                                                        <th>Passion Score Top {passion_index + 1}</th>
                                                        <td>
                                                            <div className="w-30vw">
                                                                <Select 
                                                                    options={passionData} 
                                                                    defaultValue={formDataUnique[passion_index].passion_id}
                                                                    label={"Select Passion Score"}
                                                                    onChange={(value) => updateInput('Unique', 'array', 'select-react', 'passion_id', value, 'array', 'passion', passion_index)}
                                                                    getOptionLabel ={(option) => `${option.title}: ${option.unique_ability}`}
                                                                    getOptionValue ={(option) => option.id}
                                                                    // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                                    // className="is-valid"
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Unique']
                                                ?
                                                    isBtnDisabled['Unique']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Unique')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Unique'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Unique'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Education */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Education, Diplomas and Certifications</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> List all your educational qualifications, diplomas, short programs, certifications, along with the grades you achieved.</p> */}
                                        <form autoComplete="off">
                                            <b>Education, Diplomas and Certifications:</b> List all your educational qualifications, diplomas, short programs, certifications, along with the grades you achieved. <br/><br/>
                                            <span><i>Example 1: <b>Institution Name:</b> Yokohama High School. <b>Name of the Program:</b> A Level. <b>Key Achievement or Grade:</b> Distinction. Among top 1 percentile. <b>Date:</b> 2022.</i></span><br/><br/>
                                            <span><i>Example 2: <b>Institution Name:</b> IFSPD Europe. <b>Name of the Program:</b> Presidential Fellow. <b>Key Achievement or Grade:</b> Overall best performe    r in Cohort 10. <b>Date:</b> 2022 to 2023.</i></span><br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered table">
                                                <thead className="text-center">
                                                    <tr>
                                                        <th rowSpan={2} style={{width: "10px"}}></th>
                                                        <th rowSpan={2}>Institution Name</th>
                                                        <th rowSpan={2}>Name of the Program</th>
                                                        <th rowSpan={2}>Key Achievement or Grade, if any</th>
                                                        <th colSpan={2}>Date</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Start</th>
                                                        <th>End</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                {formDataEdu.map((edu_d, edu_index) => (
                                                    <tr key={`education${edu_index}`}>
                                                        <td><b>{edu_index+1}</b></td>
                                                        <td><input className="form-control" name={`edu_clg_${edu_index}`} value={edu_d.institute_name} onChange={(e) => updateInput('Edu', 'array', 'input', 'institute_name', e.target.value, 'array', 'education', edu_index)}/></td>
                                                        <td><input className="form-control" name={`edu_program_${edu_index}`} value={edu_d.program_name} onChange={(e) => updateInput('Edu', 'array', 'input', 'program_name', e.target.value, 'array', 'education', edu_index)}/></td>
                                                        <td><input className="form-control" name={`edu_achieve_${edu_index}`} value={edu_d.achievement} onChange={(e) => updateInput('Edu', 'array', 'input', 'achievement', e.target.value, 'array', 'education', edu_index)}/></td>
                                                        <td>
                                                            <div className="w-110p">
                                                                <Select
                                                                    options={yearData} 
                                                                    defaultValue={edu_d.start_year}
                                                                    label={"Select Year"}
                                                                    onChange={(value) => updateInput('Edu', 'array', 'select-react', 'start_year', value, 'array', 'education', edu_index)}
                                                                    getOptionLabel ={(option) => option.year}
                                                                    getOptionValue ={(option) => option.id}
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {!edu_d.is_present && (
                                                                <>
                                                                <div className="w-110p">
                                                                    <Select
                                                                        options={yearData} 
                                                                        defaultValue={edu_d.end_year}
                                                                        label={"Select Year"}
                                                                        onChange={(value) => updateInput('Edu', 'array', 'select-react', 'end_year', value, 'array', 'education', edu_index)}
                                                                        getOptionLabel ={(option) => option.year}
                                                                        getOptionValue ={(option) => option.id}
                                                                        menuPlacement="auto"
                                                                        menuPosition={'fixed'}
                                                                        styles={RSstandardStyle}
                                                                    />
                                                                </div><br/>
                                                                </>
                                                            )}
                                                            <input className="form-check-input" type="checkbox" id={`edu_present_${edu_index}`} value={edu_d.is_present} checked={edu_d.is_present} onChange={(e) => updateInput('Edu', 'array', 'checkbox', 'is_present', e.target.checked, 'array', 'education', edu_index)} />
                                                            <label className="form-check-label" htmlFor={`edu_present_${edu_index}`}>&nbsp;Present</label>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                            <p className="text-end"><button className="btn btn-outline-primary me-1" onClick={(e) => addMoreRow(e, 'education')}>Add More++</button></p>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Edu']
                                                ?
                                                    isBtnDisabled['Edu']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Edu')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Edu'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Edu'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Participation and Achievements */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;IFSPD Europe Global Sustainability Council Participation and Achievements</Accordion.Header>
                                    <Accordion.Body>
                                        <form autoComplete="off">
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Glosco Level Completed</th>
                                                        <th>Year of completion</th>
                                                        <th>Individual Awards</th>
                                                        <th>Team Awards</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {initialFormData.achievement.map((achieve_d, achieve_index) => (
                                                    <tr key={`award${achieve_index}`}>
                                                        <td><b>{achieve_d.level}</b></td>
                                                        <td className="w-110p">
                                                            <div className="w-110p">
                                                                <Select
                                                                    options={yearData} 
                                                                    defaultValue={formDataAchieve[achieve_index].year}
                                                                    label={"Select Year"}
                                                                    onChange={(value) => updateInput('Achieve', 'array', 'select-react', 'year', value, 'array', 'achievement', achieve_index)}
                                                                    getOptionLabel ={(option) => option.year}
                                                                    getOptionValue ={(option) => option.id}
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                    isDisabled={formDataAchieve[achieve_index].disabled}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="w-350p">
                                                                <Select
                                                                    options={indiAwardData} 
                                                                    defaultValue={formDataAchieve[achieve_index].indi_award}
                                                                    label={"Select Individual Award"}
                                                                    onChange={(value) => updateInput('Achieve', 'array', 'select-react', 'indi_award', value, 'array', 'achievement', achieve_index)}
                                                                    getOptionLabel ={(option) => option.award_name}
                                                                    getOptionValue ={(option) => option.id}
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                    isMulti
                                                                    isDisabled={formDataAchieve[achieve_index].disabled}
                                                                    closeMenuOnSelect={false}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="w-350p">
                                                                <Select
                                                                    options={teamAwardData} 
                                                                    defaultValue={formDataAchieve[achieve_index].team_award}
                                                                    label={"Select Team Award"}
                                                                    onChange={(value) => updateInput('Achieve', 'array', 'select-react', 'team_award', value, 'array', 'achievement', achieve_index)}
                                                                    getOptionLabel ={(option) => option.award_name}
                                                                    getOptionValue ={(option) => option.id}
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                    isMulti
                                                                    isDisabled={formDataAchieve[achieve_index].disabled}
                                                                    closeMenuOnSelect={false}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Achieve']
                                                ?
                                                    isBtnDisabled['Achieve']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Achieve')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Achieve'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Achieve'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Experience */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Work Experience, Volunteering, Clubs, Organisations, Internships, Training and Passion/Personal Projects</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> List all formal, informal, volunteering and even personal work experiences that you have.</p> */}
                                        <form autoComplete="off">
                                            <b>Work Experience, Volunteering, Clubs, Organisations, Internships, Training and Passion/Personal Projects:</b> List all formal, informal, volunteering and even personal work experiences that you have. <br/><br/>
                                            <span><i>Example 1: <b>Designation:</b> Trainee. <b>Organisation Name:</b> Local Retail Store. <b>. Key Responsibility/Achievement:</b> Enabled easier stock taking by arranging the SKUs in a specific order. <b>Date:</b> April 2023 to May 2023.</i></span><br/><br/>
                                            <span><i>Example 2: <b>Designation:</b> Intern. <b>Organisation Name:</b> Sustain Global. <b>. Key Responsibility/Achievement:</b> Collected and analysed data from 100 respondents. Top performer amongst 5 interns. <b>Date:</b> April 2024 to June 2024.</i></span><br/><br/>
                                            <span><i>Example 3: <b>Designation:</b> Volunteer. <b>Organisation Name:</b> Next Door Construction Site. <b>. Key Responsibility/Achievement:</b> Tutored 3 children of next door construction workers every weekend for 1 year. Enabled them to read basic English during this time. <b>Date:</b> May 2023 to May 2024.</i></span><br/><br/>
                                            <span><i>Example 4: <b>Designation:</b> Passion Project. <b>Passion Project Name:</b> Embroidery on old clothes. <b>Key Responsibility/Achievement:</b> Created intricate embroidery on old discarded clothes and gifted them to relatives. <b>Date:</b> June 2020 to Present.</i></span><br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered table">
                                                <thead className="text-center">
                                                    <tr>
                                                        <th rowSpan={2} style={{width: "10px"}}></th>
                                                        <th rowSpan={2}>Designation</th>
                                                        <th rowSpan={2}>Organization Name or Passion Project Name</th>
                                                        <th rowSpan={2}>1 or 2 Key Responsibilities or Achievements</th>
                                                        <th colSpan={2}>Date</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Start</th>
                                                        <th>End</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {formDataExp.map((exp_d, index) => (
                                                        <tr key={`experience_${index}`}>
                                                            <td><b>{index+1}</b></td>
                                                            <td><textarea className="form-control" name={`exp_desig_${index}`} rows={4} value={exp_d.designation} onChange={(e) => updateInput('Exp', 'array', 'textarea', 'designation', e.target.value, 'array', 'experience', index)}/></td>
                                                            <td><textarea className="form-control" name={`exp_name_${index}`} rows={4} value={exp_d.organization} onChange={(e) => updateInput('Exp', 'array','textarea', 'organization', e.target.value, 'array', 'experience', index)}/></td>
                                                            <td><textarea className="form-control" name={`exp_achiev_${index}`} rows={4} value={exp_d.achievement} onChange={(e) => updateInput('Exp', 'array','textarea', 'achievement', e.target.value, 'array', 'experience', index)}/></td>
                                                            <td>
                                                                <div>
                                                                <input type="month" className="form-control w-150p p-1" name={`exp_end_${index}`} value={exp_d.start_year} onChange={(e) => updateInput('Exp', 'array','input', 'start_year', e.target.value, 'array', 'experience', index)} max={currentMMYYYY} />
                                                                {/* <div className="w-110p">
                                                                    <Select
                                                                        options={yearData} 
                                                                        defaultValue={exp_d.start_year}
                                                                        label={"Select Year"}
                                                                        onChange={(value) => updateInput('Exp', 'array','select-react', 'start_year', value, 'array', 'experience', index)}
                                                                        getOptionLabel ={(option) => option.year}
                                                                        getOptionValue ={(option) => option.id}
                                                                        menuPlacement="auto"
                                                                        menuPosition={'fixed'}
                                                                        styles={RSstandardStyle}
                                                                    />
                                                                </div> */}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {!exp_d.is_present && (
                                                                    <>
                                                                    <input type="month" className="form-control w-150p p-1" name={`exp_start_${index}`} value={exp_d.end_year} onChange={(e) => updateInput('Exp', 'array','input', 'end_year', e.target.value, 'array', 'experience', index)} max={currentMMYYYY} />
                                                                    {/* <div className="w-110p">
                                                                        <Select
                                                                            options={yearData} 
                                                                            defaultValue={exp_d.end_year}
                                                                            label={"Select Year"}
                                                                            onChange={(value) => updateInput('Exp', 'array','select-react', 'end_year', value, 'array', 'experience', index)}
                                                                            getOptionLabel ={(option) => option.year}
                                                                            getOptionValue ={(option) => option.id}
                                                                            menuPlacement="auto"
                                                                            menuPosition={'fixed'}
                                                                            styles={RSstandardStyle}
                                                                        />
                                                                    </div> */}
                                                                    <br/>
                                                                    </>
                                                                )}
                                                                <input className="form-check-input" type="checkbox" value="" id={`exp_present_${index}`} checked={exp_d.is_present} onChange={(e) => updateInput('Exp', 'array','checkbox', 'is_present', e.target.checked, 'array', 'experience', index)}/>
                                                                <label className="form-check-label" htmlFor={`exp_present_${index}`}>&nbsp;Present</label>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <p className="text-end"><button className="btn btn-outline-primary me-1" onClick={(e) => addMoreRow(e, 'experience')}>Add More++</button></p>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Exp']
                                                ?
                                                    isBtnDisabled['Exp']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, "Exp")}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Exp'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Exp'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Papers */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Projects, Assignments, Papers, Essays, Researches, Stories, Poems, Videos, Podcasts etc</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> List all significant papers, researches, audios, videos, podcasts etc that you have published somewhere</p> */}
                                        <form autoComplete="off">
                                            <b>Projects, Assignments, Papers, Essays, Researches, Stories, Poems, Videos, Podcasts etc:</b> List all significant papers, researches, audios, videos, podcasts etc that you have published somewhere<br/><br/>
                                            <span><i>Example 1: <b>Title:</b> Crown Jewel. <b>Where was it Published:</b> School Year Book. <b>Link:</b>. <b>Any key achievement:</b> None. <b>Date:</b> 2023.</i></span><br/><br/>
                                            {/* <span><i>Example 1: <b>Title:</b> Crown Jewel. <b>Where was it Published:</b> School Year Book. <b>Link:</b> Not available. <b>Any key achievement:</b> None. <b>Date:</b> 2023.</i></span><br/><br/> */}
                                            <span><i>Example 2: <b>Title:</b> Best Practices in Sustainability. <b>Where was it Published:</b> www.medium.com <b>Link:</b> xyzz.com/xyz. <b>Any key achievement:</b> Got 100 views and 20 comments in 3 months of publication. <b>Date:</b> 2024.</i></span><br/><br/>
                                            <span><i>Example 3: <b>Title:</b> Key Fashion for Today. <b>Where was it Published:</b> Youtube. <b>Link:</b> xyzz.com/xyz. <b>Any key achievement:</b> Got 500 views and 20 comments in 3 months of release. <b>Date:</b> 2024.</i></span><br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered table">
                                                <thead>
                                                    <tr>
                                                        <th style={{width: "10px"}}></th>
                                                        <th>Project Title</th>
                                                        <th className="w-200p">Where was it published</th>
                                                        <th className="w-200p">Link</th>
                                                        <th className="w-200p">Any key achievement or accolade?</th>
                                                        <th>Year of publishing</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {formDataResearch.map((research_d, research_index) => (
                                                    <tr key={`award${research_index}`}>
                                                        <td><b>{research_index+1}</b></td>
                                                        <td><textarea className="form-control" name={`title_${research_index}`} value={research_d.title} onChange={(e) => updateInput('Research', 'array', 'input', 'title', e.target.value, 'array', 'research', research_index)} rows={4}/></td>
                                                        <td><textarea className="form-control" name={`domain_${research_index}`} value={research_d.domain} onChange={(e) => updateInput('Research', 'array', 'input', 'domain', e.target.value, 'array', 'research', research_index)} rows={4}/></td>
                                                        <td><textarea className="form-control" name={`link${research_index}`} value={research_d.link} onChange={(e) => updateInput('Research', 'array', 'input', 'link', e.target.value, 'array', 'research', research_index)} rows={4} /></td>
                                                        <td><textarea className="form-control" name={`achievement${research_index}`} value={research_d.achievement} onChange={(e) => updateInput('Research', 'array', 'input', 'achievement', e.target.value, 'array', 'research', research_index)} rows={4} /></td>
                                                        <td>
                                                            <div className="w-110p">
                                                                <Select
                                                                    options={yearData} 
                                                                    defaultValue={research_d.year}
                                                                    value={research_d.year}
                                                                    label={"Select Year"}
                                                                    onChange={(value) => updateInput('Research', 'array', 'select-react', 'year', value, 'array', 'research', research_index)}
                                                                    getOptionLabel ={(option) => option.year}
                                                                    getOptionValue ={(option) => option.id}
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <p className="text-end"><button className="btn btn-outline-primary me-1" onClick={(e) => addMoreRow(e, 'research')}>Add More++</button></p>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Research']
                                                ?
                                                    isBtnDisabled['Research']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Research')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Research'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Research'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Sports */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Sports, Games, Art, Design, Tech, Science, Humanities, and all other Competitions</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> List all of your participation and achievements in any sports and games and in any art, design, science, humanities competitions.</p> */}
                                        <form autoComplete="off">
                                            <b>Sports, Games, Art, Design, Tech, Science, Humanities, and all other Competitions:</b> List all of your participation and achievements in any sports and games and in any art, design, science, humanities competitions. <br/><br/>
                                            <span><i>Example 1: <b>Which sport:</b> Football. <b>Where did you participate:</b> School junior team. <b>Any key achievement:</b> Played as key striker. Scored 3 goals in the season. <b>Date:</b> 2020 to 2024.</i></span><br/><br/>
                                            <span><i>Example 2: <b>Which art,design:</b> Caricaturing. <b>Where did you participate:</b> School annual sports. <b>Any key achievement:</b> Amongst the top 5 across all divisions. <b>Date:</b> 2023 to 2023.</i></span><br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered table">
                                                <thead className="text-center">
                                                    <tr>
                                                        <th rowSpan={2} style={{width: "10px"}}></th>
                                                        <th rowSpan={2}>Competition Name</th>
                                                        <th rowSpan={2}>Where did you participate?</th>
                                                        <th rowSpan={2}>Any key achievement or accolade?</th>
                                                        <th colSpan={2}>Year</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Start</th>
                                                        <th>End</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {formDataSport.map((sport_d, index) => (
                                                        <tr key={`sport_${index}`}>
                                                            <td><b>{index+1}</b></td>
                                                            <td><textarea className="form-control" name={`name_${index}`} rows={4} value={sport_d.name} onChange={(e) => updateInput('Sport', 'array', 'textarea', 'name', e.target.value, 'array', 'sport', index)}/></td>
                                                            <td><textarea className="form-control" name={`venue_${index}`} rows={4} value={sport_d.venue} onChange={(e) => updateInput('Sport', 'array', 'textarea', 'venue', e.target.value, 'array', 'sport', index)}/></td>
                                                            <td><textarea className="form-control" name={`achievement_${index}`} rows={4} value={sport_d.achievement} onChange={(e) => updateInput('Sport', 'array', 'textarea', 'achievement', e.target.value, 'array', 'sport', index)}/></td>
                                                            <td>
                                                                <div>
                                                                <div className="w-110p">
                                                                    <Select
                                                                        options={yearData} 
                                                                        defaultValue={sport_d.start_year}
                                                                        label={"Select Year"}
                                                                        onChange={(value) => updateInput('Sport', 'array', 'select-react', 'start_year', value, 'array', 'sport', index)}
                                                                        getOptionLabel ={(option) => option.year}
                                                                        getOptionValue ={(option) => option.id}
                                                                        menuPlacement="auto"
                                                                        menuPosition={'fixed'}
                                                                        styles={RSstandardStyle}
                                                                    />
                                                                </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {!sport_d.is_present && (
                                                                    <>
                                                                    <div className="w-110p">
                                                                        <Select
                                                                            options={yearData} 
                                                                            defaultValue={sport_d.end_year}
                                                                            label={"Select Year"}
                                                                            onChange={(value) => updateInput('Sport', 'array', 'select-react', 'end_year', value, 'array', 'sport', index)}
                                                                            getOptionLabel ={(option) => option.year}
                                                                            getOptionValue ={(option) => option.id}
                                                                            menuPlacement="auto"
                                                                            menuPosition={'fixed'}
                                                                            styles={RSstandardStyle}
                                                                        />
                                                                    </div><br/>
                                                                    </>
                                                                )}
                                                                <input className="form-check-input" type="checkbox" value="" id={`sport_present_${index}`} checked={sport_d.is_present} onChange={(e) => updateInput('Sport', 'array', 'checkbox', 'is_present', e.target.checked, 'array', 'sport', index)}/>
                                                                <label className="form-check-label" htmlFor={`sport_present_${index}`}>&nbsp;Present</label>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <p className="text-end"><button className="btn btn-outline-primary me-1" onClick={(e) => addMoreRow(e, 'sport')}>Add More++</button></p>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Sport']
                                                ?
                                                    isBtnDisabled['Sport']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Sport')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Sport'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Sport'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Leadership */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Leadership, Honours and Awards</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> Showcase all your key and most important awards and provide all the details.</p> */}
                                        <form autoComplete="off">
                                            <b>Leadership, Honours and Awards:</b> Showcase all your key and most important awards and provide all the details.<br/><br/>
                                            <span><i>Example 1: <b>Institution Name:</b> Regional Sustainability Centre. <b>Name of the Award or Position:</b> Youth Ambassador. <b>Why was this Awarded to You:</b> For creating an awareness about sustainability and for promoting sustainability practices amongst youngsters in the region. <b>Date:</b> 2023.</i></span><br/><br/>
                                            <span><i>Example 2: <b>Institution Name:</b> Global Links School. <b>Name of the Award or Position:</b> Best Outgoing Student. <b>Why was this Awarded to You:</b> Overall best performance. <b>Date:</b> 2023.</i></span><br/><br/> 
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style={{width: "10px"}}></th>
                                                        <th>Institution Name</th>
                                                        <th>Name of the Award or Position</th>
                                                        <th>Why was this awarded to you?</th>
                                                        <th>Year</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {formDataAward.map((award_d, award_index) => (
                                                    <tr key={`award${award_index}`}>
                                                        <td><b>{award_index+1}</b></td>
                                                        <td><input className="form-control" name={`award_institute_${award_index}`} value={award_d.institute_name} onChange={(e) => updateInput('Award', 'array', 'input', 'institute_name', e.target.value, 'array', 'award', award_index)} /></td>
                                                        <td><input className="form-control" name={`award_name${award_index}`} value={award_d.award_name} onChange={(e) => updateInput('Award', 'array', 'input', 'award_name', e.target.value, 'array', 'award', award_index)} /></td>
                                                        <td><input className="form-control" name={`award_why${award_index}`} value={award_d.why_awarded} onChange={(e) => updateInput('Award', 'array', 'input', 'why_awarded', e.target.value, 'array', 'award', award_index)} /></td>
                                                        <td>
                                                            <div className="w-110p">
                                                                <Select
                                                                    options={yearData} 
                                                                    defaultValue={award_d.year}
                                                                    label={"Select Year"}
                                                                    onChange={(value) => updateInput('Award', 'array', 'select-react', 'year', value, 'array', 'award', award_index)}
                                                                    getOptionLabel ={(option) => option.year}
                                                                    getOptionValue ={(option) => option.id}
                                                                    menuPlacement="auto"
                                                                    menuPosition={'fixed'}
                                                                    styles={RSstandardStyle}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <p className="text-end"><button className="btn btn-outline-primary me-1" onClick={(e) => addMoreRow(e, 'award')}>Add More++</button></p>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Award']
                                                ?
                                                    isBtnDisabled['Award']
                                                    ?
                                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                    :
                                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Award')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Award'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Award'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* Career Skills */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Key Career Skills</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> Click and Select top three.</p> */}
                                        <form autoComplete="off">
                                            <b>Your Key Career Skills:</b> Click and Select top three.
                                            {/* <span className="highlight">To be filled only by the Counsellor after referring to Glosco Notes.</span> */}
                                            <br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="w-100">
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                // components={animatedComponents}
                                                                options={careerSkillData} 
                                                                defaultValue={formDataCareer}
                                                                value={formDataCareer}
                                                                label={"Select Key Career Skills"}
                                                                onChange={(value) => updateInput('Career', 'single_value', 'select-react', 'career_skills', value, 'multi_select')}
                                                                getOptionLabel ={(option) => option.skill_name}
                                                                getOptionValue ={(option) => option.id}
                                                                // menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                                isMulti
                                                            />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Career']
                                                ?
                                                    isBtnDisabled['Career']
                                                        ?
                                                            <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                        :
                                                            <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Career')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                            
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Career'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Career'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                             {/* Personal Skills */}
                            <Accordion className="pt-3">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Key Personal Skills</Accordion.Header>
                                    <Accordion.Body>
                                        {/* <p><b>Note:</b> Click and Select top three.</p> */}
                                        <form autoComplete="off">
                                            <b>Your Key Personal Skills:</b> Click and Select top three. 
                                            {/* <span className="highlight">To be filled only by the Counsellor after referring to Glosco Notes.</span> */}
                                            <br/><br/>
                                            <div className="table-responsive">
                                            <table className="border-0 table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="w-100">
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                // components={animatedComponents}
                                                                options={personalSkillData} 
                                                                defaultValue={formDataPersonal}
                                                                label={"Select Key Personal Skills"}
                                                                onChange={(value) => updateInput('Personal', 'single_value', 'select-react', 'personal_skills', value, 'multi_select')}
                                                                getOptionLabel ={(option) => option.skill_name}
                                                                getOptionValue ={(option) => option.id}
                                                                // menuPlacement="auto"
                                                                menuPosition={'fixed'}
                                                                styles={RSstandardStyle}
                                                                isMulti
                                                            />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                            <p className="text-center mb-2">
                                                {!isProcess['Personal']
                                                ?
                                                    isBtnDisabled['Personal']
                                                        ?
                                                            <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                                        :
                                                            <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Personal')}>Submit</button>
                                                :
                                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                                }
                                           
                                                <a href={`/port/${userUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Portfolio</button></a>
                                            </p>
                                        </form>
                                        {/* Error */}
                                        <div className="col-md-12 col-12 col-sm-12">
                                        {errorData['Personal'].length > 0 && (
                                            <div className="alert alert-danger" role="alert">
                                                <p>Please enter/modify following Data:</p>
                                                <ol>
                                                {errorData['Personal'].map((error, error_index) => (
                                                    <li key={`error_${error_index}`}>{error}</li>
                                                ))}
                                                </ol>
                                            </div>
                                        )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> 
                        </div>
                        
                    // :
                    //     <div className="col-md-10 col-12 col-sm-12 card p-3">
                    //         <div className="alert alert-success text-center mb-0">
                    //             Your details have been updated successfully.<br/>
                    //             <a href="/portfolio"><button className="btn btn-primary bg-color-blue mt-3">View Your Portfolio</button></a>
                    //         </div>
                    //     </div>
                    }
                    </div>
                </div>
            </section>
        </main>
    )    
}

export default PortfolioEditForm;