import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/about.css';

function Principle(){
    SetMetaData("Principles and Objectives", "", "");

    return (
        <div className="site hfeed grid-parent" id="page">
            <div className="site-content" id="content">

                <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                    <main className="site-main" id="main">

                        <article id="post-1504" className="post-1504 page type-page status-publish" itemType="https://schema.org/CreativeWork" itemScope="">
                            <div className="inside-article">

                                <header className="entry-header" aria-label="Content">
                                    <h1 className="entry-title title" itemProp="headline">Principles and Objectives</h1>
                                </header>


                                <div className="entry-content" itemProp="text">
                                    <div id="pl-1504" className="panel-layout">
                                        <div id="pg-1504-0" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifprinciples-page panel-row-style panel-row-style-for-1504-0">
                                                <div id="pgc-1504-0-0" className="panel-grid-cell">
                                                    <div id="panel-1504-0-0-0" className="so-panel widget widget_sow-image panel-first-child" data-index="0">
                                                        <div className="so-widget-sow-image so-widget-sow-image-default-d6014b76747a-1504">

                                                            <div className="sow-image-container">
                                                                <img src={require("../../assets/images/main/body/inner-pages-grapshic-element.jpg")} width="1920" height="231" sizes="(max-width: 1920px) 100vw, 1920px" title="inner pages grapshic element" alt="" loading="lazy" className="so-widget-image" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div id="panel-1504-0-0-1" className="so-panel widget widget_sow-editor" data-index="1">
                                                        <div className="ifhightlight panel-widget-style panel-widget-style-for-1504-0-0-1">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>In the spirit of equality and mutual respect, the <strong>IFSPD</strong> aims at promoting dialogue, mutual
                                                                        understanding, people diplomacy, values of multiculturalism, analysis of challenges and threats to regional and
                                                                        international security, contributing to international efforts in prevention and settlement of conflicts, development
                                                                        of cooperation in the sphere of democratization, justice and human rights, economy, scientific research, education,
                                                                        culture and environment, development of competence, human resource development, poverty reduction, fight against
                                                                        terrorism, organized crime, illegal drug trafficking and fight against addictions.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="panel-1504-0-0-2" className="so-panel widget widget_sow-editor panel-last-child" data-index="2">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>According to the current legal provisions and the present Charter, <em>the IFSPD’s objectives are</em>:</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-1" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-1">
                                                <div id="pgc-1504-1-0" className="panel-grid-cell">
                                                    <div id="panel-1504-1-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="3">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-1-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>1</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-1-1" className="panel-grid-cell">
                                                    <div id="panel-1504-1-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="4">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To support the effective efforts of the United Nations, the European Union, the Council of Europe, the Organization
                                                                    for Security and Cooperation in Europe, BSEC, GUAM and other influential and authoritative international and regional
                                                                    organizations which aim at ensuring peace, security, mutual understanding and economic development in the world;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-2" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-2">
                                                <div id="pgc-1504-2-0" className="panel-grid-cell">
                                                    <div id="panel-1504-2-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="5">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-2-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>2</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-2-1" className="panel-grid-cell">
                                                    <div id="panel-1504-2-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="6">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To monitor and observe the democratization process, human rights and freedoms, the process of consolidation of civil
                                                                    society, freedom of the media and expression;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-3" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-3">
                                                <div id="pgc-1504-3-0" className="panel-grid-cell">
                                                    <div id="panel-1504-3-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="7">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-3-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>3</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-3-1" className="panel-grid-cell">
                                                    <div id="panel-1504-3-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="8">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To contribute to the efforts of the international community in the fight against terrorism, drug addiction, organized
                                                                    crime, human trafficking, crimes in the cyberspace and other transnational crimes;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-4" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-4">
                                                <div id="pgc-1504-4-0" className="panel-grid-cell">
                                                    <div id="panel-1504-4-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="9">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-4-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>4</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-4-1" className="panel-grid-cell">
                                                    <div id="panel-1504-4-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="10">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To support the efforts aimed at nuclear disarmament and implementation of convention on interdiction of chemical and
                                                                    bacteriological weapons;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-5" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-5">
                                                <div id="pgc-1504-5-0" className="panel-grid-cell">
                                                    <div id="panel-1504-5-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="11">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-5-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>5</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-5-1" className="panel-grid-cell">
                                                    <div id="panel-1504-5-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="12">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To support actively the programmes aimed at restoring "The Great Silk Road" transport corridor;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-6" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-6">
                                                <div id="pgc-1504-6-0" className="panel-grid-cell">
                                                    <div id="panel-1504-6-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="13">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-6-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>6</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-6-1" className="panel-grid-cell">
                                                    <div id="panel-1504-6-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="14">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To support efforts aimed at ensuring energy security, including diversification of energy sources and routes of its
                                                                    transportation as well as researches on the alternative sources of energy development;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-7" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-7">
                                                <div id="pgc-1504-7-0" className="panel-grid-cell">
                                                    <div id="panel-1504-7-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="15">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-7-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>7</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-7-1" className="panel-grid-cell">
                                                    <div id="panel-1504-7-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="16">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To work on the improvement of conditions for business activity and to provide support for individual and collective
                                                                    initiatives of enterprises, and companies participating directly in the process of economic cooperation development;
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-8" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-8">
                                                <div id="pgc-1504-8-0" className="panel-grid-cell">
                                                    <div id="panel-1504-8-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="17">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-8-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>8</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-8-1" className="panel-grid-cell">
                                                    <div id="panel-1504-8-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="18">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To promote the initiatives and actions aimed at improving and strengthening the partnership between representatives
                                                                    of business circles from the countries represented in the Association;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-9" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-9">
                                                <div id="pgc-1504-9-0" className="panel-grid-cell">
                                                    <div id="panel-1504-9-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="19">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-9-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>9</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-9-1" className="panel-grid-cell">
                                                    <div id="panel-1504-9-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="20">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To use national and international resources in order to conduct research projects that will help to improve the
                                                                    economic, scientific, cultural and human ties;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-10" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-10">
                                                <div id="pgc-1504-10-0" className="panel-grid-cell">
                                                    <div id="panel-1504-10-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="21">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-10-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>10</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-10-1" className="panel-grid-cell">
                                                    <div id="panel-1504-10-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="22">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To encourage tolerance and understanding, mutual respect, multiculturalism and dialogue among cultures/civilizations;
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-11" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-11">
                                                <div id="pgc-1504-11-0" className="panel-grid-cell">
                                                    <div id="panel-1504-11-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="23">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-11-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>11</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-11-1" className="panel-grid-cell">
                                                    <div id="panel-1504-11-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="24">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To converge the intellectual potential and possibilities of the Association’s participants in order to implement
                                                                    programs and projects aimed at achieving peace, security and wellness of nations and citizens;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-12" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-12">
                                                <div id="pgc-1504-12-0" className="panel-grid-cell">
                                                    <div id="panel-1504-12-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="25">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-12-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>12</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-12-1" className="panel-grid-cell">
                                                    <div id="panel-1504-12-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="26">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To promote a healthy lifestyle;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-13" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-13">
                                                <div id="pgc-1504-13-0" className="panel-grid-cell">
                                                    <div id="panel-1504-13-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="27">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-13-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>13</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-13-1" className="panel-grid-cell">
                                                    <div id="panel-1504-13-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="28">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To sustain the efforts aimed at environmental protection and development of international environmental standards and
                                                                    values;</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="pg-1504-14" className="panel-grid panel-has-style container-fluid container-lg pe-lg-5 ps-lg-5">
                                            <div className="ifnumberslist panel-row-style panel-row-style-for-1504-14">
                                                <div id="pgc-1504-14-0" className="panel-grid-cell">
                                                    <div id="panel-1504-14-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="29">
                                                        <div className="bignumber panel-widget-style panel-widget-style-for-1504-14-0-0">
                                                            <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                                <div className="siteorigin-widget-tinymce textwidget">
                                                                    <p>14</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="pgc-1504-14-1" className="panel-grid-cell">
                                                    <div id="panel-1504-14-1-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="30">
                                                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                                                            <div className="siteorigin-widget-tinymce textwidget">
                                                                <p>To render consultancy and expertise services according to the legislation and provisions of the present Charter.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </article>
                    </main>
                </div>


            </div>
        </div>
    )    
}

export default Principle;