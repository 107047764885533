import React, {useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import {post_api_call, get_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
import {LoaderMedium} from './../../components/loader';
import { CharacterCount } from "../../components/form/count";
const initialFormData = {
    innovative: {
        problem: '',
        challenge: '',
        permission: '',
        observers: '',
        share: ''
    }
};
function StepFourForm(props){
    const [pageLoading, setPageLoader] = useState(true);
    //Forms
    const [formDataInnovative, setFormDataInnovative] = useState({...initialFormData.innovative});
    const [errorData, setErrorData] = useState({Innovative: []});
    const [isProcess, setIsProcess] = useState({Innovative: false});
    const [isBtnDisabled, setBtnDisabled] = useState({Innovative: true});

    useEffect(() => {
        //Get Portfolio Details
        // const fetchPortfolioData = async() => {
        //     try{
        //         const portfolioApiData = await post_api_call(`portfolio-details`, {});
        //         // const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
        //         if(portfolioApiData.status === 200){
        //             console.log(portfolioApiData, 'portfolioApiData')
        //             // if(portfolioApiData.data.headline){
        //             //     window.location.href = `/port/${portfolioApiData.data.url}`;
        //             // }else{
        //                 if(!portfolioApiData.data.headline){
        //                     setIsFirstEdit(true);
        //                 }
        //                 //GeneralDetails
        //                 const fieldKeys = ['first_name' , 'last_name', 'email', 'dob', 'language', 'mobile_number', 'logo'];
        //                 const generalFieldData = {...formDataGeneral};
        //                 fieldKeys.forEach(key => {
        //                     generalFieldData[key] = portfolioApiData.data[key] ? portfolioApiData.data[key] : '';
        //                 });
        //                 generalFieldData.country_id = portfolioApiData.data.country_id ? {id: portfolioApiData.data.country_id, name: portfolioApiData.data.country} : '';
        //                 generalFieldData.phone_code_id = portfolioApiData.data.mobile_code_id ? {id: portfolioApiData.data.mobile_code_id, phone_code: portfolioApiData.data.phone_code} : '';
        //                 generalFieldData.school_name_id = portfolioApiData.data.school_id ? {id: portfolioApiData.data.school_id, school_name: portfolioApiData.data.school_name} : '';
        //                 generalFieldData.cohort_name_id = portfolioApiData.data.cohort_id ? {id: portfolioApiData.data.cohort_id, cohort_name: portfolioApiData.data.cohort_name} : '';
        //                 setFormDataGeneral({...generalFieldData});
        //                 setUserUrl(portfolioApiData.data.user_name);
        //                 //Headline
        //                 setFormDataHeadline(portfolioApiData.data.position || '');
        //                 //Short Description
        //                 setFormDataDesc({short_description: portfolioApiData.data.headline || '',
        //                                 basic_achievement: portfolioApiData.data.achievement || '',
        //                                 basic_passion: portfolioApiData.data.passion || ''});
        //                 //Unique Skills
        //                 if(portfolioApiData.data.unique_skills.length > 0){
        //                     var uniqueData = [];
        //                     portfolioApiData.data.unique_skills.forEach(skill => {
        //                         uniqueData.push({passion_id : {id: skill.passion_id, title: `${skill.title}: ${skill.unique_ability}`}});
        //                     });
        //                     setFormDataUnique(uniqueData);
        //                 }
        //                 //Education
        //                 updateInpState('Edu', portfolioApiData.data.education_details);
        //                 //Achievements
        //                 if(portfolioApiData.data.glosco_data.length > 0){
        //                     var gloscoData = formDataAchieve;
        //                     portfolioApiData.data.glosco_data.forEach(glosco => {
        //                         gloscoData[glosco.level_id-1] = {...gloscoData[glosco.level_id-1],
        //                                                         id: glosco.level_id,
        //                                                         level: glosco.level,
        //                                                         year: {id: glosco.year, year: glosco.year},
        //                                                         indi_award: glosco.indi_award,
        //                                                         team_award: glosco.team_award};

        //                     });
        //                     setFormDataAchieve(gloscoData);
        //                 }
        //                 //Experience
        //                 updateInpState('Exp', portfolioApiData.data.experience_details);
        //                 //Research
        //                 updateInpState('Research', portfolioApiData.data.research_details);
        //                 //Sport
        //                 updateInpState('Sport', portfolioApiData.data.sport_details);
        //                 //Award
        //                 updateInpState('Award', portfolioApiData.data.award_details);
        //                 //Career Skills
        //                 if(portfolioApiData.data.carrer_skill_details.length > 0){
        //                     setFormDataCareer(portfolioApiData.data.carrer_skill_details);
        //                 }
        //                 //Personal Skills
        //                 if(portfolioApiData.data.personal_skill_details.length > 0){
        //                     setFormDataPersonal(portfolioApiData.data.personal_skill_details);
        //                 }
        //                 // setFormData({...fieldData});
                        setPageLoader(false);
        //             // }
        //         }
        //     }catch(e){
        //         console.log(e);
        //         alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
        //         error_api_call({error: `portfolio/edit|fetchPortfolioData|${e}`});
        //     }
        // }
        // fetchPortfolioData();
	}, []);
    //Update Input
    const updateInput = (identifier_key, identifier_type, input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            console.log(identifier_key, input_type, field, value, field_type, form_field, index, 'inputtt')
            if(errorData[identifier_key].length){
                setErrorData({...errorData, [identifier_key]: []});
            }
            setBtnDisabled({...isBtnDisabled, [identifier_key]: false});
            if(input_type === 'input'
                || input_type === 'textarea'
            ){
                value = value.trimStart();
            }
            var fieldData = eval('formData'+identifier_key);
            if(identifier_type === 'object'){
                fieldData = {...fieldData}
            }else if(identifier_type === 'array'){
                fieldData = [...fieldData]
            }
            console.log(fieldData, 'fieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldData')
            if(field_type === 'array'){
                console.log('fieldData[form_field][index][field] = value', form_field,',', index,',', field,',',  value)
                fieldData[index][field] = value;
            }else{
                identifier_type === 'single_value' ? fieldData = value : fieldData[field] = value;
            }
            switch (identifier_key) {
                case 'Innovative':
                    setFormDataInnovative({...fieldData});
                    break;
                default:
            }
        }catch(e){
            console.log(e);
            error_api_call({error: `social|step-four|updateInput|${e}`});
        }
    }
     //Add Fellow on Submit Click
     const editSocialFour = async(e, form_type='') => {
        try{
            e.preventDefault();
            setErrorData({...errorData, [form_type]: []});
            setIsProcess({...isProcess, [form_type]: true});
            var errorDetails = {
                Innovative: []
            };
            var apiFormData = new FormData();
            //Validation
            var validation = [];
            var validationRules = {};
            switch (form_type) {
                case 'Innovative':
                    validationRules = {
                        problem: 'required|max:250',
                        challenge: 'required|max:250',
                        share: 'required|max:250',
                        permission: 'required|max:250',
                        observers: 'required|max:250'
                    };
                    validation = ValidationHelper.validation(formDataInnovative, validationRules).error;
                    if(validation.length){
                        console.log(validation)
                        // errorDetails[form_type] = validation;
                        errorDetails[form_type] = ['Please fill all the details'];
                    }else{
                        apiFormData.append('desc_data', JSON.stringify(formDataInnovative));
                    }
                    break;
                default:
            }
            console.log(errorDetails[form_type], 'errorDetails[form_type]')
            if(errorDetails[form_type].length > 0){
                setErrorData({...errorData, [form_type]: errorDetails[form_type]});
                setIsProcess({...isProcess, [form_type]: false});
                setBtnDisabled({...isBtnDisabled, [form_type]: true});
            }else{
                //Delete Later
                props.updateFormStep({next_step: 5,
                    // social_id: socialId,
                    // url: socialUrl,
                    // url: 'demo',
                    stage_id: props.data.stage_id < 4 ? 4 : props.data.stage_id
                });
                return;
                //Send Data to Server
                apiFormData.append('update_type', form_type.toLowerCase());
                // const updateFellow = await post_api_call(`update-portfolio/${form_type.toLowerCase()}`, apiFormData);
                const updateFellow = await post_api_call(`update-portfolio`, apiFormData);
				if(updateFellow.status === 200){
                    // setIsSuccess(true);
                    alertResponseMessage({alert_type: 1, message: "Information Updated Successfully"});
                    setBtnDisabled({...isBtnDisabled, [form_type]: true});
                    // setViewDisabled({...isViewDisabled, [form_type]: false});
                    console.log("Information Updated Successfully", 'Information Updated Successfully')
                }else{
                    if(typeof updateFellow.message !== "undefined"){
                        setErrorData({...errorData, [form_type]: [updateFellow.message]});
                        console.log("Errorooo")
                    }
                }
                setIsProcess({...isProcess, [form_type]: false});
            }
            
        }catch(e){
            console.log(e);
            setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
            setIsProcess({...isProcess, [form_type]: false});
            error_api_call({error: `portfolio|edit|editSocialFour|${e}`});
        }
    }
    return (
        <>
        {pageLoading
        ?
            <div className="text-center mt-5 mb-5">
                <LoaderMedium size={45} />
            </div>
        :
            <>
            <div className="col-md-10 col-12 col-sm-12">
            <div className="text-center">
                <h2 className="mb-1 mt-4"><b>Step 4: INNOVATING & REMOVING BARRIERS</b></h2>
                <p>Think through your KPI based solution in-depth; figure out early challenges, and how you can make a differentiated and lasting impact</p>
            </div>
            <div className="card p-3">
                <ul className="mb-4 ms-3">
                    <li>Study the solutions already implemented by others. Find out their short comings and how you can overcome these.</li>
                    <li>Anticipate challenges. Plan ahead.</li>
                    <li>Find out how your social start-up can be innovative and different in making a positive impact.</li>
                </ul>
                {/* Differentiation */}
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Differentiation of your social start-up: In below 250 characters each</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        {/* Problem */}
                                        <tr>
                                            <th className="w-50">
                                                How do you think your social start-up will make a difference to the problem? Why do you think your solution is innovative and different?<br/><br/>
                                                <ul className="fst-italic fw-normal li-sty-none ms-0">
                                                    <li><b>Example 1:</b> Kids of construction workers are not able to attend school because it’s too far away. Our social start-up will go to these sites and teach these kids, so that they don’t need to travel anywhere.</li>
                                                    <li><b>Example 2:</b> Currently municipal authorities just clear the garbage. Our social start-up will recycle all the dry waste.</li>
                                                </ul>
                                            </th>
                                            <td>
                                                <textarea className="form-control" name="problem" autoComplete="off" value={formDataInnovative.problem} onChange={(e) => updateInput('Innovative', 'object', 'input', 'problem', e.target.value)} rows={5}/>
                                                <CharacterCount
                                                    max={250}
                                                    string={formDataInnovative.problem}
                                                />
                                            </td>
                                        </tr>
                                        {/* challenges */}
                                        <tr>
                                            <th>
                                                What difficulties or challenges do you anticipate when rolling out your social start-up?<br/><br/>
                                                <ul className="fst-italic fw-normal li-sty-none ms-0">
                                                    <li><b>Example 1:</b> Since mental health awareness is not high, it would be difficult to convince people to attend.</li>
                                                    <li><b>Example 2:</b> Need to ensure that the disaster relief fund that is collected is reaching the right partners.</li>
                                                </ul>
                                            </th>
                                            <td>
                                                <textarea className="form-control" name="challenge" autoComplete="off" value={formDataInnovative.challenge} onChange={(e) => updateInput('Innovative', 'object', 'input', 'challenge', e.target.value)} rows={5}/>
                                                <CharacterCount
                                                    max={250}
                                                    string={formDataInnovative.challenge}
                                                />
                                            </td>
                                        </tr>
                                        {/* require */}
                                        <tr>
                                            <th>
                                                What permissions, resources, assistance, equipment etc. do you require before rolling out your social start-up? How will you obtain those?<br/><br/>
                                                <ul className="fst-italic fw-normal li-sty-none ms-0">
                                                    <li><b>Example 1:</b> Saplings in the case of tree planting, text books in the case of literacy drives, laptop and presentation in the case of STEM outreach, posters or flyers in the case of awareness drives.</li>
                                                    <li><b>Example 2:</b> Permission from local authorities, if you are taking out an awareness march, permission from the old age home, if you are visiting them, elders’ support if you are conducting a disaster relief. </li>
                                                </ul>
                                            </th>
                                            <td>
                                                <textarea className="form-control" name="permission" autoComplete="off" value={formDataInnovative.permission} onChange={(e) => updateInput('Innovative', 'object', 'input', 'permission', e.target.value)} rows={5}/>
                                                <CharacterCount
                                                    max={250}
                                                    string={formDataInnovative.permission}
                                                />
                                            </td>
                                        </tr>
                                        {/* observers */}
                                        <tr>
                                            <th>
                                                In order to gain credibility for your social start-up, you can invite some observers, people who are respectable in the society. Please specify, whom are you planning to invite as observers. If you are not planning to invite anyone, specify that.<br/><br/>
                                                <ul className="fst-italic fw-normal li-sty-none ms-0">
                                                    <li><b>Example:</b> Your teachers, local authorities, or media people or some influencers.</li>
                                                </ul>
                                            </th>
                                            <td>
                                                <textarea className="form-control" name="observers" autoComplete="off" value={formDataInnovative.observers} onChange={(e) => updateInput('Innovative', 'object', 'input', 'observers', e.target.value)} rows={5}/>
                                                <CharacterCount
                                                    max={250}
                                                    string={formDataInnovative.observers}
                                                />
                                            </td>
                                        </tr>
                                        {/* achievements */}
                                        <tr>
                                            <th>
                                                How will you popularize and share your achievements? <br/><br/>
                                                <ul className="fst-italic fw-normal li-sty-none ms-0">
                                                    <li><b>Example 1:</b> Posting on social media.</li>
                                                    <li><b>Example 2:</b> Inviting local media people to cover the social start-up.</li>
                                                    <li><b>Example 3:</b> Writing a report and publishing it online.</li>
                                                </ul>
                                            </th>
                                            <td>
                                                <textarea className="form-control" name="share" autoComplete="off" value={formDataInnovative.share} onChange={(e) => updateInput('Innovative', 'object', 'input', 'share', e.target.value)} rows={5}/>
                                                <CharacterCount
                                                    max={250}
                                                    string={formDataInnovative.share}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['Innovative']
                                ?
                                    isBtnDisabled['Innovative']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialFour(e, 'Innovative')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                    <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['Innovative'].length > 0 && (
                                <div className="alert alert-danger mt-4" role="alert">
                                    {/* <p>Please enter/modify following Data:</p> */}
                                    <ol className="li-sty-none ms-0">
                                    {errorData['Innovative'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            </div>
            </>
        }
        </>
    )    
}

export default StepFourForm;