import React, {useState, useEffect } from "react";
import { useDispatch} from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import { RSstandardStyle } from "../../config/react-select";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {post_api_call, get_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
import {update_user_info} from './../../actions/action-creators/state-update';
import picIcon from "./../../assets/images/user.png";
import {LoaderMedium} from './../../components/loader';
import { CharacterCount } from "../../components/form/count";
const initialFormData = {
    general: {
        first_name: '',
        last_name: '',
        title: 'Climate Warriors',
        location: 'Lake Town',
        state_id: {id: 36, state: 'West Bengal'},
        is_team: {id: 1, type: 'Individual'},
        logo: '',
        url: ''
    },
    team: [
        {name: '', institute: 'Test', class: {id: 1, grade_name: 'Garde'}, email: '', mobile : '', is_name_read: true, is_mob_read: true, is_email_read: true},
        {name: '', institute: '', class: '', email: '', mobile : '', is_name_read: false, is_mob_read: false, is_email_read: false},
        {name: '', institute: '', class: '', email: '', mobile : '', is_name_read: false, is_mob_read: false, is_email_read: false},
    ],
   
};
function StepOneForm(props){
    const cropperRef = React.createRef(null);
    const [pageLoading, setPageLoader] = useState(true);
    const [stateData, setStateData] = useState([]);
    const [gradeData, setGradeData] = useState([]);
    const [socialId, setSocialId] = useState(0);
    const [socialUrl, setSocialUrl] = useState("");
    //Forms
    const [formDataGeneral, setFormDataGeneral] = useState({...initialFormData.general});
    const [formDataTeam, setFormDataTeam] = useState(initialFormData.team);
    //Pic
    const [previewPicImg, setPreviewPicImg] = useState(null);
    const [croppedImg, setCroppedImg] = useState(null);
    const [previewPicBlob, setPreviewPicBlob] = useState(null);
    // const cropperRef = useRef<ReactCropperElement>(null);
    // const [formDataPic, setFormDataPic] = useState(null);
    const [errorData, setErrorData] = useState({General: [],
                                                Team: []
                                            });
    const [isProcess, setIsProcess] = useState({General: false,
                                                Team: false
                                            });
    const [isBtnDisabled, setBtnDisabled] = useState({General: true,
                                                Team: true
                                            });
    const dispatch = useDispatch();
    //Upload Profile Image Preview
    const previewProfilePic = (event) => {
        try{
            const files = event.target.files;
            if(files.length > 0){
                const documetAllowedExt = ["png", "jpg", "jpeg"];
                const allowedSize = 200000;
                const imgFile = files[0];
            // for(let i=0; i<files.length; i++){
                var ext = imgFile.name.slice((imgFile.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
                //Check for file size and extension
                console.log('imgFile.size', imgFile.size)
                if(imgFile.size <= allowedSize && documetAllowedExt.includes(ext)){
                    const imgData = {data: imgFile,
                                    src: URL.createObjectURL(imgFile),
                                    // title: '',
                                    // is_image: imageExt.includes(imgFile.type.toLowerCase()) ? true : false,
                                    ext
                                };
                    setPreviewPicImg({...imgData});
                    setBtnDisabled({...isBtnDisabled, Pic: false});
                }else{
                    setPreviewPicImg(null);
                    alertResponseMessage({alert_type: 2, message: "Photograph allowed size is 200KB and it must be an image."});
                }
            }
        }catch(e){
            setPreviewPicImg(null);
            error_api_call({error: `social|step-one|previewProfilePic|${e}`});
        }
    }
    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        setCroppedImg(cropper.getCroppedCanvas().toDataURL());
        // console.log(cropper.getCroppedCanvas().toDataURL());
        cropper.getCroppedCanvas().toBlob((blob) => {
            console.log(blob, 'blobbb');
            setPreviewPicBlob(blob);
        });
    };
    //Add More Row
    const addMoreRow = async(e, field) => {
        try{
            e.preventDefault();
            const defaultVal = {
                team: [{name: '', institute: '', class: '', email: '', mobile : '', is_name_read: false, is_mob_read: false, is_email_read: false}],
            };
            switch (field) {
                case 'team':
                    if(formDataTeam.length < 5){
                        const data = formDataTeam.concat(defaultVal[field]);
                        setFormDataTeam(data);
                    }
                    break;
                default: 
            }
        }catch(e){
			error_api_call({error: `social|step-one|addMoreRow|${e}`});
        }
    }
    //Modify Input
    const updateInpState = (identifier_key, data) => {
        try{
            switch (identifier_key) {
                case 'Team':
                    if(data.length > 0){
                        var teamData = [];
                        data.forEach(team => {
                            teamData.push({institute: team.institute_name || '',
                                            name: team.name || '',
                                            class : {id: team.class_id, grade_name: team.grade_name},
                                            mobile: team.mobile,
                                            email: team.email,
                                            id: team.ss_team_id
                                        });
                        });
                        setFormDataTeam(teamData);
                    }
                    break;
                default: 
            }
        }catch(e){
            console.log(e);
        }
    }
    //Get Data from APIs
    const fetchSelectList = async(type, url) => {
        try{
            const selectApiData = await get_api_call(url, {});
            if(selectApiData.status === 200){
                switch (type) {
                    case 'state':
                        setStateData(selectApiData.data);
                        break;
                    case 'grade':
                        setGradeData(selectApiData.data);
                        break;
                    default: 
                }
            }
        }catch(e){
			error_api_call({error: `social|step-one|fetchSelectList|${e}`});
        }
    }
    useEffect(() => {
        //Get Social Details
        const fetchSocialData = async(form_type, ref_id) => {
            try{
                if(form_type === 'edit'){
                    // const portfolioApiData = await post_api_call(`portfolio-details`, {});
                // // const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
                // if(portfolioApiData.status === 200){
                //     console.log(portfolioApiData, 'portfolioApiData')
                //     // if(portfolioApiData.data.headline){
                //     //     window.location.href = `/port/${portfolioApiData.data.url}`;
                //     // }else{
                //         if(!portfolioApiData.data.headline){
                //             setIsFirstEdit(true);
                //         }
                //         //GeneralDetails
                //         const fieldKeys = ['first_name' , 'last_name', 'email', 'dob', 'language', 'mobile_number', 'logo'];
                //         const generalFieldData = {...formDataGeneral};
                //         fieldKeys.forEach(key => {
                //             generalFieldData[key] = portfolioApiData.data[key] ? portfolioApiData.data[key] : '';
                //         });
                //         generalFieldData.country_id = portfolioApiData.data.country_id ? {id: portfolioApiData.data.country_id, name: portfolioApiData.data.country} : '';
                //         generalFieldData.phone_code_id = portfolioApiData.data.mobile_code_id ? {id: portfolioApiData.data.mobile_code_id, phone_code: portfolioApiData.data.phone_code} : '';
                //         generalFieldData.school_name_id = portfolioApiData.data.school_id ? {id: portfolioApiData.data.school_id, school_name: portfolioApiData.data.school_name} : '';
                //         generalFieldData.cohort_name_id = portfolioApiData.data.cohort_id ? {id: portfolioApiData.data.cohort_id, cohort_name: portfolioApiData.data.cohort_name} : '';
                //         setFormDataGeneral({...generalFieldData});
                //         setUserUrl(portfolioApiData.data.user_name);
                //         //Headline
                //         setFormDataHeadline(portfolioApiData.data.position || '');
                //         //Short Description
                //         setFormDataDesc({short_description: portfolioApiData.data.headline || '',
                //                         basic_achievement: portfolioApiData.data.achievement || '',
                //                         basic_passion: portfolioApiData.data.passion || ''});
                //         //Unique Skills
                //         if(portfolioApiData.data.unique_skills.length > 0){
                //             var uniqueData = [];
                //             portfolioApiData.data.unique_skills.forEach(skill => {
                //                 uniqueData.push({passion_id : {id: skill.passion_id, title: `${skill.title}: ${skill.unique_ability}`}});
                //             });
                //             setFormDataUnique(uniqueData);
                //         }
                //         //Education
                //         updateInpState('Edu', portfolioApiData.data.education_details);
                //         //Achievements
                //         if(portfolioApiData.data.glosco_data.length > 0){
                //             var gloscoData = formDataAchieve;
                //             portfolioApiData.data.glosco_data.forEach(glosco => {
                //                 gloscoData[glosco.level_id-1] = {...gloscoData[glosco.level_id-1],
                //                                                 id: glosco.level_id,
                //                                                 level: glosco.level,
                //                                                 year: {id: glosco.year, year: glosco.year},
                //                                                 indi_award: glosco.indi_award,
                //                                                 team_award: glosco.team_award};

                //             });
                //             setFormDataAchieve(gloscoData);
                //         }
                //         //Experience
                //         updateInpState('Exp', portfolioApiData.data.experience_details);
                //         //Research
                //         updateInpState('Research', portfolioApiData.data.research_details);
                //         //Sport
                //         updateInpState('Sport', portfolioApiData.data.sport_details);
                //         //Award
                //         updateInpState('Award', portfolioApiData.data.award_details);
                //         //Career Skills
                //         if(portfolioApiData.data.carrer_skill_details.length > 0){
                //             setFormDataCareer(portfolioApiData.data.carrer_skill_details);
                //         }
                //         //Personal Skills
                //         if(portfolioApiData.data.personal_skill_details.length > 0){
                //             setFormDataPersonal(portfolioApiData.data.personal_skill_details);
                //         }
                //         // setFormData({...fieldData});
                        setSocialId(ref_id);
                        setPageLoader(false);
                //     // }
                // }
                }else{
                    const userApiData = await get_api_call(`user/basic`, {type: "social"});
                    if(userApiData.status === 200){
                        setFormDataGeneral({...formDataGeneral,
                                    first_name: userApiData.data.first_name,
                                    last_name: userApiData.data.last_name ? userApiData.data.last_name : '',
                                    logo: userApiData.data.logo,
                                });
                        const teamData = formDataTeam;
                        teamData[0].name = `${userApiData.data.first_name} ${userApiData.data.last_name}`;
                        teamData[0].email = userApiData.data.email;
                        teamData[0].mobile = userApiData.data.mobile_number ? userApiData.data.mobile_number : '';
                        setFormDataTeam([...teamData]);
                    }
                    setPageLoader(false);
                }
                
            }catch(e){
                console.log(e);
                alertResponseMessage({alert_type: 2, message: "Error Loading Details! Check back after some times"});
                error_api_call({error: `social/step-one|fetchSocialData|${e}`});
            }
        }
        //Check if edit or create
        if(props.data.ref_id === 0){
            //Create
            fetchSocialData('create');
        }else{
            //Edit
            fetchSocialData('edit', props.data.ref_id);
        }
        fetchSelectList('state', 'states');
        fetchSelectList('grade', 'grades');
	}, []);
    //Update Input
    const updateInput = (identifier_key, identifier_type, input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            console.log(identifier_key, input_type, field, value, field_type, form_field, index, 'inputtt')
            if(socialId === 0){
                if(errorData[identifier_key].length){
                    setErrorData({...errorData, General: []});
                }
                setBtnDisabled({...isBtnDisabled, General: false});
            }else{
                if(errorData[identifier_key].length){
                    setErrorData({...errorData, [identifier_key]: []});
                }
                setBtnDisabled({...isBtnDisabled, [identifier_key]: false});
            }

            if(field === 'mobile_number'
                || field === 'mobile'
            ){
                if(value.length < 16){
                    value = value.replace(/[^0-9]/g, '');
                }else{
                    return false;
                }
            }else if(input_type === 'input'
                || input_type === 'textarea'
            ){
                // value = value.replace(/ /g, '');
                value = value.trimStart();
                // value = value.replace(/  /g, '');
                if(field === 'first_name'
                    || field === 'last_name'
                ){
                    value = value.replace(/[^a-zA-Z. ]/g, '')
                      .replace('  ', ' ');
                }
            }
            var fieldData = eval('formData'+identifier_key);
            if(identifier_type === 'object'){
                fieldData = {...fieldData}
            }else if(identifier_type === 'array'){
                fieldData = [...fieldData]
            }
            if(field_type === 'array'){
                console.log('fieldData[form_field][index][field] = value', form_field,',', index,',', field,',',  value)
                fieldData[index][field] = value;
            }else{
                identifier_type === 'single_value' ? fieldData = value : fieldData[field] = value;
            }
            switch (identifier_key) {
                case 'General':
                    setFormDataGeneral({...fieldData});
                    break;
                case 'Team':
                    setFormDataTeam([...fieldData]);
                    break;
                default:
            }
        }catch(e){
            console.log(e);
            error_api_call({error: `social|step-one|updateInput|${e}`});
        }
    }
    //Team Validation
    const teamFormValidation = () => {
        try{
            var error = [];
            var teamFrDetails = [];
            formDataTeam.forEach((team, team_indx) => {
                var teamDetails = team;
                if(teamDetails.name
                    || teamDetails.institute
                    || teamDetails.email
                    // || teamDetails.mobile
                ){
                    const ValidationRules = {
                        name: `required|max:150||Team details... ${team_indx + 1}: Name`,
                        institute: `required|max:150||Team details... ${team_indx + 1}: Education institution`,
                        class: `required|max:250||Team details... ${team_indx + 1}: Grade/Class`,
                        // email: `required|email|team details... ${team_indx + 1}: Email address`,
                        // mobile: `required||team details... ${team_indx + 1}: Mobile`,
                        // [`team_details..._${team_indx + 1}mobile`]: 'required|digit:|min:6|max:15',
                        [`team_details..._${team_indx + 1}_email_address`]: `required|email|team details... ${team_indx + 1}: Email address`,
                    };
                    teamDetails[`team_details..._${team_indx + 1}_email_address`] = teamDetails.email;
                    teamDetails[`team_details..._${team_indx + 1}_mobile`] = teamDetails.mobile;
                    const validationTeam = ValidationHelper.validation(teamDetails, ValidationRules).error;
                    console.log(validationTeam, 'validationTeamvalidationTeamvalidationTeamvalidationTeam')
                    if(validationTeam.length){
                        error = error.concat(validationTeam);
                    }else{
                        var ssId = typeof teamDetails.id !== "undefined" ? teamDetails.id : '';
                        teamFrDetails.push({
                            id: ssId,
                            institute: teamDetails.institute,
                            name: teamDetails.name,
                            email: teamDetails.email,
                            class: teamDetails.class.id,
                            mobile: teamDetails.mobile
                        });
                    }
                }else if(typeof teamDetails.id !== "undefined"){//To delete from database
                    teamFrDetails.push({
                        id: teamDetails.id,
                        institute: teamDetails.institute,
                        name: teamDetails.name,
                        email: teamDetails.email,
                        class: teamDetails.class.id,
                        mobile: teamDetails.mobile
                    });
                }
            });
            return {
                error,
                data: teamFrDetails
            };
        }catch(e){
            return {
                error: ['Internal Error'],
                data: []
            };
        }
    }
    //Add-Edit Social Form on Submit Click
    const editSocialOne = async(e, form_type='') => {
        try{
            e.preventDefault();
            setErrorData({...errorData, [form_type]: []});
            setIsProcess({...isProcess, [form_type]: true});
            var errorDetails = {
                General: [],
                Team: []
            };
            var apiFormData = new FormData();
            //Validation
            var validation = [];
            var validationRules = {};
            switch (form_type) {
                case 'General':
                    validationRules = {
                        first_name: 'required|max:100',
                        last_name: 'optional|max:100',
                        // title: 'required|max:70|Social Start-up name with a motto',
                        social_start_up_name: 'required|max:70',
                        location: 'required|max:250',
                        state_id: 'required||State',
                        is_team: 'required||Is this a team social start-up',
                        // email: 'required|email|max:150',
                        // phone_code_id: 'required||Mobile Country Code',
                        // mobile_number: 'required|digit:|min:6|max:15',
                    };
                    const fieldFormDataGeneral = {...formDataGeneral,
                        state_id: formDataGeneral.state_id.id,
                        is_team: formDataGeneral.is_team.id,
                        social_start_up_name: formDataGeneral.title,
                    };
                    var teamValidation = [];
                    if(socialId === 0){
                        teamValidation = teamFormValidation();
                        console.log(teamValidation, 'teamValidationteamValidationteamValidation')
                    }
                    validation = ValidationHelper.validation(fieldFormDataGeneral, validationRules).error;
                    if(validation.length
                        || teamValidation.error.length
                    ){
                        // errorDetails[form_type] = validation;
                        // validation.concat(teamValidation.error);
                        // errorDetails[form_type].concat(validation);
                        errorDetails[form_type] = [...validation,...teamValidation.error];
                    }else{
                        // apiFormData.append('general_data', fieldFormDataGeneral);
                        const basicDataKeys = [
                            'first_name',
                            'last_name',
                            'state_id',
                            'title',
                            'location',
                            'is_team'
                        ];
                        for (var key in basicDataKeys) {
                            if (fieldFormDataGeneral.hasOwnProperty(basicDataKeys[key])) {
                                apiFormData.append(`general_data[${basicDataKeys[key]}]`, fieldFormDataGeneral[basicDataKeys[key]]);
                            }
                        }
                        //Pic
                        if(previewPicImg !== null){
                            apiFormData.append('profile_pic', previewPicBlob, 'Profile Pic');
                        }
                        //Team Details
                        if(socialId === 0){
                            teamValidation.data.forEach((teamDetails, team_indx) => {
                                apiFormData.append(`team[${team_indx}][id]`, teamDetails.id);
                                apiFormData.append(`team[${team_indx}][institute]`, teamDetails.institute);
                                apiFormData.append(`team[${team_indx}][name]`, teamDetails.name);
                                apiFormData.append(`team[${team_indx}][email]`, teamDetails.email);
                                apiFormData.append(`team[${team_indx}][class]`, teamDetails.class.id);
                                apiFormData.append(`team[${team_indx}][mobile]`, teamDetails.mobile);
                            });
                        }
                    }
                    break;
                case 'Team':
                    const temValidation = teamFormValidation();
                    if(temValidation.error.length){
                        errorDetails[form_type].concat(temValidation.error);
                    }else{
                        teamValidation.data.forEach((teamDetails, team_indx) => {
                            apiFormData.append(`team[${team_indx}][id]`, teamDetails.id);
                            apiFormData.append(`team[${team_indx}][institute]`, teamDetails.institute);
                            apiFormData.append(`team[${team_indx}][name]`, teamDetails.name);
                            apiFormData.append(`team[${team_indx}][email]`, teamDetails.email);
                            apiFormData.append(`team[${team_indx}][class]`, teamDetails.class.id);
                            apiFormData.append(`team[${team_indx}][mobile]`, teamDetails.mobile);
                        });
                    }
                    break;
                default:
            }
            console.log(errorDetails[form_type], 'errorDetails[form_type]')
            if(errorDetails[form_type].length > 0){
                setErrorData({...errorData, [form_type]: errorDetails[form_type]});
                setIsProcess({...isProcess, [form_type]: false});
                setBtnDisabled({...isBtnDisabled, [form_type]: true});
            }else{
                //Send Data to Server
                apiFormData.append('update_type', form_type.toLowerCase());
                apiFormData.append('social_id', socialId);
                //Delete Later
                props.updateFormStep({next_step: 2,
                                    social_id: socialId,
                                    // url: socialUrl,
                                    url: 'demo',
                                    stage_id: props.data.stage_id < 1 ? 1 : props.data.stage_id
                                });
                console.log(socialId, 'socialIdsocialIdsocialIdsocialId')
                return;
                const updateFellow = await post_api_call(`ss/update`, apiFormData);
				if(updateFellow.status === 200){
                    // setSocialUrl
                    // setIsSuccess(true);
                    if(form_type === 'General'){
                        // Dispatch
                        dispatch(update_user_info(updateFellow.data, async cb_data => {
                            console.log('cb_data', cb_data)
                        }));
                    }else if(form_type === 'Team'){
                        updateInpState(form_type, updateFellow.data);
                    }
                    alertResponseMessage({alert_type: 1, message: "Information Updated Successfully"});
                    setBtnDisabled({...isBtnDisabled, [form_type]: true});
                    console.log("Information Updated Successfully", 'Information Updated Successfully')
                }else{
                    if(typeof updateFellow.message !== "undefined"){
                        setErrorData({...errorData, [form_type]: [updateFellow.message]});
                        console.log("Errorooo")
                    }
                }
                setIsProcess({...isProcess, [form_type]: false});
            }
            
        }catch(e){
            console.log(e);
            setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
            setIsProcess({...isProcess, [form_type]: false});
            error_api_call({error: `social|edit|editSocialOne|${e}`});
        }
    }
    return (
        <>
        {pageLoading
        ?
            <div className="text-center mt-5 mb-5">
                <LoaderMedium size={45} />
            </div>
        :
            <>
            <div className="text-center">
                <h2 className="mb-1 mt-4"><b>Step 1: TEAM FORMATION</b></h2>
                <p>Learn important lessons on team formation and leadership</p>
            </div>
            <div className="col-md-10 col-12 col-sm-12 card p-3">
                <ul className="mb-4 ms-3">
                    <li>Social start-up can be started by an individual or by a team. Maximum team size 5 members, including the leader.</li>  
                    <li>If you have a team, then you need to meet often. So, select your team members from your class or school.</li>
                    <li>If you have a team, then select a team leader. Select one person, whom team members think can lead all the efforts and update all the details.</li>
                    <li>Updating of all details and reporting, the responsibility of the leader.</li>
                </ul>
                {/* General Details */}
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Basic details of your social start-up</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        {/* Name */}
                                        <tr>
                                            <th>Your full name:<br/><span className="fw-normal"><i>This person would lead the social start-up</i></span></th>
                                            <td><input className="form-control" placeholder="First Name" name="first_name" autoComplete="off" value={formDataGeneral.first_name} onChange={(e) => updateInput('General', 'object', 'input', 'first_name', e.target.value)}/></td>
                                            <td><input className="form-control" placeholder="Last Name" name="last_name" autoComplete="off" value={formDataGeneral.last_name} onChange={(e) => updateInput('General', 'object', 'input', 'last_name', e.target.value)}/></td>
                                        </tr>
                                        {/* Photo */}
                                        <tr>
                                            <th>Upload your Photograph:<br/><span className="fw-normal"><i>Passport size, with your face making 70-80% of the photo against a plain or white background. Max 200 KB</i></span></th>
                                            <td>
                                                {previewPicImg !== null &&
                                                    <>
                                                    <div className="d-flex">
                                                    <Cropper
                                                        src={previewPicImg.src}
                                                        style={{ height: 200, width: 200 }}
                                                        // Cropper.js options
                                                        initialAspectRatio={1 / 1}
                                                        aspectRatio={1/1}
                                                        guides={false}
                                                        crop={onCrop}
                                                        ref={cropperRef}
                                                        dragMode="move"
                                                        // cropBoxResizable={false}
                                                        background={false}
                                                        // minContainerWidth={"100%"}
                                                        minContainerWidth={200}
                                                        zoomable={false}
                                                        highlight={false}
                                                    />
                                                    {/* <div className="ms-4">
                                                        <img src={croppedImg} className="img-thumbnail cropper-preview-img" alt={formDataGeneral.first_name} />
                                                    </div> */}
                                                    </div>
                                                    </>
                                                }
                                                <div className="upload-img text-center">
                                                    <div className="m-3">
                                                        {previewPicImg !== null
                                                            ?
                                                            <></>
                                                            :
                                                            formDataGeneral.logo
                                                            ?
                                                                <img src={formDataGeneral.logo} alt={formDataGeneral.first_name} className="img-thumbnail" />
                                                            :
                                                                <img src={picIcon} alt={formDataGeneral.first_name} className="img-thumbnail" />
                                                        }
                                                    </div>
                                                    
                                                    <div className="btn btn-light btn-outline-primary">
                                                        <span className="fs-12p fw-bold">Upload Your photo</span>
                                                        <input type="file" className="logo-input" name="profile_pic" onChange={(e) => previewProfilePic(e, 'Pic')} accept="image/x-png,image/jpg,image/jpeg" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="w-200p">
                                            {previewPicImg !== null &&
                                                <>
                                                <div className="d-flex">
                                                    <img src={croppedImg} className="img-thumbnail cropper-preview-img" alt={formDataGeneral.first_name} style={{height: 200, width: "200px !important"}} />
                                                </div>
                                                </>
                                            }
                                            </td>
                                        </tr>
                                        {/* Topic */}
                                        <tr>
                                            <th>
                                                Your social start-up name with a motto in 70 characters or less:<br/><br/>
                                                <ul className="fst-italic fw-normal li-sty-none ms-0">
                                                    <li><b>Example 1:</b>  Clean India. Healthy India.</li>
                                                    <li><b>Example 2:</b>  Climate Warriors</li>
                                                    <li><b>Example 3:</b>  Social Champions for Landslide Victims</li>
                                                </ul>
                                            </th>
                                            <td colSpan={2}>
                                                <textarea className="form-control" placeholder="Social Start-up Name" name="title" autoComplete="off" value={formDataGeneral.title} onChange={(e) => updateInput('General', 'object', 'input', 'title', e.target.value)} rows={4}/>
                                                <CharacterCount
                                                    max={70}
                                                    string={formDataGeneral.title}
                                                />
                                            </td>
                                        </tr>
                                        {/* Location */}
                                        <tr>
                                            <th>Location:</th>
                                            <td colSpan={2}><textarea className="form-control" placeholder="Location" name="location" autoComplete="off" value={formDataGeneral.location} onChange={(e) => updateInput('General', 'object', 'input', 'location', e.target.value)} rows={3}/></td>
                                        </tr>
                                        {/* State */}
                                        <tr>
                                            <th>State:</th>
                                            <td colSpan={2}>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={stateData} 
                                                    defaultValue={formDataGeneral.state_id}
                                                    label={"Select State"}
                                                    onChange={(value) => updateInput('General', 'object', 'select-react', 'state_id', value)}
                                                    getOptionLabel ={(option) => option.state}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                    // menuPortalTarget={document.querySelector('body')}
                                                    // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                    // className="is-valid"
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        {/* Start Up Team Type */}
                                        <tr>
                                            <th>Is this a team social start-up or individual social start-up?:</th>
                                            <td colSpan={2}>
                                                <div className="w-200p">
                                                <Select
                                                    // isDisabled={true}
                                                    options={[{id: 1, type: 'Individual'}, {id: 2, type: 'Team'}]} 
                                                    defaultValue={formDataGeneral.is_team}
                                                    label={"Select Type"}
                                                    onChange={(value) => updateInput('General', 'object', 'select-react', 'is_team', value)}
                                                    getOptionLabel ={(option) => option.type}
                                                    getOptionValue ={(option) => option.id}
                                                    styles={RSstandardStyle}
                                                    menuPlacement="auto"
                                                    menuPosition={'fixed'}
                                                    // menuPortalTarget={document.querySelector('body')}
                                                    // styles={validFields[field.name] !== "" ? (validFields[field.name] ? multiSelectValidStyle : multiSelectInValidStyle) : ""}
                                                    // className="is-valid"
                                                />
                                                </div>
                                            </td>
                                        </tr>
                                        {socialId === 0 && (
                                            <tr>
                                                <th colSpan={3}>Your social start-up team details (if you are alone, enter your details here):
                                                    <div className="table-responsive mt-3">
                                                        <table className="border-0 table-bordered table">
                                                            <thead className="text-center">
                                                                <tr>
                                                                    <th style={{width: "10px"}}></th>
                                                                    <th>Team member names<br/><span className="fw-normal">(max 5 members)</span> </th>
                                                                    <th>Education institution</th>
                                                                    <th>Grade/Class</th>
                                                                    <th>Email address</th>
                                                                    <th>Mobile</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="">
                                                            {formDataTeam.map((team_d, team_index) => (    
                                                                <tr key={`team${team_index}`}>
                                                                    <td><b>{team_index+1}</b></td>
                                                                    <td><textarea className="form-control" name={`team_name_${team_index}`} value={team_d.is_name_read === true ? `${formDataGeneral.first_name} ${formDataGeneral.last_name}` : team_d.name} onChange={(e) => updateInput('Team', 'array', 'input', 'name', e.target.value, 'array', 'team', team_index)} rows={3} readOnly={team_d.is_name_read}/></td>
                                                                    <td><textarea className="form-control" name={`edu_institue_${team_index}`} value={team_d.institute} onChange={(e) => updateInput('Team', 'array', 'input', 'institute', e.target.value, 'array', 'team', team_index)} rows={3}/></td>
                                                                    <td>
                                                                        <div className="w-110p">
                                                                            <Select
                                                                                options={gradeData} 
                                                                                defaultValue={team_d.class}
                                                                                label={"Select Grade"}
                                                                                onChange={(value) => updateInput('Team', 'array', 'select-react', 'class', value, 'array', 'team', team_index)}
                                                                                getOptionLabel ={(option) => option.grade_name}
                                                                                getOptionValue ={(option) => option.id}
                                                                                menuPlacement="auto"
                                                                                menuPosition={'fixed'}
                                                                                styles={RSstandardStyle}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td><textarea className="form-control" name={`team_email_${team_index}`} value={team_d.email} onChange={(e) => updateInput('Team', 'array', 'input', 'email', e.target.value, 'array', 'team', team_index)} rows={3} readOnly={team_d.is_email_read}/></td>
                                                                    <td><textarea className="form-control" name={`team_mobile_${team_index}`} value={team_d.mobile} onChange={(e) => updateInput('Team', 'array', 'input', 'mobile', e.target.value, 'array', 'team', team_index)} rows={3} readOnly={team_d.is_mob_read}/></td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                        {formDataTeam.length < 5 && (
                                                            <p className="text-end"><button className="btn btn-outline-primary me-1" onClick={(e) => addMoreRow(e, 'team')}>Add More++</button></p>
                                                        )}
                                                    </div>
                                                </th>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['General']
                                ?
                                    isBtnDisabled['General']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialOne(e, 'General')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                {socialUrl && (
                                    <a href={`/social-start-up/details/${socialId}/${socialUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                )}
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['General'].length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    <p>Please enter/modify following Data:</p>
                                    <ol>{}
                                    {errorData['General'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* Team Details */}
                {socialId !== 0 && ( 
                    <Accordion className="pt-3" defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Your social start-up team details (if you are alone, enter your details here)</Accordion.Header>
                            <Accordion.Body>
                                <form autoComplete="off">
                                    <div className="table-responsive">
                                    <table className="border-0 table-bordered table">
                                        <thead className="text-center">
                                            <tr>
                                                <th style={{width: "10px"}}></th>
                                                <th>Team member names<br/><span className="fw-normal">(max 5 members)</span> </th>
                                                <th>Education institution</th>
                                                <th>Grade/Class</th>
                                                <th>Email address</th>
                                                <th>Mobile</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                        {formDataTeam.map((team_d, team_index) => (    
                                            <tr key={`team${team_index}`}>
                                                <td><b>{team_index+1}</b></td>
                                                <td><textarea className="form-control" name={`team_name_${team_index}`} value={team_d.is_name_read === true ? `${formDataGeneral.first_name} ${formDataGeneral.last_name}` : team_d.name} onChange={(e) => updateInput('Team', 'array', 'input', 'name', e.target.value, 'array', 'team', team_index)} rows={3} readOnly={team_d.is_name_read}/></td>
                                                <td><textarea className="form-control" name={`edu_institue_${team_index}`} value={team_d.institute} onChange={(e) => updateInput('Team', 'array', 'input', 'institute', e.target.value, 'array', 'team', team_index)} rows={3}/></td>
                                                <td>
                                                    <div className="w-110p">
                                                        <Select
                                                            options={gradeData} 
                                                            defaultValue={team_d.class}
                                                            label={"Select Grade"}
                                                            onChange={(value) => updateInput('Team', 'array', 'select-react', 'class', value, 'array', 'team', team_index)}
                                                            getOptionLabel ={(option) => option.grade_name}
                                                            getOptionValue ={(option) => option.id}
                                                            menuPlacement="auto"
                                                            menuPosition={'fixed'}
                                                            styles={RSstandardStyle}
                                                        />
                                                    </div>
                                                </td>
                                                <td><textarea className="form-control" name={`team_email_${team_index}`} value={team_d.email} onChange={(e) => updateInput('Team', 'array', 'input', 'email', e.target.value, 'array', 'team', team_index)} rows={3} readOnly={team_d.is_email_read}/></td>
                                                <td><textarea className="form-control" name={`team_mobile_${team_index}`} value={team_d.mobile} onChange={(e) => updateInput('Team', 'array', 'input', 'mobile', e.target.value, 'array', 'team', team_index)} rows={3} readOnly={team_d.is_mob_read}/></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {formDataTeam.length < 5 && (
                                        <p className="text-end"><button className="btn btn-outline-primary me-1" onClick={(e) => addMoreRow(e, 'team')}>Add More++</button></p>
                                    )}
                                    </div>
                                    <p className="text-center mb-2">
                                        {!isProcess['Team']
                                        ?
                                            isBtnDisabled['Team']
                                            ?
                                                <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                            :
                                                <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editSocialOne(e, 'Team')}>Submit</button>
                                        :
                                            <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                        }
                                        {socialUrl && (
                                            <a href={`/social-start-up/details/${socialId}/${socialUrl}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                        )}
                                    </p>
                                </form>
                                {/* Error */}
                                <div className="col-md-12 col-12 col-sm-12">
                                {errorData['Team'].length > 0 && (
                                    <div className="alert alert-danger" role="alert">
                                        <p>Please enter/modify following Data:</p>
                                        <ol>
                                        {errorData['Team'].map((error, error_index) => (
                                            <li key={`error_${error_index}`}>{error}</li>
                                        ))}
                                        </ol>
                                    </div>
                                )}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )}
            </div>
            </>
        }
        </>
    )    
}

export default StepOneForm;