import React, { useRef, useEffect } from 'react';
// import React, { useRef, useEffect, PropsWithChildren } from 'react';
import { Carousel as NativeCarousel } from '@fancyapps/ui';
import '@fancyapps/ui/dist/carousel/carousel.css';

// import { Thumbs } from '@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js';
import { Autoplay } from "@fancyapps/ui/dist/carousel/carousel.autoplay.esm.js";

import '@fancyapps/ui/dist/carousel/carousel.thumbs.css';
import "@fancyapps/ui/dist/carousel/carousel.autoplay.css";


// import type { OptionsType } from '@fancyapps/ui/types/Carousel/options';

// interface Props {
//   options?: Partial<OptionsType>;
// }

// const defaults: Partial<OptionsType> = {
//   Dots: false,
//   Thumbs: {
//     type: 'modern',
//   },
// };
const defaults = {
    Dots: false,
    Thumbs: {
      type: 'classic',//modern
    },
    Autoplay:{
        timeout: 3000,
        showProgress: false
    },
    // showProgress: false
    // Slideshow: {
    //     playOnStart: true,
    //     timeout: 3000,
    //   },
    //   Slideshow: {
    //     playOnStart: true,
    //     timeout: 3000,
    //   },
  };
function Carousel(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const options = {
      ...defaults,
      ...(props.options || {}),
    };

    const instance = new NativeCarousel(container, options, { Autoplay });

    return () => {
      instance.destroy();
    };
  });

  return (
    <div className="f-carousel" ref={containerRef}>
      {props.children}
    </div>
  );
}

export default Carousel;
