import React, {useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import moment from "moment";
import { RSstandardStyle } from "../../config/react-select";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {post_api_call, get_api_call, error_api_call} from './../../actions/action-creators/common';
import ValidationHelper from "../../helper/validation";
import { alertResponseMessage } from "../../actions/action-creators/response";
import {update_user_info} from './../../actions/action-creators/state-update';
import picIcon from "./../../assets/images/user.png";
import { yesNoData } from "../../components/select/option";
import {LoaderMedium} from './../../components/loader';
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const startYear = currentYear - 102;
const cMonth = currentDate.getMonth()+1;
const currentMMYYYY = `${currentDate.getFullYear()}-${cMonth < 10 ? 0 : ""}${cMonth}`;

const initialFormData = {
    position: '',
    basic_desc: {
        short_description: '',
        basic_passion: '',
        basic_achievement: '',
    },
    general: {
        first_name: '',
        last_name: '',
        country_id: '',
        school_name_id: '',
        cohort_name_id: '',
        dob: '',
        email: '',
        phone_code_id: '',
        mobile_number: '',
        language: '',
        logo: ''
    },
    experience: [
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {designation: '', organization: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false}
    ],
    education: [
        {institute_name: '', program_name: '', achievement: '', start_year : '', end_year: '', is_present: false},
        {institute_name: '', program_name: '', achievement: '', start_year : '', end_year: '', is_present: false},
        {institute_name: '', program_name: '', achievement: '', start_year : '', end_year: '', is_present: false},
        // {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
        // {institute_name: '', program_name: '', achievement: '', start_month: '', start_year : '', end_month: '', end_year: '', is_present: false},
    ],
    award: [
        {institute_name: '', award_name: '', why_awarded: '', year: ''},
        {institute_name: '', award_name: '', why_awarded: '', year: ''},
        {institute_name: '', award_name: '', why_awarded: '', year: ''},
    ],
    passion: [
        {passion_id: ''},
        {passion_id: ''},
        {passion_id: ''}
    ],
    career_skills: [],
    personal_skills: [],
    achievement: [
        {id: 1, level: 'Beginner', year: '', indi_award: '', team_award: '', disabled: false},
        {id: 2, level: 'Foundation', year: '', indi_award: '', team_award: '', disabled: true},
        {id: 3, level: 'Intermediate', year: '', indi_award: '', team_award: '', disabled: true},
        {id: 4, level: 'Advanced', year: '', indi_award: '', team_award: '', disabled: true},
        {id: 5, level: 'Mastery', year: '', indi_award: '', team_award: '', disabled: true},
        {id: 6, level: 'Grand Mastery', year: '', indi_award: '', team_award: '', disabled: true},
    ],
    research: [
        {title: '', domain: '', link: '', year: '',  achievement: ''},
        {title: '', domain: '', link: '', year: '',  achievement: ''},
        {title: '', domain: '', link: '', year: '',  achievement: ''},
        {title: '', domain: '', link: '', year: '',  achievement: ''},
        {title: '', domain: '', link: '', year: '',  achievement: ''},
    ],
    sport: [
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
        {name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false},
    ],
};
function StepSixForm(props){
    const [formStepData, setFormStep] = useState({currentFormStep: 1});
    const cropperRef = React.createRef(null);
    const [pageLoading, setPageLoader] = useState(false);
    const [passionData, setPassionData] = useState([]);
    const [careerSkillData, setCareerSkillData] = useState([]);
    const [personalSkillData, setPersonalSkillData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [schoolData, setSchoolData] = useState([]);
    const [cohortData, setCohortData] = useState([]);
    const [indiAwardData, setIndiAwardData] = useState([]);
    const [teamAwardData, setTeamAwardData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [userUrl, setUserUrl] = useState("");
    // const [futureYearDataAsc, setFutureYearAsc] = useState([]);
    // const [futureYearDataDsc, setFutureYearDsc] = useState([]);
    const [previewPicImg, setPreviewPicImg] = useState(null);
    const [croppedImg, setCroppedImg] = useState(null);
    const [previewPicBlob, setPreviewPicBlob] = useState(null);
    // const cropperRef = useRef<ReactCropperElement>(null);
    //Forms
    const [formDataGeneral, setFormDataGeneral] = useState({...initialFormData.general});
    // const [formDataPic, setFormDataPic] = useState(null);
    const [formDataHeadline, setFormDataHeadline] = useState(initialFormData.position);
    const [formDataDesc, setFormDataDesc] = useState({...initialFormData.basic_desc});
    const [formDataUnique, setFormDataUnique] = useState(initialFormData.passion);
    const [formDataEdu, setFormDataEdu] = useState(initialFormData.education);
    const [formDataAchieve, setFormDataAchieve] = useState(initialFormData.achievement);
    const [formDataExp, setFormDataExp] = useState(initialFormData.experience);
    const [formDataResearch, setFormDataResearch] = useState(initialFormData.research);
    const [formDataSport, setFormDataSport] = useState(initialFormData.sport);
    const [formDataAward, setFormDataAward] = useState(initialFormData.award);
    const [formDataCareer, setFormDataCareer] = useState(initialFormData.career_skills);
    const [formDataPersonal, setFormDataPersonal] = useState(initialFormData.personal_skills);
    const [errorData, setErrorData] = useState({General: [],
                                                Headline: [],
                                                Pic: [],
                                                Desc: [],
                                                Unique: [],
                                                Edu: [],
                                                Exp: [],
                                                Achieve: [],
                                                Research: [],
                                                Sport: [],
                                                Award: [],
                                                Career: [],
                                                Personal: []
                                            });
    const [isProcess, setIsProcess] = useState({General: false,
                                                Headline: false,
                                                Pic: false,
                                                Desc: false,
                                                Unique: false,
                                                Edu: false,
                                                Exp: false,
                                                Achieve: false,
                                                Research: false,
                                                Sport: false,
                                                Award: false,
                                                Career: false,
                                                Personal: false
                                            });
    const [isBtnDisabled, setBtnDisabled] = useState({General: true,
                                                Headline: true,
                                                Pic: true,
                                                Desc: true,
                                                Unique: true,
                                                Edu: true,
                                                Exp: true,
                                                Achieve: true,
                                                Research: true,
                                                Sport: true,
                                                Award: true,
                                                Career: true,
                                                Personal: true
                                            });
    const [isFirstEdit, setIsFirstEdit] = useState(false);
    // const [isViewDisabled, setViewDisabled] = useState({General: true,
    //                                             Headline: true,
    //                                             Pic: true,
    //                                             Desc: true,
    //                                             Unique: true,
    //                                             Edu: true,
    //                                             Exp: true,
    //                                             Achieve: true,
    //                                             Research: true,
    //                                             Sport: true,
    //                                             Award: true,
    //                                             Career: true,
    //                                             Personal: true
    //                                         });
    const dispatch = useDispatch();
    //Upload Profile Image Preview
    const previewProfilePic = (event) => {
        try{
            const files = event.target.files;
            if(files.length > 0){
                const documetAllowedExt = ["png", "jpg", "jpeg"];
                const allowedSize = 200000;
                const imgFile = files[0];
            // for(let i=0; i<files.length; i++){
                var ext = imgFile.name.slice((imgFile.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
                //Check for file size and extension
                console.log('imgFile.size', imgFile.size)
                if(imgFile.size <= allowedSize && documetAllowedExt.includes(ext)){
                    const imgData = {data: imgFile,
                                    src: URL.createObjectURL(imgFile),
                                    // title: '',
                                    // is_image: imageExt.includes(imgFile.type.toLowerCase()) ? true : false,
                                    ext
                                };
                    setPreviewPicImg({...imgData});
                    setBtnDisabled({...isBtnDisabled, Pic: false});
                }else{
                    setPreviewPicImg(null);
                    alertResponseMessage({alert_type: 2, message: "Photograph allowed size is 200KB and it must be an image."});
                }
            }
        }catch(e){
            setPreviewPicImg(null);
            error_api_call({error: `portfolio|edit|previewProfilePic|${e}`});
        }
    }
    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        setCroppedImg(cropper.getCroppedCanvas().toDataURL());
        // console.log(cropper.getCroppedCanvas().toDataURL());
        cropper.getCroppedCanvas().toBlob((blob) => {
            console.log(blob, 'blobbb');
            setPreviewPicBlob(blob);
        });
    };
    //Add More Row
    const addMoreRow = async(e, field) => {
        try{
            e.preventDefault();
            const defaultVal = {
                education: [{name: '', institute_name: '', grade_id: '', email : '', mobile: ''}],
                experience: [{designation: '', organization: '', achievement: '', start_year : '', end_year: '', is_present: false}],
                research: [{title: '', domain: '', link: '', year: '',  achievement: ''}],
                sport: [{name: '', venue: '', achievement: '', start_year: '', end_year: '', is_present: false}],
                award: [{institute_name: '', award_name: '', why_awarded: '', year: ''}],
            };
            switch (field) {
                case 'education':
                    // setFormDataEdu({...formDataEdu, ...defaultVal[field]});
                    const data = formDataEdu.concat(defaultVal[field]);
                    setFormDataEdu(data);
                    break;
                case 'experience':
                    setFormDataExp(formDataExp.concat(defaultVal[field]));
                    break;
                case 'research':
                    setFormDataResearch(formDataResearch.concat(defaultVal[field]));
                    break;
                case 'sport':
                    setFormDataSport(formDataSport.concat(defaultVal[field]));
                    break;
                case 'award':
                    setFormDataAward(formDataAward.concat(defaultVal[field]));
                    break;
                default: 
            }
        }catch(e){
			error_api_call({error: `portfolio|edit|addMoreRow|${e}`});
        }
    }
    //Modify Input
    const updateInpState = (identifier_key, data) => {
        try{
            switch (identifier_key) {
                case 'Edu':
                    if(data.length > 0){
                        var eduData = [];
                        data.forEach(edu => {
                            eduData.push({institute_name: edu.institute_name || '',
                                            program_name: edu.program_name || '',
                                            achievement: edu.achievement || '',
                                            start_year : {id: edu.start_year, year: edu.start_year},
                                            end_year: edu.end_year ? {id: edu.end_year, year: edu.end_year}: '',
                                            is_present: edu.end_year ? false : true,
                                            id: edu.user_education_id
                                        });
                        });
                        setFormDataEdu(eduData);
                    }
                    break;
                case 'Exp':
                    if(data.length > 0){
                        var expData = [];
                        data.forEach(exp => {
                            console.log('exp.designation', exp, 'exp')
                            expData.push({designation: exp.designation || '',
                                            organization: exp.organization || '',
                                            achievement: exp.achievement || '',
                                            // start_year : {id: exp.start_year, year: exp.start_year},
                                            // end_year: exp.end_year ? {id: exp.end_year, year: exp.end_year}: '',
                                            start_year: moment(exp.start_date).format("YYYY-MM"),
                                            end_year: moment(exp.end_date).format("YYYY-MM"),
                                            is_present: exp.end_date ? false : true,
                                            id: exp.user_experience_id
                                        });
                        });
                        setFormDataExp(expData);
                    }
                    break;
                case 'Research':
                    if(data.length > 0){
                        var researchData = [];
                        data.forEach(research => {
                            researchData.push({title: research.title || '',
                                            domain: research.domain || '',
                                            link: research.link || '',
                                            year: {id: research.year, year: research.year},
                                            achievement: research.achievement || '',
                                            id: research.user_research_id
                                        });
                        });
                        setFormDataResearch(researchData);
                    }
                    break;
                case 'Sport':
                    if(data.length > 0){
                        var sportData = [];
                        data.forEach(sport => {
                            sportData.push({name: sport.name || '',
                                            venue: sport.venue || '',
                                            achievement: sport.achievement || '',
                                            start_year : {id: sport.start_year, year: sport.start_year},
                                            end_year: sport.end_year ? {id: sport.end_year, year: sport.end_year}: '',
                                            is_present: sport.end_year ? false : true,
                                            id: sport.user_sport_id
                                        });
                        });
                        setFormDataSport(sportData);
                    }
                    break;
                case 'Award':
                    if(data.length > 0){
                        var awardData = [];
                        data.forEach(award => {
                            awardData.push({institute_name: award.institute_name || '',
                                            award_name: award.award_name || '',
                                            why_awarded: award.why_awarded || '',
                                            year : {id: award.year, year: award.year},
                                            id: award.user_award_id
                                        });
                        });
                        
                        setFormDataAward(awardData);
                    }
                    break;
                default: 
            }
        }catch(e){
            console.log(e);
        }
    }
    useEffect(() => {
        //Get Portfolio Details
        const fetchPortfolioData = async() => {
            try{
                const portfolioApiData = await post_api_call(`portfolio-details`, {});
                // const portfolioApiData = await get_api_call(`portfolio/basic-details`, {});
                if(portfolioApiData.status === 200){
                    console.log(portfolioApiData, 'portfolioApiData')
                    // if(portfolioApiData.data.headline){
                    //     window.location.href = `/port/${portfolioApiData.data.url}`;
                    // }else{
                        if(!portfolioApiData.data.headline){
                            setIsFirstEdit(true);
                        }
                        //GeneralDetails
                        const fieldKeys = ['first_name' , 'last_name', 'email', 'dob', 'language', 'mobile_number', 'logo'];
                        const generalFieldData = {...formDataGeneral};
                        fieldKeys.forEach(key => {
                            generalFieldData[key] = portfolioApiData.data[key] ? portfolioApiData.data[key] : '';
                        });
                        generalFieldData.country_id = portfolioApiData.data.country_id ? {id: portfolioApiData.data.country_id, name: portfolioApiData.data.country} : '';
                        generalFieldData.phone_code_id = portfolioApiData.data.mobile_code_id ? {id: portfolioApiData.data.mobile_code_id, phone_code: portfolioApiData.data.phone_code} : '';
                        generalFieldData.school_name_id = portfolioApiData.data.school_id ? {id: portfolioApiData.data.school_id, school_name: portfolioApiData.data.school_name} : '';
                        generalFieldData.cohort_name_id = portfolioApiData.data.cohort_id ? {id: portfolioApiData.data.cohort_id, cohort_name: portfolioApiData.data.cohort_name} : '';
                        setFormDataGeneral({...generalFieldData});
                        setUserUrl(portfolioApiData.data.user_name);
                        //Headline
                        setFormDataHeadline(portfolioApiData.data.position || '');
                        //Short Description
                        setFormDataDesc({short_description: portfolioApiData.data.headline || '',
                                        basic_achievement: portfolioApiData.data.achievement || '',
                                        basic_passion: portfolioApiData.data.passion || ''});
                        //Unique Skills
                        if(portfolioApiData.data.unique_skills.length > 0){
                            var uniqueData = [];
                            portfolioApiData.data.unique_skills.forEach(skill => {
                                uniqueData.push({passion_id : {id: skill.passion_id, title: `${skill.title}: ${skill.unique_ability}`}});
                            });
                            setFormDataUnique(uniqueData);
                        }
                        //Education
                        updateInpState('Edu', portfolioApiData.data.education_details);
                        //Achievements
                        if(portfolioApiData.data.glosco_data.length > 0){
                            var gloscoData = formDataAchieve;
                            portfolioApiData.data.glosco_data.forEach(glosco => {
                                gloscoData[glosco.level_id-1] = {...gloscoData[glosco.level_id-1],
                                                                id: glosco.level_id,
                                                                level: glosco.level,
                                                                year: {id: glosco.year, year: glosco.year},
                                                                indi_award: glosco.indi_award,
                                                                team_award: glosco.team_award};

                            });
                            setFormDataAchieve(gloscoData);
                        }
                        //Experience
                        updateInpState('Exp', portfolioApiData.data.experience_details);
                        //Research
                        updateInpState('Research', portfolioApiData.data.research_details);
                        //Sport
                        updateInpState('Sport', portfolioApiData.data.sport_details);
                        //Award
                        updateInpState('Award', portfolioApiData.data.award_details);
                        //Career Skills
                        if(portfolioApiData.data.carrer_skill_details.length > 0){
                            setFormDataCareer(portfolioApiData.data.carrer_skill_details);
                        }
                        //Personal Skills
                        if(portfolioApiData.data.personal_skill_details.length > 0){
                            setFormDataPersonal(portfolioApiData.data.personal_skill_details);
                        }
                        // setFormData({...fieldData});
                        setPageLoader(false);
                    // }
                }
            }catch(e){
                console.log(e);
                alertResponseMessage({alert_type: 2, message: "Error Loading Portfolio Details! Check back after some times"});
                error_api_call({error: `portfolio/edit|fetchPortfolioData|${e}`});
            }
        }
        // fetchPortfolioData();
		// //Initial API Data
        // fetchCountryList();
        // fetchSelectList('passion', 'passion-scores?fetch_type=2');
        // fetchSelectList('career_skill', 'skills/career');
        // fetchSelectList('personal_skill', 'skills/personal');
        // fetchSelectList('school', 'schools');
        // fetchSelectList('cohort', 'cohorts');
        // fetchSelectList('indi_award', 'awards/individual');
        // fetchSelectList('team_award', 'awards/team');
        // // fetchSelectList('program');
        // yearDataList('year');
        // yearDataList('future');
        // futureYearDataList('asc');
        // futureYearDataList('dsc');
	}, []);
    //Update Input
    const updateInput = (identifier_key, identifier_type, input_type, field, value, field_type="", form_field="", index=0) => {
        try{
            console.log(identifier_key, input_type, field, value, field_type, form_field, index, 'inputtt')
            if(errorData[identifier_key].length){
                setErrorData({...errorData, [identifier_key]: []});
            }
            setBtnDisabled({...isBtnDisabled, [identifier_key]: false});
            // setViewDisabled({...isViewDisabled, [identifier_key]: true});
            if(field === 'mobile_number'){
                if(value.length < 16){
                    value = value.replace(/[^0-9]/g, '');
                }else{
                    return false;
                }
            }else if(input_type === 'input'
                || input_type === 'textarea'
            ){
                // value = value.replace(/ /g, '');
                value = value.trimStart();
                // value = value.replace(/  /g, '');
                if(field === 'first_name'
                    || field === 'last_name'
                ){
                    value = value.replace(/[^a-zA-Z. ]/g, '')
                      .replace('  ', ' ');
                }
            }
            // const fieldData = {...formData};
            // var fieldData = identifier_type === 'object' ? {...eval('formData'+identifier_key)} : eval('formData'+identifier_key);
            var fieldData = eval('formData'+identifier_key);
            if(identifier_type === 'object'){
                fieldData = {...fieldData}
            }else if(identifier_type === 'array'){
                fieldData = [...fieldData]
            }
            console.log(fieldData, 'fieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldDatafieldData')
            if(field_type === 'array'){
                // const fieldData = eval('formData'+identifier_key);
                console.log('fieldData[form_field][index][field] = value', form_field,',', index,',', field,',',  value)
                // fieldData[form_field][index][field] = value;
                fieldData[index][field] = value;
                // setFormData({...fieldData});
            }else{
                identifier_type === 'single_value' ? fieldData = value : fieldData[field] = value;
                // setFormData({
                //     ...formData,
                //     [field]: value
                // });
            }
            switch (identifier_key) {
                case 'General':
                    setFormDataGeneral({...fieldData});
                    break;
                case 'Headline':
                    setFormDataHeadline(fieldData);
                    break;
                case 'Desc':
                    setFormDataDesc({...fieldData});
                    break;
                case 'Unique':
                    setFormDataUnique([...fieldData]);
                    break;
                case 'Edu':
                    setFormDataEdu([...fieldData]);
                    break;
                case 'Achieve':
                    setFormDataAchieve([...fieldData]);
                    break;
                case 'Exp':
                    setFormDataExp([...fieldData]);
                    break;
                case 'Research':
                    setFormDataResearch([...fieldData]);
                    break;
                case 'Sport':
                    setFormDataSport([...fieldData]);
                    break;
                case 'Award':
                    setFormDataAward([...fieldData]);
                    break;
                case 'Career':
                    setFormDataCareer([...fieldData]);
                    break;
                case 'Personal':
                    setFormDataPersonal([...fieldData]);
                    break;
                default:
            }
        }catch(e){
            console.log(e);
            error_api_call({error: `portfolio|edit|updateInput|${e}`});
        }
    }
    //Check if Url is Valid
    const isValidUrl = (string) => {
        try {
            console.log(string, 'stringstringstringstringstring')
        //   new URL(string);
            // const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
            if(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi.test(string)) {
                return true;
            }else{
                return false;
            }
        } catch (err) {
          return false;
        }
      }
     //Add Fellow on Submit Click
     const editPortfolio = async(e, form_type='') => {
        try{
            e.preventDefault();
            setErrorData({...errorData, [form_type]: []});
            setIsProcess({...isProcess, [form_type]: true});
            var errorDetails = {
                General: [],
                Headline: [],
                Pic: [],
                Desc: [],
                Unique: [],
                Edu: [],
                Exp: [],
                Achieve: [],
                Research: [],
                Sport: [],
                Award: [],
                Career: [],
                Personal: []
            };
            var apiFormData = new FormData();
            //Validation
            var validation = [];
            var validationRules = {};
            switch (form_type) {
                case 'General':
                    validationRules = {
                        first_name: 'required|max:100',
                        last_name: 'optional|max:100',
                        school_name_id: 'required||School Name',
                        cohort_name_id: 'required||Glosco Cohort Name',
                        country_id: 'required||Country',
                        dob: 'required||Date of Birth',
                        // email: 'required|email|max:150',
                        phone_code_id: 'required||Mobile Country Code',
                        // mobile_number: 'required|digit:|min:6|max:15',
                        language: 'optional|max:250||Languages Known'
                    };
                    const fieldFormDataGeneral = {...formDataGeneral,
                        country_id: formDataGeneral.country_id.id,
                        phone_code_id: formDataGeneral.phone_code_id.id,
                        school_name_id: formDataGeneral.school_name_id.id,
                        cohort_name_id: formDataGeneral.cohort_name_id.id
                    };
                    validation = ValidationHelper.validation(fieldFormDataGeneral, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        // apiFormData.append('general_data', fieldFormDataGeneral);
                        const basicDataKeys = ['first_name',
                            'last_name',
                            'country_id',
                            'school_name_id',
                            'cohort_name_id',
                            'dob',
                            'email',
                            'phone_code_id',
                            // 'mobile_number',
                            'language'];
                        for (var key in basicDataKeys) {
                            if (fieldFormDataGeneral.hasOwnProperty(basicDataKeys[key])) {
                                apiFormData.append(`general_data[${basicDataKeys[key]}]`, fieldFormDataGeneral[basicDataKeys[key]]);
                            }
                        }
                    }
                    break;
                case 'Pic':
                    //Image
                    if(previewPicImg === null){
                        errorDetails[form_type] = ['Photograph is required'];
                    }else{
                        // apiFormData.append('profile_pic', previewPicImg.data, 'Profile Pic');
                        apiFormData.append('profile_pic', previewPicBlob, 'Profile Pic');
                        // const cropper = cropperRef.current?.cropper;
                        // await cropper.getCroppedCanvas().toBlob((blob) => {
                        //     console.log(blob)
                        //     // Pass the image file name as the third parameter if necessary.
                        //     // apiFormData.append('profile_pic', blob, 'profile-pic.png');
                        //     apiFormData.append('profile_pic', blob, 'profile-pic');
                        // });
                        console.log('skipped')
                    }
                    break;
                case 'Headline':
                    validationRules = {
                        position: 'required|max:150||Who you are',
                    };
                    const fieldFormDataHeadline = {
                        position: formDataHeadline
                    };
                    validation = ValidationHelper.validation(fieldFormDataHeadline, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        apiFormData.append('headline_data', formDataHeadline);
                    }
                    break;
                case 'Desc':
                    validationRules = {
                        short_description: 'required|max:250||Your name, grade and school',
                        basic_achievement: 'optional|max:250||Your academic and extracurricular achievements, leadership, awards, research and any internships',
                        basic_passion: 'required|max:250||Your passion and future goal',
                    };
                    validation = ValidationHelper.validation(formDataDesc, validationRules).error;
                    if(validation.length){
                        errorDetails[form_type] = validation;
                    }else{
                        // apiFormData.append(`desc_data[short_description]`, formDataDesc.short_description);
                        // apiFormData.append(`desc_data[basic_achievement]`, formDataDesc.basic_achievement);
                        // apiFormData.append(`desc_data[basic_passion]`, formDataDesc.basic_passion);
                        apiFormData.append('desc_data', JSON.stringify(formDataDesc));
                        // formData.append(`${key}.${subKey}`, data[key][subKey]);
                    }
                    break;
                case 'Unique':
                    // Unique Skills
                    var passionData = [];
                    formDataUnique.forEach((passion, passion_indx) => {
                        var passionDetails = formDataUnique[passion_indx];
                        if(passionDetails.passion_id !== ''){
                            if(passionData.indexOf(passionDetails.passion_id.id) !== -1){
                                errorDetails[form_type] = [`2 Passion Scores cannot be same`];
                            }
                            passionData.push(passionDetails.passion_id.id);
                            apiFormData.append(`passion[${passion_indx}]`, passionDetails.passion_id.id);
                        }
                    });
                    // if(passionData.length > 0 && passionData.length < 3){
                    //     errorDetails[form_type] = [`All 3 Passion Scores Required`];
                    // }
                    if(passionData.length < 3){
                        errorDetails[form_type] = [`All 3 Passion Scores Required`];
                    }
                    // else{
                    //     apiFormData.append('unique_data', passionData);
                    // }
                    break;
                case 'Edu':
                    //Education
                    // var isEduData = false;
                    // var eduData = [];
                    formDataEdu.forEach((edu, edu_indx) => {
                        var eduDetails = edu;
                        if(eduDetails.institute_name || eduDetails.program_name || eduDetails.achievement){
                            // isEduData = true;
                            const eduValidationRules = {
                                institute_name: `required|max:150||Education, Diploma... ${edu_indx + 1}: Institution Name`,
                                program_name: `required|max:100||Education, Diploma... ${edu_indx + 1}: Name of the Program`,
                                achievement: `optional|max:250||Education, Diploma... ${edu_indx + 1}: Key Achievement or Grade`,
                                start_year: `required||Education, Diploma... ${edu_indx + 1}: Start Year`
                            };
                            const validationEdu = ValidationHelper.validation(eduDetails, eduValidationRules).error;
                            if(validationEdu.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationEdu);
                            }
                            //End Data
                            if(eduDetails.is_present === false){
                                if(eduDetails.end_year === ''){
                                    errorDetails[form_type].push(`Education, Diploma... ${edu_indx + 1}: End Year is Required`);
                                }else if (eduDetails.start_year !== '' && eduDetails.start_year.id > eduDetails.end_year.id){
                                    errorDetails[form_type].push(`Education, Diploma... ${edu_indx + 1}: Start Year cannot be greater than End Year`);
                                }
                            }
                            if(!errorDetails[form_type].length){
                                // var eduEndYear = eduDetails.is_present === true ? null : eduDetails.end_year.id;
                                var userEduId = typeof eduDetails.id !== "undefined" ? eduDetails.id : '';
                                apiFormData.append(`education[${edu_indx}][id]`, userEduId);
                                apiFormData.append(`education[${edu_indx}][institute_name]`, eduDetails.institute_name);
                                apiFormData.append(`education[${edu_indx}][program_name]`, eduDetails.program_name);
                                apiFormData.append(`education[${edu_indx}][achievement]`, eduDetails.achievement);
                                apiFormData.append(`education[${edu_indx}][start_year]`, eduDetails.start_year.id);
                                apiFormData.append(`education[${edu_indx}][end_year]`, eduDetails.is_present === true ? null : eduDetails.end_year.id);
                                apiFormData.append(`education[${edu_indx}][is_present]`, eduDetails.is_present);
                            }
                        }else if(typeof eduDetails.id !== "undefined"){//To delete from database
                            var eduEndYear = eduDetails.is_present === true ? null : eduDetails.end_year.id;
                            apiFormData.append(`education[${edu_indx}][id]`, eduDetails.id);
                            apiFormData.append(`education[${edu_indx}][institute_name]`, eduDetails.institute_name);
                            apiFormData.append(`education[${edu_indx}][program_name]`, eduDetails.program_name);
                            apiFormData.append(`education[${edu_indx}][achievement]`, eduDetails.achievement);
                            apiFormData.append(`education[${edu_indx}][start_year]`, eduDetails.start_year.id);
                            apiFormData.append(`education[${edu_indx}][end_year]`, eduEndYear);
                            apiFormData.append(`education[${edu_indx}][is_present]`, eduDetails.is_present);
                        }
                    });
                    // if(!isEduData){
                    //     errorDetails.push(`Atleast 1 Education, Diploma... (Point 6) Detail Required`);
                    // }
                    // if(isEduData){
                    //     apiFormData.append('edu_data', eduData);
                    // }
                    break;
                case 'Achieve':
                    // Achievements
                    // var isAchieveData = false;
                    // var achieveData = [];
                    formDataAchieve.forEach((achieve, achieve_indx) => {
                        var achieveDetails = achieve;
                        if(achieveDetails.year || achieveDetails.team_award || achieveDetails.indi_award){
                            // isAchieveData = true;
                            // const achieveValidationRules = {
                            //     year: `required|max:150||IFSPD Europe Glo... (Point 7) ${achieveDetails.level}: Year of completion`,
                            //     indi_award: `required|max:100||IFSPD Europe Glo... (Point 7) ${achieveDetails.level}: Individual Awards`,
                            //     team_award: `required||IFSPD Europe Glo... (Point 7) ${achieveDetails.level}: Team Awards`
                            // };
                            // const validationAchieve = ValidationHelper.validation(achieveDetails, achieveValidationRules).error;
                            var validationAchieve = [];
                            if(achieveDetails.year === ''){
                                validationAchieve.push(`IFSPD Europe Glo... ${achieveDetails.level}: Year of completion is Required`);
                            }else if(achieveDetails.indi_award === '' && achieveDetails.team_award === ''){
                                validationAchieve.push(`IFSPD Europe Glo... ${achieveDetails.level}: Individual/Team Awards is Required`);
                            }
                            if(validationAchieve.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationAchieve);
                            }
                            if(!errorDetails[form_type].length){
                                const indiAwardIds = [];
                                const teamAwardIds = [];
                                if(achieveDetails.indi_award.length){
                                    const indiAward = achieveDetails.indi_award;
                                    indiAward.forEach(award => {
                                        indiAwardIds.push(award.id);
                                    });
                                }
                                if(achieveDetails.team_award.length){
                                    const teamAward = achieveDetails.team_award;
                                    teamAward.forEach(award => {
                                        teamAwardIds.push(award.id);
                                    });
                                }
                                apiFormData.append(`achievement[${achieve_indx}][id]`, achieveDetails.id);
                                apiFormData.append(`achievement[${achieve_indx}][year]`, achieveDetails.year.id);
                                apiFormData.append(`achievement[${achieve_indx}][indi_award]`, indiAwardIds);
                                apiFormData.append(`achievement[${achieve_indx}][team_award]`, teamAwardIds);
                                // achieveData.push({
                                //     'id' : achieveDetails.id,
                                //     'year' : achieveDetails.year.id,
                                //     'indi_award' : indiAwardIds,
                                //     'team_award' : teamAwardIds
                                // });
                            }
                        }
                    });
                    
                    // if(!isAchieveData){
                    //     errorDetails.push(`Atleast 1 IFSPD Europe Global Sustainabili... (Point 7) Detail Required`);
                    // }
                    break;
                case 'Exp':
                    // var expData = [];
                    // Work Experience
                    // var isExpData = false;
                    formDataExp.forEach((exp, exp_indx) => {
                        var expDetails = exp;
                        if(expDetails.designation || expDetails.organization || expDetails.achievement){
                            // isExpData = true;
                            const expValidationRules = {
                                designation: `required|max:100||Work Experience, Volunteer... ${exp_indx + 1}: Designation`,
                                organization: `required|max:100||Work Experience, Volunteer... ${exp_indx + 1}: Organization/Passion Project Name`,
                                achievement: `optional|max:250||Work Experience, Volunteer... ${exp_indx + 1}: Key Responsibilities or Achievements`,
                                start_year: `required||Work Experience, Volunteer... ${exp_indx + 1}: Start Date`
                            };
                            const validationExp = ValidationHelper.validation(expDetails, expValidationRules).error;
                            if(validationExp.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationExp);
                            }
                            //End Data
                            if(expDetails.is_present === false){
                                if(expDetails.end_year === ''
                                    || expDetails.end_year === "Invalid date"
                                ){
                                    errorDetails[form_type].push(`Work Experience, Volunteer... ${exp_indx + 1}: End Date is Required`);
                                }else if(expDetails.start_year !== '' && expDetails.start_year > expDetails.end_year){
                                    errorDetails[form_type].push(`Work Experience, Volunteer... ${exp_indx + 1}: Start Date cannot be greater than End Date`);
                                }
                                // else if(expDetails.start_year !== '' && expDetails.start_year.id > expDetails.end_year.id){
                                //     errorDetails[form_type].push(`Work Experience, Volunteer... ${exp_indx + 1}: Start Year cannot be greater than End Year`);
                                // }
                            }
                            if(!errorDetails[form_type].length){
                                var expEndYear = expDetails.is_present === true ? null : expDetails.end_year;
                                var userExpId = typeof expDetails.id !== "undefined" ? expDetails.id : '';
                                apiFormData.append(`experience[${exp_indx}][id]`, userExpId);
                                apiFormData.append(`experience[${exp_indx}][designation]`, expDetails.designation);
                                apiFormData.append(`experience[${exp_indx}][organization]`, expDetails.organization);
                                apiFormData.append(`experience[${exp_indx}][achievement]`, expDetails.achievement);
                                apiFormData.append(`experience[${exp_indx}][start_year]`, expDetails.start_year);//parseInt(expDetails.start_year.id))
                                apiFormData.append(`experience[${exp_indx}][end_year]`, expEndYear);
                                apiFormData.append(`experience[${exp_indx}][is_present]`, expDetails.is_present);
                            }
                        }else if(typeof expDetails.id !== "undefined"){//To delete from database
                            // var expEndYear = expDetails.is_present === true ? null : expDetails.end_year.id;
                            apiFormData.append(`experience[${exp_indx}][id]`, expDetails.id);
                            apiFormData.append(`experience[${exp_indx}][designation]`, expDetails.designation);
                            apiFormData.append(`experience[${exp_indx}][organization]`, expDetails.organization);
                            apiFormData.append(`experience[${exp_indx}][achievement]`, expDetails.achievement);
                            apiFormData.append(`experience[${exp_indx}][start_year]`, expDetails.start_year);//parseInt(expDetails.start_year.id))
                            apiFormData.append(`experience[${exp_indx}][end_year]`, "");
                            apiFormData.append(`experience[${exp_indx}][is_present]`, expDetails.is_present);
                        }
                    });
                    // if(!isExpData){
                    //     errorDetails.push(`Atleast 1 Work Experience, Volunteer... (Point 8) Detail Required`);
                    // }
                    break;
                case 'Research':
                    //Research
                    // var isResearchData = false;
                    formDataResearch.forEach((research, research_indx) => {
                        // var researchDetails = formDataResearch[research_indx];
                        var researchDetails = research;
                        if(researchDetails.title
                            || researchDetails.domain
                            || researchDetails.link
                            || researchDetails.achievement
                        ){
                            // isResearchData = true;
                            const researchValidationRules = {
                                title: `required|max:150||Projects, Assign... ${research_indx + 1}: Title of the Projects, Assignm...`,
                                domain: `required|max:100||Projects, Assign... ${research_indx + 1}: Where was it published`,
                                link: `optional|max:250||Projects, Assign... ${research_indx + 1}: Link`,
                                achievement: `optional|max:250||Projects, Assign... ${research_indx + 1}: Any key achievement or accolade`,
                                year: `required||Projects, Assign... ${research_indx + 1}: Year of publishing`
                            };
                            const validationEdu = ValidationHelper.validation(researchDetails, researchValidationRules).error;
                            if(validationEdu.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationEdu);
                            }else if(researchDetails.link && !isValidUrl(researchDetails.link)){
                                errorDetails[form_type] = errorDetails[form_type].concat([`Projects, Assign... ${research_indx + 1}: Link is Invalid`]);
                            }

                            if(!errorDetails[form_type].length){
                                var userResearchId = typeof researchDetails.id !== "undefined" ? researchDetails.id : '';
                                apiFormData.append(`research[${research_indx}][id]`, userResearchId);
                                apiFormData.append(`research[${research_indx}][title]`, researchDetails.title);
                                apiFormData.append(`research[${research_indx}][domain]`, researchDetails.domain);
                                apiFormData.append(`research[${research_indx}][achievement]`, researchDetails.achievement);
                                apiFormData.append(`research[${research_indx}][year]`, researchDetails.year.id);
                                apiFormData.append(`research[${research_indx}][link]`, researchDetails.link);
                            }
                        }else if(typeof researchDetails.id !== "undefined"){//To delete from database
                            apiFormData.append(`research[${research_indx}][id]`, researchDetails.id);
                            apiFormData.append(`research[${research_indx}][title]`, researchDetails.title);
                            apiFormData.append(`research[${research_indx}][domain]`, researchDetails.domain);
                            apiFormData.append(`research[${research_indx}][achievement]`, researchDetails.achievement);
                            apiFormData.append(`research[${research_indx}][year]`, researchDetails.year.id);
                            apiFormData.append(`research[${research_indx}][link]`, researchDetails.link);
                        }
                    });
                    // if(!isResearchData){
                    //     errorDetails.push(`Atleast 1 Projects, Assign... (Point 9) Detail Required`);
                    // }
                    break;
                case 'Sport':
                    //Sports
                    // var isSportData = false;
                    // var eduData = [];
                    formDataSport.forEach((sport, sport_indx) => {
                        var sportDetails = sport;
                        if(sportDetails.name || sportDetails.venue  || sportDetails.achievement){
                            // isSportData = true;
                            const sportValidationRules = {
                                name: `required|max:150||Sports, Games, A... ${sport_indx + 1}: Sports, Games, Ath...`,
                                venue : `required|max:100||Sports, Games, A... ${sport_indx + 1}: Where did you participate?`,
                                achievement: `optional|max:250||Sports, Games, A... ${sport_indx + 1}: Any key achievement or accolade`,
                                start_year: `required||Sports, Games, A... ${sport_indx + 1}: Start Year`
                            };
                            const validationSport = ValidationHelper.validation(sportDetails, sportValidationRules).error;
                            if(validationSport.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationSport);
                            }
                            //End Data
                            if(sportDetails.is_present === false){
                                if(sportDetails.end_year === ''){
                                    errorDetails[form_type].push(`Sports, Games, A... ${sport_indx + 1}: End Year is Required`);
                                }else if (sportDetails.start_year !== '' && sportDetails.start_year.id > sportDetails.end_year.id){
                                    errorDetails[form_type].push(`Sports, Games, A... ${sport_indx + 1}: Start Year cannot be greater than End Year`);
                                }
                            }
                            if(!errorDetails[form_type].length){
                                var sportEndYear = sportDetails.is_present === true ? null : sportDetails.end_year.id;
                                var userSportId = typeof sportDetails.id !== "undefined" ? sportDetails.id : '';
                                apiFormData.append(`sport[${sport_indx}][id]`, userSportId);
                                apiFormData.append(`sport[${sport_indx}][name]`, sportDetails.name);
                                apiFormData.append(`sport[${sport_indx}][venue]`, sportDetails.venue);
                                apiFormData.append(`sport[${sport_indx}][achievement]`, sportDetails.achievement);
                                apiFormData.append(`sport[${sport_indx}][start_year]`, sportDetails.start_year.id);
                                apiFormData.append(`sport[${sport_indx}][end_year]`, sportEndYear);
                                apiFormData.append(`sport[${sport_indx}][is_present]`, sportDetails.is_present);
                            }
                        }else if(typeof sportDetails.id !== "undefined"){//To delete from database
                            apiFormData.append(`sport[${sport_indx}][id]`, sportDetails.id);
                            apiFormData.append(`sport[${sport_indx}][name]`, sportDetails.name);
                            apiFormData.append(`sport[${sport_indx}][venue]`, sportDetails.venue);
                            apiFormData.append(`sport[${sport_indx}][achievement]`, sportDetails.achievement);
                            apiFormData.append(`sport[${sport_indx}][start_year]`, sportDetails.start_year.id);
                            apiFormData.append(`sport[${sport_indx}][end_year]`, sportEndYear);
                            apiFormData.append(`sport[${sport_indx}][is_present]`, sportDetails.is_present);
                        }
                    });
                    // if(!isSportData){
                    //     errorDetails.push(`Atleast 1 Sports, Games, A... (Point 10) Detail Required`);
                    // }
                    break;
                case 'Award':
                    //Award
                    // var awardData = [];
                    formDataAward.forEach((award, award_indx) => {
                        var awardDetails = award;
                        if(awardDetails.institute_name || awardDetails.award_name || awardDetails.why_awarded){
                            const awardValidationRules = {
                                institute_name: `required|max:100||Honours and Awards ${award_indx + 1}: Institution Name`,
                                award_name: `required|max:100||Honours and Awards ${award_indx + 1}: Name of the Award`,
                                why_awarded: `optional|max:250||Honours and Awards ${award_indx + 1}: Why was this awarded to you?`,
                                year: `required||Honours and Awards ${award_indx + 1}: Year`
                            };
                            const validationAward = ValidationHelper.validation(awardDetails, awardValidationRules).error;
                            if(validationAward.length){
                                errorDetails[form_type] = errorDetails[form_type].concat(validationAward);
                            }

                            if(!errorDetails[form_type].length){
                                var userAwardId = typeof awardDetails.id !== "undefined" ? awardDetails.id : '';
                                apiFormData.append(`award[${award_indx}][id]`, userAwardId);
                                apiFormData.append(`award[${award_indx}][institute_name]`, awardDetails.institute_name);
                                apiFormData.append(`award[${award_indx}][award_name]`, awardDetails.award_name);
                                apiFormData.append(`award[${award_indx}][why_awarded]`, awardDetails.why_awarded);
                                apiFormData.append(`award[${award_indx}][year]`, awardDetails.year.id);
                            }
                        }else if(typeof awardDetails.id !== "undefined"){//To delete from database
                            apiFormData.append(`award[${award_indx}][id]`, awardDetails.id);
                            apiFormData.append(`award[${award_indx}][institute_name]`, awardDetails.institute_name);
                            apiFormData.append(`award[${award_indx}][award_name]`, awardDetails.award_name);
                            apiFormData.append(`award[${award_indx}][why_awarded]`, awardDetails.why_awarded);
                            apiFormData.append(`award[${award_indx}][year]`, awardDetails.year.id);
                        }
                    });
                    break;
                case 'Career':
                    //Career Skills
                    if(formDataCareer.length > 3){
                        errorDetails[form_type].push(`Select only top three Career Skills`);
                    }else{
                        const careerSkillIds = [];
                        formDataCareer.forEach(award => {
                            careerSkillIds.push(award.id);
                        });
                        apiFormData.append(`career_skills`, careerSkillIds);
                    }
                    break;
                case 'Personal':
                    //Personal Skills
                    if(formDataPersonal.length > 3){
                        errorDetails[form_type].push(`Select only top three Personal Skills`);
                    }else{
                        const personalSkillIds = [];
                        formDataPersonal.forEach(award => {
                            personalSkillIds.push(award.id);
                        });
                        apiFormData.append(`personal_skills`, personalSkillIds);
                    }
                    break;
                default:
            }
            console.log(errorDetails[form_type], 'errorDetails[form_type]')
            console.log('formDataEduformDataEdu', formDataEdu)
            if(errorDetails[form_type].length > 0){
                setErrorData({...errorData, [form_type]: errorDetails[form_type]});
                setIsProcess({...isProcess, [form_type]: false});
                setBtnDisabled({...isBtnDisabled, [form_type]: true});
            }else{
                //Send Data to Server
                apiFormData.append('update_type', form_type.toLowerCase());
                // const updateFellow = await post_api_call(`update-portfolio/${form_type.toLowerCase()}`, apiFormData);
                const updateFellow = await post_api_call(`update-portfolio`, apiFormData);
				if(updateFellow.status === 200){
                    // setIsSuccess(true);
                    if(form_type === 'Pic'
                        || form_type === 'General'
                    ){
                        // Dispatch
                        dispatch(update_user_info(updateFellow.data, async cb_data => {
                            console.log('cb_data', cb_data)
                        }));
                    }else if(form_type === 'Edu'
                        || form_type === 'Exp'
                        || form_type === 'Research'
                        || form_type === 'Award'
                        || form_type === 'Sport'
                    ){
                        updateInpState(form_type, updateFellow.data);
                    }
                    alertResponseMessage({alert_type: 1, message: "Information Updated Successfully"});
                    setBtnDisabled({...isBtnDisabled, [form_type]: true});
                    // setViewDisabled({...isViewDisabled, [form_type]: false});
                    console.log("Information Updated Successfully", 'Information Updated Successfully')
                }else{
                    if(typeof updateFellow.message !== "undefined"){
                        setErrorData({...errorData, [form_type]: [updateFellow.message]});
                        console.log("Errorooo")
                    }
                }
                setIsProcess({...isProcess, [form_type]: false});
            }
            
        }catch(e){
            console.log(e);
            setErrorData({...errorData, [form_type]: ['Something Went Wrong, Please Try After Some Time']});
            setIsProcess({...isProcess, [form_type]: false});
            error_api_call({error: `portfolio|edit|editPortfolio|${e}`});
        }
    }
    return (
        <>
        {pageLoading
        ?
            <div className="text-center mt-5 mb-5">
                <LoaderMedium size={45} />
            </div>
        :
            <>
            <div className="text-center">
                <h2 className="mb-1 mt-4"><b>Step 6: SOCIAL START-UP REPORTING</b></h2>
                <p>Imbibe important lessons for future and create a wider publicity for your project</p>
            </div>
            <div className="col-md-10 col-12 col-sm-12 card p-3">
                <ul className="mb-4 ms-3">
                    <li>If you have a team, meet up and conduct a debriefing session.</li>
                    <li>Discuss the results that were achieved and how they compare to the goals set initially.</li>
                    <li>Discuss the challenges faced and lessons learnt.</li>
                    <li>Discuss what could have been done better.</li>
                    <li>Upload all pictures and news reports.</li>
                </ul>
                {/* Reporting */}
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Reporting: In below 250 characters each</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        <tr>
                                            <th className="w-50">What are your key learnings from this social start-up? <br/><br/></th>
                                            <td><textarea className="form-control" name="first_name" autoComplete="off" value={formDataGeneral.first_name} onChange={(e) => updateInput('General', 'object', 'input', 'first_name', e.target.value)} rows={5}/></td>
                                        </tr>
                                        <tr>
                                            <th>If you achieved, exceeded or fell short of the KPI, what are the reasons?</th>
                                            <td><textarea className="form-control" name="topic_name" autoComplete="off" value={formDataGeneral.topic_name} onChange={(e) => updateInput('General', 'object', 'input', 'topic_name', e.target.value)} rows={5}/></td>
                                        </tr>
                                        <tr>
                                            <th>What was innovative about the way you solved this problem?</th>
                                            <td><textarea className="form-control" name="topic_name" autoComplete="off" value={formDataGeneral.topic_name} onChange={(e) => updateInput('General', 'object', 'input', 'topic_name', e.target.value)} rows={5}/></td>
                                        </tr>
                                        <tr>
                                            <th>What were some of the challenges that you went through?</th>
                                            <td><textarea className="form-control" name="topic_name" autoComplete="off" value={formDataGeneral.topic_name} onChange={(e) => updateInput('General', 'object', 'input', 'topic_name', e.target.value)} rows={5}/></td>
                                        </tr>
                                        <tr>
                                            <th>Do you plan to continue this social start-up in any fashion? Why?</th>
                                            <td><textarea className="form-control" name="topic_name" autoComplete="off" value={formDataGeneral.topic_name} onChange={(e) => updateInput('General', 'object', 'input', 'topic_name', e.target.value)} rows={5}/></td>
                                        </tr>
                                        <tr>
                                            <th>How would this social start-up experience help you in your future?</th>
                                            <td><textarea className="form-control" name="topic_name" autoComplete="off" value={formDataGeneral.topic_name} onChange={(e) => updateInput('General', 'object', 'input', 'topic_name', e.target.value)} rows={5}/></td>
                                        </tr>
                                        <tr>
                                            <th>What is leadership according to you, after having implemented this project?</th>
                                            <td><textarea className="form-control" name="topic_name" autoComplete="off" value={formDataGeneral.topic_name} onChange={(e) => updateInput('General', 'object', 'input', 'topic_name', e.target.value)} rows={5}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['General']
                                ?
                                    isBtnDisabled['General']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'General')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['General'].length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    <p>Please enter/modify following Data:</p>
                                    <ol>{}
                                    {errorData['General'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* Links */}
                <Accordion className="pt-3" defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Your social start-up team details (if you are alone, enter your details here)</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table">
                                    <thead className="text-center">
                                        <tr>
                                            <th style={{width: "10px"}}></th>
                                            <th>Link</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                    {formDataEdu.map((edu_d, edu_index) => (    
                                        <tr key={`education${edu_index}`}>
                                            <td><b>{edu_index+1}</b></td>
                                            <td><input className="form-control" name={`edu_clg_${edu_index}`} value={edu_d.institute_name} onChange={(e) => updateInput('Edu', 'array', 'input', 'institute_name', e.target.value, 'array', 'education', edu_index)}/></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <p className="text-end"><button className="btn btn-outline-primary me-1" onClick={(e) => addMoreRow(e, 'education')}>Add More++</button></p>
                                </div>
                                <p className="text-center mb-2">
                                    {!isProcess['Edu']
                                    ?
                                        isBtnDisabled['Edu']
                                        ?
                                            <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                        :
                                            <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'Edu')}>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                    }
                                
                                <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['Edu'].length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    <p>Please enter/modify following Data:</p>
                                    <ol>
                                    {errorData['Edu'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* Pics */}
                <Accordion className="pt-3" defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-edit"></i>&nbsp;Upload a few pictures from your social start-up project</Accordion.Header>
                        <Accordion.Body>
                            <form autoComplete="off">
                                <div className="table-responsive">
                                <table className="border-0 table-bordered table mb-4">
                                    <tbody>
                                        <tr>
                                            <td>
                                                {previewPicImg !== null &&
                                                    <>
                                                    <div className="d-flex">
                                                    <Cropper
                                                        src={previewPicImg.src}
                                                        style={{ height: 200, width: 200 }}
                                                        // Cropper.js options
                                                        initialAspectRatio={1 / 1}
                                                        aspectRatio={1/1}
                                                        guides={false}
                                                        crop={onCrop}
                                                        ref={cropperRef}
                                                        dragMode="move"
                                                        // cropBoxResizable={false}
                                                        background={false}
                                                        // minContainerWidth={"100%"}
                                                        minContainerWidth={200}
                                                        zoomable={false}
                                                        highlight={false}
                                                    />
                                                    <div className="ms-4">
                                                        <img src={croppedImg} className="img-thumbnail cropper-preview-img" alt={formDataGeneral.first_name} />
                                                    </div>
                                                    </div>
                                                    </>
                                                }
                                                <div className="upload-img text-center">
                                                    <div className="m-3">
                                                        {/* <img src={require("./../../assets/images/user.png")} alt="User Logo" className="img-thumbnail" /> */}
                                                        {/* {formDataGeneral.logo ? */}
                                                        {previewPicImg !== null
                                                            ?
                                                            //  <img src={previewPicImg.src} alt={formDataGeneral.first_name} className="img-thumbnail cropper-preview-img" />
                                                            <></>
                                                            :
                                                            <img src={formDataGeneral.logo} alt={formDataGeneral.first_name} className="img-thumbnail cropper-preview-img" />
                                                        }
                                                    
                                                        {/* :
                                                            <img src={previewPicImg !== null ? previewPicImg.src : picIcon} alt={formDataGeneral.first_name} className="img-thumbnail" />
                                                        } */}
                                                    </div>
                                                    
                                                    <div className="btn btn-light btn-outline-primary">
                                                        <span>Upload Your photo</span>
                                                        <input type="file" className="logo-input" name="profile_pic" onChange={(e) => previewProfilePic(e, 'Pic')} accept="image/x-png,image/jpg,image/jpeg" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <p className="text-center mb-2">
                                {!isProcess['General']
                                ?
                                    isBtnDisabled['General']
                                    ?
                                        <button className="btn btn-primary btnn-disabled" type="submit" disabled>Submit</button>
                                    :
                                        <button className="btn btn-primary bg-color-blue" type="submit" onClick={(e) => editPortfolio(e, 'General')}>Submit</button>
                                :
                                    <button className="btn btn-primary bg-color-blue" disabled type="submit"><img className="loader" src={require('../../assets/images/loader.gif')} alt="Loader" /> Submitting...</button>
                                }
                                
                                <a href={`/social-start-up/details/${props.data.ref_id}/${props.data.url}`} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue ms-4" type="button">View Social Start-up</button></a>
                                </p>
                            </form>
                            {/* Error */}
                            <div className="col-md-12 col-12 col-sm-12">
                            {errorData['General'].length > 0 && (
                                <div className="alert alert-danger" role="alert">
                                    <p>Please enter/modify following Data:</p>
                                    <ol>{}
                                    {errorData['General'].map((error, error_index) => (
                                        <li key={`error_${error_index}`}>{error}</li>
                                    ))}
                                    </ol>
                                </div>
                            )}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            </>
        }
        </>
    )    
}

export default StepSixForm;