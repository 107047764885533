import React from "react";
import Fancybox from '../../components/plugin/fancyox';

function Gallery3(){
    return (
        <Fancybox
            // Sample options
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/6/3ae2b57b-356d-4488-9f94-4aa511466785_konferans_Blacksea_25-1-500x333.jpg")} className="fancybox image gallery-item" title="16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-2"><img src={require("../../assets/images/main/event/6/3ae2b57b-356d-4488-9f94-4aa511466785_konferans_Blacksea_25-1-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/6/5d2c0932-4595-4b4f-be70-0bb00ee52e54_Konferans_blacsea-2_13-500x333.jpg")} className="fancybox image gallery-item" title="16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-2"><img src={require("../../assets/images/main/event/6/5d2c0932-4595-4b4f-be70-0bb00ee52e54_Konferans_blacsea-2_13-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/6/9e1251ef-c610-4622-bd1f-bee029cb3249_Konferans_blacsea-2_23-500x333.jpg")} className="fancybox image gallery-item" title="16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-2"><img src={require("../../assets/images/main/event/6/9e1251ef-c610-4622-bd1f-bee029cb3249_Konferans_blacsea-2_23-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/6/68a4311f-d68c-4c47-b5bf-01c80036f988_Konferans_blacsea-2_15-500x333.jpg")} className="fancybox image gallery-item" title="16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-2"><img src={require("../../assets/images/main/event/6/68a4311f-d68c-4c47-b5bf-01c80036f988_Konferans_blacsea-2_15-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/6/cd24284a-332c-4baa-94ba-63a6a5202969_Konferans_blacsea_25-500x333.jpg")} className="fancybox image gallery-item" title="16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-2"><img src={require("../../assets/images/main/event/6/cd24284a-332c-4baa-94ba-63a6a5202969_Konferans_blacsea_25-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/6/ef306f84-a38c-4392-bde3-de62209751da_Konferans_blacsea-2_14-500x333.jpg")} className="fancybox image gallery-item" title="16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-2"><img src={require("../../assets/images/main/event/6/ef306f84-a38c-4392-bde3-de62209751da_Konferans_blacsea-2_14-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/6/f1c98021-bce2-4aa1-9530-21270b307daf_Konferans_blacsea-2_12-500x333.jpg")} className="fancybox image gallery-item" title="16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-2"><img src={require("../../assets/images/main/event/6/f1c98021-bce2-4aa1-9530-21270b307daf_Konferans_blacsea-2_12-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/6/f9941e1f-72fa-43e6-bda7-7fed6bbcf4ff_Konferans_blacsea-2-500x333.jpg")} className="fancybox image gallery-item" title="16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-2"><img src={require("../../assets/images/main/event/6/f9941e1f-72fa-43e6-bda7-7fed6bbcf4ff_Konferans_blacsea-2-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/6/f408384f-8a8d-405a-8dea-de5535cbf8c6_konferans_Blacksea_8-500x333.jpg")} className="fancybox image gallery-item" title="16th Board of Directors and General Assembly Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea" rel="gallery-2"><img src={require("../../assets/images/main/event/6/f408384f-8a8d-405a-8dea-de5535cbf8c6_konferans_Blacksea_8-500x333.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
        </Fancybox>
    )
}

export default Gallery3;