import React from "react";
import Fancybox from '../../components/plugin/fancyox';

function Gallery4(){
    return (
        <Fancybox
            // Sample options
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/0c9ba151-4182-4564-8d40-29f828f41fc4_DSC_0225-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/0c9ba151-4182-4564-8d40-29f828f41fc4_DSC_0225-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/6fef831c-cc4b-4a0f-83fc-092a50bdf73c_DSC_0966-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/6fef831c-cc4b-4a0f-83fc-092a50bdf73c_DSC_0966-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/8e3255e3-33e8-4bb0-b9d0-de69b64f6fd3_DSC_0094-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/8e3255e3-33e8-4bb0-b9d0-de69b64f6fd3_DSC_0094-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/23d02a1f-2fd7-40e8-b6d8-1b8b8a56718e_DSC_0988-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/23d02a1f-2fd7-40e8-b6d8-1b8b8a56718e_DSC_0988-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/33d5f8a7-0750-45c3-8d5d-2d4fe5d4532d_DSC_0077-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/33d5f8a7-0750-45c3-8d5d-2d4fe5d4532d_DSC_0077-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/69da1af9-2d16-4a09-8db0-5f51edab11f8_DSC_0223-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/69da1af9-2d16-4a09-8db0-5f51edab11f8_DSC_0223-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/80b93ff3-21b9-4edd-b29c-3cef0a9e7ac2_DSC_0936-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/80b93ff3-21b9-4edd-b29c-3cef0a9e7ac2_DSC_0936-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/363b9f7d-f7df-49f7-896c-decc9c79cc32_DSC_0051-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/363b9f7d-f7df-49f7-896c-decc9c79cc32_DSC_0051-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/7981ad82-b727-4c27-b87f-c129b9bb45bb_DSC_0221-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/7981ad82-b727-4c27-b87f-c129b9bb45bb_DSC_0221-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/32157052-8524-497b-8038-24370b8d86f7_DSC_0023-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/32157052-8524-497b-8038-24370b8d86f7_DSC_0023-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/97550368-fe0f-44f1-99f2-598afe0aa2b7_DSC_0136-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/97550368-fe0f-44f1-99f2-598afe0aa2b7_DSC_0136-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/bcc4e60f-5e6f-4ca0-984a-1c45c16a4a32_DSC_0931-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/bcc4e60f-5e6f-4ca0-984a-1c45c16a4a32_DSC_0931-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/cd8b3be6-d1f2-4e70-ab71-dc7061be6b68_DSC_0913-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/cd8b3be6-d1f2-4e70-ab71-dc7061be6b68_DSC_0913-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/dca7b3f4-099a-4ad0-9f19-aa38528b6e91_DSC_0965-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/dca7b3f4-099a-4ad0-9f19-aa38528b6e91_DSC_0965-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
            <a data-fancybox="gallery" href={require("../../assets/images/main/event/7/edaed590-6a3b-45fe-b474-f8aea8320709_DSC_0012-500x332.jpg")} className="fancybox image gallery-item" title="The Anniversary General Assembly and 12th Board Meeting of the International Fund for Cooperation and Partnership of the Black Sea and Caspian Sea. Istanbul, Turkey" rel="gallery-3"><img src={require("../../assets/images/main/event/7/edaed590-6a3b-45fe-b474-f8aea8320709_DSC_0012-500x332.jpg")} className="attachment-medium size-medium" alt="" width="500"/></a>
        </Fancybox>
    )
}

export default Gallery4;