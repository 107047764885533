import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event5(){
    SetMetaData("Resolution of 16th Board of Directors meeting", "", "");

    return (
        <div className="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
        <div className="site-content" id="content">
           <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
              <main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                 <article id="post-511" className="post-511 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings" itemType="https://schema.org/CreativeWork" itemScope="">
                    <div className="inside-article">
                       <header className="entry-header" aria-label="Content">
                          <h1 className="entry-title title" itemProp="headline">Resolution of 16th Board of Directors meeting</h1>
                          {/* <div className="entry-meta">
                             <span className="posted-on"><time className="updated" dateTime="2018-08-12T10:14:36+03:00" itemProp="dateModified">August 12, 2018</time><time className="entry-date published" dateTime="2017-06-20T08:13:15+03:00" itemProp="datePublished">June 20, 2017</time></span> 		
                          </div> */}
                       </header>
                       <div className="featured-image  page-header-image-single ">
                          <img width="210" height="130" src={require("../../assets/images/main/event/eskilogo.png")} className="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                       </div>
                       <div className="entry-content" itemProp="text">
                          <p dir="ltr"><strong>QUORUM FOR THE BOARD MEETING HAS BEEN ESTABLISHED.</strong></p>
                          <p dir="ltr">FOLLOWING THE COMPREHENSIVE DISCUSSION OF THE AGENDA, THE BOARD OF DIRECTORS HAS ADOPTED THE FOLLOWING RESOLUTIONS:</p>
                          <p dir="ltr"><strong>QUESTION </strong>№ 1: Consideration of appeals and approval of new full-fledged members (physical and juridical persons) in BSCSIF</p>
                          <p dir="ltr">1. Ms. Nina Karelina (Russian Federation)</p>
                          <p dir="ltr">2. Mr. Vladimir Pligin (Russian Federation)</p>
                          <p dir="ltr">3. Mr. Drasko Lazovic (Montenegro)</p>
                          <p dir="ltr">4. Ms. Esma Sirbubalo (Bosnia and Herzegovina)</p>
                          <p dir="ltr">5. Ms. Ljubica Vasic (Serbia)</p>
                          <p dir="ltr">6. Mr. Kydyrali Darkhan Kuandikovich (Kazakhstan)</p>
                          <p dir="ltr">7. Ms. Rene-Elisabeta Iliescu (Romania)</p>
                          <p dir="ltr"><strong>RESOLUTION</strong>: The abovementioned nominees (physical and juridical persons) are approved as full members of the BSCSIF. Voted – unanimously.</p>
                          <p dir="ltr"><strong>QUESTION </strong>№ 2: Voting on the admission of the members of the BSCSIF (in full composition) to the newly established organization</p>
                          <p dir="ltr"><strong>RESOLUTION</strong>:&nbsp;The members of the BSCSIF are accepted en bloc into the International Foundation for Sustainable Peace and Development.</p>
                          <p dir="ltr">Voted – unanimously.</p>
                          <p dir="ltr"><strong>QUESTION </strong>№ 3: Resignation of the Secretary General of the International Foundation. Appointment of the Secretary General of the International Foundation in connection with its renaming.</p>
                          <p dir="ltr"><strong>RESOLUTION</strong>: Resignation of Prof. Dr. Eldar Hasanov, &nbsp; despite his strong request, has not been accepted by the members of the Board of Directors due to the fact that he exercised finely the duties of the Secretary General entrusted to him. Mr. Eldar Hasanov has been reelected and reappointed to the post of Secretary General.</p>
                          <p dir="ltr">Voted – unanimously.</p>
                          <p dir="ltr"><strong>QUESTION </strong>№ 4: Appointment of the Executive Director of the International Foundation in connection with its renaming.</p>
                          <p dir="ltr">Ms. Elmira Uygun has been appointed to the post of Executive Director of the International Foundation.</p>
                          <p dir="ltr">Voted – unanimously.</p>
                          <p dir="ltr"><strong>QUESTION </strong>№ 5: Appointment of the head and members of the Audit Committee of the International Foundation in connection with its renaming.</p>
                          <p dir="ltr">Mr. Bahruz Asadbayli has been appointed as a head of the Audit Committee, Ms. Marcela Curmei and Ms. Rene-Elisabeta Iliescu have been appointed as members of the committee.</p>
                          <p dir="ltr">Voted – unanimously.</p>
                          <p dir="ltr"><strong>QUESTION </strong>№ 6: Bringing requisite details, symbols (logo, flag) and structure of the organization in accordance with its newly approved name.</p>
                          <p dir="ltr"><strong>RESOLUTION</strong>: It was decided 1) to instruct the Secretariat, within the shortest &nbsp;possible time, to prepare, coordinate and inform the members of the Board of Directors about the logo of the International Foundation for Sustainable Peace and Development 2) to request lawyer Mr. Roman Bradu immediately after the meeting, to launch, while maintaining continuity, registration procedure and bring into sync the corresponding documentation of the International Foundation for sustainable peace and development; 3) to instruct Secretariat and heads of branches of the International Foundation also to bring into sync documentations, stamps, letterforms and symbols of the organization with its changed name.</p>
                          <p dir="ltr">Voted – unanimously.</p>
                          <p dir="ltr"><strong>QUESTION </strong>№ 7: Discussion of the results of the audit of the triennial financial activities of the BSCSIF and election of new members of the Audit Commission of the International Foundation. Report by the member of the International Foundation Mrs. Alev Balgi.</p>
                          <p dir="ltr"><strong>RESOLUTION</strong>: The results of the audit have been approved. It was decided to instruct the Executive Director and newly elected Audit Committee to strengthen control over financial activity of the International Foundations, including receipts and expenditures as intended, while President of the International Foundation Mr. Dimitar Kostov was entrusted with supervision over implementation of this decision.</p>
                          <p dir="ltr">Voted – unanimously.</p>
                       </div>
                    </div>
                 </article>
            
                 <a className="singlepostlinkback" href="/board-meetings">← Back to the list of all Board Meetings</a>		
              </main>
           </div>
        </div>
     </div>
    )
}

export default Event5;