import React from "react";
import {SetMetaData} from './../../helper/page-title';
import './../../assets/css/pages/event.css';

function Event3(){
    SetMetaData("IFSPD 17th Extended Board of Directors and General Assembly Meetings", "", "");

    return (
        <div className="site hfeed grid-parent single-post post-image-below-header post-image-aligned-left" id="page">
            <div className="site-content" id="content">
            <div className="content-area grid-parent mobile-grid-100 grid-100 tablet-grid-100" id="primary">
                <main className="site-main container-fluid container-lg pe-lg-5 ps-lg-5" id="main">
                    <article id="post-5022" className="post-5022 post type-post status-publish format-standard has-post-thumbnail hentry category-board-meetings category-news" itemType="https://schema.org/CreativeWork" itemScope="">
                        <div className="inside-article">
                        <header className="entry-header" aria-label="Content">
                            <h1 className="entry-title title" itemProp="headline">IFSPD 17th Extended Board of Directors and General Assembly Meetings</h1>
                            {/* <div className="entry-meta">
                                <span className="posted-on"><time className="updated" dateTime="2022-01-11T11:15:00+02:00" itemProp="dateModified">January 11, 2022</time><time className="entry-date published" dateTime="2019-03-10T09:02:48+02:00" itemProp="datePublished">March 10, 2019</time></span> 		
                            </div> */}
                        </header>
                        <div className="featured-image  page-header-image-single ">
                            <img width="1024" height="683" src={require("../../assets/images/main/event/3/Meeting-024-1024x683.jpg")} className="attachment-large size-large" alt="" decoding="async" itemProp="image" loading="lazy" />
                        </div>
                        <div className="entry-content" itemProp="text">
                            <p className="text-center"><strong>17th Extended Board of Directors and General Assembly Meetings of the International Foundation for Sustainable Peace and Development (IFSPD)</strong></p>
                            <p><strong>&nbsp;</strong></p>
                            {/* <p>On 3 March 2019, the 17th Extended Board of Directors and General Assembly Meetings of the International Foundation for Sustainable Peace and Development (IFSPD) were held in Istanbul (Turkey).</p> */}
                            <p>The 17th Extended Board of Directors and General Assembly Meetings of the International Foundation for Sustainable Peace and Development (IFSPD) were held in Istanbul (Turkey).</p>
                            <p>The event was attended by the Special Adviser to the President of the Republic of Turkey and Presidential Press Secretary Mr. Ibrahim Kalin, distinguished guests from countries represented in the International Foundation, UNDP Turkey Hub representative Ms. Ekaterina Paniklova, representatives of influential international organizations, non-governmental organizations, as well as the media.</p>
                            <p>The Board of Directors and General Assembly Meetings were chaired by the President of the International Foundation, Mr. Dimitar Kostov, Ph.D., who made a speech on the activities of the International Foundation over the past period, and shared his vision regarding the future development of the organization. In his report, the IFSPD President noted that 2018 was successful and fruitful for our international organization; IFSPD organized and participated in many conferences, such as The International Conference in Honor of the Centenary of the Legendary Nelson Mandela, Conference on Eurasian Economic Relations<em>, </em>VII International Innovation and Industrial Forum: Technological Breakthrough<em>,</em> International Conference on Conflict Resolution<em>, </em>International Forum <em>Russian-Turkic World</em>: Responding to Global Challenges, International Conference: European Identity and others.</p>
                            <p>In addition, Mr. Kostov, Ph.D. emphasized that the International Foundation conducted a number of scientific studies in 2018, such as<em> The Development of Information Technology and its Influence on Sustainable Development</em>, <em>Multiculturalism as a Necessary Element for the Cultural Component of the Project One Belt, One Road</em>, <em>Abuse of the Principle of Self-Determination of Peoples and the Reaction of the International Community, Conflict Resolution</em>.</p>
                            <p>The IFSPD President also noted that the leadership and members of our organization&nbsp; have successfully implemented around 100 strategic research projects within a span of 10 years.</p>
                            <p>In the previous year, IFSPD provided assistance to families and young talents in need, as well as humanitarian help to refugees.</p>
                            <p>During the Board of Directors Meeting, individuals and legal entities from different countries were adopted by voting as new fully-fledged IFSPD members.</p>
                            <p>Research projects, which were proposed for implementation aiming at promoting peace, security and sustainable development were discussed at the Meetings, along with IFSPD topical development issues. During the Meetings, it was also decided to continue paying particular attention to the implementation of research projects, as one of the main activities of this think tank; to attract experienced international experts, consultants and specialists to IFSPD strategic research; and to train and improve experts from countries represented in the International Foundation for Sustainable Peace and Development.</p>
                            <p>The members of the International Foundation made speeches on issues in the field of peace, dialogue, education, science, humanitarian aid, innovation, issues of the quality expansion of the Foundation, the activities of its branches, etc.</p>
                            <p>The Meetings were successfully concluded, and the Istanbul Declaration on Peace, Security and New Threats and Challenges was adopted.</p>
                            {/* <p>On 4 March 2019, the IFSPD held a gala dinner in honor of the organization’s tenth anniversary, which began with an introductory speech by one of the founders of the International Foundation for Sustainable Peace and Development (IFSPD), Mr. Chingiz Abdullayev, Ph.D.In his speech, he spoke about the reasons for the creation, the path of the development, strengthening, achievements and accomplishments of the Foundation over the past 10 years. At the end, he congratulated all of those who were present at the 10th anniversary, and thanked everyone for their active participation and contribution to the organization’s work.&nbsp; At the opening of the Gala dinner the Deputy Minister of Foreign Affairs of Turkey Mr. Yavuz Selim Kiran also made his speech.</p> */}
                            <p>The IFSPD held a gala dinner in honor of the organization’s tenth anniversary, which began with an introductory speech by one of the founders of the International Foundation for Sustainable Peace and Development (IFSPD), Mr. Chingiz Abdullayev, Ph.D.In his speech, he spoke about the reasons for the creation, the path of the development, strengthening, achievements and accomplishments of the Foundation over the past 10 years. At the end, he congratulated all of those who were present at the 10th anniversary, and thanked everyone for their active participation and contribution to the organization’s work.&nbsp; At the opening of the Gala dinner the Deputy Minister of Foreign Affairs of Turkey Mr. Yavuz Selim Kiran also made his speech.</p>
                            <p>As part of this event, IFSPD Jubilee awards were granted to the founders, distinguished members, and partners of the Foundation for their special contribution to the organization’s activities.</p>
                            <p>&nbsp;</p>
                            <div id="gallery-1" className="gallery galleryid-5022 gallery-columns-4 gallery-size-medium">
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Gala-Dinner-003A-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/3/Gala-Dinner-003A-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" fetchpriority="high" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Gala-Dinner-005A-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/3/Gala-Dinner-005A-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Gala-Dinner-011A-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/3/Gala-Dinner-011A-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async"/></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-049-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-049-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-048-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-048-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-046-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-046-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-035-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-035-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-034-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-034-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-033-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-033-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-032-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-032-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-030-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-030-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-029-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-029-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-020-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-020-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-021-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-021-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-022-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-022-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-023-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-023-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-024-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-024-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-028-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-028-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-019-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-019-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-018-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-018-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-017-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-017-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-016-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-016-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-013-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-013-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-012-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-012-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-011-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-011-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-002-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/3/Meeting-002-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-003-500x332.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="332" src={require("../../assets/images/main/event/3/Meeting-003-500x332.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-004-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-004-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-005-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-005-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-006-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-006-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-007-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-007-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon landscape">
                                    <a href={require("../../assets/images/main/event/3/Meeting-008-500x333.jpg")} className="fancybox image" rel="gallery-0"><img width="500" height="333" src={require("../../assets/images/main/event/3/Meeting-008-500x333.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-009-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-009-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                                <figure className="gallery-item">
                                    <div className="gallery-icon portrait">
                                    <a href={require("../../assets/images/main/event/3/Meeting-010-333x500.jpg")} className="fancybox image" rel="gallery-0"><img width="333" height="500" src={require("../../assets/images/main/event/3/Meeting-010-333x500.jpg")} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy" /></a>
                                    </div>
                                </figure>
                            </div>
                            <p>&nbsp;</p>
                        </div>
                        </div>
                    </article>
                    
                    <a className="singlepostlinkback" href="/board-meetings">← Back to the list of all Board Meetings</a>		
                </main>
            </div>
            </div>
        </div>
    )
}

export default Event3;